import * as React from 'react';

import { LineChart as CustomLineChart } from '@mui/x-charts/LineChart';
import { format } from 'd3-format';

import { FONT_SIZE } from '../../../constants/Constants';

const LineChart = ({ data, xLabels, isAmount = false }) => (
  <CustomLineChart
    xAxis={[{ data: xLabels }]}
    series={data}
    height={300}
    slotProps={{
      legend: {
        position: {
          vertical: 'top',
          horizontal: 'left',
        },
        labelStyle: {
          fontSize: FONT_SIZE,
          fontWeight: 400,
        },
        itemMarkWidth: 12,
        itemMarkHeight: 12,
        markGap: 5,
        itemGap: 10,
      },
    }}
    yAxis={[
      {
        valueFormatter: (val) => (isAmount ? format('.0s')(val) : val),
      },
    ]}
  />
);
export default LineChart;
