import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';

import AttachEmailOutlinedIcon from '@mui/icons-material/AttachEmailOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import HandymanOutlinedIcon from '@mui/icons-material/HandymanOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { SECONDARY } from '../../constants/Colors';
import { exportToCSV } from '../../constants/common';
import {
  CUSTOMER_QUOTATION_STATUS,
  DATE_TIME_RANGE_FORMAT_DEFAULT,
  DEFECT_STATUS,
  PENDING,
  SUBMIT_TO_CUSTOMER_STATUS,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import { getProperty, getPropertyList } from '../../store/property/api';
import {
  getQuotesList,
  getQuotesPropertyDropdownList,
  getQuotesTypeDropdownList,
} from '../../store/quotes/api';
import { resetQuoteList } from '../../store/quotes/reducer';
import { patchDefectQuotationStatus } from '../../store/workOrder/api';
import { resetPatchDefectQuoteStatus } from '../../store/workOrder/reducer';
import {
  formatDateAPI,
  formatPriceWithDecimalValue,
  formatStatus,
  formattedDate,
  loggedInUserDetail,
} from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import QuoteTypeLabel from '../CommonComponents/QuoteTypeLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import PreviewDefectQuoteInvoice from './PreviewDefectQuoteInvoice';
import generatePDF from './QuotePDF';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  properties: null,
  quoteType: null,
  status: null,
  dateRange: {
    fromDueDate: null,
    toDueDate: null,
    fromQuoteDate: null,
    toQuoteDate: null,
  },
};

const QuotesList = () => {
  const { status } = useParams();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { dashboardFilter } = useOutletContext();

  const { quoteList, total } = useSelector((state) => state.quotes.get);
  const { data: quoteStatusList, isLoading: quoteStatusListLoading } =
    useSelector((state) => state.quotes.quotesStatusDropdownList);
  const { data: quotePropertyList, isLoading: quotePropertyListLoading } =
    useSelector((state) => state.quotes.quotesPropertyDropdownList);
  const { loading: isPropertyLoading, propertyList } = useSelector(
    (state) => state.property
  );

  const { isLoading: isQuotePatchDataLoading, data: quotePatchData } =
    useSelector((state) => state.workOrder.patchDefectQuoteStatus);

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...dashboardFilter,
  });
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [propertyDetail, setPropertyDetail] = useState([]);
  const [quoteDetail, setQuoteDetail] = useState([]);
  const [propertyDetailsList, setPropertyDetailsList] = useState([]);
  const [patchQuoteStatus, setPatchQuoteStatus] = useState('');
  const [buttonHide, setButtonHide] = useState(false);
  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );
  const [isPropertyDetailLoading, setIsPropertyDetailLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [patchQuoteStatusBtnDisabled, setPatchQuoteStatusBtnDisabled] =
    useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const { quotesDropdownLoading, quotesDropdownData } = getDropdownListHook({
    reducerName: 'quotes',
    dropdownListName: 'quotesTypesDropdownList',
    labelName: 'display_name',
    valueName: 'name',
  });

  const quotesStatusList = getDropdownDataHook({
    data: quoteStatusList?.data,
    labelName: 'display_name',
    valueName: 'name',
  });
  const quotesPropertyList = getDropdownDataHook({
    data: propertyDetailsList,
    labelName: 'property_name',
    valueName: 'uuid',
  });

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: 'Quote ID',
        flex: 1,
        sortable: true,
      },
      {
        field: 'property_name',
        headerName: 'Property Name',
        flex: 1,
        sortable: false,
      },
      {
        field: 'quotation_type',
        headerName: 'Type',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatType(row.quotation_type),
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: 'quote_date',
        headerName: 'Quote Date',
        flex: 1,
        sortable: false,
      },
      {
        field: 'due_date',
        headerName: 'Due Date',
        flex: 1,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 1,
        renderCell: ({ row }) => `${formatPriceWithDecimalValue(row.amount)}`,
      },
      {
        field: 'edit',
        headerName: 'Actions',
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setOpen(true);
              setIsPropertyDetailLoading(true);
              dispatch(getProperty(row?.property_uuid))
                .then((res) => setPropertyDetail(res.payload?.data[0]))
                .finally(() => setIsPropertyDetailLoading(false));
              setQuoteDetail(row);
              setButtonHide(
                row.status === SUBMIT_TO_CUSTOMER_STATUS ? false : true
              );
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const updateQuoteStatus = (status) => {
    setPatchQuoteStatus(status);
    setPatchQuoteStatusBtnDisabled(true);
    dispatch(
      patchDefectQuotationStatus({
        work_order_uuid: quoteDetail?.work_order_uuid,
        quotation_uuid: quoteDetail?.uuid,
        data: { status: status },
      })
    ).finally(() => {
      setPatchQuoteStatusBtnDisabled(false);
    });
  };

  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: loggedInUserDetail()?.profile_uuid,
    };

    dispatch(editCompany(loggedInUserDetail()?.company_uuid));
    dispatch(getPropertyList(req));
  }, []);

  useEffect(() => {
    setPropertyDetailsList(propertyList?.data?.data);
  }, [propertyList]);

  useEffect(() => {
    dispatch(getQuotesTypeDropdownList());
    dispatch(getQuotesPropertyDropdownList());
    dispatch(resetPatchDefectQuoteStatus());

    return () => {
      dispatch(resetQuoteList());
    };
  }, []);

  useEffect(() => {
    if (isQuotePatchDataLoading !== null && !isQuotePatchDataLoading) {
      if (quotePatchData?.data?.length > 0 && patchQuoteStatus !== '') {
        setOpen(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t(`attributes.work_order.${patchQuoteStatus}`),
          })
        );
      }
    }
  }, [quotePatchData, isQuotePatchDataLoading]);

  // Function to fetch data based on search, pagination, and filter
  const getAllQuoteList = useCallback(() => {
    setSelectedRows(null);
    setIsDataLoading(true);
    let status_to_pass = filters.status?.value;

    if (status_to_pass === PENDING) {
      status_to_pass = SUBMIT_TO_CUSTOMER_STATUS;
    }

    dispatch(
      getQuotesList({
        order: order,
        orderBy: orderBy === 'id' ? 'created_at' : orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        fromDueDate: filters.dateRange?.fromDueDate,
        toDueDate: filters.dateRange?.toDueDate,
        status: status_to_pass,
        type: filters.quoteType?.value,
        propertyUUID: filters.properties?.value,
        fromQuoteDate: filters.dateRange?.fromQuoteDate,
        toQuoteDate: filters.dateRange?.toQuoteDate,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    quotePatchData,
    refresh,
  ]);

  useEffect(() => {
    if (status) {
      setFilters((pre) => ({
        ...pre,
        status: quotesStatusList?.find((s) => s.value === status),
      }));
      setIsFilterComponentVisible(true);
    }
  }, []);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllQuoteList, 500), [
    getAllQuoteList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const findProperty = (property_uuid) => {
    const property = propertyDetailsList?.find(
      (prop) => prop.uuid === property_uuid
    );

    return property;
  };

  const rows = quoteList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.property_name = findProperty(
      item?.property_uuid
    )?.property_name;

    updatedItem.id = `Q-${item?.id < 10 ? '0' + item?.id : item?.id}`;
    updatedItem.property_uuid = item?.property_uuid;
    updatedItem.quote_date = formattedDate(item.quote_date);
    updatedItem.due_date = formattedDate(item.due_date);
    updatedItem.amount = item?.quotation_items.reduce(
      (sum, item) => sum + item?.sell_price * item?.qty,
      0
    );
    updatedItem.created_at = formatDateAPI(
      updatedItem.created_at,
      DATE_TIME_RANGE_FORMAT_DEFAULT
    );

    return updatedItem;
  });

  const formatType = (type) => {
    const lowerCaseStatus = type?.toLowerCase();

    if (lowerCaseStatus === DEFECT_STATUS) {
      return (
        <QuoteTypeLabel
          label="Defect"
          color="#C54036"
          backgroundColor="#c540361f"
          icon={
            <WarningAmberOutlinedIcon
              sx={{ height: '14px', width: '14px', color: '#C54036' }}
            />
          }
        />
      );
    }
    if (lowerCaseStatus === 'service') {
      return (
        <QuoteTypeLabel
          label="Service"
          color="#454545"
          backgroundColor="#4545452b"
          icon={
            <HandymanOutlinedIcon
              sx={{ height: '14px', width: '14px', color: '#454545' }}
            />
          }
        />
      );
    }
  };

  const resetFilter = () => {
    setFilters({ defaultFilters });
    setSearchText('');
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.properties ||
          filters.quoteType ||
          filters.status ||
          filters.dateRange?.fromDueDate ||
          filters.dateRange?.toDueDate ||
          filters.dateRange?.fromQuoteDate ||
          filters.dateRange?.toQuoteDate
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder="Property Name"
            options={quotesPropertyList}
            value={filters?.properties}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, properties: newValue }))
            }
            width="190px"
            isLoadingData={quotePropertyListLoading}
          />
          <Autocomplete
            placeholder="Quote Type"
            options={quotesDropdownData}
            value={filters?.quoteType}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, quoteType: newValue }))
            }
            width="190px"
            isLoadingData={quotesDropdownLoading}
          />
          <Autocomplete
            placeholder="Status"
            options={quotesStatusList}
            value={filters?.status}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, status: newValue }))
            }
            width="190px"
            isLoadingData={quoteStatusListLoading}
          />
          <CustomDateRangePicker
            placeholder={t('attributes.invoice.due_date')}
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromDueDate: val[0], toDueDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromDueDate: null, toDueDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromDueDate}
            toDate={filters.dateRange?.toDueDate}
            placement="bottomEnd"
          />
          <CustomDateRangePicker
            placeholder={t('attributes.invoice.quote_date')}
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromQuoteDate: val[0], toQuoteDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromQuoteDate: null, toQuoteDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromQuoteDate}
            toDate={filters.dateRange?.toQuoteDate}
            placement="bottomEnd"
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.QUOTE_LIST,
                    EXPORT_FILE_NAMES.QUOTE_LIST,
                    COLUMNS_TO_MAP.QUOTE_LIST
                  );
                }
              }}
            />
            <CustomButton
              text="Email a Link"
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AttachEmailOutlinedIcon />}
              onClick={() => {}}
            />
          </>
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={open}
          title={`${quoteDetail?.id ? quoteDetail?.id : '-'}`}
          onClose={() => {
            setOpen(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={false}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(quoteDetail, propertyDetail, company);
              }}
            />,
            ...(!buttonHide
              ? [
                  <CustomButton
                    text="Differed"
                    color="inherit"
                    disabled={true}
                    startIcon={<DifferenceOutlinedIcon />}
                    onClick={() => {}}
                  />,
                  <CustomButton
                    text="Rejected"
                    color="inherit"
                    disabled={patchQuoteStatusBtnDisabled}
                    startIcon={<CancelOutlinedIcon />}
                    onClick={() => {
                      updateQuoteStatus(CUSTOMER_QUOTATION_STATUS.REJECTED);
                    }}
                  />,
                  <CustomButton
                    text="Approve"
                    disabled={patchQuoteStatusBtnDisabled}
                    startIcon={<CheckCircleOutlineIcon />}
                    onClick={() => {
                      updateQuoteStatus(CUSTOMER_QUOTATION_STATUS.APPROVED);
                    }}
                  />,
                ]
              : []),
          ]}
          width={653}
        >
          <PreviewDefectQuoteInvoice
            companyData={company}
            propertyData={propertyDetail}
            quoteData={quoteDetail}
            isPropertyLoading={isPropertyDetailLoading}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default QuotesList;
