import React, { useCallback, useEffect, useState } from 'react';
import { Views } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Box, Paper } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { COMMON_ERROR_MESSAGE, LEAVE } from '../../constants/Constants';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  postAssignWOtoTech,
  unassignedWOtoTech,
} from '../../store/scheduleBoard/api';
import {
  resetAssignWOtoTech,
  resetScheduleBoardWOList,
  resetUnassignedWOtoTech,
} from '../../store/scheduleBoard/reducer';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getZonesDropdownList } from '../../store/zones/api';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import './../../assets/schedulerBoard.scss';
import ScheduleBoardCalendar from './ScheduleBoardCalendar';
import ScheduleBoardControls from './ScheduleBoardControls';
import { ScheduleBoardWOList } from './ScheduleBoardWOList';
import { WorkOrderPopUp } from './WorkOrderPopUp';

const defaultValuesForWorkOrders = {
  startDate: null,
  endDate: null,
  selectWorkOrder: null,
  title: null,
};

const ScheduleBoard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'zone',
    valueName: 'zone',
  });

  // Get the technicians list from Redux store
  const { data: technicianList } = useSelector(
    (state) => state.technician.technicianNamesDropdownList
  );

  const [workOrders, setWorkOrders] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [zonesDropdown, setZonesDropdown] = useState(null);
  const [draggedWorkOrder, setDraggedWorkOrder] = useState(null);
  const [selectWorkOrder, setSelectedWorkOrder] = useState(
    defaultValuesForWorkOrders
  );
  const [WOError, setWOError] = useState(null);
  const [selectedView, setSelectedView] = useState({
    label: 'Day',
    value: Views.DAY,
  });
  const [date, setDate] = useState(moment().toDate());
  const [isWorkOrderUpdated, setIsWorkOrderUpdated] = useState(false);
  const [displayCalendarLoader, setDisplayCalendarLoader] = useState(true);
  const [serverErrors, setServerErrors] = useState([]);

  // Technician Start
  const getAllTechnician = useCallback(() => {
    dispatch(getTechnicianNamesList(zonesDropdown?.value)).then(() => {
      setIsInitialLoading(false);
    });
  }, [dispatch, zonesDropdown, selectedView]);

  const technicianRefactoredList =
    technicianList.data?.map(({ id, display_name }) => ({
      resourceId: id,
      resourceTitle: display_name,
    })) || [];

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllTechnician, 500), [
    getAllTechnician,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // Technician End

  // Zones Start
  const getAllZones = useCallback(() => {
    dispatch(getZonesDropdownList());
  }, [dispatch]);

  useEffect(() => {
    getAllZones();
  }, [getAllZones]);
  // Zones End

  // Scheduler Start
  const handleDragStart = useCallback((event) => {
    setDraggedWorkOrder(event);
  }, []);

  const handleWorkOrderClick = (event) => {
    setSelectedWorkOrder({
      id: event.id,
      startDate: event.start,
      endDate: event.end,
      title: event.title,
      resourceId: event.resourceId,
      isEditable: event.isEditable,
    });
  };

  const handleClose = () => {
    setSelectedWorkOrder(defaultValuesForWorkOrders);
    setWOError(null);
  };

  const handleSave = () => {
    if (moment(selectWorkOrder.startDate).isBefore(moment())) {
      setWOError({
        date: t('attributes.scheduleBoard.selectFutureDateAndTime'),
      });

      return;
    }

    const selectedWOIndex = workOrders.find(
      (event) => event.id === selectWorkOrder.id
    );

    setWorkOrders((prev) =>
      prev.map((event) =>
        event.id === selectedWOIndex.id
          ? { ...event, isWOLoading: true }
          : event
      )
    );

    dispatch(
      postAssignWOtoTech({
        workOrderId: selectedWOIndex.id,
        startDate: selectWorkOrder.startDate,
        endDate: selectWorkOrder.endDate,
        resource: selectedWOIndex.resourceId,
      })
    ).then((res) => {
      if (res?.payload?.status !== 200 && res?.payload?.status !== 201) {
        setServerErrors(res?.payload?.errorDetails);
      }
      setSelectedWorkOrder(defaultValuesForWorkOrders);
      setIsWorkOrderUpdated((prev) => !prev);
      handleClose();
    });
  };

  const handleUnassignWOFromTech = () => {
    const selectedWOIndex = workOrders.find(
      (event) => event.id === selectWorkOrder?.id
    );

    setWorkOrders((prev) =>
      prev.map((event) =>
        event?.id === selectedWOIndex?.id
          ? { ...event, isWOLoading: true }
          : event
      )
    );

    if (selectedWOIndex?.id) {
      dispatch(unassignedWOtoTech({ workOrderId: selectedWOIndex.id })).finally(
        () => {
          setSelectedWorkOrder(defaultValuesForWorkOrders);
          setIsWorkOrderUpdated((prev) => !prev);
          handleClose();
        }
      );
    } else {
      setSelectedWorkOrder(defaultValuesForWorkOrders);
      setIsWorkOrderUpdated((prev) => !prev);
      handleClose();
    }
  };

  useEffect(() => {
    if (serverErrors?.length > 0) {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: true,
          msg:
            serverErrors?.[0]?.message?.[0] ||
            serverErrors[0] ||
            COMMON_ERROR_MESSAGE.default,
        })
      );
      setServerErrors([]);
    }
  }, [serverErrors]);
  // Scheduler End

  // Reset everything on unmount start
  useEffect(
    () => () => {
      resetScheduleBoardWOList();
      resetAssignWOtoTech();
      resetUnassignedWOtoTech();
    },
    []
  );
  // Reset everything on unmount end

  const renderScheduleBoard = (
    <>
      <ScheduleBoardControls
        date={date}
        setDate={setDate}
        selectedView={selectedView}
        setSelectedView={setSelectedView}
        zonesDropdown={zonesDropdown}
        setZonesDropdown={setZonesDropdown}
        disableControls={technicianRefactoredList?.length === 0}
        setDisplayCalendarLoader={setDisplayCalendarLoader}
      />
      {technicianRefactoredList?.length ? (
        <ScheduleBoardCalendar
          workOrders={workOrders}
          setWorkOrders={setWorkOrders}
          handleWorkOrderClick={handleWorkOrderClick}
          draggedWorkOrder={draggedWorkOrder}
          setDraggedWorkOrder={setDraggedWorkOrder}
          date={date}
          onNavigate={setDate}
          selectedView={selectedView}
          isWorkOrderUpdated={isWorkOrderUpdated}
          setIsWorkOrderUpdated={setIsWorkOrderUpdated}
          displayCalendarLoader={displayCalendarLoader}
          setDisplayCalendarLoader={setDisplayCalendarLoader}
          setServerErrors={setServerErrors}
        />
      ) : (
        <NewNoDataPage
          icon={<CalendarMonthOutlinedIcon />}
          onlyTitle={true}
          customText={t('attributes.scheduleBoard.noTechForSelectedZone')}
          filterHeight={528}
        />
      )}

      {selectWorkOrder?.title &&
        selectWorkOrder?.title?.toLowerCase() !== LEAVE && (
          <WorkOrderPopUp
            open={selectWorkOrder?.title}
            setOpen={handleClose}
            selectWorkOrder={selectWorkOrder}
            setSelectedWorkOrder={setSelectedWorkOrder}
            WOError={WOError}
            handleUnassignWOFromTech={handleUnassignWOFromTech}
            handleSave={handleSave}
            setWOError={setWOError}
          />
        )}
    </>
  );

  const WorkOrderWrapper = (
    <StyledMainWrapper title={t('attributes.scheduleBoard.scheduleBoard')}>
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - 301px)`,
            maxHeight: `calc(100vh - 301px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        <>
          <Box
            sx={{ bgcolor: DIVIDER_COLOR, height: '100%' }}
            display={'flex'}
            flexDirection={'column'}
            rowGap={'16px'}
          >
            <Paper sx={{ borderRadius: '6px' }}>
              <Box sx={{ padding: '16px' }}>{renderScheduleBoard}</Box>
            </Paper>
            <Paper height={'calc(100vh - 730px)'} sx={{ padding: '16px' }}>
              <ScheduleBoardWOList
                handleDragStart={handleDragStart}
                zonesDropdownLoading={zonesDropdownLoading}
                zonesDropdownData={zonesDropdownData}
                isWorkOrderUpdated={isWorkOrderUpdated}
              />
            </Paper>
          </Box>
        </>
      )}
    </StyledMainWrapper>
  );

  return <>{WorkOrderWrapper}</>;
};

export default ScheduleBoard;
