import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Modal, Typography } from '@mui/material';

import { HoverText, ImageWrapper } from '../../assets/commonStyled';
import { WARNING } from '../../constants/Colors';
import { CUSTOMER_DEFECT_STATUS } from '../../constants/Constants';
import { getSingleDefect } from '../../store/defects/api';
import { formatDate, formatStatus } from '../../utils';
import CommonCarouselView from '../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import ImageView, { imageModalView } from '../CommonComponents/ImageView';

const KeyValueComponent = ({ name, value, minWidth = '180px' }) => (
  <Box>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const ViewDashboardDefect = ({ defectUUID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [defect, setDefect] = useState(null);

  const [imgSrc, setImgSrc] = useState(null);

  useEffect(() => {
    if (defectUUID) {
      dispatch(getSingleDefect({ defect_id: defectUUID }))
        .then((res) => setDefect(res?.payload?.data?.[0]))
        .finally(() => setLoading(false));
    }
  }, [defectUUID]);

  const openImage = () => setOpen(true);

  return loading ? (
    <CustomCircularLoader />
  ) : (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
        <Card sx={{ boxShadow: 'none !important' }}>
          <Box
            sx={{
              padding: '12px',
              display: 'flex',
              width: '100%',
              columnGap: '16px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                minWidth: '50%',
              }}
            >
              <KeyValueComponent
                name={t('attributes.property.propertyName')}
                value={defect?.property?.property_name || '-'}
              />
              <KeyValueComponent
                name={`${t('attributes.property.property')} ${t('attributes.address')}`}
                value={
                  `${defect?.property?.address}${defect?.property?.city ? `, ${defect?.property?.city}` : ''}${defect?.property?.state ? `, ${defect?.property?.state}` : ''}${defect?.property?.zip_code ? `, ${defect?.property?.zip_code}` : ''}` ||
                  '-'
                }
              />
              <KeyValueComponent
                name={t('attributes.technician.technician_name')}
                value={defect?.work_order?.technician?.technician_name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.description')}
                value={defect?.defect_description || '-'}
              />
              <KeyValueComponent
                name={t('attributes.work_order.severity')}
                value={formatStatus(defect?.severity) || '-'}
              />
              <KeyValueComponent
                name={t('attributes.property.status')}
                value={
                  defect?.latest_status
                    ? formatStatus(
                        CUSTOMER_DEFECT_STATUS.find(
                          (status) => status.value === defect?.latest_status
                        )?.label
                      )
                    : '-'
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                rowGap: '16px',
                minWidth: '30%',
              }}
            >
              <KeyValueComponent
                name={t('attributes.property.asset_name')}
                value={defect?.asset?.label || '-'}
              />
              <KeyValueComponent
                name={`${t('attributes.property.asset')} ${t('attributes.location')}`}
                value={defect?.asset?.location || '-'}
              />
              <KeyValueComponent
                name={t('attributes.work_order.routineServiceName')}
                value={defect?.work_order?.type_name || '-'}
              />
              <KeyValueComponent
                name={t('attributes.recommendation')}
                value={defect?.short_resolution || '-'}
              />
              <KeyValueComponent
                name={`${t('attributes.work_order.identified')} ${t('attributes.date')}`}
                value={formatDate(defect?.created_at) || '-'}
              />
            </Box>
          </Box>
        </Card>
        <Card sx={{ boxShadow: 'none !important' }}>
          <Box sx={{ padding: '12px' }}>
            <Typography variant="body1">
              {t('attributes.work_order.image')}
              <span style={{ color: WARNING }}>*</span>
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              columnGap: '16px',
              padding: '16px',
              flexWrap: 'wrap',
            }}
          >
            {defect?.documents?.length ? (
              <CommonCarouselView
                totalItems={defect?.documents?.length}
                centerMode={true}
              >
                {defect?.documents?.map((doc, index) => (
                  <ImageWrapper>
                    <ImageView
                      uuid={doc?.document_uuid}
                      onClick={() => setOpen(true)}
                      setImgSrc={setImgSrc}
                    />
                    <HoverText className="hoverText" onClick={openImage}>
                      <Typography variant="body1">
                        {t('attributes.view')}
                      </Typography>
                    </HoverText>
                  </ImageWrapper>
                ))}
              </CommonCarouselView>
            ) : (
              <>No Document Uploaded</>
            )}
          </Box>
        </Card>
      </Box>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpen(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={defect?.documents?.length}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              centerMode={true}
              isModal={true}
            >
              {imgSrc?.map((img) => imageModalView(img))}
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </>
  );
};

export default ViewDashboardDefect;
