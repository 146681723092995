import { Box, Card, styled } from '@mui/material';

export const FlexEnd = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  columnGap: '16px',
}));

export const FlexStart = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  columnGap: '16px',
}));

export const SpaceBetween = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const ImageWrapper = styled(Box)({
  height: '100px',
  width: '100px',
  position: 'relative',
  cursor: 'pointer',
  overflow: 'hidden', // Ensure tooltip stays within the bounds
  '&:hover .hoverText': {
    visibility: 'visible',
    opacity: 1,
  },
});
export const HoverText = styled(Box)({
  position: 'absolute',
  bottom: '0',
  left: '50%',
  transform: 'translate(-50%,0)',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  color: 'white',
  height: 'inherit',
  width: 'inherit',
  borderRadius: '4px',
  visibility: 'hidden',
  opacity: 0,
  transition: 'opacity 0.3s ease, visibility 0.3s ease',
  textDecoration: 'underline',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});

export const FormFieldWrapper = styled(Box)({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

export const FormWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
});

export const CustomCard = styled(Card)({
  boxShadow: 'none',
});
