import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import VerticalAlignBottomOutlinedIcon from '@mui/icons-material/VerticalAlignBottomOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Divider, StepConnector, styled, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';

import {
  ASSET_STATUS,
  CUSTOMER_STATUS,
  FAILED_STATUS,
  INSERTED_STATUS,
  INSERTED_WITH_ERRORS_STATUS,
  PRODUCT_CATALOGUE_STATUS,
  PROPERTY_STATUS,
  SUPPLIER_STATUS,
  TECHNICIAN_STATUS,
} from '../../../constants/Constants';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import { uploadBulkDocument } from '../../../store/document/api';
import CustomButton from '../CustomButton';
import FileSelection from './FileSelection';
import ValidationPreview from './ValidationPreview';
import ValidationPreviewFinished from './ValidationPreviewFinished';

const stepIcons = [
  <PostAddOutlinedIcon key="icon-01" sx={{ height: '24px', width: '24px' }} />,
  <VisibilityOutlinedIcon
    key="icon-02"
    sx={{ height: '24px', width: '24px' }}
  />,
  <CheckCircleOutlinedIcon
    key="icon-03"
    sx={{ height: '24px', width: '24px' }}
  />,
];

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 45,
  height: 45,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const CustomStepIcon = ({ icon, active, completed, className, error }) => (
  <CustomStepIconRoot
    ownerState={{ completed, active, error }}
    className={className}
    sx={{
      backgroundColor: error && active ? 'red' : undefined,
      color: error && active ? 'white' : undefined,
    }}
  >
    {stepIcons[icon - 1]}
  </CustomStepIconRoot>
);

const CustomConnector = styled(StepConnector)(() => ({
  '& .MuiStepConnector-line': {
    borderColor: '#000000',
    borderWidth: 1,
    borderStyle: 'dashed',
  },
}));

export default function ImportStepper() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [hasError, setHasError] = useState(false);
  const [isFileEmpty, setIsFileEmpty] = useState(false);
  const [docUploadedSuccess, setDocUploadedSuccess] = useState(true);

  const { isDocumentLoading, uploadedSuccessfully, uploadError } = useSelector(
    (state) => state.document.upload
  );
  const { documentUploadStatus, isDocStatusLoading, isDocStatusError } =
    useSelector((state) => state.document.getUploadStatus);

  const steps = useMemo(() => {
    const steps = [
      {
        label: t('attributes.reports.importData.fileSelection'),
        icon: <PostAddOutlinedIcon />,
      },
      {
        label:
          activeStep !== 2
            ? t('attributes.reports.importData.validationPreview')
            : t('attributes.reports.importData.previewProcessing'),
        icon: <VisibilityOutlinedIcon />,
      },
      {
        label: t('attributes.reports.importData.finished'),
        icon: <CheckCircleOutlinedIcon />,
      },
    ];

    return steps;
  }, [activeStep]);

  const onImportClick = () => {
    setActiveStep((prevStep) => prevStep + 1);
    if (selectedDocument) {
      dispatch(
        uploadBulkDocument({
          file_type: selectedDocument?.fileModule || '',
          file: selectedDocument?.file,
        })
      );
    }
  };

  const redirectToLocation = () => {
    if (selectedDocument.fileModule === CUSTOMER_STATUS) {
      navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.CUSTOMER}`);
    }

    if (selectedDocument.fileModule === SUPPLIER_STATUS) {
      navigate(`/${ROUTENAME.USERS}/${ROUTENAME.SUPPLIERS}`);
    }

    if (selectedDocument.fileModule === PROPERTY_STATUS) {
      navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`);
    }

    if (selectedDocument.fileModule === TECHNICIAN_STATUS) {
      navigate(`/${ROUTENAME.USERS}/${ROUTENAME.TECHNICIAN}`);
    }
    if (selectedDocument.fileModule === ASSET_STATUS) {
      navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.PROPERTIES}`);
    }
    if (selectedDocument.fileModule === PRODUCT_CATALOGUE_STATUS) {
      navigate(`/${ROUTENAME.MASTER}/${ROUTENAME.PRODUCT_CATALOGUE}`);
    }
  };

  useEffect(() => {
    setHasError(false);
    if (uploadedSuccessfully && !docUploadedSuccess) {
      // Check the status from the status API response
      const status = documentUploadStatus.data.find(
        (s) =>
          s.status === INSERTED_STATUS ||
          s.status === FAILED_STATUS ||
          s.status === INSERTED_WITH_ERRORS_STATUS
      );

      if (status) {
        if (
          status.status === FAILED_STATUS ||
          status.status === INSERTED_WITH_ERRORS_STATUS
        ) {
          setHasError(true);
        }
      }
    }
  }, [uploadedSuccessfully, docUploadedSuccess]);

  return (
    <>
      <Box
        sx={{
          width: '100%',
          minHeight: '15vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 1,
          paddingX: { xs: 2, md: 8 },
        }}
      >
        <Stepper
          activeStep={activeStep}
          alternativeLabel
          connector={<CustomConnector sx={{ top: 20 }} />}
          sx={{ width: '100%' }}
        >
          {steps.map((step, idx) => (
            <Step key={step.label}>
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon
                    {...props}
                    error={
                      (activeStep === 1 && hasError && !isFileEmpty) ||
                      (activeStep === 2 &&
                        (uploadError || isDocStatusError || hasError))
                    }
                  />
                )}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant={idx <= activeStep ? 'body1' : 'body2'}
                  sx={{
                    color:
                      idx === activeStep
                        ? (hasError ?? 'primary.main')
                        : 'text.primary',
                  }}
                >
                  {step.label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Box>

      <Divider />
      {activeStep === 0 && (
        <FileSelection
          setActiveStep={setActiveStep}
          selectedDocument={selectedDocument}
          setSelectedDocument={setSelectedDocument}
        />
      )}
      {activeStep === 1 && (
        <ValidationPreview
          selectedDocument={selectedDocument}
          hasError={hasError}
          setHasError={setHasError}
          setIsFileEmpty={setIsFileEmpty}
        />
      )}
      {activeStep === 2 && (
        <ValidationPreviewFinished
          activeStep={activeStep}
          docUploadedSuccess={docUploadedSuccess}
          setDocUploadedSuccess={setDocUploadedSuccess}
        />
      )}
      <Divider />
      {activeStep !== 0 && (
        <>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                gap: 1,
              }}
            >
              {!isDocumentLoading &&
              !docUploadedSuccess &&
              !hasError &&
              activeStep === 2 ? (
                <CustomButton
                  sx={{
                    backgroundColor: 'var(--border-color)',
                    color: 'var(--black-color)',
                    '&:hover': {
                      backgroundColor: 'var(--border-color)', // Same as default to remove hover effect
                    },
                  }}
                  onClick={redirectToLocation}
                  startIcon={<VisibilityOutlinedIcon />}
                >
                  {t('attributes.view')}
                </CustomButton>
              ) : (
                hasError &&
                activeStep === 2 && (
                  <CustomButton
                    sx={{
                      backgroundColor: 'var(--border-color)',
                      color: 'var(--black-color)',
                      '&:hover': {
                        backgroundColor: 'var(--border-color)', // Same as default to remove hover effect
                      },
                    }}
                    onClick={() => {
                      navigate(
                        `/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}/${ROUTENAME.IMPORT_DATA}`
                      );
                    }}
                    startIcon={<CancelOutlinedIcon />}
                  >
                    {t('common.cancel')}
                  </CustomButton>
                )
              )}
              {!hasError ? (
                <CustomButton
                  variant="contained"
                  onClick={
                    activeStep === 1
                      ? onImportClick
                      : () =>
                          navigate(
                            `/${ROUTENAME.REPORTS}/${ROUTENAME.DATA_MANAGEMENT}/${ROUTENAME.IMPORT_DATA}`
                          )
                  }
                  startIcon={
                    activeStep === 1 ? (
                      <VerticalAlignBottomOutlinedIcon />
                    ) : (
                      <CheckCircleOutlinedIcon />
                    )
                  }
                >
                  {activeStep === 1
                    ? t('attributes.import')
                    : t('attributes.finish')}
                </CustomButton>
              ) : (
                <CustomButton
                  variant="contained"
                  startIcon={<UploadFileOutlinedIcon />}
                  onClick={() => setActiveStep(0)}
                >
                  {t('attributes.reupload')}
                </CustomButton>
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
