import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Card, Divider, Grid, styled, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import {
  CURRENCY,
  DRAFT_STATUS,
  ROUTINE_CONTRACT_STATUS,
  WORK_ORDER_TYPE_ROUTINE,
} from '../../../constants/Constants';
import { DROPDOWN } from '../../../constants/Dropdown';
import { Validation } from '../../../constants/FieldValidationMsg';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import getDropdownListHook from '../../../hooks/getDropdownListHook';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import { getFrequencyList } from '../../../store/frequency/api';
import { resetFrequencyList } from '../../../store/frequency/reducer';
import { getPropertyCustomerList } from '../../../store/property/api';
import { getPropertyRoutine } from '../../../store/routine/api';
import { resetRoutineList } from '../../../store/routine/reducer';
import { getStandardsDropdownList } from '../../../store/standards/api';
import { resetStandardsDropdownList } from '../../../store/standards/reducer';
import {
  createBillingContract,
  getBillingContractById,
  updateBillingContractById,
  updateBillingContractDetails,
} from '../../../store/users/billingContract/api';
import {
  resetCreateBillingContract,
  resetGetBillingContractById,
  resetUpdateBillingContractById,
  resetUpdateBillingContractDetails,
} from '../../../store/users/billingContract/reducer';
import { datePickerFormat, formatDateForAPI } from '../../../utils';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CommonCheckbox from '../../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import CustomTextField from '../../CommonComponents/CustomTextField';
import MainWrapper from '../../CommonComponents/MainWrapper';
import BillingContactMatrix from './BillingContactMatrix';
import BillingContactPeriodic from './BillingContactPeriodic';

const defaultValues = {
  name: '',
  customer_uuid: null,
  customer_contact_name: '',
  sub_type: null,
  contract_start_date: '',
  contract_end_date: '',
  contract_date: '',
  standard_id: null,
  cpi_increase_type: null,
  cpi_percentage_anually: 0,
  is_active: true,
};

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
}));

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const BillingContractForm = ({
  isFromProperty = false,
  nextStepTitle = false,
  selectedProperty = null,
  setNextStepTitle = () => {},
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { uuid } = useParams();
  const { t } = useTranslation();

  // Is edit billing contract
  const isEdit =
    (isFromProperty && nextStepTitle?.editId) ||
    (!isFromProperty && (uuid === 'add-new' ? null : uuid));

  // Selector for standards dropdown
  const { standardsDropdownLoading, standardsDropdownData } =
    getDropdownListHook({
      reducerName: 'standards',
      dropdownListName: 'standardsDropdownList',
      labelName: 'code',
      valueName: 'id',
      codeName: 'code',
    });

  // Selector for creating a new billing contract
  const {
    isLoading: isCreatingBillingContract,
    data: createBillingContractData,
    error: createBillingContractError,
  } = useSelector((state) => state.billingContract.createBillingContract);

  // Selector for updating billing contract
  const {
    isLoading: isUpdatingBillingContract,
    // data: updateBillingContractData,
    error: updateBillingContractError,
  } = useSelector((state) => state.billingContract.updateBillingContractById);

  // Selector for updating billing contract detail/matrix
  const {
    isLoading: isUpdatingBillingContractDetails,
    data: updateBillingContractDetailsData,
    error: updateBillingContractDetailsError,
  } = useSelector(
    (state) => state.billingContract.updateBillingContractDetails
  );

  // Selector for billing contract by its ID
  const {
    isLoading: isGetBillingContractByIdLoading,
    data: getBillingContractByIdData,
    error: getBillingContractByIdError,
  } = useSelector((state) => state.billingContract.getBillingContractById);

  // Selector for frequency list data
  const { isLoading: frequencyLoading } = useSelector(
    (state) => state.frequency.frequencyList
  );

  // Selector for routine list data
  const { isLoading: routineLoading } = useSelector(
    (state) => state.routine.routineList
  );

  // Selector for customer name list
  const {
    propertyDropdownLoading: customerDropdownLoading,
    propertyDropdownData: customerDropdownData,
  } = getDropdownListHook({
    reducerName: 'property',
    dropdownListName: 'propertyCustomerList',
    labelName: 'customer_name',
    valueName: 'uuid',
  });

  // Selector for company UUID
  const { profileDetails } = useSelector((state) => state.common);

  // Form hooks start
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    setError,
    clearErrors,
    watch,
    trigger,
  } = useForm({ defaultValues: defaultValues, shouldUnregister: true });

  const watchedStandardIDValue = watch('standard_id');
  const watchedCPIIncreaseValue = watch('cpi_increase_type');
  const watchedContractTypeValue = watch('sub_type');
  const watchedCustomerUUID = watch('customer_uuid');
  const prevValues = useRef({});
  const [changeContractName, setChangeContractName] = useState(false);
  // Form hooks end

  useEffect(() => {
    if (!isEdit && (watchedCustomerUUID || watchedContractTypeValue)) {
      const customerLabel = watchedCustomerUUID?.label || '';
      const subTypeLabel = watchedContractTypeValue?.label || '';

      setValue('name', `${customerLabel} ${subTypeLabel}`);
    }

    if (changeContractName) {
      const customerLabel = watchedCustomerUUID?.label || '';
      const subTypeLabel = watchedContractTypeValue?.label || '';

      setValue('name', `${customerLabel} ${subTypeLabel}`);
    }
  }, [
    watchedCustomerUUID,
    watchedContractTypeValue,
    setValue,
    changeContractName,
  ]);

  // State for billing contract start
  const [matrixDataSet, setMatrixDataSet] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [loading, setLoading] = useState(false);
  // State for billing contract end

  // Clears server side errors
  const clearHandler = (name) => setValue(name, '');

  // Handles server side errors
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  // Billing contract matrix start
  const routineList = useCallback(() => {
    const standardValueChanged =
      watchedContractTypeValue?.value !== prevValues.current.value;

    prevValues.current.standard_id = watchedContractTypeValue?.standard_id;
    if (
      standardValueChanged &&
      watchedContractTypeValue?.value === 'do_and_charge'
    ) {
      setLoading(true);

      const fetchData = async () => {
        try {
          await Promise.all([
            dispatch(
              getFrequencyList({
                page: 1,
                size: 1,
                limit: -1,
                companyUUID: profileDetails?.company_uuid,
                order: 'desc',
                orderBy: 'code',
                // TODO: Keeping static as there is no dynamic type required, will be changed in future
                type: 'standard,custom',
              })
            ).unwrap(),
            dispatch(
              getPropertyRoutine({
                type: 'standard',
                page: 1,
                size: 1,
                limit: -1,
              })
            ).unwrap(),
          ]);
        } catch (error) {
          console.error('Error during API calls:', error);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    } else {
      dispatch(resetRoutineList());
      dispatch(resetFrequencyList());
    }
  }, [dispatch, watchedContractTypeValue]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(routineList, 500), [
    routineList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);
  // Billing contract matrix end

  // Common useeffect start
  useEffect(() => {
    dispatch(getPropertyCustomerList());
    dispatch(getStandardsDropdownList());
    const { editId } = nextStepTitle || {};

    if (isEdit) {
      dispatch(
        getBillingContractById({ billing_contract_uuid: editId || uuid })
      );
    }

    return () => {
      dispatch(resetStandardsDropdownList());
      dispatch(resetRoutineList());
      dispatch(resetFrequencyList());
      dispatch(resetCreateBillingContract());
      dispatch(resetGetBillingContractById());
      dispatch(resetUpdateBillingContractById());
      dispatch(resetUpdateBillingContractDetails());
      setMatrixDataSet(false);
      reset({ ...defaultValues });
    };
  }, []);

  useEffect(() => {
    // Set default standard value when dropdown data is available
    if (!isEdit && standardsDropdownData?.length > 0 && !watch('standard_id')) {
      setValue('standard_id', standardsDropdownData[0], {
        shouldValidate: false,
      });
    }
  }, [standardsDropdownData, setValue, watch]);
  // Common useeffect end

  // Form submit handler start
  const onSubmit = async (data) => {
    const contractData = {
      customer_uuid:
        selectedProperty?.customer_uuid || data?.customer_uuid?.value,
      property_uuid:
        selectedProperty?.uuid ||
        getBillingContractByIdData?.property_uuid ||
        null,
      name: data?.name,
      type: ROUTINE_CONTRACT_STATUS, // Keep static as of now.
      sub_type: data?.sub_type?.value,
      // Note: Contract, Contract Date, Start and Valid Till date will use the simple contract date without time so no need to convert to UTC and Local.
      contract_date: formatDateForAPI(data?.contract_date),
      contract_start_date: formatDateForAPI(data?.contract_start_date),
      contract_end_date: formatDateForAPI(data?.contract_end_date),
      cpi_percentage_anually:
        data?.cpi_percentage_anually ||
        data?.cpi_increase_type?.percentage ||
        0,
      status: DRAFT_STATUS, // Keep static as of now.
      standard_id: data?.standard_id?.value,
      is_active: data?.is_active,
      customer_contact_name: data?.customer_contact_name,
      cpi_increase_type: data?.cpi_increase_type?.value,
      invoice_frequency: data?.sub_type?.invoiceType,
    };

    let refactoredMatrixData = [];

    if (data?.sub_type?.renderComponent) {
      refactoredMatrixData = matrixDataSet.flatMap((item) =>
        Object.values(item)
          .filter((selectedFrequency) => selectedFrequency.isChecked === true)
          .map((selectedFrequency) => ({
            routine_standard_frequency_uuid:
              selectedFrequency.routineStandardFrequencyId,
            reference_product_catalogue_uuid: null, // Keep this nullable as of now.
            product_name: selectedFrequency.name,
            product_type: WORK_ORDER_TYPE_ROUTINE, // Keep static as of now.
            product_description: selectedFrequency.name,
            price: selectedFrequency.amount,
            currency: CURRENCY.AUD, // Keep static as of now.
            um: 'each', // Keep static as of now.
            standard_id: data?.standard_id?.value,
            frequency_id: selectedFrequency.frequencyId,
            routine_id: selectedFrequency.routineId,
          }))
      );

      if (!refactoredMatrixData || refactoredMatrixData?.length === 0) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: t('label.billingContract.oneRoutineServiceMustBeSelected'),
          })
        );

        return;
      }
    } else {
      refactoredMatrixData = [
        {
          product_name: 'Annual Contract Value',
          product_type: WORK_ORDER_TYPE_ROUTINE,
          product_description: 'Annual Contract Value',
          price: data?.annual_cost,
          currency: CURRENCY.AUD, // Keep static as of now.
          standard_id: data?.standard_id?.value,
          um: 'annual',
        },
      ];
    }

    if (!isEdit) {
      dispatch(
        createBillingContract({
          ...contractData,
        })
      ).then((res) => {
        if (res?.error) {
          return;
        }
        const { uuid } = res?.payload?.data?.[0] || {};

        dispatch(
          updateBillingContractDetails({
            data: refactoredMatrixData,
            contract_uuid: uuid,
          })
        );
      });
    } else {
      dispatch(
        updateBillingContractById({
          data: contractData,
          contract_uuid: nextStepTitle?.editId || uuid,
        })
      ).then((res) => {
        if (res?.error) {
          return;
        }
        const { uuid } = res?.payload?.data?.[0] || {};

        dispatch(
          updateBillingContractDetails({
            data: refactoredMatrixData,
            contract_uuid: uuid,
          })
        );
      });
    }
  };
  // Form submit handler end

  // Handle create billing contract error start
  useEffect(() => {
    if (isCreatingBillingContract === false && createBillingContractError) {
      const { errorDetails, message } = createBillingContractError || {};

      if (errorDetails) {
        setServerErrors(errorDetails);
      } else if (message) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: message,
          })
        );
      }
    }
  }, [createBillingContractError, isCreatingBillingContract]);
  // Handle create billing contract error end

  // Get billing contract by id start
  useEffect(() => {
    if (isGetBillingContractByIdLoading === false) {
      if (getBillingContractByIdError) {
        const { errorDetails, message } = getBillingContractByIdError;

        if (isFromProperty) {
          setNextStepTitle();
        } else {
          navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_CONTRACTS}`);
        }
        if (errorDetails) {
          setServerErrors(errorDetails);
        } else {
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: true,
              msg: message,
            })
          );
        }
      } else if (getBillingContractByIdData) {
        const {
          name,
          customer_uuid,
          customer_contact_name,
          sub_type,
          contract_start_date,
          contract_end_date,
          contract_date,
          standard_id,
          cpi_increase_type,
          cpi_percentage_anually,
          is_active,
        } = getBillingContractByIdData;

        reset({
          name,
          contract_start_date: datePickerFormat(contract_start_date),
          customer_uuid: {
            label: getBillingContractByIdData?.customer?.customer_name,
            value: customer_uuid,
          },
          customer_contact_name,
          sub_type: DROPDOWN.CONTRACTS.CONTRACT_TYPE?.find(
            ({ value }) => value === sub_type
          ),
          contract_end_date: datePickerFormat(contract_end_date),
          contract_date: datePickerFormat(contract_date),
          standard_id: standardsDropdownData?.find(() => standard_id),
          cpi_increase_type: DROPDOWN.CONTRACTS.CPI_INCREASE?.find(
            ({ value }) => value === cpi_increase_type
          ),
          cpi_percentage_anually: cpi_percentage_anually,
          is_active,
          annual_cost:
            getBillingContractByIdData?.details?.[0]?.um === 'annual'
              ? Number(getBillingContractByIdData.details[0].price).toFixed(2)
              : 0,
          monthly_cost:
            getBillingContractByIdData?.details?.[0]?.um === 'annual'
              ? (
                  Number(getBillingContractByIdData?.details[0].price || 0) / 12
                ).toFixed(2)
              : 0,
        });
      }
    }
  }, [isGetBillingContractByIdLoading, getBillingContractByIdData]);
  // Get billing contract by id end

  // Update billing contract start
  useEffect(() => {
    if (isUpdatingBillingContract === false) {
      const { errorDetails, message } = updateBillingContractError || {};

      if (errorDetails) {
        setServerErrors(errorDetails);
      } else if (message) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: message,
          })
        );
      }
    }
  }, [updateBillingContractError, isUpdatingBillingContract]);
  // Update billing contract end

  // Update billing contract detail/matrix start
  useEffect(() => {
    if (
      isUpdatingBillingContractDetails !== null &&
      !isUpdatingBillingContractDetails
    ) {
      const { errorDetails, message } = updateBillingContractDetailsError || {};

      if (errorDetails) {
        setServerErrors(errorDetails);
      } else if (message) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: message,
          })
        );
      } else if (updateBillingContractDetailsData) {
        const { message: successMessage } = isEdit
          ? updateBillingContractDetailsData
          : createBillingContractData;

        if (isFromProperty) {
          setNextStepTitle();
        } else {
          navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_CONTRACTS}`);
        }
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: successMessage,
          })
        );
      }
    }
  }, [updateBillingContractDetailsError, isUpdatingBillingContractDetails]);
  // Update billing contract detail/matrix end

  // Error handling start
  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);
  // Error handling end

  if (isEdit && isGetBillingContractByIdLoading) {
    return (
      <Box sx={{ paddingTop: 'calc(100vh - 60%)' }}>
        <CustomCircularLoader />
      </Box>
    );
  }

  const billingContractDetailsWrapper = (
    <>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">{`${t('attributes.billingContract.billingContract')} ${t('common.specifications')}`}</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Controller
                name="customer_uuid"
                control={control}
                rules={{
                  required: `${t('attributes.billingContract.customerName')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.billingContract.customerName')}
                    isLoadingData={customerDropdownLoading}
                    options={customerDropdownData}
                    value={value}
                    onChange={debounce((e, newValue) => {
                      if (newValue) {
                        onChange(newValue);
                        trigger('customer_uuid');
                      }
                      if (newValue !== value) {
                        setChangeContractName(true);
                      }
                    }, 500)}
                    disableClearable={true}
                    error={error}
                    helperText={error ? error.message : ''}
                    disabled={isEdit && !getBillingContractByIdData?.is_active}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="customer_contact_name"
                control={control}
                rules={{
                  required: `${t('attributes.billingContract.customerContact')} ${Validation.general.required}`,
                  minLength: {
                    value: 2,
                    message: `${t('attributes.billingContract.customerContact')} ${t('attributes.billingContract.fieldValidation.common')}`,
                  },
                  maxLength: {
                    value: 255,
                    message: `${t('attributes.billingContract.customerContact')} ${t('attributes.billingContract.fieldValidation.common')}`,
                  },
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    isRequired={true}
                    label={t('attributes.billingContract.customerContact')}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('customer_contact_name');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearHandler(name)}
                    disabled={isEdit && !getBillingContractByIdData?.is_active}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="sub_type"
                control={control}
                defaultValue={DROPDOWN.CONTRACTS.CONTRACT_TYPE[0]}
                rules={{
                  required: `${t('attributes.billingContract.contractType')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.billingContract.contractType')}
                    options={DROPDOWN.CONTRACTS.CONTRACT_TYPE}
                    value={value}
                    onChange={debounce((e, newValue) => {
                      if (newValue) {
                        onChange(newValue);
                        setLoading(true);
                      }
                      if (newValue !== value) {
                        setChangeContractName(true);
                      }
                    }, 500)}
                    disableClearable={true}
                    error={error}
                    helperText={error ? error.message : ''}
                    disabled={isEdit && !getBillingContractByIdData?.is_active}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="name"
                control={control}
                rules={{
                  required: `${t('attributes.name')} ${Validation.general.required}`,
                  minLength: {
                    value: 2,
                    message: `${t('attributes.name')} ${t('attributes.billingContract.fieldValidation.common')}`,
                  },
                  maxLength: {
                    value: 255,
                    message: `${t('attributes.name')} ${t('attributes.billingContract.fieldValidation.common')}`,
                  },
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    isRequired={true}
                    label={t('attributes.name')}
                    fullWidth
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                      trigger('name');
                    }}
                    helperText={error ? error.message : ''}
                    error={error}
                    onClear={() => clearHandler(name)}
                    disabled={true}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                control={control}
                name="contract_start_date"
                label={t('attributes.billingContract.contractStartDate')}
                pickerType="date"
                defaultValue={null}
                trigger={trigger}
                isRequired={true}
                disabled={isEdit && !getBillingContractByIdData?.is_active}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                control={control}
                name="contract_end_date"
                label={t('attributes.billingContract.contractValidTill')}
                pickerType="date"
                defaultValue={null}
                trigger={trigger}
                isRequired={true}
                disabled={isEdit && !getBillingContractByIdData?.is_active}
              />
            </Grid>
            <Grid item xs={4}>
              <CustomDatePicker
                control={control}
                name="contract_date"
                label={t('attributes.billingContract.contractDate')}
                pickerType="date"
                defaultValue={null}
                trigger={trigger}
                isRequired={true}
                disabled={isEdit && !getBillingContractByIdData?.is_active}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="standard_id"
                control={control}
                rules={{
                  required: `${t('attributes.billingContract.standard')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.billingContract.standard')}
                    isLoadingData={standardsDropdownLoading}
                    options={standardsDropdownData}
                    value={value}
                    onChange={debounce((e, newValue) => {
                      onChange(newValue ? newValue : standardsDropdownData[0]);
                      debouncedFetchData();
                    }, 500)}
                    disableClearable={true}
                    error={error}
                    helperText={error ? error.message : ''}
                    disabled={isEdit && !getBillingContractByIdData?.is_active}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="cpi_increase_type"
                control={control}
                defaultValue={DROPDOWN.CONTRACTS.CPI_INCREASE[0]}
                rules={{
                  required: `${t('attributes.billingContract.cpiIncrease')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    label={t('attributes.billingContract.cpiIncrease')}
                    options={DROPDOWN.CONTRACTS.CPI_INCREASE}
                    value={value}
                    onChange={debounce((e, newValue) => {
                      onChange(
                        newValue ? newValue : DROPDOWN.CONTRACTS.CPI_INCREASE[0]
                      );
                      trigger('cpi_increase_type');
                    }, 500)}
                    disableClearable={true}
                    error={error}
                    helperText={error ? error.message : ''}
                    disabled={isEdit && !getBillingContractByIdData?.is_active}
                  />
                )}
              />
            </Grid>
            {watchedCPIIncreaseValue?.percentage === null && (
              <Grid item xs={4}>
                <Controller
                  name="cpi_percentage_anually"
                  control={control}
                  defaultValue={0}
                  rules={{
                    required: `${t('attributes.billingContract.annualFixedIncreasePercentage')} ${Validation.general.required}`,
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      type="number"
                      isRequired={true}
                      label={t(
                        'attributes.billingContract.annualFixedIncreasePercentage'
                      )}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        if (e?.target?.value >= 0 || e?.target?.value === '') {
                          onChange(e);
                          trigger('cpi_percentage_anually');
                        }
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                      displayEndAdornment={false}
                      disabled={
                        isEdit && !getBillingContractByIdData?.is_active
                      }
                    />
                  )}
                />
              </Grid>
            )}
            {isEdit && getBillingContractByIdData?.is_active ? (
              <Grid item xs={4}>
                <CommonCheckbox
                  name={'is_active'}
                  control={control}
                  label={t('common.active')}
                  isRequired={false}
                  showMessage={true}
                  messageOnChecked={t(
                    'label.billingContract.billingContractActiveCheckbox'
                  )}
                  messageOnUnchecked={t(
                    'label.billingContract.billingContractInactiveCheckbox'
                  )}
                  sx={{ height: 'auto' }}
                  disabled={isEdit && !getBillingContractByIdData?.is_active}
                />
              </Grid>
            ) : null}
          </Grid>
        </FormFieldWrapper>
      </CustomCard>
    </>
  );

  return (
    <Box sx={{ height: '100%', bgcolor: DIVIDER_COLOR }}>
      {!isFromProperty && (
        <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
          <MainWrapper
            defaultPadding="0px"
            title={t('attributes.billingContract.billingContracts')}
            variant="body1"
            isStep={true}
            step={`${isEdit ? `BC-${getBillingContractByIdData?.id}` : `${t('attributes.add')} ${t('attributes.new')}`}`}
            handleDefaultStep={() =>
              navigate(`/${ROUTENAME.CUSTOMERS}/${ROUTENAME.BILLING_CONTRACTS}`)
            }
          ></MainWrapper>
        </Box>
      )}
      {billingContractDetailsWrapper}
      {!watchedContractTypeValue && isEdit ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: 'calc(100vh - 490px)',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : watchedContractTypeValue?.renderComponent ? (
        <BillingContactMatrix
          selectedStandard={watchedStandardIDValue}
          matrixDataSet={matrixDataSet}
          setMatrixDataSet={setMatrixDataSet}
          isEdit={isEdit}
          loading={loading}
        />
      ) : (
        <BillingContactPeriodic
          control={control}
          setValue={setValue}
          trigger={trigger}
          clearHandler={clearHandler}
          watchedCPIIncreaseValue={watchedCPIIncreaseValue}
          isEdit={isEdit}
        />
      )}
      {getBillingContractByIdData?.is_active === false ? null : (
        <>
          <Divider />
          <Box
            sx={{
              bgcolor: 'var(--secondary-color)',
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '8px 18px 8px 0',
              borderRadius: '0px 0px 8px 8px',
            }}
          >
            <CustomButton
              text={
                isCreatingBillingContract ||
                isUpdatingBillingContract ||
                isUpdatingBillingContractDetails
                  ? t('common.loading')
                  : isEdit
                    ? t('attributes.save')
                    : t('attributes.add')
              }
              startIcon={isEdit ? <SaveOutlinedIcon /> : <AddIcon />}
              color="primary"
              onClick={handleSubmit(onSubmit)}
              disabled={
                (watchedContractTypeValue?.renderComponent &&
                  frequencyLoading) ||
                (watchedContractTypeValue?.renderComponent && routineLoading) ||
                (!matrixDataSet && watchedContractTypeValue?.renderComponent) ||
                isCreatingBillingContract ||
                isUpdatingBillingContract ||
                isUpdatingBillingContractDetails
              }
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default BillingContractForm;
