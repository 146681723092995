import React, { useEffect } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { Validation } from '../../constants/FieldValidationMsg';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getCategoryLevelsDropdown,
  getEquipmentClassDropdown,
} from '../../store/equipmentTypes/api';
import {
  resetCategoryLevels1DropdownList,
  resetCategoryLevels2DropdownList,
  resetCategoryLevels3DropdownList,
  resetEquipmentClassDropdownList,
} from '../../store/equipmentTypes/reducer';
import { getProductEnhancement } from '../../store/productCatalogue/api';
import {
  resetProductDetails,
  resetProductEnhancementData,
} from '../../store/productCatalogue/reducer';
import { getAssetDetails, getProducts } from '../../store/property/api';
import {
  resetAssetDetails,
  resetUpdateAsset,
  resetcreateAsset,
} from '../../store/property/reducer';
import { clearTextfields, datePickerFormat } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomDatePicker from '../CommonComponents/CustomDatePicker';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import { assetFormDefaultValues } from './AssetsList';

const AssetForm = ({
  id,
  control,
  watch,
  setValue,
  trigger,
  reset,
  assetStatusList,
  nextStepTitle,
  setServerErrors = () => {},
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();

  // Get equipment type list
  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'code',
      id: 'id',
    });

  // Get equipment class list
  const { equipmentClassDropdownLoading, equipmentClassDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentClassDropdownList',
      customNameForDropdown: 'equipmentClass',
      labelName: 'name',
      valueName: 'id',
    });

  // Get category levels 1 list
  const { categoryLevels1DropdownLoading, categoryLevels1DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels1DropdownList',
      customNameForDropdown: 'categoryLevels1',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Get category levels 2 list
  const { categoryLevels2DropdownLoading, categoryLevels2DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels2DropdownList',
      customNameForDropdown: 'categoryLevels2',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Get category levels 3 list
  const { categoryLevels3DropdownLoading, categoryLevels3DropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'categoryLevels3DropdownList',
      customNameForDropdown: 'categoryLevels3',
      labelName: 'name',
      valueName: 'id',
      isOther: 'is_other',
    });

  // Equipment class errors
  const { error: equipmentClassDropdownError } = useSelector(
    (state) => state.equipmentTypes.equipmentClassDropdownList
  );

  // Category levels errors
  const { error: categoryLevels1DropdownError } = useSelector(
    (state) => state.equipmentTypes.categoryLevels1DropdownList
  );
  const { error: categoryLevels2DropdownError } = useSelector(
    (state) => state.equipmentTypes.categoryLevels2DropdownList
  );
  const { error: categoryLevels3DropdownError } = useSelector(
    (state) => state.equipmentTypes.categoryLevels3DropdownList
  );

  // Product Enhancement Data
  const { isLoading: productEnhancementLoading, data: productEnhancementData } =
    useSelector((state) => state.productCatalogue.productEnhancementData);

  const { assetDetails } = useSelector((state) => state.property);

  const selectedEquipmentType = useWatch({
    control,
    name: 'equipment_type',
    defaultValue: '',
  });

  const selectedEquipmentClass = useWatch({
    control,
    name: 'product',
    defaultValue: '',
  });

  const selectedCategoryLevel1 = useWatch({
    control,
    name: 'category_level1',
    defaultValue: '',
  });

  const selectedCategoryLevel2 = useWatch({
    control,
    name: 'category_level2',
    defaultValue: '',
  });

  const selectedCategoryLevel3 = useWatch({
    control,
    name: 'category_level3',
    defaultValue: '',
  });

  const categoryName = useWatch({
    control,
    name: 'category_name',
    defaultValue: '',
  });

  useEffect(() => {
    if (selectedEquipmentType?.label) {
      dispatch(
        getEquipmentClassDropdown({
          equipment_type_id: selectedEquipmentType?.id,
          status: true,
        })
      );
    }
  }, [selectedEquipmentType]);

  useEffect(() => {
    if (selectedEquipmentClass?.label) {
      const categoryLevels = [
        {
          ecid: selectedEquipmentClass?.value,
          parent_cid: null,
          current_category: 1,
        },
        { parent_cid: selectedCategoryLevel1?.value, current_category: 2 },
        { parent_cid: selectedCategoryLevel2?.value, current_category: 3 },
      ];

      categoryLevels.forEach((level) => {
        dispatch(getCategoryLevelsDropdown(level));
      });
    }
  }, [selectedEquipmentClass, selectedCategoryLevel1, selectedCategoryLevel2]);

  useEffect(() => {
    if (
      selectedEquipmentType?.label &&
      (categoryLevels1DropdownError ||
        categoryLevels2DropdownError ||
        categoryLevels3DropdownError)
    ) {
      dispatch(
        getProductEnhancement({
          equipment_types: selectedEquipmentType?.id,
          ecid: selectedEquipmentClass?.value || null,
          category1: selectedCategoryLevel1?.value || null,
          category2: selectedCategoryLevel2?.value || null,
          category3: selectedCategoryLevel3?.value || null,
          is_ecid_null: !selectedEquipmentClass?.label,
          is_category1_null: !selectedCategoryLevel1?.label,
          is_category2_null: !selectedCategoryLevel2?.label,
          is_category3_null: !selectedCategoryLevel3?.label,
        })
      );
    }
  }, [
    selectedEquipmentType,
    categoryLevels1DropdownError,
    categoryLevels2DropdownError,
    categoryLevels3DropdownError,
  ]);

  useEffect(() => {
    if (productEnhancementData?.data?.length) {
      setValue('asset_name', productEnhancementData.data[0]?.display_name);
    }
  }, [productEnhancementData]);

  useEffect(() => {
    dispatch(getProducts({ equipment_type: selectedEquipmentType?.value }));
  }, [selectedEquipmentType?.value]);

  useEffect(() => {
    if (id) {
      dispatch(getAssetDetails({ assetId: id }));
    }

    return () => {
      dispatch(resetUpdateAsset());
      dispatch(resetcreateAsset());
      if (!nextStepTitle) {
        dispatch(resetAssetDetails());
      }
      dispatch(resetEquipmentClassDropdownList());
      dispatch(resetCategoryLevels1DropdownList());
      dispatch(resetCategoryLevels2DropdownList());
      dispatch(resetCategoryLevels3DropdownList());
      dispatch(resetProductEnhancementData());
      dispatch(resetProductDetails());
      reset(assetFormDefaultValues);
    };
  }, []);

  useEffect(() => {
    if (!assetDetails?.loading && assetDetails && id) {
      const details = assetDetails?.data;

      reset({
        ...(details.equipment_type?.id && {
          equipment_type: {
            label: details.equipment_type?.display_name,
            value: details.equipment_type?.code,
            id: details.equipment_type?.id,
          },
        }),
        ...(details?.productDetails?.equipment_class?.id && {
          product: {
            label: details?.productDetails?.equipment_class?.name,
            value: details?.productDetails?.equipment_class?.id,
          },
        }),
        ...(details?.productDetails?.category1_rel && {
          category_level1: {
            label: details?.productDetails?.category1_rel?.name,
            value: details?.productDetails?.category1_rel?.id,
            isOther: details?.productDetails?.category1_rel?.is_other,
          },
        }),
        ...(details?.productDetails?.category2_rel && {
          category_level2: {
            label: details?.productDetails?.category2_rel?.name,
            value: details?.productDetails?.category2_rel?.id,
            isOther: details?.productDetails?.category2_rel?.is_other,
          },
        }),
        ...(details?.productDetails?.category3_rel && {
          category_level3: {
            label: details?.productDetails?.category3_rel?.name,
            value: details?.productDetails?.category3_rel?.id,
            isOther: details?.productDetails?.category3_rel?.is_other,
          },
        }),
        ...(details?.other_category_name && {
          category_name: details?.other_category_name,
        }),
        category_name: details?.other_category_name,

        location: details?.location,
        identification_number: details?.identification_number,
        qr_code: details?.barcode,

        make: details?.make,
        model: details?.model,

        size: details?.size,
        quantity: details?.quantity,

        base_date: details?.base_date
          ? datePickerFormat(details?.base_date)
          : null,
        installation_date: details?.installation_date
          ? datePickerFormat(details?.installation_date)
          : null,
        internal_note: details?.internal_note,
        active: details?.is_active,
        status_id: details?.status?.id
          ? {
              label: details?.status?.display_name,
              value: details?.status?.id,
            }
          : null,
      });
    } else {
      reset();
    }
  }, [assetDetails]);

  useEffect(() => {
    setServerErrors((prev) =>
      equipmentClassDropdownError?.status === 404
        ? [
            ...prev,
            {
              field: 'product',
              message: t(
                'attributes.purchase_order.pleaseChooseRightCombination'
              ),
            },
          ]
        : prev?.filter((error) => error?.field !== 'product')
    );
  }, [equipmentClassDropdownError]);

  return assetDetails?.loading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">General Information</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="equipment_type"
            control={control}
            rules={{
              required: 'Equipment Type field is required',
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label="Equipment Type"
                options={equipmentTypesDropdownData}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  trigger('equipment_type');
                  dispatch(resetEquipmentClassDropdownList());
                  dispatch(resetCategoryLevels1DropdownList());
                  dispatch(resetCategoryLevels2DropdownList());
                  dispatch(resetCategoryLevels3DropdownList());

                  reset({
                    ...watch(),
                    equipment_type: newValue,
                    product: null,
                    category_level1: null,
                    category_level2: null,
                    category_level3: null,
                    category_name: '',
                    asset_name: '',
                  });
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={equipmentTypesDropdownLoading}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
                disabledDropdown={id}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.product')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.productCatalogue.product')}
                options={equipmentClassDropdownData}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  trigger('product');
                  dispatch(resetCategoryLevels1DropdownList());
                  dispatch(resetCategoryLevels2DropdownList());
                  dispatch(resetCategoryLevels3DropdownList());

                  reset({
                    ...watch(),
                    product: newValue,
                    category_level1: null,
                    category_level2: null,
                    category_level3: null,
                    category_name: '',
                    asset_name: '',
                  });
                }}
                isLoadingData={equipmentClassDropdownLoading}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          {selectedEquipmentClass && categoryLevels1DropdownData?.length ? (
            <Controller
              name="category_level1"
              control={control}
              rules={{
                required: `${t('attributes.productCatalogue.categoryLevel1')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.productCatalogue.categoryLevel1')}
                  options={categoryLevels1DropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      onChange(newValue);
                    }
                    trigger('category_level1');
                    dispatch(resetCategoryLevels2DropdownList());
                    dispatch(resetCategoryLevels3DropdownList());
                    reset({
                      ...watch(),
                      category_level1: newValue,
                      category_level2: null,
                      category_level3: null,
                      category_name: '',
                      asset_name: '',
                    });
                  }}
                  isLoadingData={categoryLevels1DropdownLoading}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          ) : null}
          {selectedCategoryLevel1 && categoryLevels2DropdownData?.length ? (
            <Controller
              name="category_level2"
              control={control}
              rules={{
                required: `${t('attributes.productCatalogue.categoryLevel2')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.productCatalogue.categoryLevel2')}
                  options={categoryLevels2DropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      onChange(newValue);
                    }
                    trigger('category_level2');
                    dispatch(resetCategoryLevels3DropdownList());
                    reset({
                      ...watch(),
                      category_level2: newValue,
                      category_level3: null,
                      category_name: '',
                      asset_name: '',
                    });
                  }}
                  isLoadingData={categoryLevels2DropdownLoading}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          ) : null}
          {selectedCategoryLevel2 && categoryLevels3DropdownData?.length ? (
            <Controller
              name="category_level3"
              control={control}
              rules={{
                required: `${t('attributes.productCatalogue.categoryLevel3')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.productCatalogue.categoryLevel3')}
                  options={categoryLevels3DropdownData}
                  value={value}
                  onChange={(e, newValue) => {
                    if (newValue) {
                      onChange(newValue);
                    }
                    trigger('category_level3');
                    dispatch(resetProductEnhancementData());
                    reset({
                      ...watch(),
                      category_level3: newValue,
                      category_name: '',
                      asset_name: '',
                    });
                  }}
                  isLoadingData={categoryLevels3DropdownLoading}
                  error={error}
                  helperText={error ? error.message : ''}
                />
              )}
            />
          ) : null}
          {selectedCategoryLevel1?.isOther ||
          selectedCategoryLevel2?.isOther ||
          selectedCategoryLevel3?.isOther ? (
            <Controller
              name="category_name"
              control={control}
              rules={{
                required: `${t('attributes.productCatalogue.asset')} ${t(`attributes.productCatalogue.categoryLevel${selectedCategoryLevel1?.isOther ? 1 : selectedCategoryLevel2?.isOther ? 2 : 3}`)} ${t('attributes.name')} ${Validation.general.required}`,
                maxLength: {
                  value: 80,
                  message: Validation.general.contactName,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label={`${t('attributes.productCatalogue.asset')} ${t(`attributes.productCatalogue.categoryLevel${selectedCategoryLevel1?.isOther ? 1 : selectedCategoryLevel2?.isOther ? 2 : 3}`)} ${t('attributes.name')}`}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('category_name');
                    trigger('asset_name');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(name)}
                />
              )}
            />
          ) : null}
          <Controller
            name="asset_name"
            control={control}
            rules={{
              required: `${t('attributes.property.asset_name')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.property.asset_name')}
                fullWidth
                disabled={true}
                value={
                  (selectedCategoryLevel1?.isOther ||
                    selectedCategoryLevel2?.isOther ||
                    selectedCategoryLevel3?.isOther) &&
                  value &&
                  categoryName
                    ? `${value}(${categoryName})`
                    : value
                }
                onChange={(e) => {
                  onChange(e);
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="status_id"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label="Status"
                options={assetStatusList}
                value={value}
                onChange={(e, newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  trigger('status_id');
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={false}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
          <Controller
            name="location"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label="Location"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
                <Typography variant="body2">
                  {t('attributes.property.locationInfoText')}
                </Typography>
              </Box>
            )}
          />

          <Controller
            name="identification_number"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Box
                sx={{ display: 'flex', flexDirection: 'column', rowGap: '4px' }}
              >
                <CustomTextField
                  label="Identification Number"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
                <Typography variant="body2">
                  {t('attributes.property.identificationNumberInfo')}
                </Typography>
              </Box>
            )}
          />
          {false && (
            <Controller
              name="serial"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Serial"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          )}
          <Controller
            name="qr_code"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.property.qr_code')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="make"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Make"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="model"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Model"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>

          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="size"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Size"
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
            <Controller
              name="quantity"
              control={control}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  label="Quantity"
                  fullWidth
                  value={value != null ? value : 1}
                  disabled={true}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                  isRequired={false}
                />
              )}
            />
          </Box>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <CustomDatePicker
              control={control}
              name="base_date"
              label={t('attributes.property.manufacture_date')}
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
            <CustomDatePicker
              control={control}
              name="installation_date"
              label="Installation Date"
              pickerType="date"
              defaultValue={null}
              trigger={trigger}
            />
          </Box>
          <Controller
            name="internal_note"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.address,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label="Internal note"
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('internal_note');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      {id && (
        <CustomCard>
          <Box sx={{ padding: '0 16px 0 16px' }}>
            <CommonCheckbox
              control={control}
              name="active"
              label={t('common.active')}
              isRequired={false}
              showMessage={true}
              messageOnChecked={t('label.superAdmin.assetActiveCheckbox')}
              messageOnUnchecked={t('label.superAdmin.assetInactiveCheckbox')}
            />
          </Box>
        </CustomCard>
      )}
    </FormWrapper>
  );
};

export default AssetForm;
