import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Divider, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import {
  DATE_FORMAT_API,
  DRAFT_STATUS,
  PURCHASE_ORDER_STATUS,
} from '../../../constants/Constants';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import {
  createPurchaseOrderProduct,
  deletePurchaseOrderProduct,
  getPurchaseOrders,
  updatePurchaseOrder,
  updatePurchaseOrderProduct,
} from '../../../store/purchaseOrders/api';
import {
  resetCreatePurchaseOrderProduct,
  resetUpdatePurchaseOrder,
} from '../../../store/purchaseOrders/reducer';
import { getWorkOrderQuotation } from '../../../store/workOrder/api';
import { resetDefectQuotes } from '../../../store/workOrder/reducer';
import {
  formatDateAPI,
  formatDateForAPI,
  formatStatus,
  formattedDate,
  loggedInUserDetail,
} from '../../../utils';
import { Delete, Edit } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AddPurchaseOrder from './AddPurchaseOrder';
import AddPurchaseOrderProduct from './AddPurchaseOrderProduct';
import generatePDF from './DonwloadPurchaseOrderPDF';
import EditPurchaseOrderProduct from './EditPurchaseOrderProduct';
import PreviewPurchaseOrder from './PreviewPurchaseOrder';

const defaultValues = {
  equipment_type: null,
  product_type: null,
  search: '',
};

// Styles
const StyledHeader = styled(Box)({
  padding: '16px',
  display: 'flex',
  justifyContent: 'space-between',
  backgroundColor: 'rgba(235, 235, 235, 0.2)',
});

const StyledContentBox = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  padding: '16px 0 16px 16px',
  width: '50%',
}));

const StyledColumnBox = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

const PurchaseOrderDetail = ({
  workOrderId,
  nextStepTitle,
  setOpenEditPurchaseDetail,
  setStepTitle,
  isFromSideBar,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState();
  const [selectedProductsError, setSelectedProductsError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [editProductDetail, setEditProductDetail] = useState(null);
  const [editProductFormOpen, setEditProductFormOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletePurchaseOrderDetail, setDeletePurchaseOrderDetail] = useState(
    {}
  );
  const [open, setOpen] = useState(false);
  const [addProductFromQuote, setAddProductFromQuote] = useState(false);

  const {
    isLoading: purchaseOrderDetailLoading,
    error: purchaseOrderDetailError,
    purchaseOrderDetail,
  } = useSelector((state) => state.purchaseOrders.updatePurchaseOrder);

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { purchaseOrdersList } = useSelector(
    (state) => state.purchaseOrders.get
  );

  const {
    isLoading: createdPurchaseOrderProductLoading,
    createdPurchaseOrderProduct,
  } = useSelector((state) => state.purchaseOrders.createProduct);

  const { isLoading: updatePurchaseOrderLoading } = useSelector(
    (state) => state.purchaseOrders.updatePurchaseOrder
  );

  const {
    isLoading: updatePurchaseOrderProductLoading,
    updatePurchaseOrderProductData,
  } = useSelector((state) => state.purchaseOrders.updatePurchaseOrderProduct);

  const {
    isLoading: deletePurchaseOrderProductLoading,
    deletePurchaseOrderProductData,
  } = useSelector((state) => state.purchaseOrders.deletePurchaseOrderProduct);

  const { defectQuotesList } = useSelector(
    (state) => state.workOrder.defectQuotes
  );

  const user = loggedInUserDetail();

  const { handleSubmit, control, watch, reset, setValue, trigger } = useForm({
    defaultValues: defaultValues,
  });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    const detail = purchaseOrdersList?.find((item) =>
      nextStepTitle ? item.poid === nextStepTitle.step : item.uuid === uuid
    );

    setPurchaseOrderDetails(detail);
  }, [purchaseOrdersList, uuid]);

  useEffect(() => {
    if (!createdPurchaseOrderProductLoading) {
      if (Object.keys(createdPurchaseOrderProduct).length > 0) {
        setOpenAddProduct(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: t(
              'attributes.purchase_order.purchaseOrderAddProductSuccessMsg'
            ),
          })
        );
      }
    }
  }, [createdPurchaseOrderProductLoading, createdPurchaseOrderProduct]);

  useEffect(() => {
    if (
      createdPurchaseOrderProduct ||
      updatePurchaseOrderProductData ||
      deletePurchaseOrderProductData ||
      purchaseOrderDetail
    ) {
      setIsLoading(true);
      dispatch(
        getPurchaseOrders({
          work_order_uuid: workOrderId,
          limit: -1,
        })
      ).finally(() => {
        setIsLoading(false);
      });
    }
  }, [
    createdPurchaseOrderProduct,
    updatePurchaseOrderProductData,
    deletePurchaseOrderProductData,
    purchaseOrderDetail,
  ]);

  useEffect(() => {
    if (!updatePurchaseOrderProductLoading) {
      if (updatePurchaseOrderProductData?.data?.length > 0) {
        setEditProductFormOpen(false);
        setDisableSave(false);

        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updatePurchaseOrderProductData?.message,
          })
        );
      }
    }
  }, [updatePurchaseOrderProductLoading, updatePurchaseOrderProductData]);

  useEffect(() => {
    if (!deletePurchaseOrderProductLoading) {
      if (deletePurchaseOrderProductData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deletePurchaseOrderProductData?.message,
          })
        );
      }
    }
  }, [deletePurchaseOrderProductLoading, deletePurchaseOrderProductData]);

  useEffect(() => {
    if (!purchaseOrderDetailLoading) {
      if (purchaseOrderDetail) {
        setOpen(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: purchaseOrderDetail.message,
          })
        );
      }
    }
  }, [purchaseOrderDetailLoading, purchaseOrderDetailError]);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    return () => {
      dispatch(resetCreatePurchaseOrderProduct());
      dispatch(resetDefectQuotes());
    };
  }, []);

  useEffect(() => {
    dispatch(
      getWorkOrderQuotation({
        id: workOrderId,
      })
    );
  }, [addProductFromQuote]);

  // Calculate subtotal, tax, and total
  const total = purchaseOrderDetails?.items?.reduce(
    (acc, item) => acc + Number(item?.price) * item?.qty,
    0
  );

  const productsIdFromQuotes = defectQuotesList?.quotation_items?.map(
    (item) => ({ uuid: item?.product_catalogue?.uuid, qty: item?.qty })
  );

  if (isLoading)
    return (
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          minHeight: 'calc(100vh - 150px)',
        }}
      >
        <CustomCircularLoader />
      </Box>
    );

  const columns = [
    {
      field: 'product',
      headerName: t('attributes.purchase_order.product'),
      flex: 1, // 40% width
      sortable: false,
    },
    {
      field: 'description',
      headerName: t('attributes.description'),
      flex: 2, // 10% width
      sortable: false,
    },
    {
      field: 'qty',
      headerName: t('attributes.quantity'),
      flex: 'auto',
      align: 'right',
      sortable: false,
    },
    {
      field: 'unit_price',
      headerName: t('attributes.unit_price'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    {
      field: 'total',
      headerName: t('attributes.total'),
      flex: 'auto',
      align: 'center',
      sortable: false,
    },
    ...(purchaseOrderDetails?.status === PURCHASE_ORDER_STATUS.DRAFT
      ? [
          {
            field: 'actions',
            headerName: t('attributes.actions'),
            flex: 'auto',
            sortable: false,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Edit
                  onClick={() => {
                    setEditProductDetail(row);
                    setEditProductFormOpen(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setDeleteConfirmation(true);
                    setDeletePurchaseOrderDetail({
                      purchase_order_id: row?.uuid,
                      purchase_order_item_uuid: row?.product_uuid,
                    });
                  }}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const rows = purchaseOrderDetails?.items?.map((item, index) => ({
    id: index,
    product: item.product_catalogue?.product_name,
    description: item.product_catalogue?.description || '-',
    qty: item.qty,
    unit_price: Number(item?.price)?.toFixed(2),
    total: (Number(item?.price) * item.qty)?.toFixed(2),
    product_catalogue_uuid: item.product_catalogue_uuid,
    product_catalogue_type: item.product_catalogue_type,
    uuid: purchaseOrderDetails.uuid,
    product_uuid: item.uuid,
  }));

  const onSubmit = (formData) => {
    setDisableSave(true);

    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      setSelectedProductsError(t('common.select_at_least_one_product'));
      setDisableSave(false);

      return;
    }

    const productRequests = formData.selectedProducts.map((product) => ({
      product_catalogue_uuid: product.uuid,
      product_name: product.product_name,
      product_description: product.product_description,
      qty: product.qty,
      price: parseFloat(product.cost)?.toFixed(2),
      currency: product.sell_currency,
      um: product.um,
      product_catalogue_type: product.product_catalogue_type,
    }));

    dispatch(
      createPurchaseOrderProduct({
        purchase_order_id: purchaseOrderDetails?.uuid,
        data: { data: productRequests },
      })
    );
  };

  const handleUpdatePurchaseOrderStatus = (status) => {
    const request = {
      creation_date: formatDateAPI(
        purchaseOrderDetails?.creation_date,
        DATE_FORMAT_API
      ),
      supplier_uuid: purchaseOrderDetails.supplier?.uuid,
      work_order_uuid: workOrderId
        ? workOrderId
        : purchaseOrderDetails?.work_order?.uuid,
      description: purchaseOrderDetails?.description,
      delivery_instruction: purchaseOrderDetails?.delivery_instruction,
    };

    dispatch(
      updatePurchaseOrder({
        purchase_order_id: purchaseOrderDetails?.uuid,
        data: { ...request, status: status },
      })
    ).then(() => {
      setOpenPreview(false);
    });
  };

  const onSubmitEditProduct = async (formData) => {
    setDisableSave(true);
    const createData = {
      product_name: formData.product_name,
      qty: formData.qty,
      price: formData.sell_price,
      product_catalogue_uuid: formData?.product_catalogue_uuid,
      product_catalogue_type: formData.product_catalogue_type,
    };

    dispatch(
      updatePurchaseOrderProduct({
        purchase_order_id: formData?.uuid,
        purchase_order_item_uuid: formData?.product_uuid,
        data: createData,
      })
    );
  };

  const onUpdatePurchaseOrder = (formData) => {
    const request = {
      creation_date: formatDateForAPI(formData?.creation_date),
      supplier_uuid: formData.supplier?.value,
      work_order_uuid: workOrderId
        ? workOrderId
        : purchaseOrderDetails?.work_order?.uuid,
      description: formData?.description,
      delivery_instruction: formData?.delivery_instruction,
    };

    dispatch(
      updatePurchaseOrder({
        purchase_order_id: formData?.uuid,
        data: { ...request, status: formData?.status },
      })
    );
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenAddProduct(false);
            setOpenConfirmationModal(false);
            setEditProductFormOpen(false);
            setOpen(false);
          }}
        />
      )}
      {deleteConfirmation && (
        <ConfirmationModal
          title={t('deleteConfirmationModal.title')}
          description={t('deleteConfirmationModal.description')}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onConfirm={() => {
            dispatch(
              deletePurchaseOrderProduct({
                purchase_order_id: deletePurchaseOrderDetail?.purchase_order_id,
                purchase_order_item_uuid:
                  deletePurchaseOrderDetail?.purchase_order_item_uuid,
              })
            );
            setDeleteConfirmation(false);
          }}
        />
      )}
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        {isFromSideBar && (
          <MainWrapper
            defaultPadding="0px 0px 16px 0px"
            title={t('attributes.work_order.purchase_order')}
            variant="body1"
            isStep={true}
            step={
              nextStepTitle ? nextStepTitle?.step : purchaseOrderDetails?.poid
            }
            handleDefaultStep={() => {
              setStepTitle
                ? setStepTitle()
                : navigate(
                    `/${ROUTENAME.COMMERCIAL}/${ROUTENAME.PURCHASE_ORDER}`
                  );
              dispatch(resetUpdatePurchaseOrder());
            }}
          ></MainWrapper>
        )}
      </Box>
      {isLoading || updatePurchaseOrderLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isLoading &&
        !updatePurchaseOrderLoading && (
          <Box sx={{ borderRadius: '6px 6px 0 0' }}>
            <Box>
              <Box
                sx={{
                  padding: '12px 16px 12px 16px',
                  display: 'flex',
                  gap: '8px',
                }}
              >
                {purchaseOrderDetails?.status ===
                  PURCHASE_ORDER_STATUS.DRAFT && (
                  <>
                    {rows?.length === 0 && (
                      <CustomButton
                        text={t('attributes.purchase_order.add_from_quote')}
                        color="secondary"
                        sx={{ height: '52%' }}
                        startIcon={<AddCircleOutlineOutlinedIcon />}
                        onClick={() => {
                          setOpenAddProduct(true);
                          reset(defaultValues);
                          setSelectedProductsError(null);
                          setAddProductFromQuote(true);
                        }}
                      />
                    )}
                    <CustomButton
                      text={t('attributes.purchase_order.add_product')}
                      color="secondary"
                      sx={{ height: '52%' }}
                      startIcon={<AddCircleOutlineOutlinedIcon />}
                      onClick={() => {
                        setOpenAddProduct(true);
                        setAddProductFromQuote(false);
                        reset(defaultValues);
                        setSelectedProductsError(null);
                      }}
                    />
                    {/* <CustomButton
                      text="Delete"
                      color="secondary"
                      sx={{ height: '52%' }}
                      startIcon={<DeleteOutlineOutlinedIcon />}
                      onClick={() => ({})}
                    /> */}
                  </>
                )}
                {purchaseOrderDetails?.status ===
                  PURCHASE_ORDER_STATUS.CREATED && (
                  <CustomButton
                    text={t('attributes.set_as_draft')}
                    color="secondary"
                    sx={{ height: '52%' }}
                    startIcon={<EditNoteOutlinedIcon />}
                    onClick={() =>
                      handleUpdatePurchaseOrderStatus(
                        PURCHASE_ORDER_STATUS.DRAFT
                      )
                    }
                  />
                )}
                {purchaseOrderDetails?.status ===
                  PURCHASE_ORDER_STATUS.SUBMITTED && (
                  <CustomButton
                    text={t('attributes.preview')}
                    color="secondary"
                    sx={{ height: '52%' }}
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    onClick={() => {
                      setOpenPreview(true);
                      if (user.company_uuid) {
                        dispatch(editCompany(user?.company_uuid));
                      }
                    }}
                  />
                )}
              </Box>
              <Divider />
              <StyledHeader>
                <Typography variant="body1">
                  {t('attributes.purchase_order.purchase_order_details')}
                </Typography>
                {purchaseOrderDetails?.status ===
                  PURCHASE_ORDER_STATUS.DRAFT && (
                  <ModeEditOutlineOutlinedIcon
                    onClick={() =>
                      setOpenEditPurchaseDetail
                        ? setOpenEditPurchaseDetail(true)
                        : setOpen(true)
                    }
                  />
                )}
              </StyledHeader>
              <Divider />
              <Box sx={{ display: 'flex', width: '100%' }}>
                <StyledContentBox>
                  <StyledColumnBox>
                    {/* Details section */}
                    {[
                      {
                        label: t('attributes.purchase_order.purchase_order_id'),
                        value: purchaseOrderDetails?.poid,
                      },
                      {
                        label: t('attributes.purchase_order.status'),
                        value: formatStatus(purchaseOrderDetails?.status),
                      },
                      {
                        label: t('attributes.purchase_order.created_on'),
                        value: formattedDate(
                          purchaseOrderDetails?.creation_date
                        ),
                      },
                    ].map(({ label, value }) => (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                        key={label}
                      >
                        <Typography minWidth="160px" variant="body1">
                          {label}:
                        </Typography>
                        <Typography variant="body2">{value}</Typography>
                      </Box>
                    ))}
                  </StyledColumnBox>
                </StyledContentBox>
                <StyledContentBox>
                  <StyledColumnBox>
                    {/* Additional details section */}
                    {[
                      {
                        label: t('attributes.purchase_order.supplier'),
                        value: purchaseOrderDetails?.supplier?.name,
                      },
                      {
                        label: t('attributes.description'),
                        value: purchaseOrderDetails?.description || '-',
                      },
                      {
                        label: t(
                          'attributes.purchase_order.delivery_instruction'
                        ),
                        value:
                          purchaseOrderDetails?.delivery_instruction || '-',
                      },
                    ].map(({ label, value }) => (
                      <Box
                        sx={{ display: 'flex', alignItems: 'center' }}
                        key={label}
                      >
                        <Typography minWidth="200px" variant="body1">
                          {label}:
                        </Typography>
                        <Typography variant="body2">{value}</Typography>
                      </Box>
                    ))}
                  </StyledColumnBox>
                </StyledContentBox>
              </Box>
              <Divider />
            </Box>

            {/* Table */}
            <Box sx={{ padding: '8px 16px' }}>
              <Box
                sx={{
                  border: `1px solid ${DIVIDER_COLOR}`,
                  borderRadius: '6px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <CustomGridTable
                  columns={columns}
                  rows={rows}
                  total={rows?.length}
                  checkboxSelection={false}
                  paginationRequired={false}
                  filterHeight={475}
                  disableColumnMenu={true}
                  isLoading={false}
                  isTableView={true}
                />

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    py: 2,
                    pr: 1,
                    gap: 1,
                    backgroundColor: 'rgba(235, 235, 235, 0.2)',
                    border: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Typography variant="body1">{t('common.total')}:</Typography>
                  <Typography variant="body1">{total?.toFixed(2)}</Typography>
                </Box>
              </Box>
            </Box>
            <Divider />

            {/* Footer button */}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'end',
                padding: '8px 16px 8px 16px',
              }}
            >
              {purchaseOrderDetails?.status === DRAFT_STATUS && (
                <CustomButton
                  text={t('attributes.create')}
                  startIcon={<AddIcon />}
                  disabled={purchaseOrderDetails?.items.length < 1}
                  onClick={() =>
                    handleUpdatePurchaseOrderStatus(
                      PURCHASE_ORDER_STATUS.CREATED
                    )
                  }
                />
              )}
              {purchaseOrderDetails?.status ===
                PURCHASE_ORDER_STATUS.CREATED && (
                <Box sx={{ display: 'flex', gap: '8px' }}>
                  <CustomButton
                    text={t('attributes.preview')}
                    startIcon={<RemoveRedEyeOutlinedIcon />}
                    color="inherit"
                    onClick={() => {
                      setOpenPreview(true);
                      if (user.company_uuid) {
                        dispatch(editCompany(user?.company_uuid));
                      }
                    }}
                  />
                  <CustomButton
                    text={t('common.submit')}
                    startIcon={<SendOutlinedIcon />}
                    disabled={updatePurchaseOrderLoading}
                    onClick={() =>
                      handleUpdatePurchaseOrderStatus(
                        PURCHASE_ORDER_STATUS.SUBMITTED
                      )
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        )
      )}
      {openAddProduct && (
        <SwipeableDrawer
          open={openAddProduct}
          title={
            addProductFromQuote
              ? `${t('attributes.purchase_order.add_from_quote')} Q-${defectQuotesList?.id || '-'}`
              : t('attributes.purchase_order.add_product')
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpenAddProduct(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.add')}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddPurchaseOrderProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            selectedProductsError={selectedProductsError}
            setSelectedProductsError={setSelectedProductsError}
            productsIdFromQuotes={productsIdFromQuotes}
            isFromDefectQuote={addProductFromQuote}
          />
        </SwipeableDrawer>
      )}
      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={purchaseOrderDetails?.poid}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(purchaseOrderDetails, company);
              }}
            />,
            purchaseOrderDetails?.status !==
              PURCHASE_ORDER_STATUS.SUBMITTED && (
              <CustomButton
                text={t('common.submit')}
                disabled={updatePurchaseOrderLoading}
                startIcon={<SendOutlinedIcon />}
                onClick={() =>
                  handleUpdatePurchaseOrderStatus(
                    PURCHASE_ORDER_STATUS.SUBMITTED
                  )
                }
              />
            ),
          ]}
          width={653}
        >
          <PreviewPurchaseOrder
            companyData={company}
            purchaseOrderDetails={purchaseOrderDetails}
            isLoadingCompany={isLoadingCompany}
          />
        </SwipeableDrawer>
      )}
      {editProductDetail && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={editProductFormOpen}
          title={`${editProductDetail?.product}`}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEditProductFormOpen(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.save')}
              disabled={updatePurchaseOrderLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                handleSubmit(onSubmitEditProduct)();
              }}
            />,
          ]}
          width={653}
        >
          <EditPurchaseOrderProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            data={editProductDetail}
          />
        </SwipeableDrawer>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.purchase_order.edit_purchase_order')}
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              startIcon={<SaveOutlinedIcon />}
              text={t('attributes.save')}
              onClick={handleSubmit(onUpdatePurchaseOrder)}
              disabled={purchaseOrderDetailLoading}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddPurchaseOrder
            control={control}
            setValue={setValue}
            trigger={trigger}
            reset={reset}
            nextStepTitle={{ step: purchaseOrderDetails?.poid }}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default PurchaseOrderDetail;
