import { DO_AND_CHARGE, PERIODIC } from './Constants';

export const DROPDOWN = {
  CONTRACTS: {
    CONTRACT_TYPE: [
      {
        invoiceType: 'M',
        renderComponent: 0,
        value: PERIODIC,
        label: 'Periodic',
      },
      {
        renderComponent: 1,
        value: DO_AND_CHARGE,
        label: 'Custom',
      },
    ],
    CPI_INCREASE: [
      { percentage: '0', value: 'n_a', label: 'N/A' },
      { percentage: '4', value: 'not_known', label: 'Not Known' },
      { percentage: null, value: 'other', label: 'Other' },
    ],
  },
};
