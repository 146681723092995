import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import { DisabledByDefaultOutlined } from '@mui/icons-material';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import { FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR, PRIMARY, SECONDARY } from '../../constants/Colors';
import { exportToCSV } from '../../constants/common';
import {
  APPROVED,
  CUSTOMER_DEFECT_QUOTE_LIST,
  CUSTOMER_QUOTATION_STATUS,
  DATE_FORMAT_API,
  DRAFT_STATUS,
  SUBMIT_TO_CUSTOMER_STATUS,
  WO_CREATED,
  WORK_ORDER_TYPE_REPAIR,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownDataHook from '../../hooks/getDropdownDataHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import { getProperty, getPropertyList } from '../../store/property/api';
import {
  getQuotesList,
  getQuotesPropertyDropdownList,
} from '../../store/quotes/api';
import { resetQuoteList } from '../../store/quotes/reducer';
import { getCustomerList } from '../../store/users/customer/api';
import {
  createWorkOrder,
  getDefectQuotesDetail,
  getDefectsQuotesList,
  updateDefectQuotationStatus,
} from '../../store/workOrder/api';
import {
  resetCreateWorkOrder,
  resetDefectQuotesDetail,
} from '../../store/workOrder/reducer';
import {
  formatPriceWithDecimalValue,
  formattedDate,
  loggedInUserDetail,
} from '../../utils';
import { Generate, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { FilterSection } from '../CommonComponents/FilterComponent';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import generatePDF from '../WorkOrder/DefectQuotePDF';
import { formatDefectQuotesStatus } from '../WorkOrder/DefectQuotes';
import PreviewDefectQuoteInvoice from '../WorkOrder/PreviewDefectQuoteInvoice';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  customer: null,
  property: null,
  status: null,
  dateRange: {
    fromDueDate: null,
    toDueDate: null,
    fromQuoteDate: null,
    toQuoteDate: null,
  },
};

const SuperAdminDefectsQoutes = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [quote, setQuote] = useState('');

  const [propertyDetailsList, setPropertyDetailsList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [customerOption, setCustomerOptions] = useState(null);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [propertyData, setPropertyData] = useState([]);
  const [openPreview, setOpenPreview] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);

  const { isLoading, quoteList, total, totalCount } = useSelector(
    (state) => state.quotes.get
  );
  const { propertyList } = useSelector((state) => state.property);
  const { data, isLoading: defectQuoteDetailLoading } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );
  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const user = loggedInUserDetail();

  const quotesPropertyList = getDropdownDataHook({
    data: propertyDetailsList,
    labelName: 'property_name',
    valueName: 'uuid',
  });

  const findProperty = (property_uuid) => {
    const property = propertyDetailsList?.find(
      (prop) => prop.uuid === property_uuid
    );

    return property;
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'work_order_id',
        headerName: t('attributes.work_order.work_order_ID'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'quote_id',
        headerName: t('attributes.work_order.quote_id'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'customer',
        headerName: t('roles.customer'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property_name',
        headerName: t('attributes.property.propertyName'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'property_address',
        headerName: `${t('attributes.property.property')} ${t('attributes.address')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('attributes.property.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.status ? formatDefectQuotesStatus(row?.status, t) : '-',
      },
      {
        field: 'quote_date',
        headerName: t('attributes.work_order.quote_date'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.quote_date ? formattedDate(row?.quote_date) : '-',
      },
      {
        field: 'due_date',
        headerName: t('attributes.work_order.due_date'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.due_date ? formattedDate(row?.due_date) : '-',
      },
      {
        field: 'amount',
        headerName: t('attributes.amount'),
        flex: 1,
        renderCell: ({ row }) => `${formatPriceWithDecimalValue(row.amount)}`,
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
            <View
              onClick={() => {
                if (user?.company_uuid && row?.property_uuid) {
                  setIsPropertyLoading(true);
                  dispatch(editCompany(user?.company_uuid));
                  dispatch(getProperty(row?.property_uuid))
                    .then((res) => setPropertyData(res?.payload?.data[0]))
                    .finally(() => setIsPropertyLoading(false));
                }
                if (row?.id) {
                  dispatch(
                    getDefectQuotesDetail({
                      work_order_id: row?.uuid,
                      quote_id: row?.quote_uuid,
                    })
                  );
                }
                setQuote(row);
                setOpenPreview(true);
              }}
            />
            {(row?.status === APPROVED || row?.status === WO_CREATED) && (
              <Generate
                onClick={() => {
                  dispatch(
                    getDefectsQuotesList({
                      workorder_uuid: row?.uuid,
                      limit: -1,
                    })
                  ).then((res) => generateWorkOrder(row, res?.payload?.data));
                }}
                disabled={row?.status === WO_CREATED}
              />
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = quoteList?.map((quote, index) => ({
    ...quote,
    uuid: quote?.work_order?.uuid,
    quote_uuid: quote?.uuid,
    work_order_id: quote?.work_order?.woid || index + 1,
    quote_id: `Q-${quote?.id}` || index + 1,
    customer: quote?.customer_details?.customer_name || '-',
    property_name: findProperty(quote?.property_uuid)?.property_name,
    property_address: findProperty(quote?.property_uuid)?.address,
    status: quote?.status,
    quote_date: quote?.quote_date || '',
    due_date: quote?.due_date || '',
    amount: quote?.quotation_items.reduce(
      (sum, item) => sum + item?.sell_price * item?.qty,
      0
    ),
  }));
  const generateWorkOrder = (selected = {}, defects = {}) => {
    const bodyData = {
      customer_uuid: selected?.customer_uuid,
      property_uuid: selected?.property_uuid,
      property_zone: selected?.work_order?.property_zone,
      due_date: moment().add(1, 'month').format(DATE_FORMAT_API),
      work_order_type: WORK_ORDER_TYPE_REPAIR,
      defects: defects?.map((def) => ({
        asset_uuid: def?.asset_uuid,
        defect_uuid: def?.uuid,
      })),
      parent_work_order_uuid: selected?.work_order_uuid,
      parent_quotation_approved_uuid: selected?.quote_uuid,
      work_order_name: `W-${selected?.work_order?.id} - Repair`,
    };

    dispatch(createWorkOrder({ data: bodyData })).then((res) => {
      dispatch(
        snackbarToggle({
          isOpen: true,
          isErrorMsg: false,
          msg: res?.payload?.message,
        })
      );
      debouncedFetchData();
      setOpenPreview(false);
      dispatch(resetCreateWorkOrder());
    });
  };

  const getQoutes = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getQuotesList({
        limit: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        customer_uuid: filters?.customer?.value,
        propertyUUID: filters?.property?.value,
        status: filters?.status?.value,
        fromDueDate: filters.dateRange?.fromDueDate,
        toDueDate: filters.dateRange?.toDueDate,
        fromQuoteDate: filters.dateRange?.fromQuoteDate,
        toQuoteDate: filters.dateRange?.toQuoteDate,
        search: searchText,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => setIsInitialLoading(false));
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    filters,
    searchText,
    refresh,
  ]);

  // From dashboard Chart
  useEffect(() => {
    if (queryStatus) {
      const selected = CUSTOMER_DEFECT_QUOTE_LIST?.find(
        (d) => d.value === queryStatus
      );

      setFilters((prev) => ({ ...prev, status: selected }));
      setIsFilterComponentVisible(true);
    }
  }, [queryStatus]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters?.customer?.value,
    };

    dispatch(getPropertyList(req));
  }, [filters?.customer]);

  useEffect(() => {
    dispatch(getQuotesPropertyDropdownList());

    return () => {
      dispatch(resetQuoteList());
      dispatch(resetDefectQuotesDetail());
      dispatch(resetCreateWorkOrder());
    };
  }, []);

  // For customer dropdown
  useEffect(() => {
    dispatch(getCustomerList({ limit: -1 })).then((res) => {
      const options = res?.payload?.data?.map((c) => ({
        label: c?.customer_name,
        value: c?.uuid,
      }));

      setCustomerOptions(options || []);
    });
  }, []);

  useEffect(() => {
    setPropertyDetailsList(propertyList?.data?.data);
  }, [propertyList]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getQoutes, 500), [getQoutes]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<DisabledByDefaultOutlined />}
      title={`${t('attributes.work_order.defect')} ${t('attributes.work_order.quote')}`}
      customText={t('attributes.defects_quote.no_defect_quote')}
      filterHeight={filterHeight(isFilterComponentVisible)}
      onlyTitle={true}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        searchText={searchText}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.dateRange.fromDueDate ||
          filters.dateRange.toDueDate ||
          filters.dateRange?.fromQuoteDate ||
          filters.dateRange?.toQuoteDate ||
          filters?.status ||
          filters?.customer ||
          filters?.property
        }
      />
      {isFilterComponentVisible && (
        <FlexStart
          style={{
            background: DIVIDER_COLOR,
            padding: '8px',
            margin: '8px 0',
          }}
        >
          <Autocomplete
            placeholder={t('roles.customer')}
            options={customerOption}
            width="190px"
            value={filters?.customer}
            onChange={(e, newVal) =>
              setFilters((pre) => ({ ...pre, customer: newVal }))
            }
          />
          <Autocomplete
            placeholder={t('attributes.property.property')}
            options={quotesPropertyList}
            width="190px"
            value={filters?.property}
            onChange={(e, newVal) =>
              setFilters((pre) => ({ ...pre, property: newVal }))
            }
          />
          <Autocomplete
            placeholder={t('attributes.property.status')}
            options={CUSTOMER_DEFECT_QUOTE_LIST}
            width="190px"
            value={filters?.status}
            onChange={(e, newVal) =>
              setFilters((pre) => ({ ...pre, status: newVal }))
            }
          />
          <CustomDateRangePicker
            placeholder={t('attributes.invoice.due_date')}
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromDueDate: val[0], toDueDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromDueDate: null, toDueDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromDueDate}
            toDate={filters.dateRange?.toDueDate}
            placement="bottomEnd"
          />
          <CustomDateRangePicker
            placeholder={t('attributes.invoice.quote_date')}
            onOkClick={(val) => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromQuoteDate: val[0], toQuoteDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((prev) => ({
                ...prev,
                dateRange: { fromQuoteDate: null, toQuoteDate: null },
              }));
            }}
            fromDate={filters.dateRange?.fromQuoteDate}
            toDate={filters.dateRange?.toQuoteDate}
            placement="bottomEnd"
          />
        </FlexStart>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const sendDefectQuotation = () => {
    dispatch(
      updateDefectQuotationStatus({
        work_order_id: quote?.work_order_uuid,
        quote_id: quote?.id,
        status: SUBMIT_TO_CUSTOMER_STATUS,
      })
    );
  };

  return (
    <>
      <StyledMainWrapper
        title={`${t('attributes.defects.defects')} ${t('attributes.customer.quotes')}`}
        btn={
          <>
            {/* <CustomButton
              text={t('attributes.superAdmin.add_defect_quote')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {}}
            /> */}
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.SUPER_ADMIN_DEFECT_QUOTES,
                    EXPORT_FILE_NAMES.SUPER_ADMIN_DEFECT_QUOTES
                  );
                }
              }}
            />
          </>
        }
      >
        {!isInitialLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading &&
          totalCount > 0 && (
            <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
              {renderedComponent}
            </Box>
          )
        )}
      </StyledMainWrapper>

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={`Q-${data?.id ? data?.id : '-'}`}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            quote?.status === CUSTOMER_QUOTATION_STATUS.APPROVED && (
              <CustomButton
                text="Create Repair Work Order"
                startIcon={<AddCircleOutlineOutlinedIcon />}
                onClick={() => {
                  dispatch(
                    getDefectsQuotesList({
                      workorder_uuid: quote?.uuid,
                      limit: -1,
                    })
                  ).then((res) => generateWorkOrder(quote, res?.payload?.data));
                }}
              />
            ),
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={
                isPropertyLoading ||
                isLoadingCompany ||
                defectQuoteDetailLoading
              }
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(data, propertyData, company);
              }}
            />,
            quote?.status !== CUSTOMER_QUOTATION_STATUS.PENDING &&
              quote?.status !== DRAFT_STATUS &&
              quote?.status !== CUSTOMER_QUOTATION_STATUS.WO_CREATED && (
                <CustomButton
                  text="Send Defects Quote"
                  disabled={
                    isPropertyLoading ||
                    isLoadingCompany ||
                    quote?.status === CUSTOMER_QUOTATION_STATUS.APPROVED
                  }
                  startIcon={<ForwardToInboxOutlinedIcon />}
                  onClick={sendDefectQuotation}
                  sx={{
                    '&.Mui-disabled': {
                      backgroundColor: PRIMARY,
                      color: `${SECONDARY} !important`,
                      opacity: 0.5,
                    },
                  }}
                />
              ),
          ]}
          width={653}
        >
          <PreviewDefectQuoteInvoice
            companyData={company}
            propertyData={propertyData}
            isPropertyLoading={isPropertyLoading || defectQuoteDetailLoading}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default SuperAdminDefectsQoutes;
