import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { Box, Typography } from '@mui/material';

import { SECONDARY } from '../../../constants/Colors';
import {
  WORK_ORDER_TYPE_ADHOC,
  WORK_ORDER_TYPE_MAINTENANCE,
  WORK_ORDER_TYPE_REPAIR,
  WORK_ORDER_TYPE_ROUTINE,
} from '../../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import {
  getAssetInspectionForm,
  getAssetsList,
} from '../../../store/workOrder/api';
import {
  formatStatus,
  formattedDate,
  transformInspectionForm,
} from '../../../utils';
import { Document, View } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AssetServiceReport from './AssetServiceReport';
import AssetView from './AssetView';
import generatePDF from './DownloadServiceReport';

const Assets = ({
  workOrderUUID,
  property,
  work_order_name,
  frequency,
  work_order_type,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [openServiceReport, setOpenServiceReport] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('base_date');

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [assets, setAssets] = useState(null);
  const [selectedAsset, setSelectedAsset] = useState(null);

  const { isLoading, data } = useSelector(
    (state) => state.workOrder.assetInspectionForm
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'name',
        headerName: `${t('attributes.property.asset')} ${t('attributes.name')}`,
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.name ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {row.name}
            </Typography>
          ) : (
            ''
          ),
      },
      {
        field: 'location',
        headerName: t('attributes.location'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'status',
        headerName: t('attributes.property.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      ...(work_order_type !== WORK_ORDER_TYPE_ADHOC
        ? [
            {
              field: 'base_date',
              headerName: t('attributes.property.manufacture_date'),
              flex: 1,
              sortable: false,
              renderCell: ({ row }) => formattedDate(row?.base_date) || '-',
            },
          ]
        : []),
      ...(work_order_type === WORK_ORDER_TYPE_REPAIR
        ? [
            {
              field: 'inspected_time',
              headerName: t('attributes.property.perform_date'),
              flex: 1,
              sortable: false,
              valueGetter: (value) => formattedDate(value) || '-',
            },
          ]
        : []),
      ...(work_order_type === WORK_ORDER_TYPE_ADHOC
        ? [
            {
              field: 'custom_description',
              headerName: t('attributes.productCatalogue.description'),
              flex: 1,
              sortable: false,
              valueGetter: (value) => value || '-',
            },
          ]
        : []),
      ...(work_order_type === WORK_ORDER_TYPE_ADHOC
        ? [
            {
              field: 'comment',
              headerName: t('attributes.work_order.comment'),
              flex: 1,
              sortable: false,
              valueGetter: (value) => value || '-',
            },
          ]
        : []),
      // it will use in future
      // {
      //   field: 'perform_date',
      //   headerName: `${t('attributes.property.perform')} ${t('attributes.date')}`,
      //   flex: 1,
      //   sortable: false,
      // },
      {
        field: 'view',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box display="flex" gap="8px">
            <View
              onClick={() => {
                setSelectedAsset(row);
                setOpen(true);
              }}
            />
            {(work_order_type === WORK_ORDER_TYPE_ROUTINE ||
              work_order_type === WORK_ORDER_TYPE_MAINTENANCE) && (
              <Document
                onClick={() => {
                  setOpen(false);
                  setSelectedAsset(row);
                  setOpenServiceReport(true);
                  dispatch(
                    getAssetInspectionForm({
                      work_order_id: workOrderUUID,
                      asset_uuid: row.asset_uuid,
                    })
                  );
                }}
              />
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = assets?.data?.map((asset) => ({
    ...asset,
    name: asset?.asset?.label || '-',
    location: asset?.asset?.location || '-',
    status: asset?.task_status || asset?.asset_status || '-',
    base_date: asset?.asset?.base_date,
    // perform_date: formattedDate(asset?.due_date) || '-',
  }));

  const inspectionFormData = transformInspectionForm(data?.[0]);

  const getAssets = useCallback(() => {
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getAssetsList({
        limit: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        workorder_uuid: workOrderUUID,
      })
    )
      .then((res) => setAssets(res?.payload))
      .finally(() => setIsDataLoading(false));

    setColumnVisibilityModel(visibleFieldsString);
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
  ]);

  useEffect(() => {
    if (workOrderUUID) {
      getAssets();
    }
  }, [workOrderUUID, perPageData, currentPage]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  return (
    <>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={assets?.total || 0}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        isLoading={isDataLoading}
        noData={<NoRecordFound />}
        paginationRequired={false}
      />
      {open && selectedAsset && (
        <SwipeableDrawer
          open={open}
          title={selectedAsset?.asset?.label || ''}
          onClose={() => setOpen(false)}
        >
          <AssetView
            asset_id={selectedAsset?.asset?.uuid}
            work_order_id={workOrderUUID}
          />
        </SwipeableDrawer>
      )}
      {openServiceReport && selectedAsset && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
          open={openServiceReport}
          title={`${t('attributes.invoice.inspection')?.toUpperCase()} ${t('attributes.reports.report')?.toUpperCase()}`}
          onClose={() => setOpenServiceReport(false)}
          footerButton={
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(
                  inspectionFormData,
                  property,
                  selectedAsset,
                  work_order_name,
                  frequency
                );
              }}
            />
          }
        >
          <AssetServiceReport
            inspectionFormData={inspectionFormData}
            isLoading={isLoading}
            property={property}
            asset={selectedAsset}
            work_order_name={work_order_name}
            frequency={frequency}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Assets;
