import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { exportToCSV, validateAddress } from '../../../constants/common';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import getCityByStateHook from '../../../hooks/getCityByStateHook';
import getStatesHook from '../../../hooks/getStatesHook';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import {
  createOfficeStaff,
  getOfficeStaffList,
  updateOfficeStaff,
} from '../../../store/officeStaff/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../../store/officeStaff/reducer';
import { formattedDate } from '../../../utils';
import { Edit } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomDateRangePicker from '../../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import TabView from '../../CommonComponents/TabView';
import OfficeStaffForm from './OfficeStaffForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);
const defaultValues = {
  staff_name: '',
  staff_email: '',
  cell_phone: '',
  address: '',
  state: '',
  city: '',
  postcode: '',
};

const defaultFilters = {
  state: null,
  city: null,
};

const listColumnNames = {
  office_staff_name: 'office_staff_name',
  office_staff_email: 'office_staff_email',
  cell_phone: 'cell_phone',
  address: 'address',
  state: 'state',
  city: 'city',
  created_at: 'created_at',
  edit: 'edit',
};

const OfficeStaff = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [activeTab, setActiveTab] = useState(0);
  const [filters, setFilters] = useState(defaultFilters);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [officeStaffId, setOfficeStaffId] = useState('');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const { officeStaffActiveList, officeStaffInactiveList, total, totalCount } =
    useSelector((state) => state.officeStaff.get);
  const {
    isLoading: createLoading,
    createdOfficeStaff,
    error,
  } = useSelector((state) => state.officeStaff.create);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [toDate, setToDate] = useState(null);
  const [serverErrors, setServerErrors] = useState([]);
  const [selectedRows, setSelectedRows] = useState(null);
  const {
    handleSubmit,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
    getValues,
  } = useForm();
  const stateList = getStatesHook();
  const dispatch = useDispatch();
  const cityList = getCityByStateHook(filters.state?.value);
  const [refresh, setRefresh] = useState(false);
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'name',
        headerName: t('attributes.name'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'email',
        headerName: t('attributes.email'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'cell_phone',
        headerName: t('attributes.office_staff.cell_phone'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'address',
        headerName: t('attributes.address'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            <Typography>
              {row.address}, {row.postcode}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'city',
        headerName: t('attributes.office_staff.city'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'state',
        headerName: t('attributes.office_staff.state'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.office_staff.created_at'),
        flex: 1,
        sortable: true,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Edit
            onClick={() => {
              setOfficeStaffId(row.uuid);
              setOpen(true);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const getAllOfficeStaff = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getOfficeStaffList({
        active: activeTab === 0,
        perPageData: perPageData,
        page: currentPage,
        search: searchText,
        state: filters.state?.value,
        city: filters.city?.value,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
        fromDate: fromDate,
        toDate: toDate,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    activeTab,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
    fromDate,
    toDate,
  ]);

  useEffect(() => {
    const defaultColumnVisibilityModel = listColumnNames;

    columns.forEach((column) => {
      defaultColumnVisibilityModel[column.field] = true;
    });
    setColumnVisibilityModel(defaultColumnVisibilityModel);
  }, [activeTab]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
    },
    []
  );

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdOfficeStaff) {
          setOpen(false);
          if (!officeStaffId) setActiveTab(0);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !officeStaffId
                ? t('message.office_staff.createdSuccessfully')
                : t('message.office_staff.updatedSuccessfully'),
            })
          );
          setOfficeStaffId('');
        }
      }
    }
  }, [error, createLoading]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllOfficeStaff, 500), [
    getAllOfficeStaff,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const resetFilter = () => {
    setFilters({ state: null, city: null });
    setSearchText('');
    setFromDate(null);
    setToDate(null);
  };

  const rows =
    activeTab === 0
      ? officeStaffActiveList?.map((cl) => ({
          ...cl,
          id: cl.id,
          uuid: cl.uuid,
          name: cl.name,
          address: cl.address,
          postcode: cl.postcode,
          email: cl.email,
          cell_phone: cl.cell_phone,
          state: cl.state,
          city: cl.city,
          created_at: cl.created_at,
        }))
      : officeStaffInactiveList?.map((cl) => ({
          ...cl,
          id: cl.id,
          uuid: cl.uuid,
          name: cl.name,
          address: cl.address,
          postcode: cl.postcode,
          email: cl.email,
          cell_phone: cl.cell_phone,
          state: cl.state,
          city: cl.city,
          created_at: cl.created_at,
        }));

  const onTabChange = () => {
    setIsDataLoading(true);
    setFilters({ state: null, city: null });
    setSearchText('');
    setIsFilterComponentVisible(false);
  };

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      clearErrors,
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.postcode,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'postcode',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors: setServerErrors,
    });

    if (hasError) return;

    const createdOfficeStaffData = {
      name: data.name,
      email: data.email,
      cell_phone: data.cell_phone,
      address: data.address,
      city: data.city.value,
      state: data.state.value,
      postcode: data.postcode,
      is_active: officeStaffId ? (data.active ? true : false) : true,
    };

    if (!officeStaffId) {
      dispatch(createOfficeStaff(createdOfficeStaffData));
    } else {
      dispatch(
        updateOfficeStaff({ id: data?.uuid, data: createdOfficeStaffData })
      );
    }
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<AccountCircleOutlinedIcon />}
      title={t('attributes.office_staff.office_staffs')}
      singularText={t('attributes.office_staff.office_staff')}
      openForm={() => {
        setOpen(true);
        reset(defaultValues);
      }}
      createBtnText={t('attributes.office_staff.add_office_staff')}
      filterHeight={filterHeight(isFilterComponentVisible)}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onResetFilter={resetFilter}
        // After API impl need to include debounce search
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText || filters.state || filters.city || fromDate || toDate
        }
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        isActive={isFilterComponentVisible}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('common.state')}
            options={stateList}
            value={filters?.state}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, state: newVal, city: null }))
            }
            width="190px"
          />
          <Autocomplete
            placeholder={t('common.city')}
            options={cityList}
            value={filters?.city}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, city: newVal }))
            }
            width="190px"
            // disabledDropdown={!filters.state}
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFromDate(val[0]);
              setToDate(val[1]);
            }}
            onClear={() => {
              setFromDate(null);
              setToDate(null);
            }}
            fromDate={fromDate}
            toDate={toDate}
          />
        </FilterComponent>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const OfficeStaffWrapper = (
    <StyledMainWrapper
      title={t('attributes.office_staff.office_staffs')}
      btn={
        <>
          <CustomButton
            text={t('attributes.office_staff.add_office_staff')}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            color="secondary"
            sx={{ height: '52%' }}
            onClick={() => {
              setOpen(true);
              reset(defaultValues);
            }}
          />

          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.OFFICE_STAFF,
                  EXPORT_FILE_NAMES.OFFICE_STAFF
                );
              }
            }}
          />
        </>
      }
    >
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <TabView
            tabs={[
              { label: t('common.active'), component: renderedComponent },
              {
                label: t('common.inactive'),
                component: renderedComponent,
              },
            ]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            onTabChange={onTabChange}
          />
        )
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOfficeStaffId('');
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {OfficeStaffWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !officeStaffId
              ? t('attributes.office_staff.add_office_staff')
              : t('attributes.office_staff.edit_office_staff')
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpen(false) && setOfficeStaffId('');
          }}
          footerButton={
            <CustomButton
              text={
                createLoading
                  ? t('common.loading')
                  : !officeStaffId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              disabled={createLoading}
              startIcon={!officeStaffId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <OfficeStaffForm
            id={officeStaffId}
            sethasFormValues={sethasFormValues}
            control={control}
            reset={reset}
            setValue={setValue}
            watch={watch}
            trigger={trigger}
            clearErrors={clearErrors}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default OfficeStaff;
