import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { PROPERTIES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// get compliance status
export const getComplianceStatusDropdownList = createAsyncThunk(
  PROPERTIES.GET_COMPLIANCE_STATUS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_COMPLIANCE_STATUS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Customer property Chart data
export const getCustomerPropertyChartData = createAsyncThunk(
  PROPERTIES.GET_COMPLIANCE_STATUS_CHART,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.GET_COMPLIANCE_STATUS_CHART
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
