import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR, PRIMARY, SECONDARY } from '../../constants/Colors';
import {
  IN_REVIEW,
  PENDING,
  RE_VISIT,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
  STATUS_BASED_COLOR,
  WORK_ORDER_STEPS,
  WORK_ORDER_TYPE,
  WORK_ORDER_TYPE_ADHOC,
  WORK_ORDER_TYPE_MAINTENANCE,
  WORK_ORDER_TYPE_REPAIR,
  WORK_ORDER_TYPE_ROUTINE,
  getStatusLabels,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
// eslint-disable-next-line import-helpers/order-imports
import { longDateTimeRange } from '../../constants/common';
import { getDocument } from '../../store/CommonAPI';
import { snackbarToggle } from '../../store/CommonReducer';
import { resetUpdateInvoice } from '../../store/invoices/reducer';
import { getProperty } from '../../store/property/api';
import {
  resetPagination,
  resetUpdatePurchaseOrder,
} from '../../store/purchaseOrders/reducer';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getBillingContractById } from '../../store/users/billingContract/api';
import { resetGetBillingContractById } from '../../store/users/billingContract/reducer';
import {
  getAssetsList,
  getPreChecklist,
  getWorkOrder,
  getWorkOrderQuotation,
  updateWorkOrderStatus,
} from '../../store/workOrder/api';
import {
  resetAssignTechnician,
  resetUpdateQuotationStatus,
  resetWorkOrderData,
} from '../../store/workOrder/reducer';
import { formatStatus, formattedDate } from '../../utils';
import ComingSoon from '../CommonComponents/ComingSoon';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import MainWrapper from '../CommonComponents/MainWrapper';
import PdfView from '../CommonComponents/PdfView';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import TabView from '../CommonComponents/TabView';
import Assets from './Assets/Assets';
import DefectQuotes from './DefectQuotes';
import DefectQuotesView from './DefectQuotesView';
import DefectsQuotes from './DefectsQuotes/DefectsQuotes';
import Invoices from './Invoices/Invoices';
import PurchaseOrders from './PurchaseOrders/PurchaseOrders';
import generatePDF from './RectificationReport/DownloadPDF';
import PreviewPDF from './RectificationReport/PreviewPDF';
import StatusMenu from './StatusMenu';
import WorkOrderData from './WorkOrderData';

const tabHashMap = {
  0: '', // Default tab
  1: '#assets',
  2: '#defects',
  3: '#defects_quotes',
  4: '#invoices',
  5: '#purchase_orders',
  6: '#documents',
  7: '#coming_soon',
};

const WorkOrderDetails = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const params = useParams();

  const initialTabIndex =
    Object.keys(tabHashMap).find((key) => hash === tabHashMap[key]) || 0;
  const [activeTab, setActiveTab] = useState(initialTabIndex);
  const [open, setOpen] = useState(false);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [nextStepTitle, setNextStepTitle] = useState();
  const [isDownloadingPDF, setIsDownloadingPDF] = useState(false);
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const [repairParentDetail, setRepairParentDetail] = useState();
  const [openRectificationReport, setOpenRectificationReport] = useState(false);
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [defects, setDefects] = useState(null);
  const [precheckListData, setPrecheckListData] = useState(null);
  const [imgSrc, setImgSrc] = useState(null);
  const [isDownloadingRectificationPDF, setIsDownloadingRectificationPDF] =
    useState(false);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [statusChangeLoading, setStatusChangeLoading] = useState(false);

  // Status Change

  const [anchorEl, setAnchorEl] = useState(null);
  const isOpenMenu = Boolean(anchorEl);

  const { isLoading, workOrder } = useSelector(
    (state) => state.workOrder?.details
  );

  const { data: assignTechnicianData } = useSelector(
    (state) => state.workOrder.asssignTechnician
  );

  useEffect(() => {
    // Update active tab based on hash changes
    const hashIndex = Object.keys(tabHashMap).find(
      (key) => hash === tabHashMap[key]
    );

    setActiveTab(hashIndex ? parseInt(hashIndex) : 0); // Default to 0 if no match
  }, [hash]);

  useEffect(() => {
    // setActiveTab(hash === '#assets' ? 1 : 0);
    if (params?.uuid) {
      dispatch(getTechnicianNamesList());
      dispatch(getWorkOrder({ id: params.uuid })).finally(() => {
        setIsInitialLoading(false);
      });
      setIsPropertyLoading(true);
    }

    return () => {
      dispatch(resetWorkOrderData());
      dispatch(resetGetBillingContractById());
      dispatch(resetAssignTechnician());
    };
  }, [params?.uuid, assignTechnicianData]);

  useEffect(() => {
    if (workOrder?.id && !isLoading) {
      dispatch(getProperty(workOrder?.property_uuid))
        .then((res) => setSelectedProperty(res.payload?.data[0]))
        .catch((err) => console.error('Error fetching property data:', err))
        .finally(() => setIsPropertyLoading(false));
    }
  }, [workOrder?.id, assignTechnicianData]);

  useEffect(() => {
    if (selectedProperty) {
      const { contract_assignment_details } = selectedProperty || {};
      const activeBillingContractUUID =
        contract_assignment_details?.active_billing_contract_uuid;

      dispatch(
        getBillingContractById({
          billing_contract_uuid: activeBillingContractUUID,
        })
      );
    }
  }, [selectedProperty]);

  useEffect(() => {
    if (workOrder.work_order_type === WORK_ORDER_TYPE_REPAIR) {
      dispatch(
        getWorkOrderQuotation({ id: workOrder.parent_work_order_uuid })
      ).then((res) =>
        setRepairParentDetail({
          quote_id: res?.payload?.data?.[0].id,
          work_order_id: res?.payload?.data?.[0].work_order?.id,
        })
      );
    }
  }, [workOrder]);

  const handleNextStep = () => {
    setNextStepTitle();
    dispatch(resetUpdateQuotationStatus());
    dispatch(resetPagination());
    dispatch(resetUpdatePurchaseOrder());
    dispatch(resetUpdateInvoice());
  };

  const workOrderDetailKey =
    workOrder[
      workOrder?.type_name
        ?.split(' ')
        .map((d) => d.toLowerCase())
        .join('_')
    ];

  const downloadFile = async (doc_uuid) => {
    setIsDownloadingPDF(true);
    if (workOrder?.id) {
      dispatch(getDocument({ doc_uuid }))
        .then((res) => {
          if (res.payload?.status === 200 && res?.payload?.data?.[0]) {
            const link = document.createElement('a');

            link.href = res?.payload?.data?.[0]?.presigned_url;
            link.target = '_blank';
            link.rel = 'noopener noreferrer';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: t('message.work_order.unableToDownloadPDF'),
              })
            );
          }
        })
        .finally(() => setIsDownloadingPDF(false));
    }
  };

  const handleOpenNewWindowLink = (link) => {
    const fullPath = `${window.location.origin}${link}`;

    window.open(fullPath, '_blank');
  };

  const statusHandler = (status, remark = '') => {
    let bodyData = {
      latest_status: status,
    };
    if (status === RE_VISIT) {
      bodyData = {
        ...bodyData,
        remark: remark,
        schedule_status: PENDING,
      };
    }
    setStatusChangeLoading(true);
    dispatch(updateWorkOrderStatus({ id: workOrder?.uuid, data: bodyData }))
      .then((res) => {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: res?.payload?.message,
          })
        );
        dispatch(getWorkOrder({ id: params.uuid }));
      })
      .finally(() => setStatusChangeLoading(false));
  };

  return statusChangeLoading ||
    (isInitialLoading && (isLoading || isPropertyLoading)) ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <>
      <Box sx={{ bgcolor: DIVIDER_COLOR, paddingBottom: '16px' }}>
        <MainWrapper
          defaultPadding="0px"
          title={t('attributes.workOrder.workOrder')}
          variant="body1"
          isStep={true}
          step={`W-${workOrder?.id}`}
          handleDefaultStep={() =>
            navigate(`/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}`)
          }
          nextStepTitle={nextStepTitle}
          handleNextStep={handleNextStep}
        ></MainWrapper>
      </Box>
      {nextStepTitle?.title === WORK_ORDER_STEPS.defect_quotes && (
        <DefectQuotesView workOrderId={workOrder?.uuid} />
      )}
      {nextStepTitle?.title === WORK_ORDER_STEPS.purchase_orders && (
        <PurchaseOrders
          workOrderId={workOrder?.uuid}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
        />
      )}
      {nextStepTitle?.title === WORK_ORDER_STEPS.invoice && (
        <Invoices
          workOrderId={workOrder?.uuid}
          nextStepTitle={nextStepTitle}
          setNextStepTitle={setNextStepTitle}
        />
      )}
      {!nextStepTitle?.title && (
        <Box
          sx={{
            width: '100%',
            backgroundColor: DIVIDER_COLOR,
          }}
        >
          <Box
            sx={{
              bgcolor: 'white',
              marginBottom: '16px',
              width: '100%',
              borderRadius: '4px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ padding: '16px' }}>
                  <Typography variant="body1">
                    {workOrder?.work_order_name}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    padding: '16px',
                    borderLeft: `1px solid ${DIVIDER_COLOR}`,
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                    cursor: workOrder?.latest_status === IN_REVIEW,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    columnGap: '8px',
                  }}
                  onClick={(e) => {
                    if (workOrder?.latest_status === IN_REVIEW) {
                      setAnchorEl(e.currentTarget);
                    }
                  }}
                >
                  <Typography variant="body1">
                    {formatStatus(getStatusLabels(workOrder), '10px', '10px')}
                  </Typography>
                  {workOrder?.latest_status === IN_REVIEW && (
                    <ArrowDropDownCircleOutlinedIcon
                      sx={{
                        fill: STATUS_BASED_COLOR[workOrder?.latest_status],
                      }}
                    />
                  )}
                </Box>
                {isOpenMenu && (
                  <StatusMenu
                    open={isOpenMenu}
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    onStatusSelected={statusHandler}
                  />
                )}
              </Box>
              {workOrder.work_order_type === WORK_ORDER_TYPE_MAINTENANCE && (
                <Box
                  sx={{ display: 'flex', columnGap: '8px', padding: '16px' }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.frequency')}
                  </Typography>
                  <Box
                    sx={{
                      width: '30px',
                      height: '20px',
                      border: '1px solid black',
                      borderRadius: '24px',
                      bgcolor: DIVIDER_COLOR,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {workOrderDetailKey?.frequencies?.code}
                  </Box>
                </Box>
              )}
            </Box>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ display: 'flex' }}>
                <Box sx={{ padding: '16px' }}>
                  <Typography variant="body1">
                    {t('attributes.work_order.work_order_type')}
                  </Typography>
                  <Typography variant="body2">
                    {
                      WORK_ORDER_TYPE.filter(
                        (wo) => wo.value === workOrder?.work_order_type
                      )?.[0]?.label
                    }
                  </Typography>
                </Box>
                {workOrder.work_order_type === WORK_ORDER_TYPE_REPAIR && (
                  <Box>
                    <Box sx={{ padding: '16px' }}>
                      <Typography variant="body1">Quote ID</Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: PRIMARY,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleOpenNewWindowLink(
                            `/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${workOrder?.parent_work_order_uuid}#defects_quotes`
                          )
                        }
                      >
                        {repairParentDetail &&
                          `Q-${repairParentDetail.quote_id}`}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {workOrder.work_order_type === WORK_ORDER_TYPE_REPAIR && (
                  <Box>
                    <Box sx={{ padding: '16px' }}>
                      <Typography variant="body1">Parent WO</Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          color: PRIMARY,
                          textDecoration: 'underline',
                          cursor: 'pointer',
                        }}
                        onClick={() =>
                          handleOpenNewWindowLink(
                            `/${ROUTENAME.SCHEDULING}/${ROUTENAME.WORK_ORDERS}/${workOrder?.parent_work_order_uuid}`
                          )
                        }
                      >
                        {repairParentDetail &&
                          `W-${repairParentDetail.work_order_id}`}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {(workOrder?.work_order_type === WORK_ORDER_TYPE_MAINTENANCE ||
                  workOrder?.work_order_type === WORK_ORDER_TYPE_ROUTINE) && (
                  <Box
                    sx={{
                      padding: '16px',
                      borderLeft: `1px solid ${DIVIDER_COLOR}`,
                      borderRight: `1px solid ${DIVIDER_COLOR}`,
                    }}
                  >
                    <Typography variant="body1">
                      {t('attributes.work_order.service_report')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '8px',
                        cursor: 'pointer',
                        alignItems: 'unset',
                      }}
                      onClick={() => setOpen(true)}
                    >
                      <VisibilityOutlinedIcon
                        style={{
                          height: '24px',
                          width: '24px',
                        }}
                        color="primary"
                      />
                      <Typography variant="body1" color="primary">
                        {t('attributes.view')}
                      </Typography>
                    </Box>
                  </Box>
                )}
                {workOrder?.work_order_type === WORK_ORDER_TYPE_REPAIR && (
                  <Box
                    sx={{
                      padding: '16px',
                      borderLeft: `1px solid ${DIVIDER_COLOR}`,
                      borderRight: `1px solid ${DIVIDER_COLOR}`,
                    }}
                  >
                    <Typography variant="body1">
                      {t('attributes.work_order.repair_report')}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        columnGap: '8px',
                        cursor: 'pointer',
                        alignItems: 'unset',
                      }}
                      onClick={() => {
                        setIsDataLoading(true);
                        const assetsPromise = dispatch(
                          getAssetsList({
                            workorder_uuid: workOrder?.uuid,
                          })
                        ).then((res) => setDefects(res?.payload?.data));

                        const checklistPromise = dispatch(
                          getPreChecklist({
                            work_order_id: workOrder?.uuid,
                            page: 1,
                            size: 10,
                            limit: -1,
                          })
                        ).then((res) =>
                          setPrecheckListData(res?.payload?.data[0])
                        );

                        Promise.all([assetsPromise, checklistPromise]).finally(
                          () => setIsDataLoading(false)
                        );

                        setOpenRectificationReport(true);
                      }}
                    >
                      <VisibilityOutlinedIcon
                        style={{
                          height: '24px',
                          width: '24px',
                        }}
                        color="primary"
                      />
                      <Typography variant="body1" color="primary">
                        {t('attributes.view')}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
              <Box sx={{ display: 'flex', columnGap: '20px', padding: '16px' }}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="body1">{`${t('attributes.work_order.appointment')} ${t('attributes.work_order.date_nd_time')}`}</Typography>
                  <Typography variant="body2">
                    {longDateTimeRange(
                      formattedDate(
                        workOrder?.scheduled_start_date_time,
                        SCHEDULE_BOARD_WO_UTC_LOCAL
                      ),
                      formattedDate(
                        workOrder?.scheduled_end_date_time,
                        SCHEDULE_BOARD_WO_UTC_LOCAL
                      )
                    )}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.created_on')}
                  </Typography>
                  <Typography variant="body2">
                    {formattedDate(workOrder?.created_at)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.due_date')}
                  </Typography>
                  <Typography variant="body2">
                    {formattedDate(workOrder?.due_date)}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              width: '100%',
              backgroundColor: 'white',
              borderRadius: '6px 6px 0 0',
            }}
          >
            <TabView
              tabs={[
                {
                  label: t('attributes.supplier.details'),
                  component: (
                    <WorkOrderData
                      data={workOrder}
                      selectedProperty={selectedProperty}
                    />
                  ),
                },
                {
                  label:
                    workOrder.work_order_type === WORK_ORDER_TYPE_ADHOC
                      ? t('attributes.work_order.wo_line_items')
                      : t('attributes.property.assets'),
                  component: (
                    <Assets
                      workOrderUUID={workOrder?.uuid}
                      property={selectedProperty}
                      work_order_name={workOrder?.work_order_name}
                      frequency={workOrderDetailKey?.frequencies?.name}
                      work_order_type={workOrder.work_order_type}
                    />
                  ),
                },
                {
                  label: t('attributes.customer.defects'),
                  component: (
                    <DefectsQuotes
                      workOrderUUID={workOrder?.uuid}
                      work_order_type={workOrder.work_order_type}
                    />
                  ),
                },
                {
                  label: t('attributes.work_order.defects_quote'),
                  component: (
                    <DefectQuotes
                      workOrderId={workOrder?.uuid}
                      setNextStepTitle={setNextStepTitle}
                    />
                  ),
                },
                {
                  label: t('attributes.invoice.invoice'),
                  component: (
                    <Invoices
                      workOrderId={workOrder?.uuid}
                      setNextStepTitle={setNextStepTitle}
                    />
                  ),
                },
                {
                  label: t('attributes.work_order.purchase_order'),
                  component: (
                    <PurchaseOrders
                      workOrderId={workOrder?.uuid}
                      setNextStepTitle={setNextStepTitle}
                    />
                  ),
                },
                ...(workOrder.work_order_type !== WORK_ORDER_TYPE_ADHOC &&
                workOrder.work_order_type !== WORK_ORDER_TYPE_REPAIR
                  ? [
                      {
                        label: t('attributes.documents'),
                        component: (
                          <ComingSoon height={'calc(100vh - 400px)'} />
                        ),
                      },
                    ]
                  : []),
                ...(workOrder.work_order_type !== WORK_ORDER_TYPE_ADHOC &&
                workOrder.work_order_type !== WORK_ORDER_TYPE_REPAIR
                  ? [
                      {
                        label: 'Comming Soon...',
                        component: (
                          <ComingSoon height={'calc(100vh - 400px)'} />
                        ),
                      },
                    ]
                  : []),
              ]}
              activeTab={activeTab}
              setActiveTab={(index) => {
                setActiveTab(index);
                // Update the hash based on the active tab
                const newHash = tabHashMap[index] || '';

                navigate(`${location.pathname}${newHash}`, { replace: true });
              }}
              onTabChange={() => {
                // navigate('#assets');
              }}
              isSubDetails={true}
            />
          </Box>
        </Box>
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={t('attributes.work_order.routineServiceReport')}
          onClose={() => setOpen(false)}
          footerButton={
            <CustomButton
              text="Download"
              startIcon={<DownloadOutlinedIcon />}
              disabled={
                isDownloadingPDF ||
                !workOrder?.work_order_service_report[
                  workOrder?.work_order_service_report?.length - 1
                ]?.document_uuid
              }
              onClick={() => {
                downloadFile(
                  workOrder?.work_order_service_report[
                    workOrder?.work_order_service_report?.length - 1
                  ]?.document_uuid
                );
              }}
            />
          }
        >
          <PdfView
            id={
              workOrder?.work_order_service_report[
                workOrder?.work_order_service_report?.length - 1
              ]?.document_uuid
            }
          />
        </SwipeableDrawer>
      )}
      {openRectificationReport && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openRectificationReport}
          title={t('attributes.work_order.rectification_report')}
          onClose={() => setOpenRectificationReport(false)}
          footerButton={
            <CustomButton
              text={
                isDownloadingRectificationPDF
                  ? t('common.downloading')
                  : t('attributes.download')
              }
              startIcon={<DownloadOutlinedIcon />}
              disabled={isDataLoading || isDownloadingRectificationPDF}
              onClick={() => {
                generatePDF(
                  selectedProperty,
                  defects,
                  workOrder,
                  precheckListData,
                  imgSrc,
                  setIsDownloadingRectificationPDF
                );
              }}
            />
          }
          width={653}
          noPadding={true}
        >
          <PreviewPDF
            workOrderUUID={workOrder?.uuid}
            selectedProperty={selectedProperty}
            isDataLoading={isDataLoading}
            defects={defects}
            precheckListData={precheckListData}
            setImgSrc={setImgSrc}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default WorkOrderDetails;
