import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { exportToCSV, routineTextFromCode } from '../../constants/common';
import { ROUTINE_TYPES } from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import { resetDropdown } from '../../store/company/reducer';
import {
  createRoutineServiceFrequencies,
  getRoutineServiceFrequenciesList,
  updateRoutineServiceFrequencies,
} from '../../store/routineServiceFrequencies/api';
import {
  resetCreate,
  resetEdit,
  resetPagination,
} from '../../store/routineServiceFrequencies/reducer';
import { getStandardsDropdownList } from '../../store/standards/api';
import { capitalizeFirstLetter, loggedInUserDetail } from '../../utils';
import { Edit } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import RoutineServiceFrequenciesForm from './RoutineServiceFrequenciesForm';

// Will be used for Super Admin
const defaultValues = {
  name: '',
  code: '',
  standard: '',
  applicability_note: '',
  internal_note: '',
  frequency: '',
  frequency_type: '',
  tolerance: '',
  tolerance_type: '',
};

//default filters
const defaultFilters = {
  standard: null,
};
const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

const RoutineServiceFrequencies = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const {
    isLoading: listLoading,
    data,
    total,
    totalCount,
  } = useSelector((state) => state.routineServiceFrequencies.get);
  const {
    isLoading: createLoading,
    createdRoutineServiceFrequencies,
    error,
  } = useSelector((state) => state.routineServiceFrequencies.create);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [serverErrors, setServerErrors] = useState([]);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const [routineServiceFrequenciesId, setRoutineServiceFrequenciesId] =
    useState(null);

  const [filters, setFilters] = useState(defaultFilters);
  const [refresh, setRefresh] = useState(false);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectedRows, setSelectedRows] = useState(null);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  const { standardsDropdownLoading, standardsDropdownData } =
    getDropdownListHook({
      reducerName: 'standards',
      dropdownListName: 'standardsDropdownList',
      labelName: 'code',
      valueName: 'id',
      codeName: 'code',
    });

  useEffect(() => {
    dispatch(getStandardsDropdownList());
  }, []);

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  // Memoized columns based on the role
  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: t('common.id'),
        field: 'id',
        sortable: true,
        flex: 1,
      },
      {
        headerName: t('attributes.name'),
        field: 'name',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.routineServiceFrequencies.standard'),
        field: 'standard',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.routineServiceFrequencies.frequency'),
        field: 'frequency',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              gap: '4px',
            }}
          >
            {row.frequency && (
              <Box
                sx={{
                  px: '16px',
                  py: '4px',
                  border: '1px solid black',
                  borderRadius: '24px',
                  bgcolor: DIVIDER_COLOR,
                }}
              >
                <span>{row.code}</span>
              </Box>
            )}
          </Box>
        ),
      },
      {
        headerName: t('attributes.property.type'),
        field: 'type',
        sortable: false,
        flex: 1,
        valueGetter: (value) => capitalizeFirstLetter(value),
      },
      {
        headerName: t('attributes.routineServiceFrequencies.tolerance'),
        field: 'tolerance',
        sortable: false,
        flex: 1,
        valueGetter: (value, row) =>
          `${value} ${routineTextFromCode(Number(value), row.tolerance_type, t)}`,
      },

      {
        field: 'edit',
        headerName: t('attributes.edit'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Edit
            disabled={row.type !== ROUTINE_TYPES.CUSTOM}
            onClick={() => {
              if (row.type === ROUTINE_TYPES.CUSTOM) {
                setRoutineServiceFrequenciesId(row);
                setOpen(true);
              }
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllRoutineServiceFrequencies = useCallback(() => {
    setSelectedRows(null);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getRoutineServiceFrequenciesList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        company_uuid: loggedInUserDetail()?.company_uuid,
        list_column_names: visibleFieldsString,
      })
    ).finally(() => {
      setIsDataLoading(false);
      setIsInitialLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columnVisibilityModel,
    refresh,
  ]);

  useEffect(
    () => () => {
      dispatch(resetCreate());
      dispatch(resetEdit());
      dispatch(resetPagination());
      dispatch(resetDropdown());
    },
    []
  );

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(
    debounce(getAllRoutineServiceFrequencies, 500),
    [getAllRoutineServiceFrequencies]
  );

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdRoutineServiceFrequencies) {
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !routineServiceFrequenciesId
                ? t('attributes.routineServiceFrequencies.created')
                : t('attributes.routineServiceFrequencies.updated'),
            })
          );
        }
        setOpen(false);
      }
    }
  }, [error, createLoading]);

  const rows = data?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.created_at = item.created_at;
    // TODO: As of now only 1 standard is there so keeping it static since no field is present comming from response on BE side.
    updatedItem.standard = 'AS1851-2012';

    return updatedItem;
  });

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const request = {
      name: data.name,
      display_name: data.name,
      code: data?.code,
      frequency_type: data.frequency_type.value,
      frequency: data.frequency,
      tolerance: data.tolerance,
      tolerance_type: data.tolerance_type.value,
      applicability_note: data.applicability_note,
      internal_note: data.internal_note,
      type: ROUTINE_TYPES.CUSTOM,
      is_active: true,
    };

    if (!routineServiceFrequenciesId) {
      dispatch(createRoutineServiceFrequencies(request));
    } else {
      dispatch(
        updateRoutineServiceFrequencies({ uuid: data?.uuid, req: request })
      );
    }
  };

  const resetFilter = () => {
    setFilters({ standard: null });
    setSearchText('');
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <NewNoDataPage
      icon={<HistoryToggleOffOutlinedIcon />}
      title={t(
        'attributes.routineServiceFrequencies.routineServiceFrequencies'
      )}
      singularText={t(
        'attributes.routineServiceFrequencies.routineServiceFrequencies'
      )}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
        overflow: 'hidden',
      }}
      createBtnText={`${t('attributes.add')} ${t('attributes.routineServiceFrequencies.routineServiceFrequencies')}`}
      filterHeight={isFilterComponentVisible ? 357 : 301}
    />
  );

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
        }}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        isResetButtonVisible={
          searchText || filters.state || filters.city || filters.company
        }
        onRefreshFilter={() => setRefresh(!refresh)}
        isRefresh={true}
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.routineServiceFrequencies.standard')}
            options={standardsDropdownData}
            isLoadingData={standardsDropdownLoading}
            value={filters?.standard}
            onChange={(e, newVal) =>
              setFilters((prev) => ({ ...prev, standard: newVal }))
            }
            width="190px"
          />
        </FilterComponent>
      )}

      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={t(
        'attributes.routineServiceFrequencies.routineServiceFrequencies'
      )}
      btn={
        <>
          <CustomButton
            text={`${t('attributes.add')}  ${t('attributes.routineServiceFrequencies.routineServiceFrequencies')}`}
            startIcon={<AddCircleOutlineOutlinedIcon />}
            color="secondary"
            onClick={() => {
              setOpen(true);
              setRoutineServiceFrequenciesId('');
              reset(defaultValues);
            }}
          />
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.ROUTINE_SERVICE_FREQUENCIES,
                  EXPORT_FILE_NAMES.ROUTINE_SERVICE_FREQUENCIES
                );
              }
            }}
          />
        </>
      }
    >
      <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
        {!isDataLoading && totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              maxHeight: `calc(100vh - ${isFilterComponentVisible ? 357 : 301}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading && totalCount > 0 && renderedComponent
        )}
      </Box>
    </StyledMainWrapper>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {mainWrapper}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !routineServiceFrequenciesId
              ? `${t('attributes.add')} ${t('attributes.routineServiceFrequencies.routineServiceFrequencies')}`
              : `${t('attributes.edit')} ${t('attributes.routineServiceFrequencies.routineServiceFrequencies')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              text={
                createLoading
                  ? t('common.loading')
                  : !routineServiceFrequenciesId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              disabled={createLoading}
              startIcon={
                !routineServiceFrequenciesId ? (
                  <AddIcon />
                ) : (
                  <SaveOutlinedIcon />
                )
              }
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <RoutineServiceFrequenciesForm
            editData={routineServiceFrequenciesId}
            reset={reset}
            clearErrors={clearErrors}
            control={control}
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            setServerErrors={setServerErrors}
            standardsDropdownData={standardsDropdownData}
            standardsDropdownLoading={standardsDropdownLoading}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default RoutineServiceFrequencies;
