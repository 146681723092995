import { createSlice } from '@reduxjs/toolkit';

import { TAB_STATUS } from '../../constants/Constants';
import {
  createProductCatalogue,
  getproductCatalogueList,
  getProductDetailsById,
  getProductEnhancement,
  updateProductCatalogue,
} from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  productCatalogueActiveList: [],
  productCatalogueInActiveList: [],
  productList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialEditData = {
  isLoading: false,
  error: false,
  productDetails: false,
};

const initialCreateData = {
  isLoading: false,
  error: false,
  productDetails: false,
};

const initialGetDropdownData = {
  isLoading: true,
  error: null,
  data: [],
};

const productEnhancementData = {
  isLoading: false,
  error: false,
  data: false,
};

const productCatalogueSlice = createSlice({
  name: 'productCatalogue',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    getDropdown: {
      ...initialGetDropdownData,
    },
    productEnhancementData,
  },
  reducers: {
    resetProductCatalogueList(state) {
      state.get = { ...initialGetData };
    },
    resetCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetDropdown(state) {
      state.getDropdown = { ...initialGetDropdownData };
    },

    resetProductDetails(state) {
      state.edit = { ...initialEditData };
    },
    resetProductEnhancementData(state) {
      state.productEnhancementData = {
        ...productEnhancementData,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // Product Catalogue List
      .addCase(getproductCatalogueList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getproductCatalogueList.fulfilled, (state, action) => {
        const { data, active, pagination } = action.payload;

        state.get.isLoading = false;
        if (active === TAB_STATUS.active) {
          state.get.productCatalogueActiveList = data;
        } else {
          state.get.productCatalogueInActiveList = data;
        }
        state.get.total = pagination?.total_items;
        state.get.totalCount = pagination?.total_count;
        state.get.productList = data;
      })
      .addCase(getproductCatalogueList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })

      // Create
      .addCase(createProductCatalogue.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createProductCatalogue.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.createdProduct = action.payload.data[0];
      })
      .addCase(createProductCatalogue.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update
      .addCase(updateProductCatalogue.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateProductCatalogue.fulfilled, (state, action) => {
        state.create.isLoading = false;
        state.create.error = false;
        state.create.createdProduct = action.payload.data[0];
      })
      .addCase(updateProductCatalogue.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })

      // Edit
      .addCase(getProductDetailsById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getProductDetailsById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = false;
        state.edit.productDetails = action.payload.data[0];
      })
      .addCase(getProductDetailsById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })
      // Product Enhancement
      .addCase(getProductEnhancement.pending, (state) => {
        state.productEnhancementData.isLoading = true;
      })
      .addCase(getProductEnhancement.fulfilled, (state, action) => {
        state.productEnhancementData.isLoading = false;
        state.productEnhancementData.data = action.payload;
      })
      .addCase(getProductEnhancement.rejected, (state, action) => {
        state.productEnhancementData.isLoading = false;
        state.productEnhancementData.error = action.error.message;
      });
  },
});

export const {
  resetProductCatalogueList,
  resetDropdown,
  resetProductDetails,
  resetCreate,
  resetProductEnhancementData,
} = productCatalogueSlice.actions;

export default productCatalogueSlice.reducer;
