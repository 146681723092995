import React from 'react';
import { Controller } from 'react-hook-form';

import { Box, Divider, Typography } from '@mui/material';

import { SECONDARY } from '../../../../constants/Colors';
import { INVITED_STATUS } from '../../../../constants/Constants';
import { Validation } from '../../../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../../../constants/Regex';
import { clearTextfields } from '../../../../utils';
import CustomTextField from '../../../CommonComponents/CustomTextField';

const KeyValueComponent = ({ name, value, minWidth = '120px' }) => (
  <Box sx={{ display: 'flex' }}>
    <Typography variant="body1" minWidth={minWidth}>
      {`${name}:`}
    </Typography>
    <Typography variant="body2">{value}</Typography>
  </Box>
);

const PrimaryContacts = ({
  title,
  control,
  trigger,
  setValue,
  Section,
  customerStatus,
  customerId,
}) => (
  <Box
    sx={{
      background: SECONDARY,
      borderRadius: '4px',
    }}
  >
    <Box>
      <Box sx={{ padding: '16px' }}>
        <Typography variant="h2">{title}</Typography>
      </Box>
      <Divider />
      <Section>
        <Controller
          name="contact_name"
          control={control}
          rules={{
            required: `Contact Name ${Validation.general.required}`,
            maxLength: {
              value: 80,
              message: Validation.general.contactName,
            },
          }}
          render={({
            field: { onChange, value, name, onBlur },
            fieldState: { error },
          }) =>
            customerId && customerStatus === INVITED_STATUS ? (
              <KeyValueComponent name="Contact Name" value={value} />
            ) : (
              <CustomTextField
                label="Contact Name"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('contact_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
              />
            )
          }
        />
        <Controller
          name="contact_email"
          control={control}
          rules={{
            required: `Contact Email ${Validation.general.required}`,
            maxLength: {
              value: 254,
              message: Validation.general.email,
            },
            pattern: {
              value: EMAIL_REGEX,
              message: Validation.general.validEmail,
            },
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) =>
            customerId && customerStatus === INVITED_STATUS ? (
              <KeyValueComponent name="Contact Email" value={value} />
            ) : (
              <CustomTextField
                label="Contact Email"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  trigger('contact_email');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )
          }
        />
        <Controller
          name="contact_phone"
          control={control}
          rules={{
            required: `Contact phone ${Validation.general.required}`,
            pattern: {
              value: /^\d{10}$/,
              message: Validation.general.contactPhone,
            },
          }}
          render={({
            field: { onChange, value, name },
            fieldState: { error },
          }) => (
            <CustomTextField
              label="Contact Phone"
              fullWidth
              value={value}
              onChange={(e) => {
                const numericValue = e.target.value.replace(/\D/g, '');

                onChange(numericValue);
                trigger('contact_phone');
              }}
              helperText={error ? error.message : ''}
              error={error}
              onClear={() => clearTextfields(setValue, name)}
            />
          )}
        />
      </Section>
    </Box>
  </Box>
);

export default PrimaryContacts;
