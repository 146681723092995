import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../../constants/Colors';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import ComingSoon from '../../CommonComponents/ComingSoon';
import MainWrapper from '../../CommonComponents/MainWrapper';

export const GlobalAdminSSO = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.GLOBAL_ADMIN_INTEGRATIONS}`);
  };

  const TemplateDataWrapper = (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR, width: '100%' }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.integrations.integrations')}
          variant="body1"
          isStep={true}
          step={t('attributes.integrations.ssoTitle')}
          handleDefaultStep={handleDefaultStep}
        ></MainWrapper>
      </Box>
      <ComingSoon height={'87vh'} />
    </>
  );

  return <>{TemplateDataWrapper}</>;
};
