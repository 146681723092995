import { forwardRef } from 'react';

import { Box, Divider, styled, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';

const TitleBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.divider,
  padding: '6px 0 16px 0',
  width: '100%',
}));

const StyledMainWrapper = forwardRef(function StyledMainWrapper(
  {
    children,
    title,
    btn,
    isSubDetails = false,
    style = {},
    isFromProperty = false,
    ...rest
  },
  ref
) {
  return (
    <Box sx={style} ref={ref}>
      {title && (
        <TitleBox>
          <Typography variant="h1">{title}</Typography>
        </TitleBox>
      )}
      {btn && (
        <>
          <Box sx={{ bgcolor: !isSubDetails ? DIVIDER_COLOR : '' }}>
            <Box
              sx={{
                bgcolor: SECONDARY,
                borderRadius: isFromProperty ? '' : '6px 6px 0 0',
                height: '50px',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              {btn && btn}
            </Box>
          </Box>
          <Divider />
        </>
      )}
      {children}
    </Box>
  );
});

export default StyledMainWrapper;
