import { useCallback, useEffect, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import WidgetsOutlinedIcon from '@mui/icons-material/WidgetsOutlined';
import { Box, Card, Checkbox, Grid, Typography } from '@mui/material';

import { FlexEnd, FlexStart, SpaceBetween } from '../../assets/commonStyled';
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import {
  CHART_CONST,
  DASHBOARD_CHART_LABEL_TYPE,
} from '../../constants/Constants';
import {
  getWidgetDataByUser,
  getWidgetDataRole,
  updateCharts,
} from '../../store/CommonAPI';
import {
  getComplianceChart,
  getDashboardInvoice,
  getDefectsChartData,
  getPropertyChartData,
  getPurchaseOrderChartData,
  getQoutesChartData,
} from '../../store/superAdmin/api';
import {
  displayChart,
  getMergedArray,
  getWidgetChartData,
  searchFromArray,
} from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { RefreshDashboardButton } from '../CommonComponents/FilterButton';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import CustomSearch from '../CommonComponents/Search';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import ChartCard from './ChartCard';
import DraggableChart from './DraggableChart';
import DroppableArea from './DroppableArea';

const SuperAdminDashboard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [layout, setLayout] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [search, setSearch] = useState('');
  const [activeCharts, setActiveCharts] = useState([]);
  const [fileredValue, setFilteredValue] = useState(activeCharts);

  const [chartData, setChartData] = useState({});

  const [isManageDashboard, setIsManageDashboard] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const { profileDetails, widget } = useSelector((state) => state.common);

  useEffect(() => {
    if (profileDetails?.id) {
      dispatch(getWidgetDataRole(profileDetails?.role_name)).finally(() =>
        setIsLoading(false)
      );
      dispatch(getWidgetDataByUser(profileDetails?.uuid)).finally(() =>
        setIsLoading(false)
      );
    }
  }, []);

  useEffect(() => {
    dispatch(getPropertyChartData()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.PROPERTIES]: res?.payload?.data,
      }))
    );
    dispatch(getQoutesChartData()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.QUOTES]: res?.payload?.data,
      }))
    );
    dispatch(getPurchaseOrderChartData()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.PURCHASE_ORDER]: res?.payload?.data,
      }))
    );
    dispatch(getDefectsChartData()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.DEFECTS]: res?.payload?.data,
      }))
    );
    dispatch(getDashboardInvoice()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.INVOICES]: res?.payload?.data,
      }))
    );
    dispatch(getComplianceChart()).then((res) =>
      setChartData((prev) => ({
        ...prev,
        [CHART_CONST.PROPERTY_COMPLIANCE]: res?.payload?.data,
      }))
    );
  }, [refresh]);

  useEffect(() => {
    if (widget?.data?.length) {
      const mergedArray = widget?.userChartData?.length
        ? getMergedArray(widget?.data, widget?.userChartData)
        : widget?.data;

      // Initial layout with names
      const latestLayout = mergedArray
        ?.filter(
          (c) => c?.name !== 'routine_services' && c?.name !== 'service_request'
        )
        .map((chart, index) => {
          const displayData = {};

          chart?.supported_types?.forEach((el) => {
            displayData[el?.name] = getWidgetChartData(
              chart?.name,
              el?.name,
              chartData[chart?.name]
            );
          });

          return {
            ...chart,
            chart_name: chart?.name,
            name: chart?.display_name,
            type: chart?.selected_type
              ? chart?.selected_type?.name
              : chart?.default_type?.name,
            selected_type: chart?.selected_type
              ? {
                  ...chart?.selected_type,
                  label: chart?.selected_type?.display_name,
                  value: chart?.selected_type?.name,
                }
              : {
                  ...chart?.default_type,
                  label: chart?.default_type?.display_name,
                  value: chart?.default_type?.name,
                },
            supported_types: chart?.supported_types?.map((st) => ({
              ...st,
              label: st.display_name,
              value: st?.name,
            })),
            order: chart?.order || index + 1,
            widget_data: displayData,
            label_type: [
              CHART_CONST.PROPERTIES,
              CHART_CONST.QUOTES,
              CHART_CONST.DEFECTS,
              CHART_CONST.PURCHASE_ORDER,
            ].includes(chart?.name)
              ? DASHBOARD_CHART_LABEL_TYPE.PERCENTAGE
              : DASHBOARD_CHART_LABEL_TYPE.VALUE,
          };
        })
        .sort((a, b) => a.order - b.order);

      setLayout(latestLayout?.filter((c) => c?.is_active));
      setActiveCharts(latestLayout);
    }
  }, [
    widget?.data,
    widget?.userChartData,
    isLoading,
    widget?.isLoading,
    chartData,
  ]);

  const restoreDefault = () => {
    const mergedArray = widget?.userChartData?.length
      ? getMergedArray(widget?.data, widget?.userChartData)
      : widget?.data;

    const defaultSettings = mergedArray
      ?.filter(
        (c) => c?.name !== 'routine_services' && c?.name !== 'service_request'
      )
      ?.map((chart, index) => {
        const displayData = {};

        chart?.supported_types?.forEach((el) => {
          displayData[el?.name] = getWidgetChartData(
            chart?.name,
            el?.name,
            chartData[chart?.name]
          );
        });

        return {
          ...chart,
          chart_name: chart?.name,
          name: chart?.display_name,
          type: chart?.selected_type
            ? chart?.selected_type?.name
            : chart?.default_type?.name,
          supported_types: chart?.supported_types?.map((st) => ({
            ...st,
            label: st.display_name,
            value: st?.name,
          })),
          order: chart?.order || index + 1,
          selected_type: chart?.selected_type
            ? {
                ...chart?.selected_type,
                label: chart?.selected_type?.display_name,
                value: chart?.selected_type?.name,
              }
            : {
                ...chart?.default_type,
                label: chart?.default_type?.display_name,
                value: chart?.default_type?.name,
              },
          widget_data: displayData,
          label_type: [
            CHART_CONST.PROPERTIES,
            CHART_CONST.QUOTES,
            CHART_CONST.DEFECTS,
            CHART_CONST.PURCHASE_ORDER,
          ].includes(chart?.name)
            ? DASHBOARD_CHART_LABEL_TYPE.PERCENTAGE
            : DASHBOARD_CHART_LABEL_TYPE.VALUE,
          is_active: true,
        };
      })
      .sort((a, b) => a.order - b.order);

    setLayout(defaultSettings?.filter((c) => c?.is_active));
    setActiveCharts(defaultSettings);
  };

  const handleDrop = useCallback(
    (draggedChartId, targetAreaId) => {
      setLayout((prevLayout) => {
        const newLayout = prevLayout?.map((pl) => {
          if (pl.id === draggedChartId || pl?.order === targetAreaId) {
            if (pl.id === draggedChartId) {
              return { ...pl, order: targetAreaId };
            }
            const oldItemPosition = prevLayout?.find(
              (pl) => pl?.id === draggedChartId
            )?.order;

            return { ...pl, order: oldItemPosition };
          }

          return pl;
        });

        return newLayout.sort((a, b) => a.order - b.order);
      });
    },
    [refresh]
  );

  const onChartRemove = (id) => {
    const updatedActiveCharts = activeCharts?.map((c) => {
      if (c?.id === id) {
        return { ...c, is_active: false };
      }

      return c;
    });

    setLayout(updatedActiveCharts);
    setActiveCharts(updatedActiveCharts);
  };

  const chartTypeHandler = (id, val) => {
    const updatedLayout = layout.map((chart) => {
      if (chart.id === id) {
        let updatedChartData = {
          ...chart,
          type: val.value,
          selected_type: val,
          data: chart?.widget_data[val.value]?.data,
          colors: chart?.widget_data[val.value]?.colors,
          xLabel: chart?.widget_data[val.value]?.labels,
        };

        return updatedChartData;
      }

      return chart;
    });

    setLayout(updatedLayout);
  };

  const displayChartHandler = (id, isChecked) => {
    const updatedCharts = activeCharts?.map((c) => {
      if (c?.id === id) {
        return { ...c, is_active: isChecked };
      }

      return c;
    });

    setActiveCharts(updatedCharts);
  };
  const updateChartDataHandler = () => {
    const updatedData = {
      widgets: layout?.map((l, index) => ({
        widget_id: l?.id,
        type_id: l?.selected_type?.id,
        order: l?.order || index + 1,
        is_active: l?.is_active,
      })),
    };

    dispatch(
      updateCharts({ uuid: profileDetails?.uuid, data: updatedData })
    ).then((res) => dispatch(getWidgetDataByUser(profileDetails?.uuid)));
  };

  useEffect(() => {
    setFilteredValue(activeCharts);
  }, [activeCharts]);

  const onCloseSidebar = () => {
    setSearch('');
    setFilteredValue(activeCharts);
    setOpen(false);
  };

  return (
    <>
      <SpaceBetween sx={{ flexWrap: 'wrap', rowGap: '8px' }}>
        <Typography variant="body1">
          {t('common.welcome')}, {profileDetails?.name || '-'}
        </Typography>
        <FlexEnd>
          <RefreshDashboardButton
            onClick={() => {
              setLayout([]); // Clear layout for immediate feedback
              setChartData({});
              setRefresh(!refresh);
            }}
          />
          {!isManageDashboard && (
            <CustomButton
              startIcon={<WidgetsOutlinedIcon />}
              text={t('common.manage_dashboard')}
              onClick={() => setIsManageDashboard(true)}
            />
          )}
        </FlexEnd>
      </SpaceBetween>
      {isManageDashboard && (
        <SpaceBetween
          sx={{
            background: SECONDARY,
            padding: '12px',
            marginTop: '16px',
            borderRadius: '6px',
            flexWrap: 'wrap',
            rowGap: '8px',
          }}
        >
          <FlexStart>
            <CustomButton
              startIcon={<AddchartOutlinedIcon />}
              text={t('common.add_widget')}
              variant="text"
              onClick={() => setOpen(true)}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
            <CustomButton
              startIcon={<SaveOutlinedIcon />}
              text={t('attributes.save')}
              variant="text"
              onClick={updateChartDataHandler}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
            <CustomButton
              startIcon={<RestoreOutlinedIcon />}
              text={t('common.restore_dashboard')}
              variant="text"
              onClick={restoreDefault}
              color="inherit"
              sx={{
                bgcolor: SECONDARY,
              }}
            />
          </FlexStart>
          <CustomButton
            startIcon={<CancelOutlinedIcon />}
            text={t('attributes.close')}
            variant="outlined"
            color="inherit"
            size="small"
            sx={{
              bgcolor: SECONDARY,
              height: '26px',
            }}
            onClick={() => setIsManageDashboard(false)}
          />
        </SpaceBetween>
      )}

      {isLoading || widget?.isLoading ? (
        <CustomCircularLoader />
      ) : !widget?.isLoading && !isLoading && layout?.length == 0 ? (
        <NewNoDataPage
          icon={<DashboardOutlinedIcon />}
          title={t('attributes.dashboard')}
          singularText="chart"
          filterHeight={isManageDashboard ? 422 : 350}
          createBtnText={t('common.add_widget')}
        />
      ) : (
        <DndProvider backend={HTML5Backend}>
          <Box style={{ marginTop: '16px' }}>
            <Grid container spacing={2}>
              {/* Dynamically generated droppable areas */}
              {layout
                ?.filter((l) => l?.is_active)
                .map((item, index) => (
                  <Grid item xs={12} lg={6} xl={4} key={`area${index + 1}`}>
                    <DroppableArea id={index + 1} onDrop={handleDrop}>
                      {isManageDashboard ? (
                        <DraggableChart id={item.id}>
                          <ChartCard
                            title={item.name}
                            id={item.id}
                            options={item?.supported_types}
                            isSettingMode={isManageDashboard}
                            selectedType={item?.selected_type}
                            setSelectedType={(id, val) =>
                              chartTypeHandler(id, val)
                            }
                            onChartRemove={(id) => onChartRemove(id)}
                          >
                            {displayChart(item)}
                          </ChartCard>
                        </DraggableChart>
                      ) : (
                        <ChartCard
                          title={item.name}
                          id={item.id}
                          options={item?.supported_types}
                          isSettingMode={isManageDashboard}
                          selectedType={item?.selected_type}
                          setSelectedType={(id, val) =>
                            chartTypeHandler(id, val)
                          }
                          onChartRemove={(id) => onChartRemove(id)}
                        >
                          {displayChart(item)}
                        </ChartCard>
                      )}
                    </DroppableArea>
                  </Grid>
                ))}
            </Grid>
          </Box>
        </DndProvider>
      )}

      <SwipeableDrawer
        open={open}
        title={t('common.add_widgets')}
        onClose={onCloseSidebar}
        footerButton={
          <CustomButton
            text="Add"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            onClick={() => {
              onCloseSidebar();
              setLayout(activeCharts);
            }}
          />
        }
        width="300px"
        bgColor={SECONDARY}
        fontWeight={600}
      >
        <CustomSearch
          width="100%"
          value={search}
          onChange={(e) => {
            setSearch(e?.target?.value);
            const filterResult = searchFromArray(
              e?.target?.value,
              activeCharts
            );

            setFilteredValue(filterResult);
          }}
        />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            rowGap: '8px',
            marginTop: '8px',
          }}
        >
          {fileredValue?.map((chart) => (
            <Card
              sx={{
                padding: '8px',
                borderRadius: '4px',
                border: `1px solid ${DIVIDER_COLOR}`,
                boxShadow: 'none',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                <Checkbox
                  checked={chart?.is_active}
                  onChange={(e) =>
                    displayChartHandler(chart?.id, e.target.checked)
                  }
                  color="primary"
                  heigth="16px"
                />
                <Typography variant="body1">{chart?.display_name}</Typography>
              </Box>

              <Typography variant="body2" sx={{ marginLeft: '14%' }}>
                {chart?.description}
              </Typography>
            </Card>
          ))}
        </Box>
      </SwipeableDrawer>
    </>
  );
};

export default SuperAdminDashboard;
