import { useTranslation } from 'react-i18next';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import {
  DATA_AMANAGEMENT_ICON_COLOR,
  DIVIDER_COLOR,
} from '../../constants/Colors';
import { ROUTENAME } from '../../constants/RoutesConstants';
import CommonTemplates from '../CommonComponents/CommonTemplates';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const SuperAdminTemplates = () => {
  const { t } = useTranslation();

  const temaplatesData = [
    {
      icon: (
        <ReceiptIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.customer.invoices'),
      description: t('attributes.templates.invoiceDesc'),
      redirectTo: ROUTENAME.SUPER_ADMIN_INVOICE_TEMPLATES,
    },
    {
      icon: (
        <ReceiptLongOutlinedIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.customer.quotes'),
      description: t('attributes.templates.quotesDesc'),
      redirectTo: ROUTENAME.SUPER_ADMIN_QUOTES_TEMPLATES,
    },
    {
      icon: (
        <InventoryOutlinedIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.work_order.purchase_order'),
      description: t('attributes.templates.purchaseOrderDesc'),
      redirectTo: ROUTENAME.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES,
    },
    {
      icon: (
        <EmailOutlinedIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.templates.email_templates'),
      description: t('attributes.templates.emailTemplateDesc'),
      redirectTo: ROUTENAME.SUPER_ADMIN_EMAIL_TEMPLATES,
    },
  ];

  return (
    <StyledMainWrapper
      title={t('attributes.controlPanel.templates')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <CommonTemplates data={temaplatesData} />
    </StyledMainWrapper>
  );
};

export default SuperAdminTemplates;
