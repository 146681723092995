import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import ViewInArOutlinedIcon from '@mui/icons-material/ViewInArOutlined';
import { Box, styled, Typography } from '@mui/material';
import { debounce, isEmpty } from 'lodash';

import { DIVIDER_COLOR, NODATA_ICON_COLOR } from '../../constants/Colors';
import { PROPERTY_DETAIL_STEP } from '../../constants/Constants';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  createFloorPlan,
  deleteFloorPlan,
  getFloorPlanById,
  getFloorPlanList,
  updateFloorPlan,
} from '../../store/floorPlan/api';
import {
  resetFloorPlanCreate,
  resetFloorPlanDelete,
  resetFloorPlanList,
} from '../../store/floorPlan/reducer';
import { Delete, Edit, View } from '../CommonComponents/ActionComponent';
import ComingSoon from '../CommonComponents/ComingSoon';
import CustomButton from '../CommonComponents/CustomButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import { FilterSection } from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import FloorPlanForm from './FloorPlanForm';

const defaultValues = {
  label: '',
  floor_no: '',
  document_uuid: '',
};

const ContentWrapper = styled(Box)(({ theme }) => ({
  width: '100%',
  mt: 2,
  minHeight: `calc(100vh - 357px)`,
  maxHeight: `calc(100vh - 357px)`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

const FloorPlan = ({
  propertyId,
  nextStepTitle,
  setNextStepTitle,
  setActiveTab,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [floorPlanId, setFloorPlanId] = useState('');
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [searchText, setSearchText] = useState('');
  const {
    handleSubmit,
    register,
    control,
    reset,
    trigger,
    setError,
    clearErrors,
    watch,
    setValue,
  } = useForm();

  const {
    isLoading: listLoading,
    data: floorPlanList,
    totalCount,
    total,
    error: listError,
  } = useSelector((state) => state.floorPlan.get);
  const { isLoading: deleteDataLoading, data: deleteData } = useSelector(
    (state) => state.floorPlan.delete
  );
  const {
    isLoading: createLoading,
    data: createData,
    error,
  } = useSelector((state) => state.floorPlan.create);
  const { data: editFloorPlan } = useSelector((state) => state.floorPlan.edit);

  const watchedFields = watch();

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const getAllFloorPlanList = useCallback(() => {
    const req = {
      search: searchText,
      property_uuid: propertyId,
      limit: -1,
    };

    dispatch(getFloorPlanList(req)).finally(() => {
      setIsInitialLoading(false);
    });
  }, [dispatch, searchText, propertyId]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllFloorPlanList, 500), [
    getAllFloorPlanList,
  ]);

  useEffect(() => {
    if (createLoading !== null && !createLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (!isEmpty(createData)) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: !floorPlanId
                ? t('attributes.property.floorPlanCreated')
                : t('attributes.property.floorPlanUpdated'),
            })
          );
          setFloorPlanId('');
        }
      }
    }
  }, [error, createLoading]);

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(
    () => () => {
      dispatch(resetFloorPlanCreate());
      dispatch(resetFloorPlanList());
      dispatch(resetFloorPlanDelete());
    },
    []
  );
  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    if (!deleteDataLoading) {
      if (deleteData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deleteData.message,
          })
        );
        debouncedFetchData();
      }
    }
  }, [deleteDataLoading, deleteData]);

  const noData = (
    <NewNoDataPage
      icon={<ViewInArOutlinedIcon />}
      title={t('attributes.property.floorPlans')}
      singularText={t('attributes.property.floorPlan')}
      createBtnText={`${t('attributes.add')} ${t('attributes.property.floorPlan')}`}
      filterHeight={301}
    />
  );

  const onSubmit = async (data) => {
    const isDocUploaded = data?.document_uuid;
    let docs = [];

    if (!isEmpty(isDocUploaded)) {
      docs.push({ ...isDocUploaded });
    }

    if (editFloorPlan?.document_uuid) {
      const oldDocId = editFloorPlan?.document_uuid;

      if (oldDocId !== isDocUploaded || isEmpty(isDocUploaded)) {
        docs.push({
          document_uuid: editFloorPlan.document_uuid,
          is_deleted: true,
        });
      }
    }

    const req = {
      property_uuid: propertyId,
      label: data?.label,
      floor_no: data?.floor_no,
      is_active: true,
      document_uuid: docs[0]?.document_uuid,
    };

    if (!floorPlanId) {
      dispatch(createFloorPlan(req));
    } else {
      dispatch(updateFloorPlan({ uuid: floorPlanId, req }));
    }
  };

  const styledButtonDispalyer = (
    <>
      <CustomButton
        text={`${t('attributes.add')} ${t('attributes.property.floorPlan')}`}
        color="secondary"
        sx={{ height: '52%' }}
        startIcon={<AddCircleOutlineOutlinedIcon />}
        onClick={() => {
          setOpen(true);
          reset(defaultValues);
        }}
      />
    </>
  );

  const renderedComponent = (
    <>
      <FilterSection
        sx={{ mt: 2, mr: 2 }}
        searchText={searchText}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isReset={false}
        isFilter={false}
      />

      <Box
        sx={{
          width: '100%',
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          p: '16px',
        }}
      >
        {listLoading ? (
          <ContentWrapper>
            <CustomCircularLoader />
          </ContentWrapper>
        ) : !listLoading && total !== 0 ? (
          floorPlanList?.map?.((item) => (
            <Box
              sx={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between',
                mx: '16px',
                border: `1px solid ${DIVIDER_COLOR}`,
                mb: '16px',
                borderRadius: '4px',
                px: '16px',
                py: '8px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                <Box>
                  <ViewInArOutlinedIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: NODATA_ICON_COLOR,
                    }}
                  />
                </Box>
                <Box>
                  <Typography variant="body1">{item?.label}</Typography>
                  <Typography variant="body2" sx={{ color: NODATA_ICON_COLOR }}>
                    {t('attributes.property.levelNum')}:{item?.floor_no}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  gap: '8px',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <View
                  onClick={() => {
                    dispatch(getFloorPlanById(item?.uuid));
                    setFloorPlanId(item?.uuid);
                    setNextStepTitle({
                      title: PROPERTY_DETAIL_STEP.floorPlan,
                      step: item?.label,
                    });
                    setActiveTab(0);
                  }}
                />
                <Edit
                  onClick={() => {
                    setFloorPlanId(item.uuid);
                    setOpen(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setOpenDeleteModal(true);
                    setFloorPlanId({ id: item?.uuid, label: item?.label });
                  }}
                />
              </Box>
            </Box>
          ))
        ) : (
          <ContentWrapper>
            <NoRecordFound />
          </ContentWrapper>
        )}
      </Box>
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper btn={styledButtonDispalyer} isSubDetails={true}>
      {!listLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            minHeight: `calc(100vh - 301px)`,
            maxHeight: `calc(100vh - 301px)`,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading && totalCount > 0 && renderedComponent
      )}
    </StyledMainWrapper>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setFloorPlanId('');
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {openDeleteModal && (
        <ConfirmationModal
          title={`${t('attributes.delete')} ${t('attributes.property.floorPlan')}`}
          description={t(
            'attributes.property.deleteFloorPlanDescription'
          ).replace(':label', floorPlanId?.label)}
          open={openDeleteModal}
          onClose={() => setFloorPlanId('')}
          setOpen={setOpenDeleteModal}
          onConfirm={() => {
            dispatch(deleteFloorPlan({ uuid: floorPlanId?.id }));
            setFloorPlanId('');
            setOpenDeleteModal(false);
          }}
          isDelete={true}
        />
      )}
      {/* Todo: Need to discuss with dharmesh bhai from where we will get this to display */}
      {nextStepTitle?.title === PROPERTY_DETAIL_STEP.floorPlan ? (
        <ComingSoon />
      ) : (
        mainWrapper
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            !floorPlanId
              ? `${t('attributes.add')} ${t('attributes.property.floorPlan')}`
              : `${t('attributes.edit')} ${t('attributes.property.floorPlan')}`
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpen(false) && setFloorPlanId('');
          }}
          footerButton={
            <CustomButton
              text={
                createLoading
                  ? t('common.loading')
                  : !floorPlanId
                    ? t('attributes.add')
                    : t('attributes.save')
              }
              disabled={createLoading}
              startIcon={!floorPlanId ? <AddIcon /> : <SaveOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
            />
          }
        >
          <FloorPlanForm
            id={floorPlanId}
            reset={reset}
            clearErrors={clearErrors}
            control={control}
            register={register}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            setServerErrors={setServerErrors}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default FloorPlan;
