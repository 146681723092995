import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { CKEditor } from '@ckeditor/ckeditor5-react';

const Ckeditor = ({ setData = () => {}, data, name = '', ...rest }) => (
  <CKEditor
    name={name}
    data={data}
    editor={ClassicEditor}
    config={{
      licenseKey: 'GPL',
      style: { fontWeight: 400 },
      toolbar: [
        'bold',
        'italic',
        'underline',
        'strikethrough',
        '|',
        'numberedList',
        'bulletedList',
      ],
    }}
    onChange={(e, editor) => {
      setData(editor.getData());
    }}
    {...rest}
  />
);
export default Ckeditor;
