import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Divider, styled, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { ROUTINE_TYPES } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import {
  getEquipmentClassDropdown,
  getEquipmentTypesDropdown,
} from '../../store/equipmentTypes/api';
import { resetEquipmentClassDropdownList } from '../../store/equipmentTypes/reducer';
import { getFrequencyList } from '../../store/frequency/api';
import { resetFrequencyList } from '../../store/frequency/reducer';
import { getInspectionFormList } from '../../store/inspectionForm/api';
import {
  resetCreateRoutine,
  resetRoutineList,
} from '../../store/routine/reducer';
import { getStandardsDropdownList } from '../../store/standards/api';
import { clearTextfields, loggedInUserDetail } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const FrequencyFieldWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const RoutineForm = ({ id, control, setValue, trigger, reset, watch }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [routineDetailsData, setRoutineDetailsData] = useState();
  const [frequency, setFrequency] = useState([]);
  const [scheduleRoutine, setScheduleRoutine] = useState(false);
  const [isInitialChecked, setIsInitialChecked] = useState(true);

  const { data: routinesList, isLoading } = useSelector(
    (state) => state.routine.propertyRoutineList
  );

  const { data: frequencyList, isLoading: loadingFrequency } = useSelector(
    (state) => state.frequency.frequencyList
  );

  const { standardsDropdownLoading, standardsDropdownData } =
    getDropdownListHook({
      reducerName: 'standards',
      dropdownListName: 'standardsDropdownList',
      labelName: 'code',
      valueName: 'id',
    });

  const { equipmentTypesDropdownLoading, equipmentTypesDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentTypesDropdownList',
      labelName: 'display_name',
      valueName: 'id',
    });

  const { inspectionFormDropdownLoading, inspectionFormDropdownData } =
    getDropdownListHook({
      reducerName: 'inspectionForm',
      dropdownListName: 'get',
      labelName: 'display_name',
      valueName: 'id',
    });

  // Get equipment class list
  const { equipmentClassDropdownLoading, equipmentClassDropdownData } =
    getDropdownListHook({
      reducerName: 'equipmentTypes',
      dropdownListName: 'equipmentClassDropdownList',
      customNameForDropdown: 'equipmentClass',
      labelName: 'name',
      valueName: 'id',
    });

  const selectedEquipmentType = watch('equipmentType');

  useEffect(() => {
    dispatch(getEquipmentTypesDropdown());
    dispatch(getStandardsDropdownList());
    dispatch(getInspectionFormList());
    dispatch(
      getFrequencyList({
        companyUUID: loggedInUserDetail()?.company_uuid,
        type: 'standard,custom',
        limit: -1,
      })
    );

    return () => {
      dispatch(resetFrequencyList());
      dispatch(resetRoutineList());
      dispatch(resetCreateRoutine());
    };
  }, []);

  useEffect(() => {
    const detail = routinesList?.data?.find((item) => item?.id === id);

    setRoutineDetailsData(detail);
  }, [routinesList]);

  useEffect(() => {
    if (frequencyList) {
      setFrequency(frequencyList?.data);
    }
  }, [frequencyList]);

  useEffect(() => {
    if (!isLoading && routineDetailsData && id && frequencyList) {
      if (isInitialChecked) {
        const isChecked = routineDetailsData?.standard_frequencies[0]
          ?.frequencies
          ? true
          : false;

        setScheduleRoutine(isChecked);
      }
      reset({
        name: routineDetailsData?.display_name,
        referenceCode: routineDetailsData?.code,
        standard: routineDetailsData?.standard_frequencies
          ? {
              label:
                routineDetailsData?.standard_frequencies[0]?.standards?.code,
              value: routineDetailsData?.standard_frequencies[0]?.standards?.id,
            }
          : null,
        equipmentType: {
          label: routineDetailsData?.routine_equipment_types?.display_name,
          value: routineDetailsData?.routine_equipment_types?.id,
        },
        internalNotes: routineDetailsData?.internal_note,
        standardNotes: routineDetailsData?.internal_note,
        scheduledRoutine:
          isInitialChecked &&
          routineDetailsData?.standard_frequencies[0]?.frequencies
            ? true
            : false,
        ...(routineDetailsData?.equipment_class?.id && {
          product: {
            label: routineDetailsData?.equipment_class?.name,
            value: routineDetailsData?.equipment_class?.id,
          },
        }),
        ...(routineDetailsData?.standard_frequencies[0] &&
          !routineDetailsData?.standard_frequencies[0]?.frequencies && {
            routineInspectionForm: routineDetailsData?.standard_frequencies[0]
              ?.forms
              ? {
                  label:
                    routineDetailsData?.standard_frequencies[0]?.forms
                      ?.display_name,
                  value: routineDetailsData?.standard_frequencies[0]?.forms?.id,
                }
              : null,
          }),
      });

      const updatedFrequencies = frequencyList?.data?.map((freq) => {
        const isChecked = routineDetailsData?.standard_frequencies?.some(
          (freqItem) => freqItem.frequencies?.code === freq?.code
        );

        const formDetails = routineDetailsData?.standard_frequencies?.filter(
          (freqItem) => freqItem.frequencies?.code === freq?.code
        )[0];

        if (
          routineDetailsData?.standard_frequencies[0]?.frequencies ||
          scheduleRoutine
        ) {
          return {
            ...freq,
            isChecked: isChecked,
            selectedForm: formDetails
              ? {
                  label: formDetails?.forms?.display_name,
                  value: formDetails?.forms?.id,
                }
              : null,
            standardFrequencyType: formDetails?.type,
          };
        } else {
          return {
            ...freq,
            isChecked: scheduleRoutine ? isChecked : true,
            selectedForm:
              routineDetailsData?.standard_frequencies[0]?.forms &&
              !scheduleRoutine
                ? {
                    label:
                      routineDetailsData?.standard_frequencies[0]?.forms
                        ?.display_name,
                    value:
                      routineDetailsData?.standard_frequencies[0]?.forms?.id,
                  }
                : null,
            standardFrequencyType: scheduleRoutine
              ? formDetails?.type
              : ROUTINE_TYPES.CUSTOM,
          };
        }
      });

      setValue('frequency_form', updatedFrequencies);
      setFrequency(updatedFrequencies);
    }
  }, [frequencyList, routineDetailsData, scheduleRoutine, isInitialChecked]);

  const handleCheckboxChange = (name, checked, selectedForm) => {
    const updatedFrequencies = frequency?.map((freq) => {
      const formDetails = routineDetailsData?.frequency_form?.filter(
        (freqItem) => freqItem.frequency.code === freq?.code
      )[0];

      if (freq.name === name) {
        return {
          ...freq,
          isChecked: checked,
          selectedForm: checked ? selectedForm : null,
          standardFrequencyType: formDetails?.type
            ? formDetails?.type
            : ROUTINE_TYPES.CUSTOM,
        };
      }

      return freq;
    });

    setFrequency(updatedFrequencies);
    setValue('frequency_form', updatedFrequencies);
    setValue('scheduledRoutine', scheduleRoutine);
  };

  useEffect(() => {
    if (selectedEquipmentType?.label) {
      dispatch(
        getEquipmentClassDropdown({
          equipment_type_id: selectedEquipmentType?.value,
          status: true,
        })
      );
    }
  }, [selectedEquipmentType]);

  return loadingFrequency ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper>
          <Controller
            name="name"
            control={control}
            rules={{
              required: `${t('attributes.name')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.name')}
                fullWidth
                disabled={
                  routineDetailsData?.type === ROUTINE_TYPES.STANDARD
                    ? true
                    : false
                }
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="referenceCode"
            control={control}
            rules={{
              required: `${t('attributes.routineServiceFrequencies.reference_code')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.routineServiceFrequencies.reference_code')}
                fullWidth
                disabled={
                  routineDetailsData?.type === ROUTINE_TYPES.STANDARD
                    ? true
                    : false
                }
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('referenceCode');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="standard"
            control={control}
            rules={{
              required: `${t('attributes.routineServiceFrequencies.standard')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.routineServiceFrequencies.standard')}
                options={standardsDropdownData}
                disabled={
                  routineDetailsData?.type === ROUTINE_TYPES.STANDARD
                    ? true
                    : false
                }
                value={value}
                onChange={(e, newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  trigger(ROUTINE_TYPES.STANDARD);
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={standardsDropdownLoading}
                onClear={() => clearTextfields(setValue, name)}
              />
            )}
          />
          <Controller
            name="equipmentType"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.equipment_type')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.productCatalogue.equipment_type')}
                options={equipmentTypesDropdownData}
                disabled={
                  routineDetailsData?.type === ROUTINE_TYPES.STANDARD
                    ? true
                    : false
                }
                value={value}
                onChange={(e, newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  trigger('equipmentType');
                  dispatch(resetEquipmentClassDropdownList());
                  setValue('product', null);
                }}
                helperText={error ? error.message : ''}
                error={error}
                isLoadingData={equipmentTypesDropdownLoading}
              />
            )}
          />
          <Controller
            name="product"
            control={control}
            rules={{
              required: `${t('attributes.productCatalogue.product')} ${Validation.general.required}`,
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                label={t('attributes.productCatalogue.product')}
                options={equipmentClassDropdownData}
                disabled={
                  routineDetailsData?.type === ROUTINE_TYPES.STANDARD
                    ? true
                    : false
                }
                value={value}
                onChange={(e, newValue) => {
                  if (newValue) {
                    onChange(newValue);
                  }
                  trigger('product');
                }}
                isLoadingData={equipmentClassDropdownLoading}
                error={error}
                helperText={error ? error.message : ''}
              />
            )}
          />
          {routineDetailsData?.type === ROUTINE_TYPES.STANDARD && (
            <Controller
              name="internalNotes"
              control={control}
              rules={{
                maxLength: {
                  value: 300,
                  message: Validation.general.description,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  isRequired={false}
                  disabled={true}
                  label={t(
                    'attributes.routineServiceFrequencies.internalNotes'
                  )}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('internalNotes');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
          )}
          {routineDetailsData?.type !== ROUTINE_TYPES.STANDARD && (
            <Controller
              name="standardNotes"
              control={control}
              rules={{
                maxLength: {
                  value: 300,
                  message: Validation.general.description,
                },
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <CustomTextField
                  isRequired={false}
                  label={t(
                    'attributes.routineServiceFrequencies.standardNotes'
                  )}
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('standardNotes');
                  }}
                  multiline
                  rows={2}
                  helperText={error ? error.message : ''}
                  error={error}
                  onClear={() => clearTextfields(setValue, name)}
                />
              )}
            />
          )}
        </FormFieldWrapper>
        <Box sx={{ padding: '16px' }}>
          {routineDetailsData?.type !== ROUTINE_TYPES.STANDARD && (
            <CommonCheckbox
              control={control}
              name="scheduledRoutine"
              label={t(
                'attributes.routineServiceFrequencies.scheduled_routine'
              )}
              isRequired={false}
              checked={scheduleRoutine}
              onChange={(e) => {
                setIsInitialChecked(false);
                setScheduleRoutine(e.target.checked);
                setValue('scheduledRoutine', e.target.checked);
              }}
            />
          )}

          <Box sx={{ paddingTop: '16px' }}>
            {scheduleRoutine ? (
              <Typography variant="body1">
                {t('attributes.property.frequency')}
              </Typography>
            ) : (
              <Controller
                name="routineInspectionForm"
                control={control}
                rules={{
                  required: `${t('attributes.routineServiceFrequencies.inspectionForm')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <Autocomplete
                    options={inspectionFormDropdownData}
                    label={t(
                      'attributes.routineServiceFrequencies.inspectionForm'
                    )}
                    onChange={(e, newValue) => {
                      if (newValue) {
                        onChange(newValue);
                      }
                      onChange(newValue);
                      setValue('frequency_form', [
                        {
                          isChecked: true,
                          standardFrequencyType: ROUTINE_TYPES.CUSTOM,
                          selectedForm: newValue,
                        },
                      ]);
                    }}
                    value={value}
                    error={error}
                    helperText={error ? error.message : ''}
                    onClear={() => clearTextfields(setValue, name)}
                  />
                )}
              />
            )}
          </Box>
        </Box>

        {scheduleRoutine && (
          <FrequencyFieldWrapper>
            {frequency?.map((freq) => (
              <>
                <Box
                  key={freq.code}
                  sx={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '0 16px 0 16px',
                    }}
                  >
                    <CommonCheckbox
                      control={control}
                      name={freq.name}
                      label={freq.display_name}
                      isRequired={false}
                      checked={freq.isChecked}
                      onChange={
                        (e) => handleCheckboxChange(freq.name, e.target.checked) // Update state when checkbox is toggled
                      }
                      disabled={
                        freq?.standardFrequencyType === ROUTINE_TYPES.STANDARD
                      }
                    />
                    <Box
                      sx={{
                        px: '16px',
                        py: '4px',
                        border: '1px solid black',
                        borderRadius: '24px',
                      }}
                    >
                      {freq?.code}
                    </Box>
                  </Box>

                  <Box sx={{ padding: '10px', width: '300px' }}>
                    <Controller
                      name={`inspectionForm.${freq.id}`}
                      control={control}
                      rules={{
                        required:
                          freq.isChecked && !freq.selectedForm
                            ? `${t('attributes.routineServiceFrequencies.inspectionForm')} ${Validation.general.required}`
                            : false,
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <Autocomplete
                          disabledDropdown={
                            !freq.isChecked ||
                            freq?.standardFrequencyType ===
                              ROUTINE_TYPES.STANDARD
                          }
                          label={t(
                            'attributes.routineServiceFrequencies.inspectionForm'
                          )}
                          options={inspectionFormDropdownData}
                          value={freq.selectedForm || value}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              onChange(newValue);
                            }
                            handleCheckboxChange(
                              freq.name,
                              freq.isChecked,
                              newValue
                            );
                            trigger(`inspectionForm.${freq.id}`);
                          }}
                          helperText={error ? error.message : ''}
                          error={error}
                          isLoadingData={inspectionFormDropdownLoading}
                        />
                      )}
                    />
                  </Box>
                </Box>
                <Divider />
              </>
            ))}
          </FrequencyFieldWrapper>
        )}
      </CustomCard>
    </FormWrapper>
  );
};

export default RoutineForm;
