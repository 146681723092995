import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ContactPageOutlinedIcon from '@mui/icons-material/ContactPageOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import MarkEmailReadOutlinedIcon from '@mui/icons-material/MarkEmailReadOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Box,
  Card,
  Divider,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  styled,
  Typography,
} from '@mui/material';
// Internal
import { isEmpty } from 'lodash';

import { theme } from '../../assets/theme';
import { SECONDARY, WARNING, WARNING_HOVER } from '../../constants/Colors';
import { validateAddress } from '../../constants/common';
import { FILE_UPLOAD_SIZES } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../constants/Regex';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
import useAddressDetail from '../../hooks/useAddressDetail';
import useAddressValidationHook from '../../hooks/useAddressValidationHook';
import useAutocompleteAddress from '../../hooks/useAutcompleteAddress';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { uploadDocument } from '../../store/CommonAPI';
import { resetDocumentData, snackbarToggle } from '../../store/CommonReducer';
import {
  createCompany,
  editCompany,
  updateCompany,
} from '../../store/company/api';
import {
  resetAddressDetail,
  resetAutocompleteAddress,
} from '../../store/google/reducer';
import { createSuperAdmin } from '../../store/superAdmin/api';
import { formattedDate } from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomFileUploadButton from '../CommonComponents/CustomFileUploadButton';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../CommonComponents/Modal';
import UploadedDocumentView from '../CommonComponents/UploadedDocumentView';
import SuperAdminForm from '../SuperAdmin/SuperAdminForm';
import InviteTheFireCompany from './InviteCompany';
import UploadLogo from './UploadLogo';

const FormFieldWrapper = styled(Box)(() => ({
  padding: '16px',
  rowGap: '16px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const FormWrapper = styled(Box)(() => ({
  maxHeight: '84vh',
  overflowY: 'auto',
  width: '100%',
  scrollbarWidth: 'none',
  display: 'flex',
  flexDirection: 'column',
  rowGap: '16px',
}));

const CustomCard = styled(Card)(() => ({
  boxShadow: 'none',
  overflow: 'initial',
}));

const CustomStepIconRoot = styled('div')(({ theme, ownerState }) => ({
  backgroundColor: ownerState.active ? theme.palette.primary.main : 'white',
  color: ownerState.active ? 'white' : 'black',
  zIndex: 1,
  width: 26,
  height: 26,
  border: ownerState.active ? '' : '1px solid black',
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(ownerState.completed && {
    backgroundColor: 'black',
    color: 'white',
  }),
}));

const LayoutContainer = styled(Box)(({ id }) => ({
  display: 'grid',
  gridTemplateColumns: id ? '1fr' : '1fr 2fr',
  height: '100%',
}));

const CustomConnector = styled(StepConnector)(({ theme }) => ({
  '& .MuiStepConnector-line': {
    borderColor: theme.palette.grey,
    borderWidth: 2,
    borderLeftStyle: 'dotted',
  },
}));

const stepIcons = [
  <PortraitOutlinedIcon key="icon-01" sx={{ height: '15px', width: '15px' }} />,
  <ContactPageOutlinedIcon
    key="icon-02"
    sx={{ height: '15px', width: '15px' }}
  />,
  <ForwardToInboxOutlinedIcon
    key="icon-03"
    sx={{ height: '15px', width: '15px' }}
  />,
];

const CompanyForm = ({ id, setServerError }) => {
  const dispatch = useDispatch();
  const {
    control,
    handleSubmit,
    trigger,
    reset,
    watch,
    setValue,
    clearErrors,
    setError = () => ({}),
    setServerErrors,
  } = useFormContext();

  const { isLoading, company } = useSelector((state) => state.company.edit);
  const { isLoading: createLoading } = useSelector(
    (state) => state.company.create
  );

  const selectedState = watch('state');

  const stateList = getStatesHook();
  const cityList = getCityByStateHook(selectedState?.value);

  const { t } = useTranslation();
  const {
    isLoading: isAutocompleteAddressLoading,
    data: autocompleteAddressData,
  } = useSelector((state) => state.googleAPIs.autocompleteAddress);

  const { isDocumentLoading, uploadedSuccessfully, documentData } = useSelector(
    (state) => state.common
  );
  const [uploadedDocuments, setUploadedDocuments] = useState({
    name: '',
    size: '',
  });

  const typedValue = watch('searchAddress');

  const [searchAddress, setSearchAddress] = useState('');
  const [activeStep, setActiveStep] = useState(0);
  const [isNextReady, setIsNextReady] = useState(true);
  const [addressBlur, setAddressBlur] = useState(false);
  const [stateBlur, setStateBlur] = useState(false);
  const [cityBlur, setCityBlur] = useState(false);
  const [postcodeBlur, setPostcodeBlur] = useState(false);
  const [documentError, setdocumentError] = useState('');
  const [isUploadingOther, setIsUploadingOther] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [addressRecheck, setAddressRecheck] = useState(true);
  const [newCompanyId, setNewCompanyId] = useState(null);

  useAutocompleteAddress(searchAddress);
  useAddressDetail({
    selectedAddress: typedValue,
    setValue: setValue,
    fieldName: {
      addressKey: 'address',
      stateKey: 'state',
      cityKey: 'city',
      postCodeKey: 'zip_code',
    },
  });

  const labels = (label) => t(`label.globalAdmin.${label}`);

  useEffect(() => {
    if (id) {
      dispatch(editCompany(id));
    }

    return () => {
      dispatch(resetDocumentData());
      dispatch(resetAutocompleteAddress());
      dispatch(resetAddressDetail());
    };
  }, []);

  useEffect(() => {
    if (!isLoading && company && id) {
      reset({
        uuid: company.uuid,
        company_name: company.company_name,
        abn: company.abn,
        address: company.address,
        contact_name: company.contact_name,
        email: company.email,
        cell_phone: company.cell_phone,
        city: { label: company.city, value: company.city },
        state: { label: company.state, value: company.state },
        active: company.active,
        logo_upload: `data:image/*;base64,${company.logo_upload}`,
        created_at: formattedDate(company.created_at),
        zip_code: company.zip_code,
        agreementDocument:
          company?.documents?.length > 0 ? company.documents[0] : {},
      });
    }
  }, [isLoading, company]);

  const clearHandler = (name) => setValue(name, '');

  const postCodeLength = 4;

  const address = watch('address');
  const state = watch('state');
  const city = watch('city');
  const postcode = watch('zip_code');

  useEffect(() => {
    if (address && state && city && postcode) {
      setAddressBlur(true);
      setStateBlur(true);
      setCityBlur(true);
      setAddressBlur(true);
      setAddressRecheck(true);
      trigger('address');
      trigger('state');
      trigger('city');
      trigger('zip_code');
    }
  }, [address, state, city, postcode]);

  useAddressValidationHook({
    address: address,
    addressBlur: addressBlur,
    state: state,
    stateBlur: stateBlur,
    clearErrors,
    city: city,
    cityBlur: cityBlur,
    setError,
    postcode: postcode,
    postcodeBlur: postcodeBlur,
    addressRecheck,
    fieldName: {
      addressKey: 'address',
      addressValue: t('attributes.address'),
      stateKey: 'state',
      stateValue: t('common.state'),
      cityKey: 'city',
      cityValue: t('common.city'),
      postCodeKey: 'zip_code',
      postCodeValue: t('attributes.postCode'),
    },
  });

  useEffect(() => {
    if (!isDocumentLoading && uploadedSuccessfully) {
      setIsUploadingOther(false);
      setValue('agreementDocument', {
        document_uuid: documentData?.uuid,
        document_type: documentData?.document_type,
        document_name: documentData?.document_name,
        document_size: uploadedDocuments?.size,
      });

      setAddressRecheck(false);
    }
  }, [isDocumentLoading, uploadedSuccessfully]);

  const steps = [
    'Company Details',
    'Super Admin Details',
    'Invite Fire Company',
  ];

  const CustomStepIcon = ({ icon, active, completed, className }) => (
    <CustomStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {stepIcons[icon - 1]}
    </CustomStepIconRoot>
  );
  const [serverSuperAdminErrors, setSuperAdminServerErrors] = useState([]);
  const { handleServerErrors } = useServerSideErrors(
    serverSuperAdminErrors,
    setError,
    clearErrors
  );

  useEffect(() => {
    if (serverSuperAdminErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverSuperAdminErrors, handleServerErrors]);

  const onSubmit = async (data) => {
    const hasError = await validateAddress({
      address: data.address,
      city: data.city,
      state: data.state,
      postcode: data.zip_code,
      fieldName: {
        addressKey: 'address',
        addressValue: t('attributes.address'),
        stateKey: 'state',
        stateValue: t('common.state'),
        cityKey: 'city',
        cityValue: t('common.city'),
        postCodeKey: 'zip_code',
        postCodeValue: t('attributes.postCode'),
      },
      setServerErrors,
    });

    if (hasError) return;

    const isDocUploaded = data?.agreementDocument;
    let docs = [];

    if (!isEmpty(isDocUploaded)) {
      docs.push(isDocUploaded);
    }
    if (company?.documents?.length > 0) {
      const oldDocId = company?.documents?.document_uuid;

      if (oldDocId !== isDocUploaded?.document_uuid || isEmpty(isDocUploaded)) {
        docs.push({ ...company?.documents[0], is_deleted: true });
      }
    }

    const createdCompanyData = {
      company_name: data.company_name,
      address: data.address,
      city: data.city?.value,
      state: data.state?.value,
      zip_code: data.zip_code,
      logo_upload: data.logo_upload.split(',')[1],
      contact_name: data.contact_name,
      email: data?.email,
      abn: data.abn,
      office_phone: data.cell_phone,
      cell_phone: data.cell_phone,
      ...(data.active !== undefined && {
        active: data.active,
      }),
    };

    if (!id) {
      if (!newCompanyId) {
        dispatch(createCompany(createdCompanyData)).then((res) => {
          if (res.payload?.errorDetails) {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: res.payload?.errorDetails?.[0]?.message,
              })
            );
          }
          if (res.payload?.data) {
            setNewCompanyId(res.payload?.data[0]?.uuid);
            dispatch(
              createSuperAdmin({
                name: data.name,
                email: data.superadmin_email,
                address: data.superadmin_address,
                cell_phone: data.superadmin_cell_phone,
                city: data.superadmin_city?.value,
                state: data.superadmin_state?.value,
                zip_code: data.superadmin_zip_code,
                company_uuid: res.payload?.data[0]?.uuid,
                company_name: res.payload?.data[0]?.company_name,
                active: data.active,
                documents: docs,
              })
            ).then((superAdminRes) => {
              if (superAdminRes.payload?.errorDetails) {
                setSuperAdminServerErrors(superAdminRes.payload?.errorDetails);
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: superAdminRes.payload?.errorDetails?.[0]?.message,
                  })
                );
              }
            });
          }
        });
      } else {
        dispatch(
          updateCompany({ uuid: newCompanyId, data: createdCompanyData })
        ).then((res) => {
          if (res.payload?.errorDetails) {
            dispatch(
              snackbarToggle({
                isOpen: true,
                isErrorMsg: true,
                msg: res.payload?.errorDetails?.[0]?.message,
              })
            );
          }
          if (res.payload?.data) {
            setNewCompanyId(res.payload?.data[0]?.uuid);
            dispatch(
              createSuperAdmin({
                name: data.name,
                email: data.superadmin_email,
                address: data.superadmin_address,
                cell_phone: data.superadmin_cell_phone,
                city: data.superadmin_city?.value,
                state: data.superadmin_state?.value,
                zip_code: data.superadmin_zip_code,
                company_uuid: res.payload?.data[0]?.uuid,
                company_name: res.payload?.data[0]?.company_name,
                active: data.active,
                documents: docs,
              })
            ).then((superAdminRes) => {
              if (superAdminRes.payload?.errorDetails) {
                setSuperAdminServerErrors(superAdminRes.payload?.errorDetails);
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: superAdminRes.payload?.errorDetails?.[0]?.message,
                  })
                );
              }
            });
          }
        });
      }
    } else {
      dispatch(updateCompany({ uuid: data?.uuid, data: createdCompanyData }));
    }
  };

  const handleNext = async () => {
    setIsNextReady(false);
    const isValid = await trigger(); // Trigger validation for all fields in the current step

    let hasError = false;

    if (activeStep === 0 && address && city && state && postcode) {
      hasError = await validateAddress({
        address: address,
        city: city,
        state: state,
        postcode: postcode,
        fieldName: {
          addressKey: 'address',
          addressValue: t('attributes.address'),
          stateKey: 'state',
          stateValue: t('common.state'),
          cityKey: 'city',
          cityValue: t('common.city'),
          postCodeKey: 'zip_code',
          postCodeValue: t('attributes.postCode'),
        },
        setServerErrors,
        clearErrors,
      });
      trigger();
    }

    if (hasError) {
      setIsNextReady(true);

      return;
    }

    if (isValid) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    setIsNextReady(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleFileChange = (type) => (event, onChange) => {
    const file = event.target.files[0];
    const isValidFile = file && file.type === 'application/pdf';

    if (file.size > FILE_UPLOAD_SIZES.DOCUMENT) {
      setdocumentError(t('message.customer.uploadDocumentMaxSizeError'));

      return;
    }

    if (isValidFile) {
      setAddressRecheck(false);
      onChange(event);
      const fileSize = (file.size / 1024).toFixed(0) + ' KB';

      setIsUploadingOther(true);
      setUploadedDocuments({
        name: file?.name,
        size: fileSize,
      });
      setdocumentError('');

      dispatch(
        uploadDocument({ document_type: type, uploaded_by: 1, file: file })
      );
    } else {
      setdocumentError('Invalid file type. Please upload a PDF.');
    }
  };

  const handleDeleteDocument = (type) => {
    setAddressRecheck(false);
    setUploadedDocuments((prev) => ({
      ...prev,
      [type]: null,
    }));
    setValue(`${type}Document`, null);

    const input = document.querySelector(`input[name="${type}Document"]`);

    if (input) {
      input.value = ''; // Reset the file input
    }

    setdocumentError('');
  };

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <LayoutContainer id={id}>
      {!id && (
        <Box
          sx={{
            position: 'block',
            width: '259px',
            padding: '16px',
            background: SECONDARY,
            marginRight: '16px',
            borderRadius: '4px',
            height: '84vh',
          }}
        >
          <Stepper
            activeStep={activeStep}
            orientation="vertical"
            connector={<CustomConnector />}
          >
            {steps.map((label, idx) => (
              <Step key={label}>
                <StepLabel StepIconComponent={CustomStepIcon}>
                  <Typography
                    variant={idx <= activeStep ? 'body2' : 'body2'}
                    sx={{
                      color:
                        idx === activeStep
                          ? theme.palette.primary.main
                          : 'black',
                    }}
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
      )}

      <FormWrapper component="form" onSubmit={handleSubmit(onSubmit)}>
        {activeStep === 0 && (
          <>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">{`${t('attributes.company.company')} ${t('attributes.company.details')}`}</Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Controller
                  name="company_name"
                  control={control}
                  rules={{
                    required: `${t('attributes.company.company')} ${t('attributes.name')} ${Validation.general.required}`,
                    minLength: {
                      value: 2,
                      message: Validation.company.companyName,
                    },
                    maxLength: {
                      value: 255,
                      message: Validation.company.companyName,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={`${t('attributes.company.company')} ${t('attributes.name')}`}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('company_name');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                      disabled={id ? true : false}
                    />
                  )}
                />
                <Controller
                  name="abn"
                  control={control}
                  rules={{
                    required: `${t('attributes.company.abnNumber')} ${Validation.general.required}`,
                    pattern: {
                      value: /^\d{11}$/,
                      message: Validation.general.abnNumber,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.company.abnNumber')}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('abn');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
                <Controller
                  name="searchAddress"
                  control={control}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <Autocomplete
                      label={t('common.searchAddress')}
                      options={autocompleteAddressData || []}
                      value={value}
                      isRequired={false}
                      isLoadingData={isAutocompleteAddressLoading}
                      onChange={(e, newValue) => {
                        onChange(newValue);
                        setSearchAddress(e.target?.value);
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                    />
                  )}
                />
                <Controller
                  name="address"
                  control={control}
                  rules={{
                    required: `${t('attributes.address')} ${Validation.general.required}`,
                    maxLength: {
                      value: 300,
                      message: Validation.general.address,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.address')}
                      fullWidth
                      value={value}
                      setBlur={setAddressBlur}
                      onChange={(e) => {
                        onChange(e);
                        trigger('address');
                      }}
                      multiline
                      rows={2}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />

                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="state"
                    control={control}
                    rules={{
                      required: `${t('common.state')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={stateList}
                        label={t('common.state')}
                        setBlur={setStateBlur}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          setValue('city', null);
                          clearErrors('city');
                        }}
                        value={value}
                        error={error}
                        helperText={error ? error.message : ''}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    rules={{
                      required: `${t('common.city')} ${Validation.general.required}`,
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <Autocomplete
                        options={cityList}
                        label={t('common.city')}
                        setBlur={setCityBlur}
                        onChange={(e, newValue) => {
                          onChange(newValue);
                          trigger('city');
                        }}
                        value={value}
                        error={error}
                        helperText={error ? error.message : ''}
                        onClear={() => clearHandler(name)}
                        disabledDropdown={!selectedState}
                      />
                    )}
                  />
                </Box>
                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="zip_code"
                    control={control}
                    rules={{
                      required: `${t('attributes.postCode')} ${Validation.general.required}`,
                      validate: (value) => {
                        if (value && !/^\d+$/.test(value)) {
                          return Validation.general.numericValue;
                        }

                        return true;
                      },
                      minLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                      maxLength: {
                        value: postCodeLength,
                        message: `${Validation.superAdmin.postCodeCharacter} ${postCodeLength} ${Validation.superAdmin.characters}`,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.postCode')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(
                            /\D/g,
                            ''
                          );

                          onChange(numericValue);
                          trigger('zip_code');
                        }}
                        setBlur={setPostcodeBlur}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                </Box>
                <Typography variant="body1">
                  {t('attributes.preview')}
                  <span style={{ color: WARNING }}>*</span>
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    columnGap: '12px',
                    width: '100%',
                  }}
                >
                  <Controller
                    name="logo_upload"
                    control={control}
                    rules={{
                      required: `${t('attributes.company.company')} ${t('attributes.company.logo')} ${Validation.general.required}`,
                    }}
                    render={({ field: { value }, fieldState: { error } }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                        }}
                      >
                        <UploadLogo logoUrl={value} />
                        {!value && error && (
                          <Typography variant="body2" color={WARNING}>
                            {error.message}
                          </Typography>
                        )}
                      </Box>
                    )}
                  />
                  <Controller
                    name="logo_upload"
                    control={control}
                    render={({ field: { value } }) => (
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          rowGap: '16px',
                        }}
                      >
                        <input
                          accept="image/*"
                          style={{ display: 'none' }}
                          id="upload-button-file"
                          type="file"
                          onChange={(event) => {
                            const file = event.target.files[0];

                            event.target.value = '';
                            if (file && file.size > FILE_UPLOAD_SIZES.IMAGE) {
                              setError('logo_upload', {
                                type: 'custom',
                                message: t(
                                  'message.company.uploadLogoMaxSizeError'
                                ),
                              });

                              return;
                            }
                            if (file) {
                              const reader = new FileReader();

                              reader.onload = function (e) {
                                const dataUrl = e.target.result;

                                setValue('logo_upload', dataUrl);
                              };

                              reader.readAsDataURL(file); // Read the file as a data URL
                            }
                          }}
                        />
                        <label
                          htmlFor={!value ? 'upload-button-file' : undefined}
                        >
                          <CustomButton
                            text={`${t('attributes.company.upload')} ${t('attributes.company.company')} ${t('attributes.company.logoCapital')}`}
                            startIcon={<UploadFileIcon />}
                            disabled={value ? true : false}
                            component="span"
                          />
                        </label>

                        {value && (
                          <CustomButton
                            text={t('attributes.delete')}
                            startIcon={<DeleteOutlineIcon />}
                            sx={{
                              width: '100px',
                              background: WARNING,
                              '&:hover': {
                                backgroundColor: WARNING_HOVER,
                              },
                            }}
                            onClick={() =>
                              setValue('logo_upload', null, {
                                shouldValidate: true,
                              })
                            }
                          />
                        )}
                      </Box>
                    )}
                  />
                </Box>

                <Box>
                  <Typography variant="body1">
                    {t('common.attach_agreement_documents')}
                  </Typography>
                  {/* <FormFieldWrapper> */}
                  <Box
                    sx={{
                      paddingTop: '16px',
                      rowGap: '16px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <Controller
                      name="agreementDocument"
                      control={control}
                      render={({
                        field: { onChange, name, value },
                        fieldState: { error },
                      }) => (
                        <>
                          <CustomFileUploadButton
                            name={name}
                            onFileChange={(event) => {
                              handleFileChange('agreement')(event, onChange);
                              trigger('agreementDocument');
                            }}
                            multiple={false}
                            buttonText="Click to upload or drag and drop"
                            accept=".pdf"
                            error={!!documentError || error}
                            errorMessage={documentError || error?.message}
                          />
                          {!isEmpty(value) && (
                            <UploadedDocumentView
                              fileName={
                                isUploadingOther
                                  ? uploadedDocuments?.name
                                  : value?.document_name || ''
                              }
                              fileSize={
                                isUploadingOther
                                  ? uploadDocument?.size
                                  : value?.document_size
                              }
                              isUploading={isUploadingOther}
                              onDelete={() => setOpenConfirmationModal(true)}
                            />
                          )}
                        </>
                      )}
                    />
                  </Box>
                  {/* </FormFieldWrapper> */}
                </Box>
              </FormFieldWrapper>
            </CustomCard>
            <CustomCard>
              <Box sx={{ padding: '16px' }}>
                <Typography variant="body1">{`${t('common.business_owner')} ${t('attributes.information')}`}</Typography>
              </Box>
              <Divider />
              <FormFieldWrapper>
                <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
                  <Controller
                    name="contact_name"
                    control={control}
                    rules={{
                      required: `${t('common.business_owner')} ${t('attributes.name')} ${Validation.general.required}`,
                      maxLength: {
                        value: 80,
                        message: Validation.general.contactName,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={`${t('common.business_owner')} ${t('attributes.name')}`}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('contact_name');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                  <Controller
                    name="cell_phone"
                    control={control}
                    rules={{
                      required: `${t('attributes.company.cell_phone')} ${Validation.general.required}`,
                      pattern: {
                        value: /^\d{10}$/,
                        message: Validation.general.cellPhone,
                      },
                    }}
                    render={({
                      field: { onChange, value, name },
                      fieldState: { error },
                    }) => (
                      <CustomTextField
                        label={t('attributes.company.cell_phone')}
                        fullWidth
                        value={value}
                        onChange={(e) => {
                          onChange(e);
                          trigger('cell_phone');
                        }}
                        helperText={error ? error.message : ''}
                        error={error}
                        onClear={() => clearHandler(name)}
                      />
                    )}
                  />
                </Box>
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: `${t('attributes.email')} ${Validation.general.required}`,
                    maxLength: {
                      value: 254,
                      message: Validation.general.email,
                    },
                    pattern: {
                      value: EMAIL_REGEX,
                      message: Validation.general.validEmail,
                    },
                  }}
                  render={({
                    field: { onChange, value, name },
                    fieldState: { error },
                  }) => (
                    <CustomTextField
                      label={t('attributes.email')}
                      fullWidth
                      value={value}
                      onChange={(e) => {
                        onChange(e);
                        trigger('email');
                      }}
                      helperText={error ? error.message : ''}
                      error={error}
                      onClear={() => clearHandler(name)}
                    />
                  )}
                />
              </FormFieldWrapper>
            </CustomCard>
          </>
        )}
        {activeStep === 1 && (
          <SuperAdminForm
            clearErrors={clearErrors}
            control={control}
            trigger={trigger}
            watch={watch}
            setValue={setValue}
            setServerErrors={setServerError}
            isFromCompanyForm={true}
          />
        )}

        {activeStep === 2 && (
          <InviteTheFireCompany title={steps[2]} watch={watch} />
        )}
        {id && (
          <CustomCard>
            <Box sx={{ padding: '0 16px 0 16px' }}>
              <CommonCheckbox
                control={control}
                name="active"
                label={t('common.active')}
                isRequired={false}
                showMessage={true} // Enable message display
                messageOnChecked={labels('fireCompanyActiveCheckbox')}
                messageOnUnchecked={labels('fireCompanyInactiveCheckbox')}
              />
            </Box>
          </CustomCard>
        )}
      </FormWrapper>

      <Box
        sx={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          height: '54px',
          width: '100%',
          display: 'flex',
          justifyContent: 'right',
          alignItems: 'center',
          backgroundColor: SECONDARY,
          padding: '16px',
          gap: 2,
        }}
      >
        {activeStep !== 0 && (
          <CustomButton
            startIcon={<KeyboardArrowLeftIcon />}
            text={t('attributes.previous')}
            color="inherit"
            disabled={activeStep === 0}
            onClick={handleBack}
          />
        )}

        {activeStep === steps.length - 1 || id ? (
          <CustomButton
            text={
              createLoading
                ? t('common.loading')
                : id
                  ? t('attributes.save')
                  : t('common.send_the_invite')
            }
            startIcon={
              id ? <SaveOutlinedIcon /> : <MarkEmailReadOutlinedIcon />
            }
            onClick={handleSubmit(onSubmit)}
            disabled={createLoading}
          />
        ) : (
          <CustomButton
            endIcon={<ChevronRightIcon />}
            text="Next"
            onClick={handleNext}
            disabled={!isNextReady}
          />
        )}
      </Box>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('common.delete_document')}
          description={t('common.delete_document_confirmation')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            handleDeleteDocument('agreement');
            setOpenConfirmationModal(false);
          }}
        />
      )}
    </LayoutContainer>
  );
};

export default CompanyForm;
