import { createSlice } from '@reduxjs/toolkit';

import {
  createFloorPlan,
  deleteFloorPlan,
  getFloorPlanById,
  getFloorPlanList,
  updateFloorPlan,
} from './api';

const initialCreateData = {
  isLoading: false,
  error: false,
  data: [],
};

const initialEditData = {
  isLoading: false,
  error: false,
  data: [],
};

const initialGetData = {
  isLoading: true,
  error: null,
  data: [],
  size: 10,
  total: 0,
  totalCount: 0,
};

const floorPlanSlice = createSlice({
  name: 'floorPlan',
  initialState: {
    get: { ...initialGetData },
    create: {
      ...initialCreateData,
    },
    edit: {
      ...initialEditData,
    },
    delete: {
      ...initialCreateData,
    },
  },
  reducers: {
    resetFloorPlanCreate(state) {
      state.create = { ...initialCreateData };
    },
    resetFloorPlanEdit(state) {
      state.edit = { ...initialEditData };
    },
    resetFloorPlanList(state) {
      state.get = { ...initialGetData };
    },
    resetFloorPlanDelete(state) {
      state.delete = { ...initialCreateData };
    },
  },
  extraReducers: (builder) => {
    builder
      // Floor Plan List
      .addCase(getFloorPlanList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getFloorPlanList.fulfilled, (state, action) => {
        const { data, total, total_records } = action.payload;

        state.get.isLoading = false;
        state.get.data = data;
        state.get.total = total;
        state.get.totalCount = total_records;
      })
      .addCase(getFloorPlanList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Create Floor Plan
      .addCase(createFloorPlan.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(createFloorPlan.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.data = action.payload.data[0];
      })
      .addCase(createFloorPlan.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Update Floor Plan
      .addCase(updateFloorPlan.pending, (state) => {
        state.create.isLoading = true;
      })
      .addCase(updateFloorPlan.fulfilled, (state, action) => {
        state.create.error = false;
        state.create.isLoading = false;
        state.create.data = action.payload.data[0];
      })
      .addCase(updateFloorPlan.rejected, (state, action) => {
        state.create.isLoading = false;
        state.create.error = action.payload;
      })
      // Edit
      .addCase(getFloorPlanById.pending, (state) => {
        state.edit.isLoading = true;
      })
      .addCase(getFloorPlanById.fulfilled, (state, action) => {
        state.edit.isLoading = false;
        state.edit.data = action.payload.data[0];
      })
      .addCase(getFloorPlanById.rejected, (state, action) => {
        state.edit.isLoading = false;
        state.edit.error = action.payload;
      })
      .addCase(deleteFloorPlan.pending, (state) => {
        state.delete.isLoading = true;
      })
      .addCase(deleteFloorPlan.fulfilled, (state, action) => {
        state.delete.isLoading = false;
        state.delete.error = false;
        state.delete.data = action.payload;
      })
      .addCase(deleteFloorPlan.rejected, (state, action) => {
        state.delete.isLoading = false;
        state.delete.error = action.payload;
      });
  },
});

export const {
  resetFloorPlanCreate,
  resetFloorPlanEdit,
  resetFloorPlanList,
  resetFloorPlanDelete,
} = floorPlanSlice.actions;

export default floorPlanSlice.reducer;
