import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import {
  DATE_FORMAT_API,
  DATE_RANGE_FORMAT_API,
  PAID,
} from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import {
  CUSTOMER,
  GET_INVOICE_CHART_BY_AMOUNT,
  GET_INVOICE_CHART_BY_AMOUNT_CHART,
  GET_INVOICE_CHART_BY_STATUS,
  GET_INVOICE_CHART_BY_STATUS_CHART,
} from '../actionPrefix';
import {
  axiosInstanceBilling,
  axiosInstanceReporting,
  axiosInstanceTechnician,
} from '../axios';

const {
  GET_INVOICE_LIST,
  GET_INVOICE_TYPE,
  GET_INVOICE_PROPERTYNAME,
  GET_INVOICE_STATUS,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  CREATE_INVOICE_PRODUCT,
  UPDATE_INVOICE_PRODUCT,
  DELETE_INVOICE_PRODUCT,
} = CUSTOMER;

export const getInvoiceList = createAsyncThunk(
  GET_INVOICE_LIST,
  async (
    {
      page,
      search,
      size,
      type,
      status,
      payment_status,
      order,
      orderBy,
      work_order_uuid,
      invoiceFromDate,
      invoiceToDate,
      limit,
      uuid,
      customer_uuid,
      property_uuid,
      dueDateFrom,
      dueDateTo,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (type) params.append('type', type);
    if (status) {
      if (status === PAID) {
        params.append('payment_status', status);
      } else {
        params.append('status', status);
      }
    }
    if (payment_status) params.append('payment_status', payment_status);
    if (property_uuid) params.append('property_uuid', property_uuid);
    if (work_order_uuid) params.append('work_order_uuid', work_order_uuid);
    if (invoiceFromDate)
      params.append(
        'invoice_creation_date__gte',

        formatDateAPI(moment(invoiceFromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (invoiceToDate)
      params.append(
        'invoice_creation_date__lte',
        formatDateAPI(
          moment(invoiceToDate).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );
    if (search) params.append('search', search);
    if (uuid) params.append('uuid', uuid);
    if (customer_uuid) params.append('customer_uuid', customer_uuid);
    if (dueDateFrom)
      params.append(
        'due_date__gte',
        formatDateAPI(moment(dueDateFrom).format(DATE_RANGE_FORMAT_API))
      );
    if (dueDateTo)
      params.append(
        'due_date__lte',

        formatDateAPI(
          moment(dueDateTo).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );
    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.CUSTOMER.GET_INVOICE_LIST}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Type List API
export const getInvoiceTypeDropdownList = createAsyncThunk(
  GET_INVOICE_TYPE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_TYPE
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Property List API
export const getInvoicePropertyDropdownList = createAsyncThunk(
  GET_INVOICE_PROPERTYNAME,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_PROPERTYNAME
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Invoice Status List API
export const getInvoiceStatusDropdownList = createAsyncThunk(
  GET_INVOICE_STATUS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_INVOICE_STATUS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoice = createAsyncThunk(
  CREATE_INVOICE,
  async ({ work_order_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.WORK_ORDER.CREATE_INVOICE.replace(
          '{work_order_uuid}',
          work_order_uuid
        )
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoice = createAsyncThunk(
  UPDATE_INVOICE,
  async ({ invoice_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.patch(
        APIs.CUSTOMER.UPDATE_INVOICE.replace('{invoice_uuid}', invoice_uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createInvoiceProduct = createAsyncThunk(
  CREATE_INVOICE_PRODUCT,
  async ({ invoice_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.CUSTOMER.CREATE_INVOICE_PRODUCT.replace(
          '{invoice_uuid}',
          invoice_uuid
        ),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateInvoiceProduct = createAsyncThunk(
  UPDATE_INVOICE_PRODUCT,
  async ({ invoice_uuid, invoice_item_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.put(
        APIs.CUSTOMER.UPDATE_INVOICE_PRODUCT.replace(
          '{invoice_uuid}',
          invoice_uuid
        ).replace('{item_uuid}', invoice_item_uuid),
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteInvoiceProduct = createAsyncThunk(
  DELETE_INVOICE_PRODUCT,
  async ({ invoice_uuid, invoice_item_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.delete(
        APIs.CUSTOMER.UPDATE_INVOICE_PRODUCT.replace(
          '{invoice_uuid}',
          invoice_uuid
        ).replace('{item_uuid}', invoice_item_uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvoiceByAmount = createAsyncThunk(
  GET_INVOICE_CHART_BY_AMOUNT,
  async ({ start_date, end_date, propertyUUID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        `${APIs.INVOICE.GET_INVOICE_CHART_BY_AMOUNT}`,
        {
          from_date: moment(start_date).format(DATE_FORMAT_API),
          to_date: moment(end_date).format(DATE_FORMAT_API),
          ...(propertyUUID && { property_uuids: [propertyUUID] }),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvoiceByStatus = createAsyncThunk(
  GET_INVOICE_CHART_BY_STATUS,
  async ({ start_date, end_date, propertyUUID }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        `${APIs.INVOICE.GET_INVOICE_CHART_BY_STATUS}`,
        {
          from_date: moment(start_date).format(DATE_FORMAT_API),
          to_date: moment(end_date).format(DATE_FORMAT_API),
          ...(propertyUUID && { property_uuids: [propertyUUID] }),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvoiceByAmountChart = createAsyncThunk(
  GET_INVOICE_CHART_BY_AMOUNT_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        `${APIs.INVOICE.GET_INVOICE_CHART_BY_AMOUNT_CHART}`,
        {
          from_date: moment(start_date).format(DATE_FORMAT_API),
          to_date: moment(end_date).format(DATE_FORMAT_API),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getInvoiceByStatusChart = createAsyncThunk(
  GET_INVOICE_CHART_BY_STATUS_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        `${APIs.INVOICE.GET_INVOICE_CHART_BY_STATUS_CHART}`,
        {
          from_date: moment(start_date).format(DATE_FORMAT_API),
          to_date: moment(end_date).format(DATE_FORMAT_API),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
