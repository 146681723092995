import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Box, Divider, Stack, styled, Typography } from '@mui/material';

import { DarkLogo } from '../../../assets/images';
import { DEFECT_STATUS, RESOLVED } from '../../../constants/Constants';
import { formatDate } from '../../../utils';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import ImageView from '../../CommonComponents/ImageView';

const InvoiceContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const PreviewPDF = ({
  selectedProperty,
  isDataLoading,
  defects,
  precheckListData,
  setImgSrc,
}) => {
  const { t } = useTranslation();

  const { workOrder } = useSelector((state) => state.workOrder?.details);

  useEffect(
    () => () => {
      setImgSrc(null);
    },
    []
  );

  return isDataLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <InvoiceContainer>
      <Box sx={{ padding: '16px' }}>
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box minWidth="140px">
                <Typography variant="body1">{`${t('attributes.customer.customer_name')}:`}</Typography>
              </Box>
              <Box variant="body2">
                <Typography variant="body2">
                  {selectedProperty?.customer || '-'}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box minWidth="140px" variant="body1">
                <Typography variant="body1">{`${t('attributes.property.propertyName')}:`}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  {selectedProperty?.property_name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'start' }}>
              <Box minWidth="140px">
                <Typography variant="body1">
                  {`${t('attributes.work_order.property_address')}:`}
                </Typography>
              </Box>
              <Box>
                <Box>
                  <Typography variant="body2">
                    {`${selectedProperty?.address}, ${selectedProperty?.state}, ${selectedProperty?.city}, ${selectedProperty?.zip_code}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box minWidth="140px" variant="body1">
                <Typography variant="body1">{`${t('attributes.work_order.work_order_name')}:`}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">
                  {workOrder?.work_order_name}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box minWidth="140px" variant="body1">
                <Typography variant="body1">{`${t('attributes.work_order.work_order_ID')}:`}</Typography>
              </Box>
              <Box>
                <Typography variant="body2">{workOrder?.woid}</Typography>
              </Box>
            </Box>
          </Box>
          <img src={DarkLogo} alt="Fire Logo" height="40px" width="107px" />
        </Stack>
        <Box sx={{ paddingTop: '18px' }}>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th
                  colSpan={4}
                  style={{
                    padding: '16px',
                    border: '1px solid #EBEBEB',
                    textAlign: 'left',
                    backgroundColor: '#45454533',
                  }}
                >
                  <Typography variant="body1">
                    {' '}
                    {t('attributes.work_order.asset_location')}
                  </Typography>
                </th>
                <th
                  style={{
                    padding: '16px',
                    border: '1px solid #EBEBEB',
                    textAlign: 'center',
                    backgroundColor: '#45454533',
                  }}
                >
                  <Typography variant="body1">
                    {t('attributes.work_order.result')}
                  </Typography>
                </th>
              </tr>
            </thead>
            <tbody>
              {defects?.length > 0 &&
                defects.map((item, index) => (
                  <>
                    <tr>
                      <td
                        colSpan={4}
                        style={{
                          padding: '16px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'left',
                          backgroundColor: '#ebebeb20',
                        }}
                      >
                        <Typography variant="body1">
                          {`${index + 1} - ${item?.asset?.label}`}
                        </Typography>
                        <Typography variant="body2">
                          {`${t('attributes.location')}: ${item?.asset?.location || '-'}`}
                        </Typography>
                      </td>
                      <td
                        style={{
                          padding: '16px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                          backgroundColor: '#ebebeb20',
                        }}
                      >
                        <Typography variant="body1">
                          {item?.defect_status === RESOLVED
                            ? t('attributes.work_order.resolved')
                            : t('attributes.work_order.open')}
                        </Typography>
                      </td>
                    </tr>
                    {item?.defect_status === RESOLVED && (
                      <>
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              padding: '16px',
                              border: '1px solid #EBEBEB',
                              textAlign: 'left',
                            }}
                          >
                            <Typography variant="body1">
                              {t('attributes.work_order.defect_description')}
                            </Typography>
                            <Typography variant="body2">
                              {item?.defect?.defect_description || '-'}
                            </Typography>

                            <Box sx={{ paddingTop: '16px' }}>
                              <Typography variant="body1" paddingBottom="8px">
                                {t('attributes.work_order.photos')}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '8px',
                                }}
                              >
                                {item?.defect?.documents?.map(
                                  (doc) =>
                                    doc?.document_type === DEFECT_STATUS && (
                                      <Box
                                        sx={{
                                          height: '70px',
                                          width: '69px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                        key={doc?.document_uuid}
                                      >
                                        <ImageView
                                          uuid={doc?.document_uuid}
                                          containerSx={{
                                            width: '100%',
                                            height: '100%',
                                          }}
                                          imageSx={{ borderRadius: '8px' }}
                                          setImgSrc={setImgSrc}
                                          storeAsObject={true}
                                        />
                                      </Box>
                                    )
                                )}
                              </Box>
                            </Box>
                          </td>
                        </tr>
                        <tr>
                          <td
                            colSpan={5}
                            style={{
                              padding: '16px',
                              border: '1px solid #EBEBEB',
                              textAlign: 'left',
                            }}
                          >
                            <Typography variant="body1">
                              {t('attributes.work_order.resolution_comments')}
                            </Typography>
                            <Typography variant="body2">
                              {item?.defect?.resolution_comment || '-'}
                            </Typography>

                            <Box sx={{ paddingTop: '16px' }}>
                              <Typography variant="body1" paddingBottom="8px">
                                {t('attributes.work_order.resolution_photos')}
                              </Typography>

                              <Box
                                sx={{
                                  display: 'flex',
                                  flexWrap: 'wrap',
                                  gap: '8px',
                                }}
                              >
                                {item?.defect?.documents?.map(
                                  (doc) =>
                                    doc?.document_type === RESOLVED && (
                                      <Box
                                        sx={{
                                          height: '70px',
                                          width: '69px',
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignItems: 'center',
                                        }}
                                        key={doc?.document_uuid}
                                      >
                                        <ImageView
                                          uuid={doc?.document_uuid}
                                          containerSx={{
                                            width: '100%',
                                            height: '100%',
                                          }}
                                          imageSx={{ borderRadius: '8px' }}
                                          setImgSrc={setImgSrc}
                                          storeAsObject={true}
                                        />
                                      </Box>
                                    )
                                )}
                              </Box>
                            </Box>
                          </td>
                        </tr>
                      </>
                    )}
                  </>
                ))}
            </tbody>
          </table>
        </Box>
      </Box>
      <Divider />
      <Box sx={{ padding: '16px' }}>
        <Stack
          direction="row"
          sx={{ justifyContent: 'space-between', alignItems: 'center' }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '20px',
            }}
          >
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body1">
                {t('attributes.technician.technician_name')}:
              </Typography>
              <Typography variant="body1">
                {t('attributes.work_order.performed_date')}:
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'left' }}>
              <Typography variant="body2">
                {defects && defects[0]?.defect?.technician?.technician_name}
              </Typography>
              <Typography variant="body2">
                {formatDate(precheckListData?.signature_time)}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="body1">
              {t('attributes.work_order.technician_signature')}
            </Typography>
            <Box
              sx={{
                height: '45px',
                width: '85px',
              }}
            >
              {precheckListData?.technician_signature_document_uuid && (
                <ImageView
                  uuid={precheckListData?.technician_signature_document_uuid}
                  containerSx={{
                    width: '100%',
                    height: '100%',
                  }}
                  imageSx={{ borderRadius: '8px' }}
                  setImgSrc={setImgSrc}
                  storeAsObject={true}
                />
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
    </InvoiceContainer>
  );
};

export default PreviewPDF;
