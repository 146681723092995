import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Card, Divider, Modal, Typography } from '@mui/material';

import { HoverText, ImageWrapper } from '../../../assets/commonStyled';
import { getAssetDetails } from '../../../store/workOrder/api';
import { formatStatus, formattedDate } from '../../../utils';
import CommonCarouselView from '../../CommonComponents/CommonCarouselView';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import ImageView, { imageModalView } from '../../CommonComponents/ImageView';

const KeyValueComponent = ({ name, value }) => (
  <Box>
    <Typography variant="body1">{name}</Typography>
    <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
      {value}
    </Typography>
  </Box>
);

const AssetView = ({ asset_id, work_order_id }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [asset, setAsset] = useState(null);

  const [imgSrc, setImgSrc] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (asset_id && work_order_id) {
      setIsLoading(true);
      dispatch(
        getAssetDetails({ work_order_id: work_order_id, asset_uuid: asset_id })
      )
        .then((res) => setAsset(res?.payload?.data?.[0]))
        .finally(() => setIsLoading(false));
    }
  }, [work_order_id, asset_id]);

  const openImage = () => setOpen(true);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <Box sx={{ display: 'flex', flexDirection: 'column', rowGap: '16px' }}>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">{`${t('attributes.property.asset')} ${t('attributes.supplier.details')}`}</Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            padding: '12px',
            display: 'flex',
            width: '100%',
            columnGap: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
              minWidth: '50%',
            }}
          >
            <KeyValueComponent
              name={t('attributes.productCatalogue.equipment_type')}
              value={asset?.asset?.equipment_type?.display_name || '-'}
            />
            <KeyValueComponent
              name={`${t('attributes.property.asset')} ${t('attributes.name')}`}
              value={asset?.asset?.label || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.status')}
              value={formatStatus(asset?.asset_status) || '-'}
            />
            <KeyValueComponent
              name={`${t('attributes.property.identification')} ${t('attributes.number')}`}
              value={asset?.asset?.identification_number || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.qr_code')}
              value={asset?.asset?.barcode || '-'}
            />
            <KeyValueComponent
              name={t('attributes.model')}
              value={asset?.asset?.model || '-'}
            />
            <KeyValueComponent
              name={t('attributes.quantity')}
              value={asset?.asset?.quantity || '-'}
            />
            <KeyValueComponent
              name={`${t('attributes.installation')} ${t('attributes.date')}`}
              value={formattedDate(asset?.asset?.installation_date) || '-'}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              rowGap: '16px',
              minWidth: '30%',
            }}
          >
            <KeyValueComponent
              name={t('attributes.property.equipment_class')}
              value={asset?.asset?.product?.display_name || '-'}
            />
            <KeyValueComponent
              name={t('attributes.location')}
              value={asset?.asset?.location || '-'}
            />
            <KeyValueComponent
              name={t('common.active')}
              value={formatStatus(asset?.asset?.is_active) || '-'}
            />
            <KeyValueComponent
              name={`${t('attributes.serial')} ${t('attributes.number')}`}
              value={asset?.asset?.serial_number || '-'}
            />
            <KeyValueComponent
              name={t('attributes.make')}
              value={asset?.asset?.make || '-'}
            />
            <KeyValueComponent
              name={t('attributes.size')}
              value={asset?.asset?.size || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.manufacture_date')}
              value={formattedDate(asset?.asset?.base_date) || '-'}
            />
            <KeyValueComponent
              name={t('attributes.property.internalNote')}
              value={asset?.asset?.internal_note || '-'}
            />
          </Box>
        </Box>
      </Card>
      <Card sx={{ boxShadow: 'none !important' }}>
        <Box sx={{ padding: '12px' }}>
          <Typography variant="body1">
            {t('attributes.work_order.photos')}
          </Typography>
        </Box>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            columnGap: '16px',
            padding: '16px',
            flexWrap: 'wrap',
          }}
        >
          {asset?.asset?.documents?.length ? (
            <CommonCarouselView
              totalItems={asset?.asset?.documents?.length}
              centerMode={true}
            >
              {asset?.asset?.documents?.map((doc, index) => (
                <ImageWrapper>
                  <ImageView
                    uuid={doc?.document_uuid}
                    onClick={() => setOpen(true)}
                    setImgSrc={setImgSrc}
                  />
                  <HoverText className="hoverText" onClick={openImage}>
                    <Typography variant="body1">
                      {t('attributes.view')}
                    </Typography>
                  </HoverText>
                </ImageWrapper>
              ))}
            </CommonCarouselView>
          ) : (
            <>No Document Uploaded</>
          )}
        </Box>
      </Card>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: '#000000D9',
          }}
        >
          <>
            <CloseIcon
              style={{
                fill: 'white',
                height: '45px',
                width: '45px',
                position: 'absolute',
                top: '10px',
                right: '10px',
              }}
              onClick={() => setOpen(false)}
            />
            <CommonCarouselView
              widthInPercentage={100}
              totalItems={asset?.asset?.documents?.length}
              showThumbs={true}
              arrowSize="16px"
              arrowWrapperSize="40px"
              arrowLeft="40px"
              arrowRight="40px"
              centerMode={true}
              isModal={true}
            >
              {imgSrc?.map((img) => imageModalView(img))}
            </CommonCarouselView>
          </>
        </Modal>
      )}
    </Box>
  );
};

export default AssetView;
