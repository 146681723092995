import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { BILLING_CARD } from '../actionPrefix';
import { axiosInstanceBilling } from '../axios';

export const getBillingList = createAsyncThunk(
  BILLING_CARD.GET_BILLING_LIST,
  async (
    {
      page,
      search,
      size,
      limit,
      order,
      orderBy,
      billing_uuid,
      fromDate,
      toDate,
      is_accounting_partner,
      customer_uuid,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (billing_uuid) params.append('uuid', billing_uuid);
    if (customer_uuid) params.append('customer_uuid', customer_uuid);

    if (is_accounting_partner)
      params.append('is_accounting_partner', is_accounting_partner);

    if (fromDate)
      params.append(
        'created_at__gte',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'created_at__lte',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.BILLING_CARD.BILLING_CARDS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getBill = createAsyncThunk(
  BILLING_CARD.GET_BILL,
  async ({ uuid }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (uuid) params.append('uuid', uuid);
    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.BILLING_CARD.BILLING_CARDS}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createBillingCard = createAsyncThunk(
  BILLING_CARD.CREATE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        `${APIs.BILLING_CARD.BILLING_CARDS}`,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const updateBillingCard = createAsyncThunk(
  BILLING_CARD.CREATE,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.put(
        APIs.BILLING_CARD.UPDATE_BILLING_CARD.replace(':billing_uuid', uuid),
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const deleteBillingCard = createAsyncThunk(
  BILLING_CARD.DELETE,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.delete(
        APIs.BILLING_CARD.UPDATE_BILLING_CARD.replace(':billing_uuid', uuid)
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);
