import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Chip, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { getRolesList } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import {
  getEmailTemplateDetail,
  getTemplateDropDownData,
} from '../../store/template/api';
import {
  capitalizeFirstLetterRemoveUnderscore,
  clearTextfields,
} from '../../utils';
import Autocomplete from '../CommonComponents/AutoComplete';
import Ckeditor from '../CommonComponents/Ckeditor';
import CommonCheckbox from '../CommonComponents/CustomCheckbox';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const EmailTemplateForm = ({
  id,
  control,
  trigger,
  setValue,
  clearErrors,
  watch,
  reset,
  isLoadingEditForm,
  setIsLoadingEditForm,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [templateData, setTemplateData] = useState([]);

  const [emailTypeOptions, setEmailTypeOptions] = useState([]);
  const [variables, setVariables] = useState([]);

  const subjectRef = useRef(null);
  const bodyRef = useRef(null);
  const [activeTextBox, setActiveTextBox] = useState(null);
  const [editorInstance, setEditorInstance] = useState(null);

  const rolesList = getRolesList();

  const selectedEmailTemplate = watch('email_type');

  useEffect(() => {
    setIsLoadingEditForm(true);
    dispatch(getTemplateDropDownData())
      .then((res) => {
        const data = res?.payload?.data;
        const options = data?.map((d) => ({
          label: capitalizeFirstLetterRemoveUnderscore(d?.email_type),
          value: d?.email_type,
        }));

        setTemplateData(data);
        setEmailTypeOptions(options);
      })
      .finally(() => setIsLoadingEditForm(false));
  }, []);

  useEffect(() => {
    if (selectedEmailTemplate) {
      const variables = templateData?.find(
        (td) => td?.email_type == selectedEmailTemplate?.value
      );

      setVariables(variables?.variables);
      setValue('variables', variables?.variables);
    }
  }, [selectedEmailTemplate]);

  useEffect(() => {
    if (id) {
      dispatch(getEmailTemplateDetail({ id: id }))
        .then((res) => {
          const detail = res?.payload?.data?.[0];

          reset({
            ...detail,
            // If there are master template than please take its first
            role: rolesList?.find((r) =>
              detail?.master_template
                ? r.value === detail?.master_template?.role
                : r.value === detail?.role
            ),
            email_type: emailTypeOptions?.find((r) =>
              detail?.master_template
                ? r.value === detail?.master_template?.email_type
                : r?.value === detail?.email_type
            ),
            template_name: detail?.master_template
              ? detail?.master_template?.template_name
              : detail?.template_name,
            subject: detail?.subject,
            reply_to: detail?.reply_to,
            editor_data: detail?.template_content,
          });
        })
        .finally(() => setIsLoadingEditForm(false));
    }
  }, [id, emailTypeOptions]);

  const handleInputFocus = (index) => {
    setActiveTextBox(index);
  };

  const handleInsertLabel = (label) => {
    if (activeTextBox === null) return; // No active input

    if (activeTextBox === 0) {
      const input = subjectRef?.current?.querySelector(
        '.MuiInputBase-input.MuiOutlinedInput-input'
      );

      const start = input.selectionStart;
      const end = input.selectionEnd;
      const newValue =
        input?.value.slice(0, start) + label + input?.value.slice(end);

      setValue('subject', newValue);
      setTimeout(() => {
        input.focus();
        input.setSelectionRange(start + label.length, start + label.length);
      }, 0);
    } else if (activeTextBox === 1) {
      if (editorInstance) {
        const editor = editorInstance;

        editor.model.change((writer) => {
          const selection = editor.model.document.selection;
          const range = selection.getFirstRange();

          if (!range) {
            console.error('No range selected');

            return;
          }

          if (!selection.isCollapsed) {
            // Replace selected text with label
            writer.remove(range);
            writer.insertText(label, range.start);
          } else {
            // Insert label at caret position
            writer.insertText(label, range.start);
          }
        });
      }
    }
  };

  return isLoadingEditForm ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.templates.email_type')}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="role"
              control={control}
              rules={{
                required: `${t('attributes.role')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.role')}
                  options={rolesList}
                  value={value}
                  isRequired={true}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  disabledDropdown={true}
                />
              )}
            />
            <Controller
              name={'email_type'}
              control={control}
              rules={{
                required: `${t('attributes.templates.email_type')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value, name },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('attributes.property.type')}
                  options={emailTypeOptions}
                  value={value}
                  isRequired={true}
                  isLoadingData={isLoadingEditForm}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                  disabledDropdown={true}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">
            {t('attributes.templates.email_detail')}
          </Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Controller
            name="template_name"
            control={control}
            rules={{
              required: `${t('attributes.templates.template_name')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.templates.template_name')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('template_name');
                  clearErrors('template_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
                disabled={true}
              />
            )}
          />
          <Controller
            name="subject"
            control={control}
            rules={{
              required: `${t('common.subject')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('common.subject')}
                fullWidth
                value={value}
                ref={subjectRef}
                onChange={(e) => {
                  onChange(e);
                  trigger('subject');
                  clearErrors('subject');
                }}
                onFocus={() => handleInputFocus(0)}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={true}
              />
            )}
          />
          <Controller
            name="reply_to"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('common.reply_to')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('reply_to');
                  clearErrors('reply_to');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
          <Controller
            name="editor_data"
            control={control}
            render={({ field: { value, onChange, name } }) => (
              <Ckeditor
                onFocus={() => handleInputFocus(1)}
                ref={bodyRef}
                name={name}
                onReady={(editor) => {
                  setEditorInstance(editor); // Store editor instance
                }}
                data={value}
                setData={(d) => {
                  onChange(d);
                }}
              />
            )}
          />
          <Controller
            name="variables"
            control={control}
            render={({ field: { value, name } }) => (
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  columnGap: '16px',
                  border: `1px solid ${DIVIDER_COLOR}`,
                  padding: '10px 14px',
                }}
              >
                <Typography variant="body1">{`${t('common.variables')}:`}</Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '8px',
                    flexWrap: 'wrap',
                    rowGap: '8px',
                  }}
                >
                  {variables?.map((v) => (
                    <Chip
                      sx={{
                        cursor: 'pointer',
                      }}
                      onClick={() => handleInsertLabel(`{${v}}`)}
                      label={
                        <Typography variant="body2">{`{${v}}`}</Typography>
                      }
                    ></Chip>
                  ))}
                </Box>
              </Box>
            )}
          />
          <Controller
            name="is_active"
            control={control}
            render={({ field: { name } }) => (
              <Box sx={{ padding: '0 8px' }}>
                <CommonCheckbox
                  control={control}
                  name={name}
                  label={t('common.active')}
                  isRequired={false}
                />
              </Box>
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default EmailTemplateForm;
