import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import { Box } from '@mui/material';

import {
  DATA_AMANAGEMENT_ICON_COLOR,
  DIVIDER_COLOR,
} from '../../../constants/Colors';
import {
  ROUTE_NAVIGATE_CONST,
  redirectURL,
} from '../../../constants/Constants';
import { ROUTENAME } from '../../../constants/RoutesConstants';
import { getUserRole } from '../../../hoc/Permission';
import CommonTemplates from '../../CommonComponents/CommonTemplates';
import MainWrapper from '../../CommonComponents/MainWrapper';

export const GlobalAdminTemplates = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const temaplatesData = [
    {
      icon: (
        <ReceiptIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.customer.invoices'),
      description: t('attributes.templates.invoiceDesc'),
      redirectTo: ROUTENAME.GLOBAL_ADMIN_INVOICE_TEMPLATES,
    },
    {
      icon: (
        <ReceiptLongOutlinedIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.customer.quotes'),
      description: t('attributes.templates.quotesDesc'),
      redirectTo: ROUTENAME.GLOBAL_ADMIN_QUOTES_TEMPLATES,
    },
    {
      icon: (
        <InventoryOutlinedIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.work_order.purchase_order'),
      description: t('attributes.templates.purchaseOrderDesc'),
      redirectTo: ROUTENAME.GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES,
    },
    {
      icon: (
        <EmailOutlinedIcon
          sx={{
            height: '20px',
            width: '20px',
            fill: DATA_AMANAGEMENT_ICON_COLOR,
          }}
        />
      ),
      title: t('attributes.templates.email_templates'),
      description: t('attributes.templates.emailTemplateDesc'),
      redirectTo: ROUTENAME.GLOBAL_ADMIN_EMAIL_TEMPLATES,
    },
  ];

  const handleDefaultStep = () => {
    navigate(redirectURL[getUserRole()]?.[ROUTE_NAVIGATE_CONST.CONTROL_PANEL]);
  };

  return (
    <Box sx={{ backgroundColor: DIVIDER_COLOR, width: '100%', height: '100%' }}>
      <MainWrapper
        defaultPadding="0px 0px 16px 0px"
        title={t('attributes.controlPanel.title')}
        variant="body1"
        isStep={true}
        step={t('attributes.controlPanel.templates')}
        handleDefaultStep={handleDefaultStep}
      ></MainWrapper>
      <CommonTemplates data={temaplatesData} />
    </Box>
  );
};
