import React from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import { SECONDARY } from '../../../../constants/Colors';
import { Validation } from '../../../../constants/FieldValidationMsg';
import { EMAIL_REGEX } from '../../../../constants/Regex';
import { clearTextfields } from '../../../../utils';
import CommonCheckbox from '../../../CommonComponents/CustomCheckbox';
import CustomTextField from '../../../CommonComponents/CustomTextField';

const Billing = ({ title, control, getValues, trigger, setValue, Section }) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        background: SECONDARY,
        borderRadius: '4px',
      }}
    >
      <Box>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="h2">{title}</Typography>
        </Box>
        <Divider />
        <Section>
          <Box
            sx={{
              background: SECONDARY,
              borderRadius: '4px',
              width: '468px',
            }}
          >
            <CommonCheckbox
              control={control}
              name="sameAsPrimary"
              label={t('attributes.billingCard.same_as_primary_contact')}
              isRequired={false}
              messageOnChecked=""
              messageOnUnchecked=""
              onCheckboxChange={(val) => {
                if (val) {
                  const primaryContactName = getValues('contact_name');
                  const primaryContactEmail = getValues('contact_email');

                  setValue('billing_contact_name', primaryContactName);
                  setValue('billingEmailTo', primaryContactEmail);
                } else {
                  setValue('billing_contact_name', '');
                  setValue('billingEmailTo', '');
                }
              }}
            />
          </Box>
          <Controller
            name="billing_contact_name"
            control={control}
            rules={{
              maxLength: {
                value: 80,
                message: Validation.general.billingContactName,
              },
            }}
            render={({
              field: { onChange, value, name, onBlur },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Billing Contact Name"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('billing_contact_name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
                disabled={getValues('sameAsPrimary')}
                onBlur={() => {
                  onChange(value.trim());
                  onBlur();
                }}
              />
            )}
          />
          <Controller
            name="billingEmailTo"
            control={control}
            rules={{
              maxLength: {
                value: 254,
                message: Validation.general.email,
              },
              pattern: {
                value: EMAIL_REGEX,
                message: Validation.general.validEmail,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label="Email To"
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e.target.value);
                  trigger('billingEmailTo');
                }}
                disabled={getValues('sameAsPrimary')}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearTextfields(setValue, name)}
                isRequired={false}
              />
            )}
          />
        </Section>
      </Box>
    </Box>
  );
};

export default Billing;
