import { useNavigate } from 'react-router-dom';

import EastIcon from '@mui/icons-material/East';
import { Box, Card, Typography, styled } from '@mui/material';

const TemplateCard = styled(Card)({
  display: 'flex',
  flexDirection: 'column',
  rowGap: '8px',
  padding: '16px',
  width: '366px',
  cursor: 'pointer',
});

const TemplateWrapper = styled(Box)({
  display: 'flex',
  columnGap: '24px',
  flexWrap: 'wrap',
  rowGap: '24px',
});

const CommonTemplates = ({ data = [] }) => {
  const navigate = useNavigate();
  return (
    <TemplateWrapper>
      {data?.map((d, index) => (
        <TemplateCard key={index} onClick={() => navigate(d?.redirectTo)}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            {d?.icon}
            <EastIcon
              style={{
                height: '24px',
                width: '24px',
              }}
            />
          </Box>
          <Typography variant="body1">{d?.title}</Typography>
          <Typography variant="body2">{d?.description}</Typography>
        </TemplateCard>
      ))}
    </TemplateWrapper>
  );
};

export default CommonTemplates;
