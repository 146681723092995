import { useCallback, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box, Typography } from '@mui/material';
import { debounce, isArray } from 'lodash';

import {
  COMPLIANCE_STATUS,
  CUSTOMER_COMPLIANCE_STATUS,
  LABELS,
  NON_COMPLIANCE_STATUS,
  redirectURL,
  ROUTE_NAVIGATE_CONST,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../constants/Roles';
import { SWIPEABLE_DRAWER_STEPPER_WIDTH } from '../../constants/Typography';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import getStatesHook from '../../hooks/getStatesHook';
// eslint-disable-next-line
import { exportToCSV } from '../../constants/common';
import useServerSideErrors from '../../hooks/useServerSideErrors';
import { getBillingList } from '../../store/billing/api';
import { resetBillingData } from '../../store/billing/reducer';
// eslint-disable-next-line
import { PRIMARY, WARNING } from '../../constants/Colors';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  getBuildingClassList,
  getBuildingEraList,
  getBuildingTypeList,
  getConstructionClassList,
  getLocalGovernmentAreaList,
  getParentPropertyList,
  getProperty,
  getPropertyAccessProcedureList,
  getPropertyAccessScheduleList,
  getPropertyCustomerList,
  getPropertyList,
  getPropertyStatusList,
} from '../../store/property/api';
import {
  resetCreatePropertyList,
  resetUpdatePropertyList,
} from '../../store/property/reducer';
import { getTechnicianNamesList } from '../../store/technician/api';
import { getBillingContractById } from '../../store/users/billingContract/api';
import { getZonesDropdownList } from '../../store/zones/api';
import { formattedDate, loggedInUserRole } from '../../utils';
import { Edit, View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../CommonComponents/Modal';
import NewNoDataPage from '../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import PropertyDetails from './PropertyDetails';
import { PropertyForm } from './PropertyForm';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 330 : 300);
const defaultFilters = {
  city: null,
  state: null,
};

// Property Component
const Properties = () => {
  const dispatch = useDispatch();
  const { uuid } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');

  const {
    propertyList,
    createPropertyList,
    updatePropertyList,
    propertyStatusList,
    propertyTagList,
    propertyAccessScheduleList,
    propertyAccessProcedureList,
    propertyBuildingEraList,
    propertyBuildingClassList,
    propertyConstructionClassList,
    propertyBuildingTypeList,
    propertyLocalGovernmentAreaList,
    parentPropertyList,
    propertyCustomerList,
  } = useSelector((state) => state.property);

  // Selector for billing dropdown
  const { billingDropdownLoading, billingDropdownData } = getDropdownListHook({
    reducerName: 'billing',
    dropdownListName: 'getList',
    fetchDataFrom: 'billingList',
    labelName: 'billing_name',
    valueName: 'uuid',
  });

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [selectStatus, setSelectStatus] = useState(null);
  const [complianceStatus, setComplianceStatus] = useState(null);
  const [customerList, setCustomerList] = useState([]);
  const [selectCustomer, setSelectCustomer] = useState(null);
  const [selectBillingCard, setSelectBillingCard] = useState(null);
  const [formMode, setFormMode] = useState('create');
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [open, setOpen] = useState(false);
  const [propertiesData, setPropertiesData] = useState({});
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [serverErrors, setServerErrors] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [formStatusList, setFormStatusList] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [accessScheduleList, setAccessScheduleList] = useState([]);
  const [accessProcedureList, setAccessProcedureList] = useState([]);
  const [buildingEraList, setBuildingEraList] = useState([]);
  const [buildingClassList, setBuildingClassList] = useState([]);
  const [constructionClassList, setConstructionClassList] = useState([]);
  const [selectConstructionClass, setSelectConstructionClass] = useState(null);
  const [buildingTypeList, setBuildingTypeList] = useState([]);
  const [localGovermentAreaList, setLocalGovernmentAreaList] = useState([]);
  const [selectParentProperty, setSelectParentProperty] = useState(null);
  const methods = useForm();
  const { clearErrors, setError, watch, setValue } = methods;
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [propertyDataloading, setPropertyDataLoading] = useState(
    uuid ? true : false
  );
  const [activeStep, setActiveStep] = useState(0);
  const [isFromDetails, setIsFromDetails] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [propertyNumId, setPropertyNumId] = useState();
  const [selectedRows, setSelectedRows] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [filters, setFilters] = useState(defaultFilters);

  const { zonesDropdownLoading, zonesDropdownData } = getDropdownListHook({
    reducerName: 'zones',
    dropdownListName: 'zonesDropdownList',
    labelName: 'zone',
    valueName: 'zone',
  });

  // Use the custom hook
  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();

  const watchedFields = watch();

  const role = loggedInUserRole();

  useEffect(() => {
    sethasFormValues(
      Object.values(watchedFields).some((value) => {
        if (isArray(value)) {
          return value.length > 0;
        } else {
          return value;
        }
      })
    );
  }, [watchedFields]);

  // From dashboard chart
  useEffect(() => {
    if (queryStatus && statusList?.length) {
      const selected = statusList?.find((d) => d?.name === queryStatus);
      const selectedCompliance = CUSTOMER_COMPLIANCE_STATUS?.find(
        (d) => d?.value === queryStatus
      );

      if (selected) {
        setSelectStatus(selected);
      }
      if (selectedCompliance) {
        setComplianceStatus(selectedCompliance);
      }

      setSelectStatus(selected);
      setIsFilterComponentVisible(true);
    }
    if (queryStatus && stateList?.length) {
      const slectedState = stateList?.find(
        (v) => v?.value?.toLowerCase() === queryStatus
      );
      if (slectedState) {
        setIsFilterComponentVisible(true);
        setFilters((prev) => ({
          ...prev,
          state: slectedState,
        }));
      }
    }
  }, [queryStatus, statusList, stateList]);

  useEffect(() => {
    dispatch(getZonesDropdownList());
  }, []);

  const fetchPropertyDetails = async (id) => {
    setPropertyDataLoading(true);
    try {
      const response = await dispatch(getProperty(id));
      const { payload } = response;
      const { contract_assignment_details } = payload?.data?.[0] || {};
      const activeBillingContractUUID =
        contract_assignment_details?.active_billing_contract_uuid;
      const billingContractResponse = await dispatch(
        getBillingContractById({
          billing_contract_uuid: activeBillingContractUUID,
        })
      );
      const {
        name: contractName,
        contract_start_date: contractStartDate,
        contract_end_date: contractEndDate,
      } = billingContractResponse?.payload?.data?.[0] || {};

      setPropertyNumId(payload?.data?.[0]?.id || '-');
      if (payload.data[0]) {
        setSelectedProperty({
          ...payload.data[0],
          contract_assignment_details: {
            ...contract_assignment_details,
            contract_name: contractName,
            contract_start_date: contractStartDate,
            contract_end_date: contractEndDate,
          },
        });
      } else {
        setSelectedProperty(null);
      }
    } catch (error) {
      console.error('Error fetching property data:', error);
    } finally {
      setPropertyDataLoading(false);
    }
  };

  useEffect(() => {
    if (uuid) {
      fetchPropertyDetails(uuid);
    }
  }, [uuid]);

  const columns = useMemo(() => {
    const baseColumn = [
      {
        headerName: t('attributes.property.propertyId'),
        field: 'pid',
        sortable: role === GLOBAL_ADMIN,
        flex: 1,
      },
      {
        headerName: `${t('attributes.property.property')} ${t('attributes.name')}`,
        field: 'property_name',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography
            variant="body1"
            sx={{ textDecoration: 'underline', cursor: 'pointer' }}
            onClick={() => {
              setSelectedProperty(null);
              setPropertyDataLoading(true);
              setPropertyNumId(row.id);
              navigate(row.uuid);
            }}
          >
            {row.property_name}
          </Typography>
        ),
      },
      {
        headerName: t('attributes.address'),
        field: 'address',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Typography variant="body2">
            {row?.address},{row?.city},{row?.state}, {row?.zip_code}
          </Typography>
        ),
      },
      {
        headerName: t('attributes.customer.customer'),
        field: 'customer_uuid',
        sortable: false,
        flex: 1,
      },
      {
        headerName: t('attributes.property.compliance_status'),
        field: 'latest_compliance_status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.latest_compliance_status),
      },
      {
        headerName: t('attributes.property.status'),
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.property_status.display_name),
      },
      {
        headerName: t('attributes.customer.created_at'),
        field: 'created_at',
        sortable: role === SUPER_ADMIN,
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              onClick={() => {
                setSelectedProperty(null);
                setPropertyDataLoading(true);
                setPropertyNumId(row.id);
                navigate(row.uuid);
              }}
            />
            {role !== GLOBAL_ADMIN && (
              <Edit
                onClick={() => {
                  setSelectedProperty(null);
                  setOpen(true);
                  setFormMode('edit');
                  setIsFromDetails(false);
                  dispatch(getProperty(row.uuid)).then((response) => {
                    setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
                  });
                }}
              />
            )}
          </Box>
        ),
      },
    ];

    return baseColumn;
  }, []);

  const getAllProperties = useCallback(
    (text = '') => {
      setSelectedRows(null);
      const visibleFieldsString = columns
        .filter((col) => columnVisibilityModel[col.field] !== false)
        .map((col) => col.field)
        .join(',');

      setIsDataLoading(true);
      const req = {
        page: currentPage,
        size: perPageData,
        status: selectStatus?.value,
        latest_compliance_status: complianceStatus?.value,
        constructionClass: selectConstructionClass?.value,
        customerUuid: selectCustomer?.value,
        parentProperty: selectParentProperty?.value,
        billingCard: selectBillingCard?.value,
        search: searchText,
        fromDate: fromDate,
        toDate: toDate,
        order: order,
        orderBy: orderBy,
        list_column_names: visibleFieldsString,
        state: filters.state?.value,
        city: filters.city?.value,
      };

      dispatch(getPropertyList(req)).finally(() => {
        setIsDataLoading(false);
        setIsInitialLoading(false);
      });
    },
    [
      currentPage,
      perPageData,
      selectStatus,
      complianceStatus,
      selectCustomer,
      selectConstructionClass,
      createPropertyList.data,
      updatePropertyList.data,
      selectParentProperty,
      selectBillingCard,
      order,
      orderBy,
      fromDate,
      toDate,
      searchText,
      columnVisibilityModel,
      refresh,
      filters,
    ]
  );
  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllProperties, 500), [
    getAllProperties,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    selectStatus,
    selectCustomer,
    searchText,
    selectConstructionClass,
    fromDate,
    toDate,
    filters,
  ]);

  const handleStatus = (status, color) => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          bgcolor: color,
          height: '16px',
          width: '16px',
          borderRadius: '50%',
        }}
      ></Box>
      <Typography
        sx={{
          color: color,
          fontSize: '12px',
          weight: '600',
          marginLeft: '8px',
        }}
      >
        {status}
      </Typography>
    </Box>
  );

  const formatStatus = (status) => {
    if (status?.toLowerCase() == 'inactive') {
      return handleStatus(status, '#C54036');
    }
    if (status?.toLowerCase() == 'active') {
      return handleStatus(status, '#95C020');
    }
    if (status?.toLowerCase() == 'hold') {
      return handleStatus(status, '#FD7E14');
    }
    if (status?.toLowerCase() == 'setup') {
      return handleStatus(status, '#007BFF');
    }
    if (status?.toLowerCase() == COMPLIANCE_STATUS) {
      return handleStatus(LABELS.COMPLIANCE_STATUS, PRIMARY);
    }
    if (status?.toLowerCase() == NON_COMPLIANCE_STATUS) {
      return handleStatus(LABELS.NON_COMPLIANCE_STATUS, WARNING);
    }
  };

  useEffect(
    () => () => {
      dispatch(resetUpdatePropertyList());
      dispatch(resetCreatePropertyList());
    },
    []
  );

  // Status Filter Dropdown list data

  useEffect(() => {
    dispatch(getPropertyStatusList());
    dispatch(getTechnicianNamesList());
  }, [dispatch]);

  // Set data for filter dropdown list
  useEffect(() => {
    if (propertyStatusList?.data) {
      const statusList = propertyStatusList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
        name: item?.name,
      }));

      setStatusList(statusList);
    }
  }, [propertyStatusList, dispatch]);

  // Set data for form dropdown list
  useEffect(() => {
    if (propertyStatusList?.data) {
      const statusList = propertyStatusList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setFormStatusList(statusList);
    }
  }, [propertyStatusList, dispatch]);

  // Property access schedule dropdown data
  useEffect(() => {
    dispatch(getPropertyAccessScheduleList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccessScheduleList?.data) {
      const accessSchedule = propertyAccessScheduleList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccessScheduleList(accessSchedule);
    }
  }, [propertyAccessScheduleList, dispatch]);

  // Property access procedure dropdown data
  useEffect(() => {
    dispatch(getPropertyAccessProcedureList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyAccessProcedureList?.data) {
      const accessProcedure = propertyAccessProcedureList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setAccessProcedureList(accessProcedure);
    }
  }, [propertyAccessProcedureList, dispatch]);

  // Property Building era dropdown data
  useEffect(() => {
    dispatch(getBuildingEraList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingEraList?.data) {
      const buildinEra = propertyBuildingEraList?.data?.data?.map((item) => ({
        label: item.display_name,
        value: item.id,
      }));

      setBuildingEraList(buildinEra);
    }
  }, [propertyBuildingEraList, dispatch]);

  // Property Building era dropdown data
  useEffect(() => {
    dispatch(getBuildingClassList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingClassList?.data) {
      const buildinClass = propertyBuildingClassList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setBuildingClassList(buildinClass);
    }
  }, [propertyBuildingClassList, dispatch]);

  // Property construction list dropdown data
  useEffect(() => {
    dispatch(getConstructionClassList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyConstructionClassList?.data) {
      const constructionClass = propertyConstructionClassList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setConstructionClassList(constructionClass);
    }
  }, [propertyConstructionClassList, dispatch]);

  // Property building list dropdown data
  useEffect(() => {
    dispatch(getBuildingTypeList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyBuildingTypeList?.data) {
      const buildingTypeList = propertyBuildingTypeList?.data?.data?.map(
        (item) => ({
          label: item.display_name,
          value: item.id,
        })
      );

      setBuildingTypeList(buildingTypeList);
    }
  }, [propertyBuildingTypeList, dispatch]);

  // Property local government area dropdown data
  useEffect(() => {
    dispatch(getLocalGovernmentAreaList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyLocalGovernmentAreaList?.data) {
      const localGArea = propertyLocalGovernmentAreaList?.data?.data?.map(
        (item) => ({
          label: item.local_government_area,
          value: item.local_government_area,
        })
      );

      setLocalGovernmentAreaList(localGArea);
    }
  }, [propertyLocalGovernmentAreaList, dispatch]);

  // Parent property dropdown data
  useEffect(() => {
    dispatch(getParentPropertyList({ limit: -1 }));
  }, [dispatch, open]);

  const { parentPropertyDropdownLoading, parentPropertyDropdownData } =
    getDropdownListHook({
      reducerName: 'property',
      dropdownListName: 'parentPropertyList',
      customNameForDropdown: 'parentProperty',
      labelName: 'property_name',
      valueName: 'uuid',
    });

  // Property Customer dropdown data
  useEffect(() => {
    dispatch(getPropertyCustomerList());
  }, [dispatch]);

  useEffect(() => {
    if (propertyCustomerList?.data) {
      const customersList = propertyCustomerList?.data?.data?.map((item) => ({
        label: item.customer_name,
        value: item.uuid,
      }));

      setCustomerList(customersList);
    }
  }, [propertyCustomerList, dispatch]);

  // Property billing card dropdown data
  useEffect(() => {
    dispatch(
      getBillingList({
        limit: -1,
        customer_uuid: selectCustomer?.value || null,
      })
    );

    return () => {
      dispatch(resetBillingData());
    };
  }, [dispatch, selectCustomer]);

  useEffect(() => {
    if (propertyList.data) {
      const updatedData = propertyList.data.data?.map((item) => {
        const updatedItem = Object.keys(item).reduce((acc, key) => {
          acc[key] = item[key] === ('' || null) ? '-' : item[key];

          return acc;
        }, {});

        updatedItem.access_schedule =
          item?.access_details?.[0]?.access_detail_schedule?.map(
            (schedule) => schedule?.schedules?.display_name
          ) || [];

        updatedItem.access_procedure =
          item?.access_details?.[0]?.access_detail_procedure?.map(
            (procedure) => procedure?.procedures?.display_name
          ) || [];

        updatedItem.access_code = item?.access_details?.[0]?.access_code || '';
        updatedItem.access_note = item?.access_details?.[0]?.access_note || '';

        updatedItem.signature_required =
          item?.site_requirements?.[0]?.signature_required || false;
        updatedItem.induction_required =
          item?.site_requirements?.[0]?.induction_required || false;
        updatedItem.log_book_entry_required =
          item?.site_requirements?.[0]?.log_book_entry_required || false;
        updatedItem.police_check_required =
          item?.site_requirements?.[0]?.police_check_required || false;
        updatedItem.working_with_children_check_required =
          item?.site_requirements?.[0]?.working_with_children_check_required ||
          false;
        updatedItem.service_requirements =
          item?.site_requirements?.[0]?.service_requirements || false;
        updatedItem.technician_requirements =
          item?.site_requirements?.[0]?.technician_requirements || false;

        updatedItem.annual_due_certification_date =
          item?.building_attributes?.[0]?.annual_due_certification_date || '';
        updatedItem.property_building_era =
          item?.building_attributes?.[0]?.property_building_era?.display_name ||
          '';
        updatedItem.property_building_class =
          item?.building_attributes?.[0]?.property_building_class
            ?.display_name || '';
        updatedItem.property_building_type =
          item?.building_attributes?.[0]?.property_building_type
            ?.display_name || '';
        updatedItem.building_size =
          item?.building_attributes?.[0]?.building_size || '';
        updatedItem.stories_above_ground =
          item?.building_attributes?.[0]?.stories_above_ground || '';
        updatedItem.property_construction_class =
          item?.building_attributes?.[0]?.property_construction_class
            ?.display_name || '';
        updatedItem.building_part =
          item?.building_attributes?.[0]?.building_part || '';
        updatedItem.building_tenancies_no =
          item?.building_attributes?.[0]?.building_tenancies_no || '';
        // updatedItem.property_building_standard =
        //   item?.building_attributes?.[0]?.property_building_standard
        //     ?.standard_display_name || '';

        updatedItem.op_number = item?.occupancy_permit?.[0]?.op_number || '';
        updatedItem.op_date = item?.occupancy_permit?.[0]?.op_date || '';
        updatedItem.op_location =
          item?.occupancy_permit?.[0]?.op_location || '';

        updatedItem.customer_uuid = item.customer;
        updatedItem.account_manager_uuid =
          item.property_account_manager?.display_name;
        updatedItem.status = item.property_status.display_name;
        updatedItem.created_at = item.created_at;

        return updatedItem;
      });

      setPropertiesData(updatedData);
    }
  }, [propertyList, dispatch]);

  useEffect(() => {
    if (createPropertyList.loading !== null) {
      if (createPropertyList.error) {
        if (createPropertyList.error.errorDetails) {
          setServerErrors(createPropertyList.error.errorDetails);
        }
      } else {
        if (createPropertyList.data) {
          setOpen(false);
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: createPropertyList.data.message,
            })
          );
        }
      }
    }
  }, [createPropertyList.data, createPropertyList.error]);

  useEffect(() => {
    if (updatePropertyList.loading !== null) {
      if (updatePropertyList.error) {
        if (updatePropertyList.error.errorDetails) {
          setServerErrors(updatePropertyList.error.errorDetails);
        }
      } else {
        if (updatePropertyList.data) {
          setPropertyDataLoading(true);
          setOpen(false);
          if (uuid) {
            fetchPropertyDetails(updatePropertyList.data?.data[0]?.uuid);
          }
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: updatePropertyList.data.message,
            })
          );
        }
      }
    }
  }, [updatePropertyList.data, updatePropertyList.error]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(
      propertiesData?.filter((row) => newSelection.includes(row.id))
    );
  };

  const noData =
    role !== GLOBAL_ADMIN ? (
      <NewNoDataPage
        icon={<HomeWorkOutlinedIcon />}
        title={t('attributes.property.property')}
        createBtnText={`${t('attributes.add')} ${t('attributes.property.property')}`}
        singularText={t('attributes.property.property')}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    ) : (
      <NewNoDataPage
        icon={<HomeWorkOutlinedIcon />}
        title={t('attributes.property.property')}
        filterHeight={filterHeight(isFilterComponentVisible)}
        onlyTitle={true}
        customText={t('attributes.property.noPropertyText')}
      />
    );
  const onResetFilter = () => {
    setSelectCustomer(null);
    setComplianceStatus(null);
    setSelectStatus(null);
    setFromDate(null);
    setToDate(null);
    setSearchText('');
    setSelectBillingCard(null);
    setSelectConstructionClass(null);
    setSelectParentProperty(null);
    setFilters({ state: null, city: null });
  };

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        searchText={searchText}
        isRefresh={true}
        onRefreshFilter={() => setRefresh(!refresh)}
        onSearchChange={(e) => {
          setSearchText(e.target.value);
        }}
        isActive={isFilterComponentVisible}
        onResetFilter={onResetFilter}
        isResetButtonVisible={
          selectStatus ||
          complianceStatus ||
          selectCustomer ||
          fromDate ||
          toDate ||
          selectConstructionClass ||
          selectParentProperty ||
          filters?.state ||
          filters?.city
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          {role !== GLOBAL_ADMIN && (
            <Autocomplete
              placeholder={t('attributes.property.compliance_status')}
              options={CUSTOMER_COMPLIANCE_STATUS}
              value={complianceStatus}
              onChange={(e, newValue) => setComplianceStatus(newValue)}
              width="190px"
            />
          )}
          {role !== GLOBAL_ADMIN && (
            <Autocomplete
              placeholder={t('attributes.property.status')}
              options={statusList}
              value={selectStatus}
              onChange={(e, newValue) => setSelectStatus(newValue)}
              width="190px"
            />
          )}
          <Autocomplete
            placeholder={t('attributes.customer.customer')}
            options={customerList}
            value={selectCustomer}
            onChange={(e, newValue) => setSelectCustomer(newValue)}
            width="190px"
          />
          <Autocomplete
            placeholder={`${t('attributes.property.parent')} ${t('attributes.property.property')}`}
            options={parentPropertyDropdownData}
            value={selectParentProperty}
            onChange={(e, newValue) => setSelectParentProperty(newValue)}
            isLoadingData={parentPropertyDropdownLoading}
            width="190px"
          />
          <CustomDateRangePicker
            onOkClick={(val) => {
              setFromDate(val[0]);
              setToDate(val[1]);
            }}
            onClear={() => {
              setFromDate(null);
              setToDate(null);
            }}
            fromDate={fromDate}
            toDate={toDate}
            placeholder={t('common.createdAt')}
          />
          {role !== GLOBAL_ADMIN && (
            <Autocomplete
              placeholder={t('attributes.property.billingCard')}
              options={billingDropdownData}
              value={selectBillingCard}
              onChange={(e, newValue) => setSelectBillingCard(newValue)}
              width="190px"
              isLoadingData={billingDropdownLoading}
            />
          )}
          {role !== GLOBAL_ADMIN && (
            <Autocomplete
              placeholder={t('attributes.property.constructionClass')}
              options={constructionClassList}
              value={selectConstructionClass}
              width="190px"
              onChange={(e, newValue) => setSelectConstructionClass(newValue)}
            />
          )}
          {role === GLOBAL_ADMIN && (
            <>
              <Autocomplete
                placeholder={t('common.state')}
                options={stateList}
                value={filters?.state}
                onChange={(e, newVal) =>
                  setFilters((prev) => ({ ...prev, state: newVal, city: null }))
                }
                width="190px"
              />
              <Autocomplete
                placeholder={t('common.city')}
                options={cityList}
                value={filters?.city}
                onChange={(e, newVal) =>
                  setFilters((prev) => ({ ...prev, city: newVal }))
                }
                width="190px"
                disabledDropdown={!filters.state}
              />
            </>
          )}
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={propertiesData}
        total={propertyList.total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  const mainWrapper = (
    <StyledMainWrapper
      title={
        role === GLOBAL_ADMIN
          ? t('attributes.property.properties')
          : t('attributes.property.property')
      }
      btn={
        <>
          {role !== GLOBAL_ADMIN && (
            <CustomButton
              text={`${t('attributes.add')} ${t('attributes.property.property')}`}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                setOpen(true);
                setFormMode('create');
                setActiveStep(0);
              }}
            />
          )}
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.PROPERTY,
                  EXPORT_FILE_NAMES.PROPERTY,
                  COLUMNS_TO_MAP.PROPERTY
                );
              }
            }}
          />
        </>
      }
    >
      <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
        {!isDataLoading && propertyList?.totalCount === 0 && noData}
        {isInitialLoading ? (
          <Box
            sx={{
              width: '100%',
              mt: 2,
              minHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              maxHeight: `calc(100vh - ${filterHeight(isFilterComponentVisible)}px)`,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <CustomCircularLoader />
          </Box>
        ) : (
          !isInitialLoading && propertyList?.totalCount > 0 && renderedComponent
        )}
      </Box>
    </StyledMainWrapper>
  );

  const handleEditPropertyForm = (step) => {
    setIsFromDetails(true);
    setActiveStep(step);
    setOpen(true);
    setFormMode('edit');
    dispatch(getProperty(uuid)).then((response) => {
      setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
    });
  };

  const handleDefaultStep = () => {
    navigate(redirectURL[role][ROUTE_NAVIGATE_CONST.PROPERTIES]);
    setIsFilterComponentVisible(false);
    onResetFilter();
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {!uuid ? (
        mainWrapper
      ) : (
        <PropertyDetails
          propertyId={uuid}
          propertyNumId={propertyNumId}
          selectedProperty={selectedProperty}
          propertyDataloading={propertyDataloading}
          handleEditPropertyForm={handleEditPropertyForm}
          handleDefaultStep={handleDefaultStep}
          isEditable={role !== GLOBAL_ADMIN}
        />
      )}
      {open && (
        <SwipeableDrawer
          open={open}
          title={
            formMode === 'create'
              ? `${t('attributes.add')} ${t('attributes.property.property')}`
              : `${t('attributes.edit')} ${t('attributes.property.property')}`
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          width={SWIPEABLE_DRAWER_STEPPER_WIDTH}
        >
          <FormProvider {...methods}>
            <PropertyForm
              formMode={formMode}
              propertyData={selectedProperty}
              formStatusList={formStatusList}
              accessScheduleList={accessScheduleList}
              accessProcedureList={accessProcedureList}
              buildingEraList={buildingEraList}
              buildingClassList={buildingClassList}
              constructionClassList={constructionClassList}
              buildingTypeList={buildingTypeList}
              localGovermentAreaList={localGovermentAreaList}
              parentPropertyDropdownData={parentPropertyDropdownData}
              customerList={customerList}
              watch={watch}
              setValue={setValue}
              isOpen={open}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              isFromDetails={isFromDetails}
              zoneList={zonesDropdownData}
              isZoneLoading={zonesDropdownLoading}
              setServerErrors={setServerErrors}
              createLoading={createPropertyList.loading}
              updateLoading={updatePropertyList.loading}
              setSelectedProperty={setSelectedProperty}
            />
          </FormProvider>
        </SwipeableDrawer>
      )}
    </>
  );
};

export default Properties;
