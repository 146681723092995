import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useOutletContext, useParams } from 'react-router-dom';

import { WarningAmberOutlined } from '@mui/icons-material';
import DifferenceOutlinedIcon from '@mui/icons-material/DifferenceOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PaymentIcon from '@mui/icons-material/Payment';
import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';
import moment from 'moment';

import { RED, SECONDARY } from '../../constants/Colors';
import { exportToCSV } from '../../constants/common';
import {
  OVERDUE,
  PAID,
  PENDING,
  SCHEDULE_BOARD_WO_UTC_LOCAL,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { editCompany } from '../../store/company/api';
import { getInvoiceList } from '../../store/invoices/api';
import { resetInvoiceList } from '../../store/invoices/reducer';
import { getPropertyList } from '../../store/property/api';
import {
  formattedDate,
  getAmountByQtyAndPrice,
  loggedInUserDetail,
} from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterComponent,
  FilterSection,
} from '../CommonComponents/FilterComponent';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import StatusLabel from '../CommonComponents/StatusLabel';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import generatePDF from '../WorkOrder/Invoices/DownloadPreviewInvoice';
import PreviewInvoice from '../WorkOrder/Invoices/PreviewInvoice';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  property: null,
  status: null,
  invoice_date: {
    startDate: null,
    endDate: null,
  },
  due_date: {
    startDate: null,
    endDate: null,
  },
};

const InvoiceList = () => {
  // const dispatch = useDispatch();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { status } = useParams();

  const { profileDetails } = useSelector((state) => state.common);
  const { invoiceList, total } = useSelector((state) => state.invoices.get);
  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { dashboardFilter } = useOutletContext();

  const invoiceStatusList = [
    {
      value: PENDING,
      label: 'Pending',
    },
    {
      value: PAID,
      label: 'Paid',
    },
    {
      value: OVERDUE,
      label: 'Overdue',
    },
  ];

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('invoice_creation_date');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState({
    ...defaultFilters,
    ...dashboardFilter,
  });
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [open, setOpen] = useState(false);
  const [viewInvoice, setViewInvoice] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedRows, setSelectedRows] = useState(null);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.invoice.invoice_id'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (
          <Box>
            <Typography variant="body1">
              {row.account_partner_invoice_number || '-'}
            </Typography>
          </Box>
        ),
      },
      {
        field: 'property_name',
        headerName: t('attributes.invoice.property_name'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => {
          row?.property?.property_name;
        },
      },

      {
        field: 'invoice_creation_date',
        headerName: t('attributes.invoice.invoice_date'),
        flex: 1,
        sortable: true,
      },
      {
        field: 'due_date',
        headerName: t('attributes.invoice.due_date'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => {
          if (
            moment(
              formattedDate(row?.due_date, SCHEDULE_BOARD_WO_UTC_LOCAL)
            ).isBefore(moment())
          ) {
            return (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography color={RED}>
                  {formattedDate(row?.due_date)}
                </Typography>
                <WarningAmberOutlined sx={{ color: `${RED}!important` }} />
              </Box>
            );
          }

          return formattedDate(row.due_date);
        },
      },
      {
        field: 'payment_date',
        headerName: t('attributes.invoice.payment_date'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => formattedDate(row.payment_date),
      },
      {
        field: 'total',
        headerName: t('attributes.invoice.total'),
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('attributes.invoice.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setOpen(row.uuid);
              if (profileDetails.company_uuid) {
                dispatch(editCompany(profileDetails?.company_uuid));
              }
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: loggedInUserDetail()?.profile_uuid,
    };

    dispatch(getPropertyList(req));
  }, []);

  useEffect(() => {
    if (status) {
      setFilters((pre) => ({
        ...pre,
        status: invoiceStatusList?.find((s) => s.value === status),
      }));
      setIsFilterComponentVisible(true);
    }
  }, []);

  useEffect(() => {
    dispatch(getInvoiceList());

    return () => {
      dispatch(resetInvoiceList());
    };
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllInvoiceList = useCallback(() => {
    setSelectedRows(null);
    setIsDataLoading(true);

    dispatch(
      getInvoiceList({
        order: order,
        orderBy: orderBy,
        page: currentPage,
        size: perPageData,
        search: searchText,
        invoiceFromDate: filters.invoice_date?.startDate,
        invoiceToDate: filters.invoice_date?.endDate,
        dueDateFrom: filters.due_date?.startDate,
        dueDateTo: filters.due_date?.endDate,
        payment_status: filters.status?.value,
        type: filters.quoteType?.label,
        property_uuid: filters.property?.value,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
  ]);

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllInvoiceList, 500), [
    getAllInvoiceList,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = invoiceList?.map((item) => {
    const updatedItem = Object.keys(item).reduce((acc, key) => {
      acc[key] = item[key] === ('' || null) ? '-' : item[key];

      return acc;
    }, {});

    updatedItem.id = `INV-${item.id}`;
    updatedItem.invoice_creation_date =
      formattedDate(item.invoice_creation_date) || '-';
    updatedItem.due_date = item.due_date;
    updatedItem.total = getAmountByQtyAndPrice(item.items).toFixed(2) || '-';
    updatedItem.status = item.payment_status;
    updatedItem.payment_date = item?.payment_receive_date;
    updatedItem.property_name =
      item.work_order_data?.property_equipment_routine_service_schedule?.property?.property_name;

    return updatedItem;
  });

  const formatStatus = (status) => {
    const lowerCaseStatus = status?.toLowerCase();

    if (lowerCaseStatus === OVERDUE) {
      return (
        <StatusLabel label={t('attributes.invoice.overdue')} color="#C54036" />
      );
    }
    if (lowerCaseStatus === PAID) {
      return (
        <StatusLabel label={t('attributes.invoice.paid')} color="#95C020" />
      );
    }
    if (lowerCaseStatus === PENDING) {
      return (
        <StatusLabel label={t('attributes.invoice.pending')} color="#FD7E14" />
      );
    }
  };
  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  useEffect(() => {
    const detail = invoiceList?.find((item) => item.uuid === open);

    detail && setViewInvoice(detail);
  }, [open]);

  const renderedComponent = (
    <>
      <FilterSection
        onFilterBtnClick={() =>
          setIsFilterComponentVisible(!isFilterComponentVisible)
        }
        isRefresh={true}
        searchText={searchText}
        onRefreshFilter={() => setRefresh(!refresh)}
        isActive={isFilterComponentVisible}
        onResetFilter={resetFilter}
        onSearchChange={(e) => setSearchText(e.target.value)}
        isResetButtonVisible={
          searchText ||
          filters.property ||
          filters.status ||
          filters.invoice_date.startDate ||
          filters.invoice_date.endDate ||
          filters.due_date.startDate ||
          filters.due_date.endDate
        }
      />
      {isFilterComponentVisible && (
        <FilterComponent>
          <Autocomplete
            placeholder={t('attributes.invoice.property_name')}
            options={propertyDropdownData}
            value={filters?.property}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, property: newValue }))
            }
            width="190px"
            isLoadingData={propertyDropdownLoading}
          />
          <Autocomplete
            placeholder={t('attributes.invoice.status')}
            options={invoiceStatusList}
            value={filters?.status}
            onChange={(e, newValue) =>
              setFilters((prev) => ({ ...prev, status: newValue }))
            }
            width="190px"
          />
          <CustomDateRangePicker
            placeholder={t('attributes.invoice.invoice_date')}
            onOkClick={(val) => {
              setFilters((pre) => ({
                ...pre,
                invoice_date: { startDate: val[0], endDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((pre) => ({
                ...pre,
                invoice_date: { startDate: null, endDate: null },
              }));
            }}
            fromDate={filters.invoice_date?.startDate}
            toDate={filters.invoice_date?.endDate}
          />
          <CustomDateRangePicker
            placeholder={t('attributes.invoice.due_date')}
            onOkClick={(val) => {
              setFilters((pre) => ({
                ...pre,
                due_date: { startDate: val[0], endDate: val[1] },
              }));
            }}
            onClear={() => {
              setFilters((pre) => ({
                ...pre,
                due_date: { startDate: null, endDate: null },
              }));
            }}
            fromDate={filters.due_date?.startDate}
            toDate={filters.due_date?.endDate}
          />
        </FilterComponent>
      )}
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        btn={
          <>
            <CustomButton
              text={t('attributes.export')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<OpenInNewIcon />}
              onClick={() => {
                if (!selectedRows || selectedRows?.length === 0) {
                  dispatch(
                    snackbarToggle({
                      isOpen: true,
                      isErrorMsg: true,
                      msg: t('message.common.noRecordExportMessage'),
                    })
                  );
                  setSelectedRows(null);
                } else {
                  exportToCSV(
                    selectedRows,
                    COLUMNS_TO_EXPORT.CUSTOMER_INVOICES,
                    EXPORT_FILE_NAMES.INVOICES
                  );
                }
              }}
            />
          </>
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={open}
          title={viewInvoice?.account_partner_invoice_number}
          onClose={() => {
            setOpen(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(viewInvoice, company);
              }}
            />,
            <CustomButton
              text={t('common.differed')}
              color="inherit"
              disabled={false}
              startIcon={<DifferenceOutlinedIcon />}
              onClick={() => {}}
            />,
            <CustomButton
              text={t('common.pay')}
              disabled={false}
              startIcon={<PaymentIcon />}
              onClick={() => {}}
            />,
          ]}
          width={653}
        >
          <PreviewInvoice
            companyData={company}
            isLoadingCompany={isLoadingCompany}
            invoiceDetails={viewInvoice}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default InvoiceList;
