export const EXPORT_FILE_DATE_TIME_FORMAT = 'YYYY-MM-DD_HH:MM:SS';
export const COLUMNS_TO_EXPORT = {
  CUSTOMER_INVOICES: [
    'ID',
    'PROPERTY_NAME',
    'INVOICE_DATE',
    'DUE_DATE',
    'PAYMENT_DATE',
    'TOTAL',
    'STATUS',
  ],
  SUPER_ADMIN: [
    'ID',
    'NAME',
    'ADDRESS',
    'CELL_PHONE',
    'ZIP_CODE',
    'CITY',
    'EMAIL',
    'STATE',
    'CREATED_AT',
  ],
  CUSTOMER_DEFECTS: [
    'ID',
    'DEFECT_TITLE',
    'SEVERITY',
    'PROPERTY_NAME',
    'ASSET',
    'RAISED_BY',
    'DESCRIPTION',
    'STATUS',
    'CREATED_AT',
  ],
  SUPER_ADMIN_DEFECTS: [
    'ID',
    'CUSTOMER_NAME',
    'PROPERTY_NAME',
    'ASSET',
    'RAISED_BY',
    'DESCRIPTION',
    'STATUS',
    'CREATED_AT',
  ],
  USER_MANAGEMENT: [
    'USER_NAME',
    'COMPANY_NAME',
    'ROLE',
    'EMAIL',
    'CELL_PHONE',
    'ACTIVE',
    'CREATED_AT',
  ],
  GLOBAL_SUPER_ADMIN: [
    'ID',
    'NAME',
    'COMPANY_NAME',
    'ADDRESS',
    'CELL_PHONE',
    'ZIP_CODE',
    'CITY',
    'EMAIL',
    'STATE',
    'CREATED_AT',
  ],
  TECHNICIAN: [
    'ID',
    'TECHNICIAN_NAME',
    'TECHNICIAN_EMAIL',
    'CATEGORIES',
    'CREATED_AT',
  ],
  SUPPLIER: [
    'ID',
    'NAME',
    'BUSINESS_NUMBER',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'ADDRESS',
    'STATE',
    'CITY',
    'POSTCODE',
    'CREATED_AT',
  ],
  PRODUCT_CATALOGUE: [
    'ID',
    'PRODUCT_NAME',
    'TYPE',
    'SUPPLIER',
    'COST',
    'COST_CURRENCY',
    'COST_PRICE_TYPE',
    'SELL_PRICE',
    'SELL_CURRENCY',
    'SELL_PRICE_TYPE',
    'EQUIPMENT_TYPE',
    'ESTIMATED_TIME_HOURS',
    'UM',
    'CREATED_AT',
  ],
  COMPANY: [
    'ID',
    'COMPANY_NAME',
    'ABN',
    'CELL_PHONE',
    'CONTACT_NAME',
    'EMAIL',
    'ADDRESS',
    'CITY',
    'STATE',
    'ZIP_CODE',
    'CREATED_AT',
  ],
  OFFICE_STAFF: [
    'ID',
    'NAME',
    'EMAIL',
    'CELL_PHONE',
    'ADDRESS',
    'CITY',
    'STATE',
    'POSTCODE',
    'CREATED_AT',
  ],
  OFFICE_MANAGEMENT: [
    'ID',
    'NAME',
    'EMAIL',
    'CELL_PHONE',
    'ADDRESS',
    'CITY',
    'STATE',
    'POSTCODE',
    'CREATED_AT',
  ],
  ASSETS: [
    'ID',
    'ASSET_NAME',
    'EQUIPMENT_TYPE',
    'EQUIPMENT_CLASS',
    'QR_CODE',
    'LOCATION',
    'STATUS',
    'ACTIVE',
    'IDENTIFICATION_NUMBER',
    'MAKE',
    'MODEL',
    'SIZE',
    'QUANTITY',
    'BASE_DATE',
    'INSTALLATION_DATE',
    'INTERNAL_NOTE',
  ],
  ROUTINE: [
    'ROUTINE_NAME',
    'TYPE',
    'WO_CREATION_MODE',
    'FREQUENCY',
    'CREATED_AT',
  ],
  WORK_ORDER: [
    'ID',
    'WORK_ORDER_NAME',
    'WORK_ORDER_TYPE',
    'PROPERTY',
    'ASSET_COUNT',
    'TECHNICIAN',
    'ROUTINE_STANDARD_NAME',
    'ROUTINE_NAME',
    'FREQUENCY',
    'STATUS',
    'SCHEDULE_START_DATE',
    'SCHEDULE_END_DATE',
    'DUE_DATE',
    'PROPERTY_ZONE',
  ],
  TIMESHEET: [
    'ID',
    'TECHNICIAN_NAME',
    'WORK_ORDER_NAME',
    'STATUS',
    'TYPE',
    'START_DATE_TIME',
    'END_DATE_TIME',
    'TASK_DESCRIPTION',
    'CREATED_AT',
  ],
  QUOTE_LIST: [
    'ID',
    'PROPERTY_NAME',
    'DUE_DATE',
    'QUOTE_DATE',
    'TYPE',
    'STATUS',
    'AMOUNT',
    'CREATED_AT',
  ],
  PROPERTY: [
    'ID',
    'PROPERTY_NAME',
    'ADDRESS',
    'STATE',
    'CITY',
    'POST_CODE',
    'ZONES',
    'LOCAL_GOVERNMENT_AREA',
    'LAND/LOT_NUMBER',
    'STATUS',
    'CUSTOMER',
    'BILLING_CARD',
    'PARENT_PROPERTY',
    'OCSP_OR_DP/DN_NUMBER',
    'INTERNAL_NOTE',
    'ANNUAL_COMPLIANCE_DATE',
    'CONTRACT_START_DATE',
    'CONTRACT_VALID_TILL',
    'DEFAULT_TECHNICIAN',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'ACCESS_SCHEDULE',
    'ACCESS_PROCEDURE',
    'ACCESS_CODE',
    'ACCESS_NOTE',
    'SIGNATURE_REQUIRED',
    'INDUCTION_REQUIRED',
    'LOG_BOOK_ENTRY_REQUIRED',
    'POLICE_CHECK_REQUIRED',
    'IS_BOOKING_REQUIRED',
    'WORKING_WITH_CHILDREN_CHECK_REQUIRED',
    'SERVICE_REQUIREMENTS',
    'SAFTEY_REQUIREMENTS',
    'ANNUAL_DUE_CERTIFICATION_DATE',
    'BUILDING_ERA',
    'BUILDING_CLASS',
    'BUILDING_TYPE',
    'BUILDING_SIZE(SQFT)',
    'STORIES_ABOVE_GROUND',
    'STORIES_BELOW_GROUND',
    'CONSTRUCTION_CLASS',
    'BUILDING_STANDARDS',
    'OCCUPANCY_PERMIT_NUMBER',
    'OCCUPANCY_PERMIT_DATE',
    'OCCUPANCY_PERMIT_LOCATION',
    'CREATED_AT',
  ],
  CUSTOMER: [
    'ID',
    'CUSTOMER_NAME',
    'ABN_NUMBER',
    'CATEGORY',
    'AGREEMENT_SIGNED_DATE',
    'REGISTERED_ADDRESS',
    'STATE',
    'CITY',
    'POSTCODE',
    'NOTES',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'ACCOUNT_MANAGER',
    'INVITATION_STATUS',
    'CREATED_AT',
  ],
  BILLING_CONTRACTS: [
    'ID',
    'NAME',
    'CUSTOMER',
    'PROPERTY',
    'CONTRACT_START_DATE',
    'CONTRACT_VALID_TILL',
    'CONTRACT_DATE',
    'STANDARD_ID',
    'ANNUAL_FIXED_PERCENTAGE(%)_INCREASE',
    'CREATED_AT',
  ],
  INVOICES: [
    'ID',
    'CUSTOMER',
    'PROPERTY',
    'WO-ID',
    'BILLING_INFORMATION',
    'INVOICE_DATE',
    'DUE_DATE',
    'TOTAL_AMOUNT',
    'STATUS',
    'CREATED_AT',
  ],
  ROUTINE_SERVICE_FREQUENCIES: [
    'ID',
    'NAME',
    'STANDARD',
    'CODE',
    'FREQUENCY',
    'FREQUENCY_TYPE',
    'TOLERANCE',
    'TOLERANCE_TYPE',
    'APPLICABILITY_NOTE',
    'INTERNAL_NOTE',
  ],
  PURCHASE_ORDER: [
    'ID',
    'SUPPLIER',
    'DESCRIPTION',
    'STATUS',
    'TOTAL_AMOUNT',
    'CREATED_ON',
  ],
  SUPER_ADMIN_DEFECT_QUOTES: [
    'WORK_ORDER_ID',
    'QUOTE_ID',
    'CUSTOMER',
    'PROPERTY_NAME',
    'PROPERTY_ADDRESS',
    'STATUS',
    'QUOTE_DATE',
    'DUE_DATE',
    'AMOUNT',
  ],
  BILLING_INFORMATION: [
    'ID',
    'BILLING_NAME',
    'CUSTOMER',
    'CONTACT_NAME',
    'CONTACT_EMAIL',
    'CONTACT_PHONE',
    'ADDRESS',
    'CREATED_AT',
  ],
};

export const EXPORT_FILE_NAMES = {
  SUPER_ADMIN: 'SUPER_ADMIN',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  CUSTOMER_DEFECTS: 'CUSTOMER_DEFECTS',
  SUPER_ADMIN_DEFECTS: 'SUPER_ADMIN_DEFECTS',
  GLOBAL_SUPER_ADMIN: 'GLOBAL_SUPER_ADMIN',
  TECHNICIAN: 'TECHNICIAN',
  SUPPLIER: 'SUPPLIER',
  PRODUCT_CATALOGUE: 'PRODUCT_CATALOGUE',
  COMPANY: 'COMPANY',
  OFFICE_STAFF: 'OFFICE_STAFF',
  ASSETS: 'ASSETS',
  ROUTINE: 'ROUTINE',
  WORK_ORDER: 'WORK_ORDER',
  TIMESHEET: 'TIMESHEET',
  QUOTE_LIST: 'QUOTE_LIST',
  PROPERTY: 'PROPERTY',
  CUSTOMER: 'CUSTOMER',
  BILLING_CONTRACTS: 'BILLING_CONTRACTS',
  INVOICES: 'INVOICES',
  OFFICE_MANAGEMENT: 'OFFICE_MANAGEMENT',
  ROUTINE_SERVICE_FREQUENCIES: 'ROUTINE_SERVICE_FREQUENCIES',
  PURCHASE_ORDERS: 'PURCHASE_ORDERS',
  SUPER_ADMIN_DEFECT_QUOTES: 'SUPER_ADMIN_DEFECT_QUOTES',
  BILLING_INFORMATION: 'BILLING_INFORMATION',
};

export const COLUMNS_TO_MAP = {
  ASSETS: {
    id: 'ID',
    label: 'ASSET_NAME',
    equipment_type: 'EQUIPMENT_TYPE',
    product: 'EQUIPMENT_CLASS',
    qr_code: 'QR_CODE',
    location: 'LOCATION',
    status: 'STATUS',
    is_active: 'ACTIVE',
    identification_number: 'IDENTIFICATION_NUMBER',
    make: 'MAKE',
    model: 'MODEL',
    size: 'SIZE',
    quantity: 'QUANTITY',
    base_date: 'BASE_DATE',
    installation_date: 'INSTALLATION_DATE',
    internal_note: 'INTERNAL_NOTE',
  },
  ROUTINE: {
    routine_name: 'ROUTINE_NAME',
    type: 'TYPE',
    wo_creation_mode: 'WO_CREATION_MODE',
    export_frequency: 'FREQUENCY',
    created_at: 'CREATED_AT',
  },
  WORK_ORDER: {
    id: 'ID',
    work_order_name: 'WORK_ORDER_NAME',
    work_order_type: 'WORK_ORDER_TYPE',
    property_name: 'PROPERTY',
    asset_count: 'ASSET_COUNT',
    technician_name: 'TECHNICIAN',
    'property_equipment_routine_service_schedule.standards.name':
      'ROUTINE_STANDARD_NAME',
    'property_equipment_routine_service_schedule.routine_types.name':
      'ROUTINE_NAME',
    'property_equipment_routine_service_schedule.frequencies.display_name':
      'FREQUENCY',
    latest_status: 'STATUS',
    scheduled_start_date_time: 'SCHEDULED_START_DATE_TIME',
    scheduled_end_date_time: 'SCHEDULED_END_DATE_TIME',
    due_date: 'DUE_DATE',
    property_zone: 'PROPERTY_ZONE',
  },
  TIMESHEET: {
    id: 'ID',
    'technician.technician_name': 'TECHNICIAN_NAME',
    'work_order.work_order_name': 'WORK_ORDER_NAME',
    status: 'STATUS',
    type: 'TYPE',
    start_date_time: 'START_DATE_TIME',
    end_date_time: 'END_DATE_TIME',
    task_description: 'TASK_DESCRIPTION',
    created_at: 'CREATED_AT',
  },
  QUOTE_LIST: {
    id: 'ID',
    property_name: 'PROPERTY_NAME',
    due_date: 'DUE_DATE',
    quote_date: 'QUOTE_DATE',
    quotation_type: 'TYPE',
    status: 'STATUS',
    amount: 'AMOUNT',
    created_at: 'CREATED_AT',
  },
  PROPERTY: {
    id: 'ID',
    property_name: 'PROPERTY_NAME',
    address: 'ADDRESS',
    state: 'STATE',
    city: 'CITY',
    zip_code: 'POST_CODE',
    zone: 'ZONES',
    local_government_area_name: 'LOCAL_GOVERNMENT_AREA',
    lot_number: 'LAND/LOT_NUMBER',
    'property_status.display_name': 'STATUS',
    customer: 'CUSTOMER',
    'property_billing_card.display_name': 'BILLING_CARD',
    'property_parent.display_name': 'PARENT_PROPERTY',
    ocsp_number: 'OCSP_OR_DP/DN_NUMBER',
    internal_note: 'INTERNAL_NOTE',
    'contract_assignment_details.annual_compliance_date':
      'ANNUAL_COMPLIANCE_DATE',
    'contract_assignment_details.contract_start_date': 'CONTRACT_START_DATE',
    'contract_assignment_details.contract_expiry_date': 'CONTRACT_VALID_TILL',
    'contract_assignment_details.technician': 'DEFAULT_TECHNICIAN',
    'property_contact_info.name': 'CONTACT_NAME',
    'property_contact_info.email': 'CONTACT_EMAIL',
    'property_contact_info.phone': 'CONTACT_PHONE',
    access_schedule: 'ACCESS_SCHEDULE',
    access_procedure: 'ACCESS_PROCEDURE',
    access_code: 'ACCESS_CODE',
    access_note: 'ACCESS_NOTE',
    signature_required: 'SIGNATURE_REQUIRED',
    induction_required: 'INDUCTION_REQUIRED',
    log_book_entry_required: 'LOG_BOOK_ENTRY_REQUIRED',
    police_check_required: 'POLICE_CHECK_REQUIRED',
    is_booking_required: 'IS_BOOKING_REQUIRED',
    working_with_children_check_required:
      'WORKING_WITH_CHILDREN_CHECK_REQUIRED',
    service_requirements: 'SERVICE_REQUIREMENTS',
    technician_requirements: 'SAFTEY_REQUIREMENTS',
    annual_due_certification_date: 'ANNUAL_DUE_CERTIFICATION_DATE',
    property_building_era: 'BUILDING_ERA',
    property_building_class: 'BUILDING_CLASS',
    property_building_type: 'BUILDING_TYPE',
    building_size: 'BUILDING_SIZE(SQFT)',
    stories_above_ground: 'STORIES_ABOVE_GROUND',
    stories_below_ground: 'STORIES_BELOW_GROUND',
    property_construction_class: 'CONSTRUCTION_CLASS',
    property_building_standard: 'BUILDING_STANDARDS',
    op_number: 'OCCUPANCY_PERMIT_NUMBER',
    op_date: 'OCCUPANCY_PERMIT_DATE',
    op_location: 'OCCUPANCY_PERMIT_LOCATION',
    created_at: 'CREATED_AT',
  },
  CUSTOMER: {
    id: 'ID',
    customer_name: 'CUSTOMER_NAME',
    customer_abn_number: 'ABN_NUMBER',
    categories: 'CATEGORY',
    agreement_signed_date: 'AGREEMENT_SIGNED_DATE',
    address: 'REGISTERED_ADDRESS',
    state: 'STATE',
    city: 'CITY',
    postcode: 'POSTCODE',
    notes: 'NOTES',
    'primary_contact.contact_name': 'CONTACT_NAME',
    'primary_contact.contact_email': 'CONTACT_EMAIL',
    'primary_contact.contact_phone': 'CONTACT_PHONE',
    account_manager_name: 'ACCOUNT_MANAGER',
    invitation_status: 'INVITATION_STATUS',
    created_at: 'CREATED_AT',
  },
  BILLING_CONTRACTS: {
    id: 'ID',
    name: 'NAME',
    customer: 'CUSTOMER',
    exportProperty: 'PROPERTY',
    contract_start_date: 'CONTRACT_START_DATE',
    contract_valid_till: 'CONTRACT_VALID_TILL',
    contract_date: 'CONTRACT_DATE',
    standard_id: 'STANDARD_ID',
    price_increase: 'ANNUAL_FIXED_PERCENTAGE(%)_INCREASE',
    created_at: 'CREATED_AT',
  },
  INVOICES: {
    id: 'ID',
    customer: 'CUSTOMER',
    'property.property_name': 'PROPERTY',
    wo_id: 'WO-ID',
    billing_information: 'BILLING_INFORMATION',
    invoice_creation_date: 'INVOICE_DATE',
    due_date: 'DUE_DATE',
    totalAmount: 'TOTAL_AMOUNT',
    status: 'STATUS',
    created_at: 'CREATED_AT',
  },
  PURCHASE_ORDERS: {
    poid: 'ID',
    supplier: 'SUPPLIER',
    description: 'DESCRIPTION',
    status: 'STATUS',
    totalAmount: 'TOTAL_AMOUNT',
    createdOn: 'CREATED_ON',
  },
  DEFECTS: {
    id: 'ID',
    customer_name: 'CUSTOMER_NAME',
    property_name: 'PROPERTY_NAME',
    asset: 'ASSET',
    raised_by: 'RAISED_BY',
    description: 'DESCRIPTION',
    status: 'STATUS',
    date: 'CRAETED_AT',
  },
};
