import { createBrowserRouter, Navigate } from 'react-router-dom';

// Internal
import { AboutApp } from './components/AboutApp';
import Billing from './components/Billing/Billing';
import ViewBillingCard from './components/Billing/ViewBillingCard';
import ComingSoon from './components/CommonComponents/ComingSoon';
import { DataManagement } from './components/CommonComponents/Reports/DataManagement';
import { ExportData } from './components/CommonComponents/Reports/ExportData';
import { GlobalAdminExportData } from './components/CommonComponents/Reports/GlobalAdmin/ExportData';
import { GlobalAdminImportData } from './components/CommonComponents/Reports/GlobalAdmin/ImportData';
import { RefernceData } from './components/CommonComponents/Reports/GlobalAdmin/ReferenceData';
import { TBC } from './components/CommonComponents/Reports/GlobalAdmin/TBC';
import ImportData from './components/CommonComponents/Reports/ImportData';
import { Templates } from './components/CommonComponents/Reports/Templates';
import Companies from './components/Company/Companies';
import ControlPanel from './components/ControlPanel/ControlPanel';
import { GlobalAdminLanguages } from './components/ControlPanel/GlobalAdmin/Languages';
import { GlobalAdminRoutineService } from './components/ControlPanel/GlobalAdmin/RoutineService';
import { GlobalAdminSecurityGroups } from './components/ControlPanel/GlobalAdmin/SecurityGroups';
import { GlobalAdminStandards } from './components/ControlPanel/GlobalAdmin/Standards';
import { GlobalAdminTemplates } from './components/ControlPanel/GlobalAdmin/Templates';
import { GlobalAdminTimezone } from './components/ControlPanel/GlobalAdmin/Timezone';
import CustomerPropertyDashboard from './components/Dashboard/CustomerPropertyDashboard';
import Dashboard from './components/Dashboard/Dashboard';
import { DeepLinking } from './components/DeepLinking';
import DefectDashboard from './components/DefectDashboard/DefectDashboard';
import DefectDashboardLayout from './components/DefectDashboard/DefectDashboardLayout';
import DefectList from './components/DefectDashboard/DefectList';
import HelpAndSupport from './components/HelpAndSupport/HelpAndSupport';
import { GlobalAdminBusinessCentral } from './components/Integrations/GlobalAdmin/BusinessCentral';
import { GlobalAdminGoogleIndoorMaps } from './components/Integrations/GlobalAdmin/GoogleIndoorMaps';
import { GlobalAdminGoogleMaps } from './components/Integrations/GlobalAdmin/GoogleMaps';
import { GlobalAdminSSO } from './components/Integrations/GlobalAdmin/SSO';
import { GlobalAdminXeroIntegration } from './components/Integrations/GlobalAdmin/XeroIntegrations';
import Integrations from './components/Integrations/Integrations';
import XeroIntegrations from './components/Integrations/XeroIntegrations';
import InvoiceDashboard from './components/InvoiceDashboard/InvoiceDashboard';
import InvoiceDashboardLayout from './components/InvoiceDashboard/InvoiceDashboardLayout';
import InvoiceList from './components/InvoiceDashboard/InvoiceList';
import LandingPage from './components/LandingPage';
import NoFound from './components/NotFound/NotFound';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import ProductCatalogue from './components/ProductCatalogue/ProductCatalogue';
import Profile from './components/Profile/Profile';
import Properties from './components/Properties/Properties';
import QuotesDashboard from './components/QuotesDashboard/QuotesDashboard';
import QuotesDashboardLayout from './components/QuotesDashboard/QuotesDashboardLayout';
import QuotesList from './components/QuotesDashboard/QuotesList';
import ActivityReport from './components/Reporting/ActivityReport';
import Reporting from './components/Reporting/Reporting';
import RoutineServiceFrequencies from './components/RoutineServiceFrequencies/RoutineServiceFrequencies';
import ScheduleBoard from './components/ScheduleBoard/ScheduleBoard';
import Layout from './components/Shared/Layout';
import SuperAdminDefectsList from './components/SuperAdmin/DefectList';
import SuperAdmins from './components/SuperAdmin/SuperAdmin';
import SuperAdminDefectsQoutes from './components/SuperAdmin/SuperAdminDefectsQoutes';
import SuperAdminTemplates from './components/SuperAdmin/SuperAdminTemplates';
import Suppliers from './components/Suppliers/Suppliers';
import EmailTemplateWrapper from './components/Templates/EmailTemplateWrapper';
import InvoicesTemplate from './components/Templates/Invoices';
import PurchaseOrdersTemplate from './components/Templates/PurchaseOrder';
import QuotesTemplate from './components/Templates/Quotes';
import { TermsService } from './components/TermsService';
import Timesheet from './components/Timesheet/Timesheet';
import BillingContractForm from './components/Users/BillingContracts/BillingContractForm';
import BillingContract from './components/Users/BillingContracts/BillingContracts';
import Customer from './components/Users/Customer/Customer';
import OfficeStaff from './components/Users/OfficeStaff/OfficeStaff';
import StaffManagement from './components/Users/StaffManagement/StaffManagement';
import Technician from './components/Users/Technician/Technician';
import { UsersManagement } from './components/UsersManagement/UsersManagement';
import Invoices from './components/WorkOrder/Invoices/Invoices';
import PurchaseOrderDetail from './components/WorkOrder/PurchaseOrders/PurchaseOrderDetail';
import PurchaseOrders from './components/WorkOrder/PurchaseOrders/PurchaseOrders';
import WorkOrder from './components/WorkOrder/WorkOrder';
import WorkOrderDetails from './components/WorkOrder/WorkOrderDetails';
import { AUTH_ROUTES, ROUTENAME } from './constants/RoutesConstants';
import AuthLayout from './hoc/AuthLayout';
import AuthRoute from './hoc/AuthRoute';
import UnAuthLayout from './hoc/UnAuthLayout';

export const routes = createBrowserRouter([
  // Public route for the landing page
  {
    path: ROUTENAME.DEFAULT_ROUTE, // e.g., '/'
    element: (
      <UnAuthLayout>
        <LandingPage />
      </UnAuthLayout>
    ),
  },
  {
    path: ROUTENAME.DEEP_LINKING_ROUTE,
    element: <DeepLinking />,
  },
  {
    path: ROUTENAME.PRIVACY_POLICY,
    element: <PrivacyPolicy />,
  },
  {
    path: ROUTENAME.TERMS_SERVICE,
    element: <TermsService />,
  },
  {
    path: ROUTENAME.ABOUT_APP,
    element: <AboutApp />,
  },
  // Protected routes under AuthLayout
  {
    path: ROUTENAME.DEFAULT_ROUTE,
    element: (
      <AuthLayout>
        <Layout />
      </AuthLayout>
    ),
    children: [
      {
        path: ROUTENAME.DASHBOARD,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.ADMIN_DASHBOARD}>
                {/* Global Admin & Super Admin */}
                <Dashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_DASHBOARD_PROPERTIES}>
                <CustomerPropertyDashboard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.QUOTES,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES}>
                {/* Customer Quotes Dashboard */}
                <QuotesDashboardLayout />
              </AuthRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES}>
                    <QuotesDashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.QUOTES_STATUS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES_STATUS}>
                    <QuotesList />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICES}>
                {/* Customer */}
                <InvoiceDashboardLayout />
              </AuthRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICES}>
                    <InvoiceDashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.INVOICES_STATUS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_INVOICE_STATUS}>
                    <InvoiceList />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.DEFECTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.DEFECTS}>
                {/* Customer */}
                <DefectDashboardLayout />
              </AuthRoute>
            ),
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DEFECTS}>
                    {/* Customer */}
                    <DefectDashboard />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.DEFECT_STATUS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.CUSTOMER_QUOTES_DEFECTS_STATUS}>
                    {/* Customer */}
                    <DefectList />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.HELP_AND_SUPPORT,
        element: (
          <AuthRoute type={AUTH_ROUTES.HELP_AND_SUPPORT}>
            <HelpAndSupport />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.SYSTEM_SETTING,
        element: (
          <AuthRoute type={AUTH_ROUTES.SYSTEM_SETTING}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.BELL_NOTIFICATION,
        element: (
          <AuthRoute type={AUTH_ROUTES.BELL_NOTIFICATION}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.DATA_MANAGEMENT,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.DATA_MANAGEMENT}>
                <DataManagement />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.REFERENCE_DATA,
            element: (
              <AuthRoute type={AUTH_ROUTES.REFERENCE_DATA}>
                <RefernceData />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.IMPORT_DATA,
            element: (
              <AuthRoute type={AUTH_ROUTES.IMPORT_DATA}>
                <GlobalAdminImportData />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.EXPORT_DATA,
            element: (
              <AuthRoute type={AUTH_ROUTES.EXPORT_DATA}>
                <GlobalAdminExportData />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.TBC_DATA,
            element: (
              <AuthRoute type={AUTH_ROUTES.TBC_DATA}>
                <TBC />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.GLOBAL_ADMIN_INTEGRATIONS,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INTEGRATIONS}>
                <Integrations />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_XERO_INTEGRATIONS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_XERO_INTEGRATIONS}>
                <GlobalAdminXeroIntegration />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_BUSINESS_CENTRAL,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_BUSINESS_CENTRAL}>
                <GlobalAdminBusinessCentral />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_GOOGLE_MAPS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_GOOGLE_MAPS}>
                <GlobalAdminGoogleMaps />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS}>
                <GlobalAdminGoogleIndoorMaps />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_SSO,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_SSO}>
                <GlobalAdminSSO />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.MONITORING_ALERT,
        element: (
          <AuthRoute type={AUTH_ROUTES.MONITORING_ALERT}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.PROPERTIES,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_PROPERTIES}>
                <Properties />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES_DETAILS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_PROPERTIES_DETAILS}>
                <Properties />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.SCHEDULING,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.SCHEDULE_BOARD} />,
          },
          // {
          //   path: ROUTENAME.ROUTINE_SERVICES,
          //   element: (
          //     <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ROUTINE_SERVICES}>
          //       <ComingSoon />
          //     </AuthRoute>
          //   ),
          // },
          // {
          //   path: ROUTENAME.SERVICE_REQUESTS,
          //   element: (
          //     <AuthRoute type={AUTH_ROUTES.SERVICE_REQUESTS}>
          //       <ComingSoon />
          //     </AuthRoute>
          //   ),
          // },
          {
            path: ROUTENAME.SCHEDULE_BOARD,
            element: (
              <AuthRoute type={AUTH_ROUTES.SCHEDULE_BOARD}>
                <ScheduleBoard />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.WORK_ORDERS,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.WORK_ORDERS}>
                    <WorkOrder />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.WORK_ORDERS_VIEW,
                element: (
                  <AuthRoute type={AUTH_ROUTES.WORK_ORDERS_VIEW}>
                    <WorkOrderDetails />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.CUSTOMERS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.CUSTOMER} />,
          },
          {
            path: ROUTENAME.CUSTOMER,
            element: (
              <AuthRoute type={AUTH_ROUTES.NAME}>
                <Customer />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROPERTIES,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_PROPERTIES}>
                    <Properties />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.PROPERTIES_DETAILS,
                element: (
                  <AuthRoute type={AUTH_ROUTES.PROPERTIES_DETAILS}>
                    <Properties />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.BILLING_CONTRACTS,
            exact: true,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.BILLING_CONTRACTS}>
                    <BillingContract />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.BILLING_CONTRACTS_EDIT,
                element: (
                  <AuthRoute type={AUTH_ROUTES.BILLING_CONTRACTS_EDIT}>
                    <BillingContractForm />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.BILLING_INFORMATION,
            exact: true,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_BILLING_INFO}>
                    <Billing />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.BILLING_DETAIL,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_BILLING_VIEW}>
                    <ViewBillingCard />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.USERS,
        children: [
          {
            path: ROUTENAME.SUPER_ADMIN,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN}>
                <SuperAdmins />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.OFFICE_STAFF,
            element: (
              <AuthRoute type={AUTH_ROUTES.OFFICE_STAFFS}>
                <OfficeStaff />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.STAFF_MANAGEMENT,
            element: (
              <AuthRoute type={AUTH_ROUTES.STAFF_MANAGEMENT}>
                <StaffManagement />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.TECHNICIAN,
            element: (
              <AuthRoute type={AUTH_ROUTES.TECHNICIANS}>
                <Technician />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SUPPLIERS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPPLIERS}>
                {/* Supplier */}
                <Suppliers />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.MASTER,
        children: [
          {
            path: ROUTENAME.PRODUCT_CATALOGUE,
            element: (
              <AuthRoute type={AUTH_ROUTES.PRODUCT_CATALOGUE}>
                {/* Product Catalogue */}
                <ProductCatalogue />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.COMMERCIAL,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.DEFECTS_QUOTES} />,
          },
          // Super Admin
          {
            path: ROUTENAME.DEFECTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_DEFECTS}>
                <SuperAdminDefectsList />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.DEFECTS_QUOTES,
            element: (
              <AuthRoute type={AUTH_ROUTES.DEFECTS_QUOTES}>
                <SuperAdminDefectsQoutes />
              </AuthRoute>
            ),
          },
          // {
          //   path: ROUTENAME.QUOTES_SERVICES,
          //   element: (
          //     <AuthRoute type={AUTH_ROUTES.QUOTES_SERVICES}>
          //       <ComingSoon />
          //     </AuthRoute>
          //   ),
          // },
          {
            path: ROUTENAME.PURCHASE_ORDER,
            element: (
              <AuthRoute
                type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER}
              >
                <PurchaseOrders isFromSideBar={true} />
              </AuthRoute>
            ),
          },

          {
            path: ROUTENAME.PURCHASE_ORDER,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute
                    type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER}
                  >
                    <PurchaseOrders isFromSideBar={true} />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.PURCHASE_ORDER_EDIT,
                element: (
                  <AuthRoute
                    type={
                      AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_PURCHASE_ORDER_EDIT
                    }
                  >
                    <PurchaseOrderDetail isFromSideBar={true} />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_COMMERCIAL_INVOICES}>
                <Invoices isFromSideBar={true} isFromWorkOrder={false} />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.TIMESHEET,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_TIMESHEET}>
                <Timesheet />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.WAREHOUSE,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.PRODUCTS} />,
          },
          {
            path: ROUTENAME.PRODUCTS,
            element: (
              <AuthRoute type={AUTH_ROUTES.PRODUCTS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ASSETS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INVOICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_INVOICES}>
                {/* Super Admin */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.USERS,
        children: [
          {
            path: ROUTENAME.GLOBAL_ADMIN,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMINS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.CONTROL_PANEL,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_CONTROL_PANEL}>
                <ControlPanel />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_TEMPLATES,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_TEMPLATES}>
                    <GlobalAdminTemplates />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_INVOICE_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INVOICE_TEMPLATES}>
                    <InvoicesTemplate />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_QUOTES_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_QUOTES_TEMPLATES}>
                    <QuotesTemplate />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES,
                element: (
                  <AuthRoute
                    type={AUTH_ROUTES.GLOBAL_ADMIN_PURCHASE_ORDER_TEMPLATES}
                  >
                    <PurchaseOrdersTemplate />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.GLOBAL_ADMIN_EMAIL_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_EMAIL_TEMPLATES}>
                    <EmailTemplateWrapper />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_ROUTINE_SERVICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ROUTINE_SERVICES}>
                <GlobalAdminRoutineService />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_STANDARDS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_STANDARDS}>
                <GlobalAdminStandards />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_LANGUAGES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_LANGUAGES}>
                <GlobalAdminLanguages />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_TIMEZONE,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_TIMEZONE}>
                <GlobalAdminTimezone />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.GLOBAL_ADMIN_SECURITY_GROUPS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_SECURITY_GROUPS}>
                <GlobalAdminSecurityGroups />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.REPORTS,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={[AUTH_ROUTES.CUSTOMER_REPORTS]}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.DATA_MANAGEMENT,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.DATA_MANAGEMENT}>
                    <DataManagement />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.IMPORT_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.IMPORT_DATA}>
                    <ImportData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.EXPORT_DATA,
                element: (
                  <AuthRoute type={AUTH_ROUTES.EXPORT_DATA}>
                    <ExportData />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SAMPLE_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SAMPLE_TEMPLATES}>
                    <Templates />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.SUPER_ADMIN_REPORTING,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_REPORTING}>
                    <Reporting />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.ACTIVITY_REPORT,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_ACITVITY_REPORT}>
                    <ActivityReport />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.REPORTING,
            element: (
              <AuthRoute type={AUTH_ROUTES.REPORTING}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.FIRE_COMPANY,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.COMPANIES} />,
          },
          {
            path: ROUTENAME.COMPANIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.FIRE_COMPANIES}>
                <Companies />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.SUPER_ADMINS,
            element: (
              <AuthRoute type={AUTH_ROUTES.SUPER_ADMINS}>
                <SuperAdmins />
              </AuthRoute>
            ),
          },
        ],
      },
      {
        path: ROUTENAME.FORMS,
        children: [
          {
            index: true,
            element: <Navigate to={ROUTENAME.ROUTINE_SERVICES} />,
          },
          {
            path: ROUTENAME.ROUTINE_SERVICES,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ROUTINE_SERVICES}>
                {/* Add dependencies in package.json for survey-core */}
                {/* <Forms />  */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ASSETS,
            element: (
              <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_ASSETS}>
                <ComingSoon />
              </AuthRoute>
            ),
          },
        ],
      },

      {
        path: ROUTENAME.INVOICES,
        element: (
          <AuthRoute type={AUTH_ROUTES.GLOBAL_ADMIN_INVOICES}>
            {/* Global Admin */}
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.MAKE_REQUEST,
        element: (
          <AuthRoute type={AUTH_ROUTES.MAKE_A_REQUEST}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.ASSETS,
        element: (
          <AuthRoute type={AUTH_ROUTES.CUSTOMER_ASSETS}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.ROUTINE_ACTIVITIES,
        element: (
          <AuthRoute type={AUTH_ROUTES.ROUTINE_ACTIVITIES}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.REACTIVE_TASK,
        element: (
          <AuthRoute type={AUTH_ROUTES.REACTIVE_TASK}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.DOCUMENTS,
        element: (
          <AuthRoute type={AUTH_ROUTES.DOCUMENTS}>
            <ComingSoon />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.SETTINGS,
        children: [
          {
            path: ROUTENAME.TEMPLATES,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.TEMPLATES}>
                    <SuperAdminTemplates />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SUPER_ADMIN_INVOICE_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_INVOICE_TEMPLATES}>
                    <InvoicesTemplate />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SUPER_ADMIN_QUOTES_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_QUOTES_TEMPLATES}>
                    <QuotesTemplate />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES,
                element: (
                  <AuthRoute
                    type={AUTH_ROUTES.SUPER_ADMIN_PURCHASE_ORDER_TEMPLATES}
                  >
                    <PurchaseOrdersTemplate />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.SUPER_ADMIN_EMAIL_TEMPLATES,
                element: (
                  <AuthRoute type={AUTH_ROUTES.SUPER_ADMIN_EMAIL_TEMPLATES}>
                    <EmailTemplateWrapper />
                  </AuthRoute>
                ),
              },
            ],
          },
          {
            path: ROUTENAME.FORMS,
            element: (
              <AuthRoute type={AUTH_ROUTES.FORMS}>
                {/* Product Catalogue */}
                <ComingSoon />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.ROUTINE_SERVICE_FREQUENCIES,
            element: (
              <AuthRoute type={AUTH_ROUTES.ROUTINE_SERVICE_FREQUENCIES}>
                <RoutineServiceFrequencies />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.INTEGRATIONS,
            children: [
              {
                index: true,
                element: (
                  <AuthRoute type={AUTH_ROUTES.INTEGRATIONS}>
                    <Integrations />
                  </AuthRoute>
                ),
              },
              {
                path: ROUTENAME.XERO_INTEGRATION,
                element: (
                  <AuthRoute type={AUTH_ROUTES.XERO_INTEGRATION}>
                    <XeroIntegrations />
                  </AuthRoute>
                ),
              },
            ],
          },
        ],
      },
      {
        path: ROUTENAME.PROFILE,
        element: (
          <AuthRoute type={AUTH_ROUTES.PROFILE}>
            <Profile />
          </AuthRoute>
        ),
      },
      {
        path: ROUTENAME.USERS_MANAGEMENT,
        children: [
          {
            index: true,
            element: (
              <AuthRoute type={AUTH_ROUTES.USERS_MANAGEMENT}>
                <UsersManagement />
              </AuthRoute>
            ),
          },
          {
            path: ROUTENAME.PROFILE_DETAIL,
            element: (
              <AuthRoute type={AUTH_ROUTES.PROFILE_DETAIL_VIEW}>
                <Profile />
              </AuthRoute>
            ),
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <NoFound />,
  },
]);
