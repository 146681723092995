import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Typography } from '@mui/material';

import {
  FAILED_STATUS,
  INSERTED_STATUS,
  INSERTED_WITH_ERRORS_STATUS,
} from '../../../constants/Constants';
import { getDocumentUploadStatus } from '../../../store/document/api';
import { resetDocumentData } from '../../../store/document/reducer';
import CustomCircularLoader from '../CustomLoader';

const ValidationPreviewFinished = ({
  docUploadedSuccess,
  setDocUploadedSuccess,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => () => dispatch(resetDocumentData()), []);
  const { isDocumentLoading, uploadedSuccessfully, uploadError, documentData } =
    useSelector((state) => state.document.upload);

  const { documentUploadStatus, isDocStatusLoading, isDocStatusError } =
    useSelector((state) => state.document.getUploadStatus);

  useEffect(() => {
    let interval;

    const fetchStatus = async () => {
      const result = await dispatch(
        getDocumentUploadStatus({ documentId: documentData.uuid })
      ).unwrap();

      // Check if we have reached a final status
      if (
        result?.data &&
        result.data.some(
          (status) =>
            status.status === INSERTED_STATUS ||
            status.status === FAILED_STATUS ||
            status.status === INSERTED_WITH_ERRORS_STATUS
        )
      ) {
        clearInterval(interval); // Stop polling if a final status is found
        setDocUploadedSuccess(false);
      }
    };

    if (uploadedSuccessfully) {
      // Start polling every 5 seconds
      interval = setInterval(fetchStatus, 5000);
    }

    return () => {
      clearInterval(interval); // Clean up the interval when the component unmounts
    };
  }, [uploadedSuccessfully, documentData, dispatch]);

  const getStatusMessage = () => {
    if (isDocStatusError || uploadError) {
      // If there's an error in either the upload or status check, show the error message
      return {
        icon: (
          <CancelOutlinedIcon
            sx={{ color: 'var(--state-colors-red)', width: 40, height: 40 }}
          />
        ),
        title: t('attributes.reports.importData.docErrorTitle'),
        description: t('attributes.reports.importData.docErrorDescription'),
      };
    }

    if (docUploadedSuccess || isDocumentLoading) {
      // Show loading message if either API call is still loading
      return {
        icon: (
          <CustomCircularLoader
            size={40}
            height="15%"
            sx={{ color: 'var(--import-data-loader-color)' }}
          />
        ),
        title: t('attributes.reports.importData.docLoadingTitle'),
        description: t('attributes.reports.importData.docLoadingDescription'),
      };
    }

    if (uploadedSuccessfully && !docUploadedSuccess) {
      // Check the status from the status API response
      const status = documentUploadStatus.data.find(
        (s) =>
          s.status === INSERTED_STATUS ||
          s.status === FAILED_STATUS ||
          s.status === INSERTED_WITH_ERRORS_STATUS
      );

      if (status) {
        if (status.status === INSERTED_STATUS) {
          return {
            icon: (
              <CheckCircleOutlinedIcon
                sx={{
                  color: 'var(--import-data-loader-color)',
                  width: 40,
                  height: 40,
                }}
              />
            ),
            title: t('attributes.reports.importData.docSuccessTitle'),
            description: t('attributes.reports.importData.docSuccessBody'),
          };
        }

        if (
          status.status === FAILED_STATUS ||
          status.status === INSERTED_WITH_ERRORS_STATUS
        ) {
          return {
            icon: (
              <CancelOutlinedIcon
                sx={{ color: 'var(--state-colors-red)', width: 40, height: 40 }}
              />
            ),
            title: t('attributes.reports.importData.docErrorTitle'),
            description: t('attributes.reports.importData.docErrorDescription'),
          };
        }
      }
    }

    return null;
  };

  const statusMessage = getStatusMessage();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        height: 'calc(100vh - 37vh)',
      }}
    >
      <Box
        sx={{
          width: '100%',
          maxWidth: 600,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 1,
          paddingX: { xs: 2, md: 4 },
          paddingY: { xs: 2, md: 3 },
        }}
      >
        {statusMessage && (
          <>
            {statusMessage.icon}
            <Typography variant="body1">{statusMessage.title}</Typography>
            <Typography variant="body2" align="center">
              {statusMessage.description}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ValidationPreviewFinished;
