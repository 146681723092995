import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import SaveIcon from '@mui/icons-material/Save';
import { Box, Divider, Link, Stack, styled, Typography } from '@mui/material';

import { APIs } from '../../constants/APIConstants';
import { DIVIDER_COLOR, SECONDARY } from '../../constants/Colors';
import { INTEGRATION_TYPES } from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { snackbarToggle } from '../../store/CommonReducer';
import {
  addAccountPartnerConfiguration,
  getAccountPartnerConfiguration,
  verifyAccountPartnerConfiguration,
} from '../../store/integrations/api';
import {
  resetAddAccountPartnerConfiguration,
  resetGetAccountPartnerConfiguration,
  resetVerifyAccountPartnerConfiguration,
} from '../../store/integrations/reducer';
import CustomButton from '../CommonComponents/CustomButton';
import CustomTextField from '../CommonComponents/CustomTextField';
import MainWrapper from '../CommonComponents/MainWrapper';

const StyledOrderedList = styled('ol')({
  listStyleType: 'decimal',
  paddingLeft: '16px',
  margin: 0,
});

const StyledListItem = styled('li')({
  marginBottom: '8px',
  fontWeight: 'normal',
});

const XeroIntegrations = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { control, handleSubmit, setValue, reset } = useForm();

  const { profileDetails } = useSelector((state) => state.common);
  const { isLoading, data, error } = useSelector(
    (state) => state.accountConfiguration.add
  );

  const {
    isLoading: verifyLoading,
    data: verifyData,
    error: verifyError,
  } = useSelector((state) => state.accountConfiguration.verify);

  const { isLoading: getConfigurationDataLoading, data: getConfigurationData } =
    useSelector((state) => state.accountConfiguration.get);

  useEffect(() => {
    if (!getConfigurationDataLoading && getConfigurationData) {
      reset({
        clientId: getConfigurationData?.client_id,
        clientSecret: 'Hidden client secrent ...................',
        tenantId: getConfigurationData?.tenant_id,
        webhookKey: 'Hidden web hook key ...................',
      });
    }
  }, [getConfigurationData]);

  useEffect(() => {
    dispatch(getAccountPartnerConfiguration());

    return () => {
      dispatch(resetAddAccountPartnerConfiguration());
      dispatch(resetGetAccountPartnerConfiguration());
      dispatch(resetVerifyAccountPartnerConfiguration());
    };
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (error) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: error.message,
          })
        );
      }
      if (data) {
        dispatch(getAccountPartnerConfiguration());
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: data.message,
          })
        );
      }
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (!verifyLoading) {
      if (verifyError) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: true,
            msg: verifyError.message,
          })
        );
      }
      if (verifyData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: verifyData.message,
          })
        );
      }
    }
  }, [verifyLoading, verifyData]);

  const onSubmit = (data) => (type) => {
    const requestData = {
      account_partner_type: 'XERO',
      tenant_id: data.tenantId,
      client_id: data.clientId,
      secret: data.clientSecret.includes('Hidden') ? null : data.clientSecret,
    };

    if (type === INTEGRATION_TYPES.VERIFY) {
      dispatch(verifyAccountPartnerConfiguration(requestData));
    } else {
      (requestData.webhook_key = data.webhookKey.includes('Hidden')
        ? null
        : data.webhookKey),
        dispatch(addAccountPartnerConfiguration(requestData));
    }
  };

  const handleDefaultStep = () => {
    navigate(`/${ROUTENAME.SETTINGS}/${ROUTENAME.INTEGRATIONS}`);
  };

  return (
    <>
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        <MainWrapper
          defaultPadding="0px 0px 16px 0px"
          title={t('attributes.integrations.integrations')}
          variant="body1"
          isStep={true}
          step={t('attributes.integrations.xero')}
          handleDefaultStep={handleDefaultStep}
        />
      </Box>
      <Box
        sx={{ backgroundColor: DIVIDER_COLOR, height: 'calc(100vh - 120px)' }}
      >
        <Box sx={{ backgroundColor: SECONDARY, borderRadius: '6px' }}>
          {/* Client Details */}
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
              {t('attributes.integrations.clientTitle')}
            </Typography>
            <StyledOrderedList>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint1')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint2')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint3')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint4')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint5')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint6')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint7')}
                </Typography>
              </StyledListItem>
            </StyledOrderedList>
            <Stack spacing={2} direction="row" sx={{ paddingTop: '16px' }}>
              <Controller
                name="clientId"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.clientId')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.clientId')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
              <Controller
                name="clientSecret"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.clientSecret')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.clientSecret')}
                    sx={{ width: '362px' }}
                    type={getConfigurationData ? 'password' : null}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
            </Stack>
          </Box>
          <Divider />
          {/* Tenant Details */}
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
              {t('attributes.integrations.tenantTitle')}:
            </Typography>
            <StyledOrderedList start={8}>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint8')}
                </Typography>
              </StyledListItem>
            </StyledOrderedList>
            <Box sx={{ paddingTop: '16px' }}>
              <Controller
                name="tenantId"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.tenantId')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.tenantId')}
                    sx={{ width: '362px' }}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
            </Box>
          </Box>
          <Divider />
          {/* Web hook Details */}
          <Box sx={{ padding: '16px' }}>
            <Typography variant="body1" sx={{ paddingBottom: '8px' }}>
              {t('attributes.integrations.webhookTitle')}
            </Typography>
            <StyledOrderedList start={9}>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint9')}{' '}
                  <Link
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${process.env.REACT_APP_API_END_POINT}/${process.env.REACT_APP_SERVICE_BILLING}${APIs.INTEGRATIONS.XERO_INVOICE}/${profileDetails?.company_uuid}`
                      );
                      dispatch(
                        snackbarToggle({
                          isOpen: true,
                          msg: t('common.copied'),
                          isErrorMsg: false,
                          displayIcon: false,
                        })
                      );
                    }}
                    sx={{
                      '&:hover': {
                        color: (theme) => theme.palette.primary.main,
                      },
                      cursor: 'pointer',
                    }}
                  >
                    {t('attributes.integrations.xeroPoint9_1')}
                  </Link>{' '}
                  {t('attributes.integrations.xeroPoint9_2')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint10')}
                </Typography>
              </StyledListItem>
              <StyledListItem>
                <Typography variant="body2">
                  {t('attributes.integrations.xeroPoint11')}
                </Typography>
              </StyledListItem>
            </StyledOrderedList>
            <Stack spacing={2} direction="row">
              <Controller
                name="webhookKey"
                control={control}
                rules={{
                  required: `${t('attributes.integrations.webhookKey')} ${Validation.general.required}`,
                }}
                render={({
                  field: { onChange, value, name },
                  fieldState: { error },
                }) => (
                  <CustomTextField
                    label={t('attributes.integrations.webhookKey')}
                    sx={{ width: '362px' }}
                    type={getConfigurationData ? 'password' : null}
                    value={value}
                    onChange={(e) => onChange(e)}
                    helperText={error ? error.message : ''}
                    error={!!error}
                    onClear={() => setValue(name, '')}
                    isRequired={true}
                  />
                )}
              />
            </Stack>
          </Box>
          <Divider />
          <Stack direction="row" justifyContent="end" padding="16px">
            <CustomButton
              text={
                getConfigurationData || verifyData
                  ? t('attributes.save')
                  : t('attributes.integrations.verify')
              }
              color="primary"
              sx={{ height: '52%' }}
              startIcon={<SaveIcon />}
              onClick={handleSubmit((data) =>
                onSubmit(data)(
                  getConfigurationData || verifyData
                    ? INTEGRATION_TYPES.SAVE
                    : INTEGRATION_TYPES.VERIFY
                )
              )}
              disabled={isLoading}
            />
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default XeroIntegrations;
