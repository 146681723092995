import { CloseOutlined } from '@mui/icons-material';
import { Backdrop, Box, Modal, Typography, styled } from '@mui/material';

import { WARNING, WARNING_HOVER } from '../../constants/Colors';
import CustomButton from './CustomButton';

const style = (topPosition) => ({
  position: 'absolute',
  top: topPosition, // Adjust this value as needed (e.g., '10%')
  left: '50%',
  transform: 'translate(-50%, 0)', // Remove the vertical translate
  width: 470,
  bgcolor: 'background.paper',
  borderRadius: '4px',
});

const TitleStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.divider,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px 4px 0 0',
}));

const DescriptionStyled = styled(Box)(() => ({
  padding: '16px',
}));

const FooterButton = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '16px',
  columnGap: '16px',
}));

export const ConfirmationModal = ({
  open,
  setOpen,
  title,
  description,
  onConfirm = () => {},
  topPosition = '10%',
  isDelete = false,
  onClose = () => {},
  confirmText = 'Confirm',
}) => (
  <Modal
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    open={open}
    onClose={null}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
  >
    <Box sx={style(topPosition)}>
      <TitleStyled>
        <Typography variant="h1">{title}</Typography>
        <CloseOutlined
          onClick={() => {
            setOpen(false);
            onClose();
          }}
          style={{ cursor: 'pointer' }}
        />
      </TitleStyled>
      <DescriptionStyled>
        <Typography variant="body2">{description}</Typography>
      </DescriptionStyled>
      <FooterButton>
        <CustomButton
          text="Cancel"
          color="inherit"
          onClick={() => {
            setOpen(false);
            onClose();
          }}
        />
        <CustomButton
          text={isDelete ? 'Delete' : confirmText}
          sx={{
            backgroundColor: isDelete && WARNING,
            '&:hover': {
              backgroundColor: isDelete && WARNING_HOVER,
            },
          }}
          onClick={onConfirm}
        />
      </FooterButton>
    </Box>
  </Modal>
);

export const InformativeModal = ({ title, open, description, onConfirm }) => (
  <Modal
    aria-labelledby="modal-title"
    aria-describedby="modal-description"
    open={open}
    onClose={null}
    closeAfterTransition
    slots={{ backdrop: Backdrop }}
  >
    <Box sx={{ ...style(), transform: 'translate(-50%, -50%)', top: '50%' }}>
      <TitleStyled>
        <Typography variant="h1">{title}</Typography>
      </TitleStyled>
      <DescriptionStyled>
        <Typography variant="body2">{description}</Typography>
      </DescriptionStyled>
      <FooterButton>
        <CustomButton text="Ok" onClick={onConfirm} />
      </FooterButton>
    </Box>
  </Modal>
);
