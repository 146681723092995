import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import HistoryToggleOffOutlinedIcon from '@mui/icons-material/HistoryToggleOffOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import LocalPoliceOutlinedIcon from '@mui/icons-material/LocalPoliceOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';

import {
  DATA_AMANAGEMENT_ICON_COLOR,
  DIVIDER_COLOR,
} from '../../constants/Colors';
import { GLOBAL_ADMIN } from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import Cards from '../CommonComponents/CustomCards';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const ControlPanel = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cardData = {
    [GLOBAL_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_TEMPLATES);
        },
        rightIcon: (
          <BallotOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.controlPanel.templates'),
        description: t('attributes.controlPanel.templateDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_ROUTINE_SERVICES);
        },
        rightIcon: (
          <HistoryToggleOffOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.controlPanel.routineService'),
        description: t('attributes.controlPanel.routineServiceDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_STANDARDS);
        },
        rightIcon: (
          <LocalPoliceOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.controlPanel.standards'),
        description: t('attributes.controlPanel.standardsDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_LANGUAGES);
        },
        rightIcon: (
          <LanguageOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.controlPanel.languages'),
        description: t('attributes.controlPanel.languagesDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_TIMEZONE);
        },
        rightIcon: (
          <WatchLaterOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.controlPanel.timezone'),
        description: t('attributes.controlPanel.timezoneDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_SECURITY_GROUPS);
        },
        rightIcon: (
          <WatchLaterOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.controlPanel.securityGroups'),
        description: t('attributes.controlPanel.securityGroupsDescription'),
      },
    ],
  };

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.controlPanel.title')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards cardData={cardData} role={getUserRole()} />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};

export default ControlPanel;
