import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Divider } from '@mui/material';
import { debounce } from 'lodash';

import { SECONDARY } from '../../../constants/Colors';
import { exportToCSV } from '../../../constants/common';
import {
  DATE_FORMAT_API,
  PURCHASE_ORDER_STATUS,
  PURCHASE_ORDER_STATUS_DROPDOWN,
  WORK_ORDER_STEPS,
} from '../../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../../constants/ExportConstant';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import getDropdownDataHook from '../../../hooks/getDropdownDataHook';
import useServerSideErrors from '../../../hooks/useServerSideErrors';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import {
  createPurchaseOrder,
  getPurchaseOrders,
  updatePurchaseOrder,
} from '../../../store/purchaseOrders/api';
import {
  resetCreatePurchaseOrder,
  resetUpdatePurchaseOrder,
} from '../../../store/purchaseOrders/reducer';
import { getSupplierDropdownList } from '../../../store/supplier/api';
import {
  formatDateAPI,
  formatDateForAPI,
  formatStatus,
  formattedDate,
  loggedInUserDetail,
} from '../../../utils';
import { Edit, View } from '../../CommonComponents/ActionComponent';
import Autocomplete from '../../CommonComponents/AutoComplete';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomDateRangePicker from '../../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import {
  FilterComponent,
  FilterSection,
} from '../../CommonComponents/FilterComponent';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import NewNoDataPage from '../../CommonComponents/NoDataPage/NewNoDataPage';
import NoRecordFound from '../../CommonComponents/NoDataPage/NoRecordFound';
import StyledMainWrapper from '../../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AddPurchaseOrder from './AddPurchaseOrder';
import generatePDF from './DonwloadPurchaseOrderPDF';
import PreviewPurchaseOrder from './PreviewPurchaseOrder';
import PurchaseOrderDetail from './PurchaseOrderDetail';

const defaultValues = {
  start_date: '',
  supplier_uuid: '',
  work_order_uuid: '',
  description: '',
  delivery_instruction: '',
};

//default filters
const defaultFilters = {
  supplier: null,
  status: null,
  created_at: {
    startDate: null,
    endDate: null,
  },
};

const PurchaseOrders = ({
  workOrderId,
  nextStepTitle,
  setNextStepTitle,
  isFromSideBar = false,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [hasFormValues, sethasFormValues] = useState(false);
  const [serverErrors, setServerErrors] = useState([]);
  const [open, setOpen] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [poid, setPoid] = useState();
  const [purchaseOrderDetails, setPurchaseOrderDetails] = useState({});
  const [loadingDetails, setLoadingDetails] = useState(false);
  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [filters, setFilters] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [selectedRows, setSelectedRows] = useState(null);
  const [stepTitle, setStepTitle] = useState();

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    trigger,
    setError,
    clearErrors,
    reset,
  } = useForm({ defaultValues });

  const { handleServerErrors } = useServerSideErrors(
    serverErrors,
    setError,
    clearErrors
  );

  const watchedFields = watch();

  const { purchaseOrdersList, total, totalCount } = useSelector(
    (state) => state.purchaseOrders.get
  );

  const { getDropdown } = useSelector((state) => state.supplier);

  const {
    isLoading: purchaseOrderDetailLoading,
    error: purchaseOrderDetailError,
    purchaseOrderDetail,
  } = useSelector((state) => state.purchaseOrders.updatePurchaseOrder);

  const {
    createdPurchaseOrder,
    isLoading: createdPurchaseOrderLoading,
    error,
  } = useSelector((state) => state.purchaseOrders.create);

  const filterHeight = (isFilterOpen) =>
    isFilterOpen ? 360 : isFromSideBar ? 301 : 390;

  const supplierList = getDropdownDataHook({
    data: getDropdown?.data?.data,
    labelName: 'name',
    valueName: 'uuid',
  });

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const user = loggedInUserDetail();

  // From dashboard chart
  useEffect(() => {
    if (queryStatus) {
      const selected = PURCHASE_ORDER_STATUS_DROPDOWN?.find(
        (d) => d.value === queryStatus
      );
      setFilters((prev) => ({ ...prev, status: selected }));
      setIsFilterComponentVisible(true);
    }
  }, [queryStatus]);

  useEffect(() => {
    const detail = purchaseOrdersList?.find((item) => item.poid === poid);

    if (detail) {
      setPurchaseOrderDetails(detail);
      setLoadingDetails(false);
    }
  }, [purchaseOrdersList, poid]);

  useEffect(() => {
    if (serverErrors?.length > 0) {
      handleServerErrors(); // Call the function to set the server-side errors in the form
    }
  }, [serverErrors, handleServerErrors]);

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        headerName: `${t('attributes.purchase_order.purchase_order_id')}`,
        field: 'poid',
        sortable: true,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.work_order')} ${t('common.id')}`,
        field: 'work_order_uuid',
        sortable: true,
        flex: 1,
        valueGetter: (value) => `W-${value}`,
      },
      {
        headerName: `${t('attributes.purchase_order.supplier')}`,
        field: 'supplier',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.description')}`,
        field: 'description',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.purchase_order.status')}`,
        field: 'status',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => formatStatus(row.status),
      },
      {
        headerName: `${t('common.total_amount')}`,
        field: 'totalAmount',
        sortable: false,
        flex: 1,
      },
      {
        headerName: `${t('attributes.work_order.created_on')}`,
        field: 'createdOn',
        sortable: false,
        flex: 1,
      },
      {
        field: `${t('attributes.actions')}`,
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', gap: '8px' }}>
            <View
              disabled={row?.status === PURCHASE_ORDER_STATUS.DRAFT}
              onClick={() => {
                setLoadingDetails(true);
                setPoid(row?.poid);
                setOpenPreview(true);
                if (user.company_uuid) {
                  dispatch(editCompany(user?.company_uuid));
                }
              }}
            />
            <Edit
              onClick={() => {
                setPoid(row?.poid);
                if (isFromSideBar) {
                  navigate(row.id);
                } else {
                  setNextStepTitle &&
                    setNextStepTitle({
                      title: WORK_ORDER_STEPS.purchase_orders,
                      step: row?.poid,
                    });
                  setStepTitle({
                    title: WORK_ORDER_STEPS.purchase_orders,
                    step: row?.poid,
                  });
                }

                dispatch(resetUpdatePurchaseOrder());
              }}
            />
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const getAmountByQtyAndPrice = (data) =>
    data
      ?.reduce((sum, item) => sum + item?.qty * parseFloat(item?.price || 0), 0)
      .toFixed(2);

  const rows = purchaseOrdersList?.map((item) => ({
    id: item.uuid,
    work_order_uuid: item?.work_order?.id,
    poid: item.poid,
    supplier: item.supplier?.name,
    description: item.description || '-',
    status: item.status,
    totalAmount: getAmountByQtyAndPrice(item.items) || '-',
    createdOn: formattedDate(item.creation_date) || '-',
    purchaseOrderId: item.id,
  }));

  useEffect(() => {
    dispatch(getSupplierDropdownList());

    return () => {
      dispatch(resetCreatePurchaseOrder());
      dispatch(resetUpdatePurchaseOrder());
    };
  }, []);

  // Function to fetch data based on search, pagination, and filter
  const getAllPurchaseOrder = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);

    dispatch(
      getPurchaseOrders({
        work_order_uuid: workOrderId,
        list_column_names: visibleFieldsString,
        order: order,
        orderBy: orderBy === 'poid' ? 'id' : orderBy,
        limit: workOrderId ? -1 : 1,
        supplier_uuid: filters.supplier?.value,
        status: filters.status?.value,
        page: currentPage,
        size: perPageData,
        search: searchText,
        createdAtDateFrom: filters.created_at.startDate,
        createdAtDateTo: filters.created_at.endDate,
      })
    ).finally(() => {
      setIsInitialLoading(false);
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    order,
    orderBy,
    columns,
    searchText,
    currentPage,
    perPageData,
    filters,
    refresh,
    columnVisibilityModel,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getAllPurchaseOrder, 500), [
    getAllPurchaseOrder,
  ]);

  // Call debouncedFetchData whenever search, page, or filter changes
  useEffect(() => {
    debouncedFetchData();

    // Clean up function for debounce to avoid memory leaks
    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    if (!createdPurchaseOrderLoading) {
      if (error) {
        if (error.errorDetails) {
          setServerErrors(error.errorDetails);
        }
      } else {
        if (createdPurchaseOrder) {
          setOpen(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: t(
                'attributes.purchase_order.purchase_order_created_successfully'
              ),
            })
          );
          setNextStepTitle &&
            setNextStepTitle({
              title: WORK_ORDER_STEPS.purchase_orders,
              step: createdPurchaseOrder?.poid,
            });
          setStepTitle({
            title: WORK_ORDER_STEPS.purchase_orders,
            step: createdPurchaseOrder?.poid,
          });
        }
      }
    }
  }, [error, createdPurchaseOrderLoading]);

  useEffect(() => {
    if (!purchaseOrderDetailLoading) {
      if (purchaseOrderDetailError) {
        if (purchaseOrderDetailError.errorDetails) {
          setServerErrors(purchaseOrderDetailError.errorDetails);
        }
      } else {
        if (purchaseOrderDetail) {
          setOpen(false);
          setOpenPreview(false);
          debouncedFetchData();
          dispatch(
            snackbarToggle({
              isOpen: true,
              isErrorMsg: false,
              msg: purchaseOrderDetail.message,
            })
          );
        }
      }
    }
  }, [purchaseOrderDetailLoading, purchaseOrderDetailError]);

  const onSubmit = (formData) => {
    const request = {
      creation_date: formatDateForAPI(formData?.creation_date),
      supplier_uuid: formData.supplier?.value,
      work_order_uuid: workOrderId
        ? workOrderId
        : purchaseOrderDetails?.work_order?.uuid,
      description: formData?.description,
      delivery_instruction: formData?.delivery_instruction,
    };

    if (nextStepTitle ? nextStepTitle : stepTitle) {
      dispatch(
        updatePurchaseOrder({
          purchase_order_id: formData?.uuid,
          data: { ...request, status: formData?.status },
        })
      );
    } else {
      dispatch(createPurchaseOrder(request));
    }
  };

  const handleUpdatePurchaseOrderStatus = (status) => {
    const request = {
      creation_date: formatDateAPI(
        purchaseOrderDetails?.creation_date,
        DATE_FORMAT_API
      ),
      supplier_uuid: purchaseOrderDetails.supplier?.uuid,
      work_order_uuid: workOrderId
        ? workOrderId
        : purchaseOrderDetails?.work_order?.uuid,
      description: purchaseOrderDetails?.description,
      delivery_instruction: purchaseOrderDetails?.delivery_instruction,
    };

    dispatch(
      updatePurchaseOrder({
        purchase_order_id: purchaseOrderDetails?.uuid,
        data: { ...request, status: status },
      })
    );
  };

  const onResetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const noData = (
    <>
      <NewNoDataPage
        icon={<PersonOutlineOutlinedIcon />}
        title={t('attributes.work_order.purchase_order')}
        createBtnText={t('common.create_new')}
        singularText={t('attributes.work_order.purchase_order')}
        filterHeight={380}
      />
    </>
  );

  const RenderedComponent = (
    <>
      {!isFromSideBar && (
        <>
          <Box sx={{ padding: '16px' }}>
            <CustomButton
              text={t('common.create_new')}
              color="secondary"
              sx={{ height: '52%' }}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={() => {
                setOpen(true);
                reset(defaultValues);
              }}
            />
          </Box>
          <Divider />
        </>
      )}
      {!isDataLoading && totalCount === 0 && noData}
      {isInitialLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isInitialLoading &&
        totalCount > 0 && (
          <Box>
            {isFromSideBar && (
              <Box sx={{ p: '16px' }}>
                <FilterSection
                  onFilterBtnClick={() =>
                    setIsFilterComponentVisible(!isFilterComponentVisible)
                  }
                  searchText={searchText}
                  onSearchChange={(e) => {
                    setSearchText(e.target.value);
                  }}
                  isRefresh={true}
                  onRefreshFilter={() => setRefresh(!refresh)}
                  isActive={isFilterComponentVisible}
                  onResetFilter={onResetFilter}
                  isResetButtonVisible={
                    searchText ||
                    filters.supplier ||
                    filters.status ||
                    filters.created_at.startDate ||
                    filters.created_at.endDate
                  }
                />
                {isFilterComponentVisible && (
                  <FilterComponent>
                    {isFromSideBar && (
                      <>
                        <Autocomplete
                          placeholder={t('attributes.purchase_order.supplier')}
                          options={supplierList}
                          onChange={(e, newVal) => {
                            if (newVal !== undefined) {
                              setFilters((prev) => ({
                                ...prev,
                                supplier: newVal,
                              }));
                            }
                          }}
                          value={filters?.supplier}
                          isLoadingData={getDropdown?.isLoading}
                          width="190px"
                        />
                        <Autocomplete
                          placeholder={t('attributes.property.status')}
                          options={PURCHASE_ORDER_STATUS_DROPDOWN}
                          value={filters?.status}
                          onChange={(e, newVal) =>
                            setFilters((prev) => ({
                              ...prev,
                              status: newVal,
                            }))
                          }
                          width="190px"
                        />
                        <CustomDateRangePicker
                          placeholder={t('attributes.work_order.created_on')}
                          onOkClick={(val) => {
                            setFilters((pre) => ({
                              ...pre,
                              created_at: {
                                startDate: val[0],
                                endDate: val[1],
                              },
                            }));
                          }}
                          onClear={() => {
                            setFilters((pre) => ({
                              ...pre,
                              created_at: { startDate: null, endDate: null },
                            }));
                          }}
                          fromDate={filters.created_at?.startDate}
                          toDate={filters.created_at?.endDate}
                        />
                      </>
                    )}
                  </FilterComponent>
                )}
              </Box>
            )}
            <CustomGridTable
              columns={columns}
              rows={rows || []}
              total={total}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              perPageData={perPageData}
              setPerPageData={setPerPageData}
              order={order}
              orderBy={orderBy}
              setOrder={setOrder}
              setOrderBy={setOrderBy}
              columnVisibilityModel={columnVisibilityModel}
              onRowSelectionModelChange={handleSelectionChange}
              setColumnVisibilityModel={setColumnVisibilityModel}
              noData={<NoRecordFound />}
              isLoading={isDataLoading}
              checkboxSelection={isFromSideBar ? true : false}
              paginationRequired={isFromSideBar ? true : false}
              sx={{ paddingLeft: '18px' }}
              filterHeight={filterHeight(isFilterComponentVisible)}
            />
          </Box>
        )
      )}
    </>
  );

  const MainWrapper = isFromSideBar ? (
    <StyledMainWrapper
      title={isFromSideBar ? t('attributes.work_order.purchase_order') : ' '}
      btn={
        isFromSideBar && (
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.PURCHASE_ORDER,
                  EXPORT_FILE_NAMES.PURCHASE_ORDERS,
                  COLUMNS_TO_MAP.PURCHASE_ORDERS
                );
              }
            }}
          />
        )
      }
    >
      {RenderedComponent}
    </StyledMainWrapper>
  ) : (
    <>{RenderedComponent}</>
  );

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpen(false);
            setOpenConfirmationModal(false);
          }}
        />
      )}
      {!isFromSideBar && (nextStepTitle ? nextStepTitle : stepTitle) ? (
        <PurchaseOrderDetail
          workOrderId={workOrderId}
          nextStepTitle={nextStepTitle ? nextStepTitle : stepTitle}
          setStepTitle={setStepTitle}
          isFromSideBar={isFromSideBar}
          setOpenEditPurchaseDetail={setOpen}
        />
      ) : (
        MainWrapper
      )}

      {open && (
        <SwipeableDrawer
          open={open}
          title={
            (nextStepTitle ? nextStepTitle : stepTitle)
              ? t('attributes.purchase_order.edit_purchase_order')
              : t('attributes.purchase_order.create_new_purchase_order')
          }
          onClose={() => {
            hasFormValues ? setOpenConfirmationModal(true) : setOpen(false);
          }}
          footerButton={
            <CustomButton
              startIcon={
                (nextStepTitle ? nextStepTitle : stepTitle) ? (
                  <SaveOutlinedIcon />
                ) : null
              }
              endIcon={
                (nextStepTitle ? nextStepTitle : stepTitle) ? null : (
                  <ChevronRightIcon />
                )
              }
              text={
                (nextStepTitle ? nextStepTitle : stepTitle)
                  ? t('attributes.save')
                  : t('common.next')
              }
              onClick={handleSubmit(onSubmit)}
              disabled={createdPurchaseOrderLoading}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddPurchaseOrder
            control={control}
            setValue={setValue}
            trigger={trigger}
            reset={reset}
            nextStepTitle={nextStepTitle ? nextStepTitle : stepTitle}
          />
        </SwipeableDrawer>
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={purchaseOrderDetails?.poid}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoadingCompany}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(purchaseOrderDetails, company);
              }}
            />,
            purchaseOrderDetails?.status !==
              PURCHASE_ORDER_STATUS.SUBMITTED && (
              <CustomButton
                text={t('common.submit')}
                disabled={purchaseOrderDetailLoading || isLoadingCompany}
                startIcon={<SendOutlinedIcon />}
                onClick={() =>
                  handleUpdatePurchaseOrderStatus(
                    PURCHASE_ORDER_STATUS.SUBMITTED
                  )
                }
              />
            ),
          ]}
          width={653}
        >
          <PreviewPurchaseOrder
            companyData={company}
            purchaseOrderDetails={purchaseOrderDetails}
            isLoadingCompany={isLoadingCompany}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default PurchaseOrders;
