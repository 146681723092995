import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  CUSTOMER_DEFECT_STATUS,
  DEFECT_SEVERITY,
} from '../../constants/Constants';
import { getCustomerDefectList } from '../../store/users/customer/api';
import { formatChipStatus, formatStatus, formattedDate } from '../../utils';
import { Warning } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import DefectHistoryView from './DefectHistoryView';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  status: null,
  severity: null,
  dateRange: {
    fromDate: null,
    toDate: null,
  },
};

const DefectHistoryList = ({ propertyId, assetUUID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('id');
  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [defects, setDefects] = useState(null);
  const [total, setTotal] = useState(0);
  const [selectedDefect, setSelectedDefect] = useState(null);

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.work_order.defect_id'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) =>
          row?.id ? (
            <Typography
              variant="body1"
              sx={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => setOpen(true)}
            >
              {`D-${row.id}`}
            </Typography>
          ) : (
            '-'
          ),
      },
      {
        field: 'defect_title',
        headerName: t('attributes.defects.defectTitle'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'severity',
        headerName: 'Severity',
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => formatStatus(row?.severity),
      },
      {
        field: 'raised_by',
        headerName: t('attributes.defects.raisedBy'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'description',
        headerName: t('attributes.defects.description'),
        flex: 1,
        sortable: false,
      },

      {
        field: 'created_at',
        headerName: t('attributes.defects.identifiedDate'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => formattedDate(row?.created_at),
      },
      {
        field: 'status',
        headerName: t('attributes.defects.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.status ? formatChipStatus(row.status) : '-',
      },
      {
        field: 'edit',
        headerName: t('attributes.defects.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Warning
            onClick={() => {
              setOpen(true);
              setSelectedDefect(row);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = defects?.map((defect, index) => ({
    ...defect,
    id: defect?.id || index + 1,
    severity: defect?.severity,
    property_name: defect?.property?.property_name || '-',
    asset: defect?.asset?.label || '-',
    raised_by: defect?.work_order?.technician?.technician_name || '-',
    description: defect?.defect_description,
    status: defect?.latest_status,
  }));

  const getDefects = useCallback(() => {
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getCustomerDefectList({
        limit: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        severity: filters?.severity?.value,
        status: filters?.status?.value,
        fromDate: filters?.dateRange?.fromDate,
        toDate: filters?.dateRange?.toDate,
        search: searchText,
        property_uuid: propertyId,
        asset_uuid: assetUUID,
        list_column_names: visibleFieldsString,
      })
    )
      .then((res) => {
        setDefects(res?.payload?.data?.[0] || []);
        setTotal(res?.payload?.pagination?.total_count);
      })
      .finally(() => setIsDataLoading(false));
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    filters,
    searchText,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getDefects, 500), [
    getDefects,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <RefreshDashboardButton
            label={t('attributes.defects.refresh')}
            onClick={debouncedFetchData}
          />
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters?.dateRange?.fromDate ||
                filters?.dateRange?.toDate ||
                filters?.severity ||
                filters?.status ||
                filters?.asset ||
                filters?.property_name
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.defects.severity')}
              options={DEFECT_SEVERITY}
              width="190px"
              value={filters?.severity}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, severity: newVal }))
              }
            />

            <Autocomplete
              placeholder={t('attributes.defects.status')}
              options={CUSTOMER_DEFECT_STATUS}
              width="190px"
              value={filters?.status}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, status: newVal }))
              }
            />
            <CustomDateRangePicker
              placeholder={t('attributes.defects.identifiedDate')}
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: val[0], toDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: null, toDate: null },
                }));
              }}
              fromDate={filters?.dateRange?.fromDate}
              toDate={filters?.dateRange?.toDate}
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
        paginationRequired={total > 0}
        checkboxSelection={false}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper>
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          open={open}
          title={`${t('attributes.work_order.defect')} #${selectedDefect?.id}`}
          onClose={() => {
            setOpen(false);
          }}
          width={500}
        >
          <DefectHistoryView defectUUID={selectedDefect?.uuid} />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectHistoryList;
