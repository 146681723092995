import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import AddIcon from '@mui/icons-material/Add';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import EditNoteOutlinedIcon from '@mui/icons-material/EditNoteOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { Box, Divider, Typography } from '@mui/material';

import { DIVIDER_COLOR, SECONDARY } from '../../../constants/Colors';
import {
  CREATED_STATUS,
  DRAFT_STATUS,
  PAID,
  PURCHASE_ORDER_STATUS,
} from '../../../constants/Constants';
import { SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH } from '../../../constants/Typography';
import { snackbarToggle } from '../../../store/CommonReducer';
import { editCompany } from '../../../store/company/api';
import {
  createInvoiceProduct,
  deleteInvoiceProduct,
  updateInvoice,
  updateInvoiceProduct,
} from '../../../store/invoices/api';
import {
  resetCreateInvoiceProduct,
  resetDeleteInvoiceProduct,
  resetUpdateInvoice,
  resetUpdateInvoiceProduct,
} from '../../../store/invoices/reducer';
import { getWorkOrderQuotation } from '../../../store/workOrder/api';
import { resetDefectQuotes } from '../../../store/workOrder/reducer';
import {
  formatDateAPI,
  formatStatus,
  formattedDate,
  loggedInUserDetail,
} from '../../../utils';
import { Delete, Edit } from '../../CommonComponents/ActionComponent';
import CustomButton from '../../CommonComponents/CustomButton';
import CustomGridTable from '../../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';
import MainWrapper from '../../CommonComponents/MainWrapper';
import { ConfirmationModal } from '../../CommonComponents/Modal';
import SwipeableDrawer from '../../CommonComponents/SwipeableDrawer';
import AddInvoiceProduct from './AddInvoiceProduct';
import generatePDF from './DownloadPreviewInvoice';
import EditInvoiceProduct from './EditInvoiceProduct';
import PreviewInvoice from './PreviewInvoice';

const defaultValues = {
  equipment_type: null,
  product_type: null,
  search: '',
};

const InvoiceDetail = ({
  nextStepTitle,
  setStepTitle,
  isFromSideBar,
  isFromWorkOrder,
  workOrderId,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [openAddProduct, setOpenAddProduct] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState();
  const [openPreview, setOpenPreview] = useState(false);
  const [isShowLoading, setIsShowLoading] = useState(true);
  const [hasFormValues, sethasFormValues] = useState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [selectedProductsError, setSelectedProductsError] = useState(false);
  const [disableSave, setDisableSave] = useState(false);
  const [editProductDetail, setEditProductDetail] = useState(null);
  const [editProductFormOpen, setEditProductFormOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deleteInvoiceDetail, setDeleteInvoiceDetail] = useState({});
  const [addProductFromQuote, setAddProductFromQuote] = useState(false);

  const { isLoading: isLoadingCompany, company } = useSelector(
    (state) => state.company.edit
  );

  const { invoiceList, isLoading } = useSelector((state) => state.invoices.get);
  const {
    data: createInvoiceProductData,
    isLoading: createInvoiceProductLoading,
  } = useSelector((state) => state.invoices.createProduct);

  const {
    data: updateInvoiceProductData,
    isLoading: updateInvoiceProductLoading,
  } = useSelector((state) => state.invoices.updateProduct);

  const {
    data: deleteInvoiceProductData,
    isLoading: deleteInvoiceProductLoading,
  } = useSelector((state) => state.invoices.deleteProduct);

  const { isLoading: updateInvoiceLoading } = useSelector(
    (state) => state.invoices.update
  );

  const { defectQuotesList } = useSelector(
    (state) => state.workOrder.defectQuotes
  );

  const user = loggedInUserDetail();
  const { handleSubmit, control, watch, reset, setValue, trigger } = useForm({
    defaultValues: defaultValues,
  });

  const watchedFields = watch();

  useEffect(() => {
    sethasFormValues(Object.values(watchedFields).some((value) => value));
  }, [watchedFields]);

  useEffect(() => {
    const detail = invoiceList?.find(
      (item) => item.account_partner_invoice_number === nextStepTitle.step
    );

    setInvoiceDetails(detail);
  }, [invoiceList]);

  useEffect(() => {
    setTimeout(() => {
      setIsShowLoading(false);
    }, 1000);

    return () => {
      dispatch(resetCreateInvoiceProduct());
      dispatch(resetUpdateInvoiceProduct());
      dispatch(resetDeleteInvoiceProduct());
      dispatch(resetUpdateInvoice());
      dispatch(resetDefectQuotes());
    };
  }, []);

  useEffect(() => {
    if (!createInvoiceProductLoading) {
      if (createInvoiceProductData) {
        setOpenAddProduct(false);
        setDisableSave(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: createInvoiceProductData.message,
          })
        );
      }
    }
  }, [createInvoiceProductLoading, createInvoiceProductData]);

  useEffect(() => {
    if (!updateInvoiceProductLoading) {
      if (updateInvoiceProductData) {
        setEditProductFormOpen(false);
        setDisableSave(false);
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: updateInvoiceProductData.message,
          })
        );
      }
    }
  }, [updateInvoiceProductLoading, updateInvoiceProductData]);

  useEffect(() => {
    if (!deleteInvoiceProductLoading) {
      if (deleteInvoiceProductData) {
        dispatch(
          snackbarToggle({
            isOpen: true,
            isErrorMsg: false,
            msg: deleteInvoiceProductData.message,
          })
        );
      }
    }
  }, [deleteInvoiceProductLoading, deleteInvoiceProductData]);

  useEffect(() => {
    dispatch(
      getWorkOrderQuotation({
        id: workOrderId,
      })
    );
  }, [addProductFromQuote]);

  // Calculate subtotal, tax, and total
  const total = invoiceDetails?.items?.reduce(
    (acc, item) => acc + Number(item?.price) * item?.qty,
    0
  );

  const productsIdFromQuotes = defectQuotesList?.quotation_items?.map(
    (item) => ({ uuid: item?.product_catalogue?.uuid, qty: item?.qty })
  );

  const columns = [
    {
      field: 'product',
      headerName: t('attributes.purchase_order.product'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'description',
      headerName: t('attributes.description'),
      flex: 1,
      sortable: false,
    },
    {
      field: 'qty',
      headerName: t('attributes.quantity'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'unit_price',
      headerName: t('attributes.unit_price'),
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'total',
      headerName: t('attributes.total'),
      flex: 0.5,
      sortable: false,
    },
    ...(invoiceDetails?.status === DRAFT_STATUS
      ? [
          {
            field: 'action',
            headerName: t('attributes.action'),
            flex: 0.5,
            sortable: false,
            renderCell: ({ row }) => (
              <Box sx={{ display: 'flex', gap: '8px' }}>
                <Edit
                  onClick={() => {
                    setEditProductDetail(row);
                    setEditProductFormOpen(true);
                  }}
                />
                <Delete
                  onClick={() => {
                    setDeleteConfirmation(true);
                    setDeleteInvoiceDetail({
                      invoice_uuid: row?.uuid,
                      invoice_item_uuid: row?.product_uuid,
                    });
                  }}
                />
              </Box>
            ),
          },
        ]
      : []),
  ];

  const rows = invoiceDetails?.items?.map((item, index) => ({
    id: index,
    product: item?.product_name,
    description: item?.product_description || '-',
    qty: item.qty,
    unit_price: Number(item?.price)?.toFixed(2),
    total: (Number(item?.price) * item.qty)?.toFixed(2),
    product_catalogue_uuid: item.product_catalogue_uuid,
    product_catalogue_type: item.product_catalogue_type,
    uuid: invoiceDetails.uuid,
    product_uuid: item.uuid,
    currency: item.currency,
  }));

  const handleUpdateInvoiceStatus = (status) => {
    const request = {
      type: invoiceDetails?.type,
      invoice_creation_date: formatDateAPI(invoiceDetails?.created_at),
      due_date: formatDateAPI(invoiceDetails?.due_date),
      payment_status: invoiceDetails?.payment_status,
    };

    dispatch(
      updateInvoice({
        invoice_uuid: invoiceDetails?.uuid,
        data: { ...request, status: status },
      })
    );
  };

  const onSubmit = (formData) => {
    // setDisableSave(true);

    if (!formData.selectedProducts || formData.selectedProducts.length === 0) {
      setSelectedProductsError(t('common.select_at_least_one_product'));
      setDisableSave(false);

      return;
    }

    formData.selectedProducts.forEach((product) => {
      const createProductRequest = {
        type: invoiceDetails?.type,
        routine_standard_frequency_id:
          invoiceDetails?.routine_standard_frequency_id,
        routine_standard_frequency_uuid:
          invoiceDetails?.routine_standard_frequency_id,
        product_name: product?.product_name,
        product_description: product?.description,
        price: product?.sell_price,
        qty: product?.qty,
        currency: product?.sell_currency,
        UM: product?.um,
        product_type: product?.product_catalogue_type,
        reference_product_catalogue_uuid:
          invoiceDetails?.reference_product_catalogue_uuid,
      };

      dispatch(
        createInvoiceProduct({
          invoice_uuid: invoiceDetails?.uuid,
          data: createProductRequest,
        })
      );
    });
  };

  const onSubmitEditProduct = async (formData) => {
    setDisableSave(true);
    const createData = {
      type: invoiceDetails?.type,
      routine_standard_frequency_id:
        invoiceDetails?.routine_standard_frequency_id,
      routine_standard_frequency_uuid:
        invoiceDetails?.routine_standard_frequency_id,
      product_name: formData?.product_name,
      product_description: formData?.product_description,
      price: formData?.sell_price,
      qty: formData?.qty,
      currency: formData?.currency,
      UM: formData?.um,
      product_type: formData?.product_catalogue_type,
      reference_product_catalogue_uuid:
        invoiceDetails?.reference_product_catalogue_uuid,
    };

    dispatch(
      updateInvoiceProduct({
        invoice_uuid: invoiceDetails?.uuid,
        invoice_item_uuid: formData?.product_uuid,
        data: createData,
      })
    );
  };

  return (
    <>
      {openConfirmationModal && (
        <ConfirmationModal
          title={t('confirmationModal.title')}
          description={t('confirmationModal.description')}
          open={openConfirmationModal}
          setOpen={setOpenConfirmationModal}
          onConfirm={() => {
            setOpenAddProduct(false);
            setOpenConfirmationModal(false);
            setEditProductFormOpen(false);
          }}
        />
      )}
      {deleteConfirmation && (
        <ConfirmationModal
          title={t('deleteConfirmationModal.title')}
          description={t('deleteConfirmationModal.description')}
          open={deleteConfirmation}
          setOpen={setDeleteConfirmation}
          onConfirm={() => {
            dispatch(
              deleteInvoiceProduct({
                invoice_uuid: deleteInvoiceDetail?.invoice_uuid,
                invoice_item_uuid: deleteInvoiceDetail?.invoice_item_uuid,
              })
            );
            setDeleteConfirmation(false);
          }}
        />
      )}
      <Box sx={{ backgroundColor: DIVIDER_COLOR }}>
        {isFromSideBar && (
          <MainWrapper
            defaultPadding="0px 0px 16px 0px"
            title={t('attributes.invoice.invoices')}
            variant="body1"
            isStep={true}
            step={nextStepTitle?.step}
            handleDefaultStep={() => setStepTitle()}
          ></MainWrapper>
        )}
      </Box>
      {isLoading || isShowLoading || updateInvoiceLoading ? (
        <Box
          sx={{
            width: '100%',
            mt: 2,
            display: 'flex',
            paddingTop: '200px',
            alignItems: 'center',
          }}
        >
          <CustomCircularLoader />
        </Box>
      ) : (
        !isLoading &&
        !isShowLoading &&
        !updateInvoiceLoading && (
          <Box
            sx={{
              width: '100%',
              backgroundColor: DIVIDER_COLOR,
            }}
          >
            <Box
              sx={{
                bgcolor: 'white',
                marginBottom: '16px',
                width: '100%',
                borderRadius: '4px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                <Box sx={{ display: 'flex' }}>
                  <Box sx={{ padding: '16px' }}>
                    <Typography variant="body1">
                      {nextStepTitle?.step}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      padding: '16px',
                      borderLeft: `1px solid ${DIVIDER_COLOR}`,
                      borderRight: `1px solid ${DIVIDER_COLOR}`,
                    }}
                  >
                    <Typography variant="body1">
                      {formatStatus(
                        invoiceDetails?.payment_status === PAID
                          ? PAID
                          : invoiceDetails?.status
                      )}
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Divider />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Box sx={{ padding: '16px' }}>
                    <Typography variant="body1">
                      {t('attributes.invoice.invoice_date')}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ textTransform: 'capitalize' }}
                    >
                      {formattedDate(invoiceDetails?.created_at)}
                    </Typography>
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: 'flex',
                    borderRight: `1px solid ${DIVIDER_COLOR}`,
                  }}
                >
                  <Box sx={{ padding: '16px' }}>
                    <Typography variant="body1">
                      {t('attributes.invoice.due_date')}
                    </Typography>
                    <Typography variant="body2">
                      {invoiceDetails?.due_date
                        ? formattedDate(invoiceDetails?.due_date)
                        : '-'}
                    </Typography>
                  </Box>
                </Box>

                {!isFromWorkOrder && invoiceDetails?.work_order_data && (
                  <Box
                    sx={{
                      display: 'flex',
                      borderRight: `1px solid ${DIVIDER_COLOR}`,
                    }}
                  >
                    <Box sx={{ padding: '16px' }}>
                      <Typography variant="body1">
                        {t('attributes.invoice.work_order_id')}
                      </Typography>
                      <Typography variant="body2">
                        {invoiceDetails?.work_order_data?.woid}
                      </Typography>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                borderRadius: '6px 6px 0 0',
                height: 'calc(100vh - 310px)',
                bgcolor: 'white',
              }}
            >
              {/* Table */}
              <Box sx={{ padding: '16px' }}>
                <Box
                  sx={{
                    border: `1px solid ${DIVIDER_COLOR}`,
                    borderRadius: '6px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box sx={{ display: 'flex', padding: '6px' }}>
                    {invoiceDetails?.status === PURCHASE_ORDER_STATUS.DRAFT && (
                      <>
                        {rows?.length === 0 && (
                          <CustomButton
                            text={t('attributes.purchase_order.add_from_quote')}
                            color="secondary"
                            sx={{ height: '52%' }}
                            startIcon={<AddCircleOutlineOutlinedIcon />}
                            onClick={() => {
                              setOpenAddProduct(true);
                              reset(defaultValues);
                              setAddProductFromQuote(true);
                            }}
                          />
                        )}
                        <CustomButton
                          text={t('attributes.purchase_order.add_product')}
                          color="secondary"
                          sx={{ height: '52%' }}
                          startIcon={<AddCircleOutlineOutlinedIcon />}
                          onClick={() => {
                            setOpenAddProduct(true);
                            setAddProductFromQuote(false);
                            reset(defaultValues);
                          }}
                        />
                        {/* <CustomButton
                          text="Delete"
                          color="secondary"
                          sx={{ height: '52%' }}
                          startIcon={<DeleteOutlineOutlinedIcon />}
                          onClick={() => ({})}
                        /> */}
                      </>
                    )}
                    {invoiceDetails?.status ===
                      PURCHASE_ORDER_STATUS.CREATED && (
                      <CustomButton
                        text={t('attributes.set_as_draft')}
                        color="secondary"
                        sx={{ height: '52%' }}
                        startIcon={<EditNoteOutlinedIcon />}
                        onClick={() =>
                          handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.DRAFT)
                        }
                      />
                    )}
                    {invoiceDetails?.status ===
                      PURCHASE_ORDER_STATUS.SUBMITTED && (
                      <CustomButton
                        text={t('attributes.preview')}
                        color="secondary"
                        sx={{ height: '52%' }}
                        startIcon={<RemoveRedEyeOutlinedIcon />}
                        onClick={() => {
                          setOpenPreview(true);
                          if (user.company_uuid) {
                            dispatch(editCompany(user?.company_uuid));
                          }
                        }}
                      />
                    )}
                  </Box>
                  <Divider />
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}
                  >
                    <CustomGridTable
                      columns={columns}
                      rows={rows}
                      total={rows?.length}
                      checkboxSelection={false}
                      paginationRequired={false}
                      filterHeight={460}
                      disableColumnMenu={true}
                      isLoading={false}
                      isTableView={true}
                    />
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        py: 2,
                        pr: 1,
                        gap: 1,
                        backgroundColor: 'rgba(235, 235, 235, 0.2)',
                        border: `1px solid ${DIVIDER_COLOR}`,
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.total')}:
                      </Typography>
                      <Typography variant="body1">
                        {total?.toFixed(2)}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Divider />

              {/* Footer button */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'end',
                  padding: '8px 16px 0 0',
                }}
              >
                {invoiceDetails?.status === DRAFT_STATUS && (
                  <CustomButton
                    text={t('attributes.create')}
                    startIcon={<AddIcon />}
                    disabled={
                      invoiceDetails?.items.length < 1 || updateInvoiceLoading
                    }
                    onClick={() =>
                      handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.CREATED)
                    }
                  />
                )}
                {invoiceDetails?.status === CREATED_STATUS && (
                  <Box sx={{ display: 'flex', gap: '8px' }}>
                    <CustomButton
                      text={t('attributes.preview')}
                      startIcon={<RemoveRedEyeOutlinedIcon />}
                      color="inherit"
                      onClick={() => {
                        setOpenPreview(true);
                        if (user.company_uuid) {
                          dispatch(editCompany(user?.company_uuid));
                        }
                      }}
                    />
                    <CustomButton
                      text={t('common.submit')}
                      startIcon={<SendOutlinedIcon />}
                      disabled={updateInvoiceLoading}
                      onClick={() =>
                        handleUpdateInvoiceStatus(
                          PURCHASE_ORDER_STATUS.SUBMITTED
                        )
                      }
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        )
      )}

      {openPreview && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={openPreview}
          title={invoiceDetails?.account_partner_invoice_number}
          onClose={() => {
            setOpenPreview(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.download')}
              color="inherit"
              disabled={isLoading}
              startIcon={<DownloadOutlinedIcon />}
              onClick={() => {
                generatePDF(invoiceDetails, company);
              }}
            />,
            invoiceDetails?.status !== PURCHASE_ORDER_STATUS.SUBMITTED && (
              <CustomButton
                text={t('attributes.invoice.send_invoice')}
                disabled={updateInvoiceLoading}
                startIcon={<ForwardToInboxOutlinedIcon />}
                onClick={() =>
                  handleUpdateInvoiceStatus(PURCHASE_ORDER_STATUS.SUBMITTED)
                }
              />
            ),
          ]}
          width={653}
        >
          <PreviewInvoice
            companyData={company}
            isLoadingCompany={isLoadingCompany}
            invoiceDetails={invoiceDetails}
          />
        </SwipeableDrawer>
      )}

      {openAddProduct && (
        <SwipeableDrawer
          open={openAddProduct}
          title={
            addProductFromQuote
              ? `${t('attributes.purchase_order.add_from_quote')} Q-${defectQuotesList?.id || '-'}`
              : t('attributes.purchase_order.add_product')
          }
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setOpenAddProduct(false);
          }}
          footerButton={
            <CustomButton
              text={t('attributes.add')}
              startIcon={<AddCircleOutlineOutlinedIcon />}
              onClick={handleSubmit(onSubmit)}
              disabled={disableSave}
            />
          }
          width={SWIPEABLE_DRAWER_DEFECT_QUOTE_ADD_PRODUCT_WIDTH}
        >
          <AddInvoiceProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            selectedProductsError={selectedProductsError}
            setSelectedProductsError={setSelectedProductsError}
            productsIdFromQuotes={productsIdFromQuotes}
            isFromDefectQuote={addProductFromQuote}
          />
        </SwipeableDrawer>
      )}
      {editProductDetail && (
        <SwipeableDrawer
          bgColor={SECONDARY}
          open={editProductFormOpen}
          title={`${editProductDetail?.product}`}
          onClose={() => {
            hasFormValues
              ? setOpenConfirmationModal(true)
              : setEditProductFormOpen(false);
          }}
          footerButton={[
            <CustomButton
              text={t('attributes.save')}
              // disabled={updateInvoiceProductLoading}
              startIcon={<SaveOutlinedIcon />}
              onClick={() => {
                handleSubmit(onSubmitEditProduct)();
              }}
            />,
          ]}
          width={653}
        >
          <EditInvoiceProduct
            control={control}
            watch={watch}
            reset={reset}
            setValue={setValue}
            trigger={trigger}
            data={editProductDetail}
          />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default InvoiceDetail;
