import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import { Box, styled } from '@mui/material';
import { Divider } from 'rsuite';

import { EQUIPMENT_ICONS } from '../../constants/Constants';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import ImageView from '../CommonComponents/ImageView';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const StickyControlsWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: '40px',
  right: '20px',
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
}));

const FloorPlanDetail = () => {
  const { isLoading, data } = useSelector((state) => state.floorPlan.edit);
  const containerRef = useRef(null);
  const [hasInteracted, setHasInteracted] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const calculateInitialTranslation = () => {
    const containerWidth = containerRef.current?.offsetWidth || 0;
    const containerHeight = containerRef.current?.offsetHeight || 0;

    const imageWidth = containerHeight * (384 / 805.3333333333334);
    const imageHeight = containerHeight;

    const initialPositionX = (containerWidth - imageWidth) / 2; // Center horizontally
    const initialPositionY = (containerHeight - imageHeight) / 2; // Center vertically

    return { initialPositionX, initialPositionY };
  };

  const { initialPositionX, initialPositionY } = calculateInitialTranslation();

  return (
    <StyledMainWrapper
      ref={containerRef}
      style={{
        width: '100%',
        height: `calc(100vh - 125px)`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      {isLoading && isImageLoading ? (
        <CustomCircularLoader />
      ) : (
        <>
          <TransformWrapper
            panning={{ disabled: false }}
            initialPositionX={initialPositionX} // Set initial X translation
            initialPositionY={initialPositionY}
            wheel={{ disabled: false }}
            pinch={{ disabled: false }}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <TransformComponent
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                    maxWidth: '100%',
                    maxHeight: '100%',
                  }}
                >
                  <Box
                    position="relative"
                    sx={{
                      height: `${containerRef?.current?.offsetHeight || '805.3333333333334'}px`,
                      width: `${containerRef?.current?.offsetHeight * (384 / 805.3333333333334) || '384'}px`,
                    }}
                  >
                    <ImageView
                      uuid={data?.document_uuid}
                      imageSx={{
                        height: `${containerRef?.current?.offsetHeight || '805.3333333333334'}px`,
                        width: `${containerRef?.current?.offsetHeight * (384 / 805.3333333333334) || '384'}px`,
                        objectFit: 'contain',
                      }}
                      setImageLoading={setIsImageLoading}
                    />
                    {data?.meta_data?.floorPlanAssets?.map((cords, index) => (
                      <img
                        key={index}
                        src={
                          EQUIPMENT_ICONS[cords?.asset?.equipment_type?.name]
                        }
                        style={{
                          position: 'absolute',
                          height: `${containerRef?.current?.offsetWidth * (384 / 805.3333333333334) * 0.05 || '36.88'}px`,
                          width: `${containerRef?.current?.offsetWidth * (384 / 805.3333333333334) * 0.05 || '36.88'}px`,
                          left: `${cords.positionInPercentageX}%`,
                          top: `${cords.positionInPercentageY}%`,
                          transform: 'translate(-50%, -50%)',
                        }}
                        alt={`Equipment ${cords?.asset?.equipment_type?.name}`}
                      />
                    ))}
                  </Box>
                </TransformComponent>
                <StickyControlsWrapper>
                  {/* Zoom Controls */}
                  <Box
                    height="80px"
                    width="40px"
                    backgroundColor="white"
                    boxShadow="0px 10px 20px rgba(0, 0, 0, 0.15), 0px 6px 6px rgba(0, 0, 0, 0.1)"
                    borderRadius="10px"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-between"
                    padding="8px 0"
                  >
                    <AddOutlinedIcon
                      onClick={() => zoomIn()}
                      style={{ cursor: 'pointer' }}
                    />
                    <Divider style={{ margin: '1px 0', width: '80%' }} />
                    <RemoveOutlinedIcon
                      onClick={() => zoomOut()}
                      style={{ cursor: 'pointer' }}
                    />
                  </Box>
                </StickyControlsWrapper>
              </>
            )}
          </TransformWrapper>
        </>
      )}
    </StyledMainWrapper>
  );
};

export default FloorPlanDetail;
