export const FAQS_DATA = {
  super_admin: [
    {
      id: '1',
      title: 'How can I add a new customer?',
      answer:
        'To add a new customer, go to the Customers section. There, you’ll see an Add Customer button. Click on this button to enter the customer’s details and save the new customer to the system.',
    },
    {
      id: '2',
      title: 'How can I add a new property?',
      answer:
        'To add a new property, go to the Customers section and select Properties. There, you’ll see an Add Property button. Click on this button to enter the property details and save the new property to the system.',
    },
    {
      id: '3',
      title: 'How can I add an asset within a property?',
      answer:
        'To add an asset within a property, go to the Customers section and select Properties. Choose the specific property where you want to add the asset. You’ll see an Asset option; click on it to display the list of all assets associated with that property. Then, click on the Add Asset option to enter the asset details and add the new asset to that property.',
    },
    {
      id: '4',
      title: 'Can I see the defect history?',
      answer:
        'Yes, you can view the defect history by selecting a particular asset. Once you choose the asset, the defect history associated with it will be displayed, allowing you to review past issues and actions taken.',
    },
    {
      id: '5',
      title: 'What happens after the purchase order invoice is created?',
      answer:
        'Once the purchase order invoice is created, the status of the purchase order changes to Created. At this point, you can no longer be able to edit the products. You can, however, download and submit the purchase order invoice to the supplier.',
    },
    {
      id: '6',
      title: 'Can I edit the purchase order after it is submitted?',
      answer:
        'No, once a purchase order is submitted, you are unable to add or update any line items on the purchase order.',
    },
    {
      id: '7',
      title: 'Can I download the service report?',
      answer:
        'Yes, you can download the service report. Go to the Work Orders section in the side menu and select the specific work order. From there, navigate to the Asset section and click on the icon to view the service report for the particular asset. Once the report is open, you can download it directly from there.',
    },
    {
      id: '8',
      title: 'How can I add a product to the defect quote?',
      answer:
        'To add a product to a defect quote, go to the Work Order section, select the relevant work order, and then navigate to the Defect Quote section. Choose the desired quote from the list and add the product with the necessary details. Save the changes to update the defect quote.',
    },
    {
      id: '9',
      title: 'Can I edit an invoice when the status is draft?',
      answer:
        'Yes, you can edit an invoice when its status is draft. However, once the status is set to created, you will no longer be able to make any changes to the invoice.',
    },
    {
      id: '10',
      title: 'How do I manage technicians and suppliers?',
      answer:
        'Under the Users section, you’ll find options for Technicians and Suppliers. Here, you can view and manage the details for each technician and supplier associated with your company.',
    },
    {
      id: '11',
      title: 'How can I view the timesheet?',
      answer:
        'The timesheet can be viewed in Monthly, Weekly, or Daily formats. It displays essential details such as the Work Order ID, Session Type, and Total Duration. Technicians can also filter timesheets by zones using a dropdown menu.',
    },
    {
      id: '12',
      title: 'Can I edit a submitted timesheet?',
      answer:
        'Yes, you can review, edit, and make changes to a submitted timesheet as needed. The Super Admin has options to Delete, Approve, or Save individual sessions in the timesheet.',
    },
    {
      id: '13',
      title: 'What if a technician forgets to log a session?',
      answer:
        'If a technician forgets to log a session, they must contact the office staff. The office staff can then create a new entry, submit it, and approve the session.',
    },
    {
      id: '14',
      title: 'What is a routine?',
      answer:
        'Routine refers to the inspections scheduled for assets according to a predefined service schedule. These routines help ensure that assets are regularly inspected or serviced, supporting their upkeep and compliance. The system allows users to efficiently set up and manage these routines, assign work orders to technicians, and track the progress of each scheduled work order.',
    },
    {
      id: '15',
      title: 'What is the Schedule Board?',
      answer:
        'The Schedule Board is a tool for managing and viewing scheduled work orders, appointments, and assignments for technicians within an organization. It allows you to view schedules by day, week, or month.',
    },
    {
      id: '16',
      title: 'How do I view schedules by day, week, or month?',
      answer:
        'At the top of the Schedule Board, you’ll find options to toggle between day, week, and month views. Select your preferred view to see the corresponding schedule.',
    },
    {
      id: '17',
      title: 'How can I view detailed information for a specific work order?',
      answer:
        'Click on a work order from the list to view more details, including the work order type, property location, asset count, appointment date & time, and additional comments.',
    },
    {
      id: '18',
      title: "What does 'In Review' status mean in a defect quote?",
      answer:
        "The 'In Review' status means that the defect quote is being evaluated or reviewed by the office staff. During this phase, office staff can edit the quote and add products.",
    },
    {
      id: '19',
      title: 'How do I add a new product to the catalog?',
      answer:
        "To add a new product, click on the 'Add Product' button located at the top of the Product Catalogue section. A form will appear on the right where you can enter details like Product Name and Product Type. Once you've filled in the required fields, click Save to add the product.",
    },
    {
      id: '20',
      title: 'What are the different Product Types and when should I use them?',
      answer: {
        Material:
          'Use this for products that are consumed or remain on-site after a task is completed. Example: Fire extinguishers or exit lights.',
        Equipment:
          ' Select this for products that are temporarily brought to a site and not left there. Example: Tools needed for installation.',
        Expenses:
          "Choose this for costs that aren't related to materials or equipment but are incurred for tasks. Example: Travel expenses or material delivery fees.",
        Labour:
          'This category covers charges for inspections, repairs, or replacements done by personnel. Example: Labor fees for installations.',
      },
    },
    {
      id: '21',
      title: 'Can I edit a product after adding it to the catalog?',
      answer:
        'Yes, you can edit products in the catalog. Click on the product you wish to modify, make the necessary changes in the details form, and click Save to update.',
    },
    {
      id: '22',
      title: 'How do I access the Template settings?',
      answer:
        'Go to Settings and navigate to the Template section. Here, you’ll find options to manage and customise templates for Emails, Invoices, Quotes, and Purchase Orders.',
    },
    {
      id: '23',
      title: 'Can I set a template as the default?',
      answer: 'Yes, you can set any customized template as the default.',
    },
    {
      id: '24',
      title: 'Can I edit my profile information?',
      answer:
        'No, you cannot edit or update any information in your profile. Profile details are view-only, so you can only view the existing information.',
    },
    {
      id: '25',
      title: 'How do I start importing data into the system?',
      answer:
        'To begin, go to the Import section from the Data Management and select the type of data you want to import. Now upload your data file and click on import.',
    },
    {
      id: '26',
      title: 'Where can I find the data template file?',
      answer:
        'In the Template section, select the specific data type you want to download from the dropdown menu, then click Download to get the sample template file.',
    },
    {
      id: '27',
      title: 'What should I do if my file fails validation?',
      answer:
        'If validation fails, review the error messages for each affected row, make the necessary corrections in the template file, and re-upload it to the system.',
    },
    {
      id: '28',
      title: "What does 'Pass' mean in the import status?",
      answer:
        'Pass indicates that the row has been imported successfully, and no further action is needed for that entry.',
    },
    {
      id: '29',
      title: "What does 'Fail' mean in the import status?",
      answer:
        'Fail indicates that the row did not import successfully due to an error. Review the error for the specific row, correct it in the template file, and re-upload.',
    },
    {
      id: '30',
      title: 'Can I download the file I uploaded for data import?',
      answer:
        'Yes, you can download your uploaded file after it has been processed.',
    },
    {
      id: '31',
      title:
        'What information will be included when I download my uploaded file?',
      answer:
        'The downloaded file will include a status for each row, indicating whether it Passed or Failed during the import process.',
    },
    {
      id: '32',
      title:
        'How do I manage Super Admin, Office Staff, Management, Technician and Suppliers?',
      answer:
        'Under the Users section, you’ll find options for Super Admin, Office Staff, Management, Technician and Suppliers. Here, you can add, view and manage the details for each Super Admin, Office Staff, Management, Technician and Suppliers associated with your company.',
    },
    {
      id: '33',
      title: 'How do I configure Xero?',
      answer:
        'To set up Xero, go to the Integrations section and follow the authentication steps using your Xero account credentials.',
    },
  ],
  global_admin: [
    {
      id: '1',
      title: 'What is the role of a Global Admin?',
      answer:
        'A Global Admin has top-level control and access across the system, managing user accounts, fire company setup, template customisation, integrations, data management, and monitoring alerts. This role is designed for comprehensive oversight and configuration capabilities.',
    },
    {
      id: '2',
      title: 'What can I do with User Management?',
      answer:
        'As a Global Admin, you can view the complete list of users within each company. This includes seeing their roles, and account status.',
    },
    {
      id: '3',
      title: 'How do I add a new fire company to the system?',
      answer:
        'Adding a fire company is straightforward. Go to the Fire Company section in the admin panel, fill in the required details, and save.',
    },
    {
      id: '4',
      title: 'What is the Control Panel used for?',
      answer:
        'The Control Panel allows you to set and customise templates for Emails, Invoices, Quotes, and Purchase Orders. You can configure each template to match company needs. Additionally, you have the option to set a template as the default for consistency across communications.',
    },
    {
      id: '5',
      title: 'Can I restrict certain permissions within the Global Admin role?',
      answer:
        'No, the Global Admin role is designed for full system access and control. For restricted access, consider creating or assigning users with more limited permissions.',
    },
    {
      id: '6',
      title:
        'What should I do if I encounter issues with any of the Global Admin features?',
      answer:
        'If you experience any issues, please contact our support team. They can assist with troubleshooting and provide guidance on feature usage.',
    },
    {
      id: '7',
      title: 'Can I set a template as the default?',
      answer: 'Yes, you can set any customized template as the default.',
    },
  ],
  customer: [
    {
      id: '1',
      title: 'What can I view on the Dashboard?',
      answer:
        'The Dashboard provides an overview of your properties. You can see a complete list of all your properties along with a map view that displays the location of each property. Additionally, the compliance summary for each property is shown in a bar graph format, making it easy to track the compliance status across your properties.',
    },
    {
      id: '2',
      title: 'What information can I find within each property?',
      answer:
        'Within each property, you can view a list of assets that belong to the property. For each asset, you can access detailed information such as specifications, status, and maintenance history. This allows you to have a comprehensive view of your property’s assets at any time.',
    },
    {
      id: '3',
      title: 'How do I view quotes for my property?',
      answer:
        'In the Quotes section, you can view all quotes associated with your property. The quotes are categorized by their current status, such as approved, rejected, overdue, or pending. This enables you to efficiently track and manage the progress of all quotes.',
    },
    {
      id: '4',
      title: 'What does the Invoices section show?',
      answer:
        'The Invoices section provides detailed information about the status of all invoices related to your property. You can see the pending amount, which represents any outstanding balances, as well as the amount due, which is the remaining balance on partially paid invoices.',
    },
    {
      id: '5',
      title: 'How can I manage defects in my property?',
      answer:
        'In the Defects section, you can view any defects associated with your property’s assets. For each defect, you can see detailed information, including photos that provide better context. Additionally, you have the option to submit rectification for each defect, confirming that the issue has been addressed and resolved.',
    },
    {
      id: '6',
      title: 'How do I submit rectification for a defect?',
      answer:
        'When you view the details of a defect, there is an option to submit rectification directly through the defect screen.',
    },
    {
      id: '7',
      title: 'Can I track the compliance status of my properties?',
      answer:
        'Yes, the compliance summary displayed on the Dashboard gives you a quick visual representation of the compliance status for each property, helping you stay on top of required actions and certifications.',
    },
    {
      id: '8',
      title: 'What information can I see for each asset?',
      answer:
        'In the Asset section, you can view a comprehensive list of all assets within your property. For each asset, detailed information is provided, including the type, status, and maintenance records. This helps you track the health of each asset and ensures they are regularly maintained to meet the required standards.',
    },
    {
      id: '9',
      title: 'How can I approve or reject my defect quote?',
      answer:
        'You can approve or reject your defect quote directly from the email notification you receive. Simply open the email, review the quote details, and select the option to approve or reject it as needed.',
    },
  ],
  office_staff: [
    {
      id: '1',
      title: 'How can I add a new customer?',
      answer:
        'To add a new customer, go to the Customers section. There, you’ll see an Add Customer button. Click on this button to enter the customer’s details and save the new customer to the system.',
    },
    {
      id: '2',
      title: 'How can I add a new property?',
      answer:
        'To add a new property, go to the Customers section and select Properties. There, you’ll see an Add Property button. Click on this button to enter the property details and save the new property to the system.',
    },
    {
      id: '3',
      title: 'How can I add an asset within a property?',
      answer:
        'To add an asset within a property, go to the Customers section and select Properties. Choose the specific property where you want to add the asset. You’ll see an Asset option; click on it to display the list of all assets associated with that property. Then, click on the Add Asset option to enter the asset details and add the new asset to that property.',
    },
    {
      id: '4',
      title: 'Can I see the defect history?',
      answer:
        'Yes, you can view the defect history by selecting a particular asset. Once you choose the asset, the defect history associated with it will be displayed, allowing you to review past issues and actions taken.',
    },
    {
      id: '5',
      title: 'What happens after the purchase order invoice is created?',
      answer:
        'Once the purchase order invoice is created, the status of the purchase order changes to Created. At this point, you can no longer be able to edit the products. They can, however, download and submit the purchase order invoice to the supplier.',
    },
    {
      id: '6',
      title: 'Can I edit the purchase order after it is submitted?',
      answer:
        'No, once a purchase order is submitted, you are unable to add or update any line items on the purchase order.',
    },
    {
      id: '7',
      title: 'Can I download the service report?',
      answer:
        'Yes, you can download the service report. Go to the Work Orders section in the side menu and select the specific work order. From there, navigate to the Asset section and click on the icon to view the service report for the particular asset. Once the report is open, you can download it directly from there.',
    },
    {
      id: '8',
      title: 'How can I add a product to the defect quote?',
      answer:
        'To add a product to a defect quote, go to the Work Order section, select the relevant work order, and then navigate to the Defect Quote section. Choose the desired quote from the list and add the product with the necessary details. Save the changes to update the defect quote.',
    },
    {
      id: '9',
      title: 'Can I edit an invoice when the status is draft?',
      answer:
        'Yes, you can edit an invoice when its status is draft. However, once the status is set to created, you will no longer be able to make any changes to the invoice.',
    },
    {
      id: '10',
      title: 'How do I manage technicians and suppliers?',
      answer:
        'Under the Users section, you’ll find options for Technicians and Suppliers. Here, you can add, view and manage the details for each technician and supplier associated with your company.',
    },
    {
      id: '11',
      title: 'How can I view the timesheet?',
      answer:
        'The timesheet can be viewed in Monthly, Weekly, or Daily formats. It displays essential details such as the Work Order ID, Session Type, and Total Duration. Technicians can also filter timesheets by zones using a dropdown menu.',
    },
    {
      id: '12',
      title: 'Can I edit a submitted timesheet?',
      answer:
        'Yes, you can review, edit, and make changes to a submitted timesheet as needed. The Super Admin has options to Delete, Approve, or Save individual sessions in the timesheet.',
    },
    {
      id: '13',
      title: 'What if a technician forgets to log a session?',
      answer:
        'If a technician forgets to log a session, they must contact the office staff. The office staff can then create a new entry, submit it, and approve the session.',
    },
    {
      id: '14',
      title: 'What is a routine?',
      answer:
        'Routine refers to the inspections scheduled for assets according to a predefined service schedule. These routines help ensure that assets are regularly inspected or serviced, supporting their upkeep and compliance. The system allows users to efficiently set up and manage these routines, assign work orders to technicians, and track the progress of each scheduled work order.',
    },
    {
      id: '15',
      title: 'What is the Schedule Board?',
      answer:
        'The Schedule Board is a tool for managing and viewing scheduled work orders, appointments, and assignments for technicians within an organization. It allows you to view schedules by day, week, or month.',
    },
    {
      id: '16',
      title: 'How do I view schedules by day, week, or month?',
      answer:
        'At the top of the Schedule Board, you’ll find options to toggle between day, week, and month views. Select your preferred view to see the corresponding schedule.',
    },
    {
      id: '17',
      title: 'How can I view detailed information for a specific work order?',
      answer:
        'Click on a work order from the list to view more details, including the work order type, property location, asset count, appointment date & time, and additional comments.',
    },
    {
      id: '18',
      title: "What does 'In Review' status mean in a defect quote?",
      answer:
        "The 'In Review' status means that the defect quote is being evaluated or reviewed by the office staff. During this phase, office staff can edit the quote and add products.",
    },
    {
      id: '19',
      title: 'How do I add a new product to the catalog?',
      answer:
        "To add a new product, click on the 'Add Product' button located at the top of the Product Catalogue section. A form will appear on the right where you can enter details like Product Name and Product Type. Once you've filled in the required fields, click Save to add the product.",
    },
    {
      id: '20',
      title: 'What are the different Product Types and when should I use them?',
      answer: {
        Material:
          'Use this for products that are consumed or remain on-site after a task is completed. Example: Fire extinguishers or exit lights.',
        Equipment:
          ' Select this for products that are temporarily brought to a site and not left there. Example: Tools needed for installation.',
        Expenses:
          "Choose this for costs that aren't related to materials or equipment but are incurred for tasks. Example: Travel expenses or material delivery fees.",
        Labour:
          'This category covers charges for inspections, repairs, or replacements done by personnel. Example: Labor fees for installations.',
      },
    },
    {
      id: '21',
      title: 'Can I edit a product after adding it to the catalog?',
      answer:
        'Yes, you can edit products in the catalog. Click on the product you wish to modify, make the necessary changes in the details form, and click Save to update.',
    },
  ],
  office_management: [
    {
      id: '1',
      title: 'How can I add a new customer?',
      answer:
        'To add a new customer, go to the Customers section. There, you’ll see an Add Customer button. Click on this button to enter the customer’s details and save the new customer to the system.',
    },
    {
      id: '2',
      title: 'How can I add a new property?',
      answer:
        'To add a new property, go to the Customers section and select Properties. There, you’ll see an Add Property button. Click on this button to enter the property details and save the new property to the system.',
    },
    {
      id: '3',
      title: 'How can I add an asset within a property?',
      answer:
        'To add an asset within a property, go to the Customers section and select Properties. Choose the specific property where you want to add the asset. You’ll see an Asset option; click on it to display the list of all assets associated with that property. Then, click on the Add Asset option to enter the asset details and add the new asset to that property.',
    },
    {
      id: '4',
      title: 'Can I see the defect history?',
      answer:
        'Yes, you can view the defect history by selecting a particular asset. Once you choose the asset, the defect history associated with it will be displayed, allowing you to review past issues and actions taken.',
    },
    {
      id: '5',
      title: 'What happens after the purchase order invoice is created?',
      answer:
        'Once the purchase order invoice is created, the status of the purchase order changes to Created. At this point, you can no longer be able to edit the products. You can, however, download and submit the purchase order invoice to the supplier.',
    },
    {
      id: '6',
      title: 'Can I edit the purchase order after it is submitted?',
      answer:
        'No, once a purchase order is submitted, you are unable to add or update any line items on the purchase order.',
    },
    {
      id: '7',
      title: 'Can I download the service report?',
      answer:
        'Yes, you can download the service report. Go to the Work Orders section in the side menu and select the specific work order. From there, navigate to the Asset section and click on the icon to view the service report for the particular asset. Once the report is open, you can download it directly from there.',
    },
    {
      id: '8',
      title: 'How can I add a product to the defect quote?',
      answer:
        'To add a product to a defect quote, go to the Work Order section, select the relevant work order, and then navigate to the Defect Quote section. Choose the desired quote from the list and add the product with the necessary details. Save the changes to update the defect quote.',
    },
    {
      id: '9',
      title: 'Can I edit an invoice when the status is draft?',
      answer:
        'Yes, you can edit an invoice when its status is draft. However, once the status is set to created, you will no longer be able to make any changes to the invoice.',
    },
    {
      id: '10',
      title: 'How do I manage technicians and suppliers?',
      answer:
        'Under the Users section, you’ll find options for Technicians and Suppliers. Here, you can view and manage the details for each technician and supplier associated with your company.',
    },
    {
      id: '11',
      title: 'How can I view the timesheet?',
      answer:
        'The timesheet can be viewed in Monthly, Weekly, or Daily formats. It displays essential details such as the Work Order ID, Session Type, and Total Duration. Technicians can also filter timesheets by zones using a dropdown menu.',
    },
    {
      id: '12',
      title: 'Can I edit a submitted timesheet?',
      answer:
        'Yes, you can review, edit, and make changes to a submitted timesheet as needed. The Super Admin has options to Delete, Approve, or Save individual sessions in the timesheet.',
    },
    {
      id: '13',
      title: 'What if a technician forgets to log a session?',
      answer:
        'If a technician forgets to log a session, they must contact the office staff. The office staff can then create a new entry, submit it, and approve the session.',
    },
    {
      id: '14',
      title: 'What is a routine?',
      answer:
        'Routine refers to the inspections scheduled for assets according to a predefined service schedule. These routines help ensure that assets are regularly inspected or serviced, supporting their upkeep and compliance. The system allows users to efficiently set up and manage these routines, assign work orders to technicians, and track the progress of each scheduled work order.',
    },
    {
      id: '15',
      title: 'What is the Schedule Board?',
      answer:
        'The Schedule Board is a tool for managing and viewing scheduled work orders, appointments, and assignments for technicians within an organization. It allows you to view schedules by day, week, or month.',
    },
    {
      id: '16',
      title: 'How do I view schedules by day, week, or month?',
      answer:
        'At the top of the Schedule Board, you’ll find options to toggle between day, week, and month views. Select your preferred view to see the corresponding schedule.',
    },
    {
      id: '17',
      title: 'How can I view detailed information for a specific work order?',
      answer:
        'Click on a work order from the list to view more details, including the work order type, property location, asset count, appointment date & time, and additional comments.',
    },
    {
      id: '18',
      title: "What does 'In Review' status mean in a defect quote?",
      answer:
        "The 'In Review' status means that the defect quote is being evaluated or reviewed by the office staff. During this phase, office staff can edit the quote and add products.",
    },
    {
      id: '19',
      title: 'How do I add a new product to the catalog?',
      answer:
        "To add a new product, click on the 'Add Product' button located at the top of the Product Catalogue section. A form will appear on the right where you can enter details like Product Name and Product Type. Once you've filled in the required fields, click Save to add the product.",
    },
    {
      id: '20',
      title: 'What are the different Product Types and when should I use them?',
      answer: {
        Material:
          'Use this for products that are consumed or remain on-site after a task is completed. Example: Fire extinguishers or exit lights.',
        Equipment:
          ' Select this for products that are temporarily brought to a site and not left there. Example: Tools needed for installation.',
        Expenses:
          "Choose this for costs that aren't related to materials or equipment but are incurred for tasks. Example: Travel expenses or material delivery fees.",
        Labour:
          'This category covers charges for inspections, repairs, or replacements done by personnel. Example: Labor fees for installations.',
      },
    },
    {
      id: '21',
      title: 'Can I edit a product after adding it to the catalog?',
      answer:
        'Yes, you can edit products in the catalog. Click on the product you wish to modify, make the necessary changes in the details form, and click Save to update.',
    },
  ],
};
