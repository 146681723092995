import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { PRODUCT_CATALOGUE } from '../actionPrefix';
import { axiosInstanceTechnician, axiosPropertyUrl } from '../axios';

const {
  GET_PRODUCT_CATALOGUE_list,
  GET_PRODUCT_DETAILS,
  CREATE_PRODUCT_CATALOGUE,
  UPDATE_PRODUCT_CATALOGUE,
  GET_PRODUCT_ENHANCEMENT_LIST,
} = PRODUCT_CATALOGUE;

export const getproductCatalogueList = createAsyncThunk(
  GET_PRODUCT_CATALOGUE_list,
  async (
    {
      page,
      search,
      size,
      limit,
      equipment_type,
      type,
      active,
      supplier_uuid,
      order,
      orderBy,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (size) params.append('size', size);
    if (active) params.append('status', active);
    if (limit) params.append('limit', limit);
    if (equipment_type) params.append('equipment_type', equipment_type);
    if (type) params.append('type', type);
    if (supplier_uuid) params.append('supplier_uuid', supplier_uuid);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.PRODUCT_CATALOGUE.GET_PRODUCT_CATALOGUE_LIST}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateProductCatalogue = createAsyncThunk(
  UPDATE_PRODUCT_CATALOGUE,
  async ({ uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        APIs.PRODUCT_CATALOGUE.UPDATE_PRODUCT_CATALOGUE.replace('{uuid}', uuid),
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createProductCatalogue = createAsyncThunk(
  CREATE_PRODUCT_CATALOGUE,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        APIs.PRODUCT_CATALOGUE.CREATE_PRODUCT_CATALOGUE,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const getProductDetailsById = createAsyncThunk(
  GET_PRODUCT_DETAILS,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        APIs.PRODUCT_CATALOGUE.GET_PRODUCT_CATALOGUE_DETAILS.replace(
          '{product_catalogue_uuid}',
          uuid
        )
      );

      const { data } = response;

      if (data?.data?.[0]?.reference_product_uuid) {
        const productResponse = await axiosPropertyUrl.get(
          `${APIs.PRODUCT_CATALOGUE.PRODUCT_ENHANCEMENT}?uuid=${data?.data?.[0]?.reference_product_uuid}`
        );

        return {
          data: [
            {
              ...data?.data?.[0],
              productDetails: productResponse?.data?.data?.[0] || {},
            },
          ],
        };
      } else {
        return response.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// get product enhancement list
export const getProductEnhancement = createAsyncThunk(
  GET_PRODUCT_ENHANCEMENT_LIST,
  async (
    {
      equipment_types,
      ecid,
      category1,
      category2,
      category3,
      is_ecid_null,
      is_category1_null,
      is_category2_null,
      is_category3_null,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (equipment_types) params.append('equipment_types', equipment_types);
    if (ecid) params.append('ecid', ecid);
    if (category1) params.append('category1', category1);
    if (category2) params.append('category2', category2);
    if (category3) params.append('category3', category3);
    if (is_ecid_null) params.append('is_ecid_null', is_ecid_null);
    if (is_category1_null)
      params.append('is_category1_null', is_category1_null);
    if (is_category2_null)
      params.append('is_category2_null', is_category2_null);
    if (is_category3_null)
      params.append('is_category3_null', is_category3_null);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PRODUCT_CATALOGUE.PRODUCT_ENHANCEMENT}?${params.toString()}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
