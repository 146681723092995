import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { DIVIDER_COLOR, STATUS_COLORS } from '../../constants/Colors';
import {
  CUSTOMER_QUOTATION_CHART_LABEL,
  CUSTOMER_QUOTATION_STATUS,
  DASHBOARD,
  MONTHS_DROPDOWN,
  SIX_MONTHS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import {
  getQuotationStatus,
  getQuotationTotalAmount,
  getQuotesChartByAmount,
  getQuotesStatusDropdownList,
} from '../../store/quotes/api';
import {
  resetQuotationStatusCount,
  resetQuotationTotalAmount,
} from '../../store/quotes/reducer';
import {
  formatPriceWithDecimalValue,
  getMomentDateTime,
  getXAxisLabel,
} from '../../utils';
import CustomBarChart from '../Chart/BarChart/CustomBarChart';
import CustomLineChart from '../Chart/LineChart/CustomLineChart';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import DashboardCard from '../CommonComponents/DashboardCard';
import ChartCard from '../Dashboard/ChartCard';

const defaultRangeData = {
  quoteStatusRange: MONTHS_DROPDOWN[0],
  quoteAmountRange: MONTHS_DROPDOWN[0],
};

const QuotesDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { refresh } = useOutletContext();

  const [ranges, setRanges] = useState(defaultRangeData);
  const [chartLoading, setChartLoading] = useState({
    status: false,
    amount: false,
  });

  const { PENDING, WO_CREATED_APPROVED, OVERDUE, REJECTED } =
    CUSTOMER_QUOTATION_STATUS;

  const [xAxisLabel, setXaxisLabel] = useState({
    status: [PENDING, WO_CREATED_APPROVED, OVERDUE, REJECTED],
    amount: SIX_MONTHS,
  });
  const [amountData, setAmountData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const { isLoading, data } = useSelector(
    (state) => state.quotes.quotationStatusCount
  );

  const { isLoading: totalAmountLoading, data: totalAmountData } = useSelector(
    (state) => state.quotes.quotationTotalAmount
  );

  const quoteCardData = [
    {
      title: t('common.status.pending'),
      click: true,
      value: 0,
      label: CUSTOMER_QUOTATION_STATUS.PENDING,
    },
    {
      title: t('common.status.approved'),
      value: 0,
      click: true,
      label: CUSTOMER_QUOTATION_STATUS.WO_CREATED_APPROVED,
    },
    {
      title: t('common.status.rejected'),
      value: 0,
      click: true,
      label: CUSTOMER_QUOTATION_STATUS.REJECTED,
    },
    {
      title: t('common.status.overdue'),
      value: 0,
      click: true,
      label: CUSTOMER_QUOTATION_STATUS.OVERDUE,
    },
    {
      title: t('attributes.customer.totalQuoteAmount'),
      value: 0,
      click: false,
      label: CUSTOMER_QUOTATION_STATUS.TOTAL_AMOUNT,
    },
  ];

  data &&
    data?.forEach((item) => {
      const card = quoteCardData.find((card) =>
        card.label.includes(item.status)
      );

      if (card) {
        card.value = item.count;
      }
    });
  totalAmountData &&
    (quoteCardData.at(-1).value =
      `${formatPriceWithDecimalValue(totalAmountData[0].total)}`);

  const colors = [
    STATUS_COLORS.PENDING,
    STATUS_COLORS.APPROVED,
    STATUS_COLORS.IN_PROGRESS,
    STATUS_COLORS.REJECTED,
  ];

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      const formattedStatus = status.toLowerCase().replace(' ', '-');

      navigate(
        `/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}/${formattedStatus}`
      );
    } else {
      navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.QUOTES}`);
    }
  };

  useEffect(
    () => () => {
      dispatch(resetQuotationTotalAmount);
      dispatch(resetQuotationStatusCount);
    },
    []
  );

  // Quotes Status Chart
  useEffect(() => {
    setChartLoading((pre) => ({ ...pre, status: true }));

    dispatch(
      getQuotationStatus({
        start_date: moment().subtract(ranges.quoteStatusRange?.value, 'months'),
        end_date: moment(),
      })
    )
      .then((res) => {
        let statusData = xAxisLabel?.status?.map(
          (d) => res?.payload?.data?.find((pd) => pd?.status === d)?.count || 0
        );

        setStatusData(statusData);
      })
      .finally(() => setChartLoading((pre) => ({ ...pre, status: false })));
  }, [ranges?.quoteStatusRange, refresh]);

  // Quotes Amount Chart
  useEffect(() => {
    setXaxisLabel((pre) => ({
      ...pre,
      amount: getXAxisLabel(ranges.quoteAmountRange?.value),
    }));
    setChartLoading((pre) => ({ ...pre, amount: true }));
    dispatch(
      getQuotesChartByAmount({
        start_date: moment().subtract(ranges.quoteAmountRange?.value, 'months'),
        end_date: moment(),
      })
    )
      .then((res) => {
        let amountData = [];

        for (let index = 0; index < ranges.quoteAmountRange?.value; index++) {
          amountData.push(
            res?.payload?.data?.find(
              (d) => d.month === moment().month() - index + 1
            )?.total || 0
          );
        }

        setAmountData(amountData?.reverse());
      })
      .finally(() => setChartLoading((pre) => ({ ...pre, amount: false })));
  }, [ranges?.quoteAmountRange, refresh]);

  // Quotes Status Count and Amt
  useEffect(() => {
    const req = getMomentDateTime();

    dispatch(getQuotationStatus(req));
    dispatch(getQuotesStatusDropdownList());
    dispatch(getQuotationTotalAmount(req));
  }, [dispatch, refresh]);

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {quoteCardData?.map((item) => (
            <Grid xs={12} sm={6} md={3} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={item.value}
                click={item.click}
                onClick={() => handleCardClick(item.title)}
                isLoading={isLoading || totalAmountLoading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Grid container spacing={2}>
        {/* Quote Status Chart */}
        <Grid item xs={12} md={6}>
          <ChartCard
            title={t('attributes.customer.quotesByStatus')}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.quoteStatusRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                quoteStatusRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            {chartLoading?.status ? (
              <CustomCircularLoader />
            ) : (
              <CustomBarChart
                data={statusData}
                xLabels={xAxisLabel?.status?.map(
                  (st) => CUSTOMER_QUOTATION_CHART_LABEL[st]
                )}
                colors={colors}
              />
            )}
          </ChartCard>
        </Grid>
        {/* Quote Amount Chart */}
        <Grid item xs={12} md={6}>
          <ChartCard
            title={t('attributes.customer.quoteAmount')}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.quoteAmountRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                quoteAmountRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            {chartLoading?.amount ? (
              <CustomCircularLoader />
            ) : (
              <CustomLineChart
                xAxisLabels={xAxisLabel?.amount}
                chartData={amountData}
                height={257}
                isAmount={true}
              />
            )}
          </ChartCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default QuotesDashboard;
