import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import {
  DATE_FORMAT_API,
  DATE_FORMAT_API_WITH_TIME,
} from '../../constants/Constants';
import { CUSTOMER } from '../actionPrefix';
import { axiosInstanceReporting, axiosInstanceTechnician } from '../axios';

const {
  GET_QUOTES_LIST,
  GET_QUOTES_TYPE,
  GET_QUOTES_PROPERTYNAME,
  GET_QUOTES_STATUS,
  GET_QUOTATION_STATUS,
  GET_QUOTATION_TOTAL_AMOUNT,
  GET_QUOTES_CHART_BY_AMOUNT,
} = CUSTOMER;

export const getQuotesList = createAsyncThunk(
  GET_QUOTES_LIST,
  async (
    {
      page,
      search,
      size,
      type,
      status,
      order,
      orderBy,
      fromDueDate,
      toDueDate,
      property_name,
      customer_uuid,
      propertyUUID,
      fromQuoteDate,
      toQuoteDate,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (order) params.append('sort', order);
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (type) params.append('type', type);
    if (status) params.append('status', status);
    if (customer_uuid) params.append('customer_uuid', customer_uuid);
    if (property_name) params.append('property_name', property_name);
    if (propertyUUID) params.append('property_uuid', propertyUUID);
    if (fromDueDate)
      params.append(
        'from_due_date',
        moment(fromDueDate).format(DATE_FORMAT_API)
      );
    if (toDueDate)
      params.append('to_due_date', moment(toDueDate).format(DATE_FORMAT_API));
    if (fromQuoteDate)
      params.append(
        'from_quote_date',
        moment(fromQuoteDate).format(DATE_FORMAT_API)
      );
    if (toQuoteDate)
      params.append(
        'to_quote_date',
        moment(toQuoteDate).format(DATE_FORMAT_API)
      );
    if (search) params.append('search', search);
    if (orderBy) params.append('sort_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.CUSTOMER.GET_QUOTES_LIST}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Quote Type List API
export const getQuotesTypeDropdownList = createAsyncThunk(
  GET_QUOTES_TYPE,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_QUOTES_TYPE
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Quote Property List API
export const getQuotesPropertyDropdownList = createAsyncThunk(
  GET_QUOTES_PROPERTYNAME,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_QUOTES_PROPERTYNAME
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Quote Status List API
export const getQuotesStatusDropdownList = createAsyncThunk(
  GET_QUOTES_STATUS,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceReporting.get(
        APIs.CUSTOMER.GET_QUOTES_STATUS
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getQuotationStatus = createAsyncThunk(
  GET_QUOTATION_STATUS,
  async (
    { start_date, end_date, property_uuid, type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.CUSTOMER.GET_QUOTATION_STATUS}`,
        {
          start_date: moment(start_date).format(DATE_FORMAT_API_WITH_TIME),
          end_date: moment(end_date).format(DATE_FORMAT_API_WITH_TIME),
          ...(property_uuid && { property_uuid: property_uuid }),
          ...(type && { type: type }),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getQuotationTotalAmount = createAsyncThunk(
  GET_QUOTATION_TOTAL_AMOUNT,
  async (
    { start_date, end_date, property_uuid, type },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.CUSTOMER.GET_QUOTATION_TOTAL_AMOUNT}`,
        {
          start_date: moment(start_date).format(DATE_FORMAT_API_WITH_TIME),
          end_date: moment(end_date).format(DATE_FORMAT_API_WITH_TIME),
          ...(property_uuid && { property_uuid: property_uuid }),
          ...(type && { type: type }),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getQuotesChartByAmount = createAsyncThunk(
  GET_QUOTES_CHART_BY_AMOUNT,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.CUSTOMER.GET_QUOTES_CHART_BY_AMOUNT}`,
        {
          start_date: moment(start_date).format(DATE_FORMAT_API_WITH_TIME),
          end_date: moment(end_date).format(DATE_FORMAT_API_WITH_TIME),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
