import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../../constants/Constants';
import { formatDateAPI } from '../../../utils';
import { CUSTOMER } from '../../actionPrefix';
import { axiosInstanceCompany, axiosInstanceTechnician } from '../../axios';

export const getCustomerList = createAsyncThunk(
  CUSTOMER.GET_CUSTOMERS,
  async (
    {
      page,
      search,
      page_size,
      active,
      state,
      city,
      order,
      orderBy,
      category,
      account_manager,
      list_column_names,
      limit,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (search) params.append('search', search);
    if (page) params.append('page', page);
    if (page_size) params.append('items_per_page', page_size);
    if (active) params.append('active', active);
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (category) params.append('categories', category);
    if (account_manager)
      params.append('account_manager_uuids', account_manager);
    if (list_column_names)
      params.append('list_column_names', list_column_names);
    if (limit) params.append('size', limit);

    try {
      const response = await axiosInstanceCompany.get(
        `${APIs.CUSTOMER.GET_CUSTOMERS}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getCustomerById = createAsyncThunk(
  CUSTOMER.GET_CUSTOMER_BY_ID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.CUSTOMER.GET_UPDATE_CUSTOMER.replace('{uuid}', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateCustomer = createAsyncThunk(
  CUSTOMER.UPDATE_CUSTOMER,
  async ({ uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.put(
        APIs.CUSTOMER.GET_UPDATE_CUSTOMER.replace('{uuid}', uuid),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createCustomer = createAsyncThunk(
  CUSTOMER.CREATE_CUSTOMER,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.post(
        APIs.CUSTOMER.CREATE_CUSTOMER,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Category List API
export const getCustomerCategoryDropdownList = createAsyncThunk(
  CUSTOMER.GET_CUSTOMER_CATEGORY_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceCompany.get(
        APIs.CUSTOMER.GET_CUSTOMER_CATEGORY_DROPDOWN_LIST
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Cusomer Defect Count
export const getCustomerDefectCount = createAsyncThunk(
  CUSTOMER.GET_DEFECT_COUNT,
  async ({ start_date, end_date, property_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.CUSTOMER.GET_DEFECT_COUNT}`,
        {
          from_date: formatDateAPI(start_date),
          to_date: formatDateAPI(end_date),
          ...(property_uuid && { property_uuid: property_uuid }),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Cusomer Defects
export const getCustomerDefectList = createAsyncThunk(
  CUSTOMER.GET_DEFECT_LIST,
  async (
    {
      search,
      perPageData,
      page,
      order,
      orderBy,
      property_uuid,
      asset_uuid,
      severity,
      status,
      fromDate,
      toDate,
      customer_uuid,
      list_column_names,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (search) params.append('search', search);
    if (page) params.append('page', page);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (perPageData) params.append('size', perPageData);
    if (property_uuid) params.append('propertys_uuids', property_uuid);
    if (asset_uuid) params.append('assets_uuids', asset_uuid);
    if (severity) params.append('severity', severity);
    if (status) params.append('status', status);
    if (customer_uuid) params.append('customer_uuids', customer_uuid);
    if (fromDate)
      params.append(
        'created_from',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'created_to',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.CUSTOMER.GET_DEFECT_LIST}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
