import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Box, Typography } from '@mui/material';

import { DIVIDER_COLOR, PRIMARY } from '../../constants/Colors';
import { ROUTE_NAVIGATE_CONST, redirectURL } from '../../constants/Constants';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../constants/Roles';
import { getUserRole } from '../../hoc/Permission';
import TemplatesTable from '../CommonComponents/TemplatesTable';

const Quotes = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const bredcrum = {
    [GLOBAL_ADMIN]: [
      {
        title: t('attributes.controlPanel.title'),
        onClick: () =>
          navigate(
            redirectURL[getUserRole()][ROUTE_NAVIGATE_CONST.CONTROL_PANEL]
          ),
      },
      {
        title: t('attributes.reports.dataManagement.templates'),
        onClick: () =>
          navigate(redirectURL[getUserRole()][ROUTE_NAVIGATE_CONST.TEMPLATE]),
      },
      {
        title: t('attributes.customer.quotes'),
        isLastStep: true,
      },
    ],
    [SUPER_ADMIN]: [
      {
        title: t('attributes.reports.dataManagement.templates'),
        onClick: () =>
          navigate(redirectURL[getUserRole()][ROUTE_NAVIGATE_CONST.TEMPLATES]),
      },
      {
        title: t('attributes.customer.quotes'),
        isLastStep: true,
      },
    ],
  };

  const defaultBredcrum = bredcrum[getUserRole()];

  return (
    <>
      <Box
        sx={{
          backgroundColor: DIVIDER_COLOR,
          padding: '0px 0px 16px 0px',
          display: 'flex',
          alignItems: 'center',
          columnGap: '8px',
        }}
      >
        {defaultBredcrum?.map((br) => {
          if (br?.isLastStep) {
            return <Typography variant={'h2'}>{br?.title}</Typography>;
          }
          return (
            <>
              <Typography
                variant="h2"
                onClick={br?.onClick}
                sx={{ cursor: 'pointer', color: PRIMARY }}
              >
                {br?.title}
              </Typography>
              <ArrowForwardIosIcon sx={{ height: '12px', width: '12px' }} />
            </>
          );
        })}
      </Box>
      <Box sx={{ padding: '16px' }}>
        <TemplatesTable />
      </Box>
    </>
  );
};

export default Quotes;
