import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { PROPERTIES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

export const getFloorPlanList = createAsyncThunk(
  PROPERTIES.GET_FLOOR_PLAN,
  async (
    {
      page,
      search,
      limit,
      active,
      state,
      city,
      order,
      orderBy,
      company,
      fromDate,
      toDate,
      list_column_names,
      property_uuid,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search', search);
    if (limit) params.append('limit', limit);
    if (typeof active === 'boolean') params.append('active', active); // For boolean, explicitly check
    if (state) params.append('state', state);
    if (city) params.append('city', city);
    if (order) params.append('sort_order', order);
    if (orderBy) params.append('sort_by', orderBy);
    if (company) params.append('company_name', company);
    if (fromDate)
      params.append(
        'from_date',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'to_date',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosPropertyUrl.get(
        `${APIs.PROPERTIES.GET_FLOOR_PLAN_LIST.replace(':property_uuid', property_uuid)}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getFloorPlanById = createAsyncThunk(
  PROPERTIES.GET_FLOOR_PLAN_BY_UUID,
  async (uuid, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.get(
        APIs.PROPERTIES.GET_FLOOR_PLAN.replace(':uuid', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateFloorPlan = createAsyncThunk(
  PROPERTIES.UPDATE_FLOOR_PLAN,
  async ({ uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.patch(
        APIs.PROPERTIES.UPDATE_FLOOR_PLAN.replace(':uuid', uuid),
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createFloorPlan = createAsyncThunk(
  PROPERTIES.CREATE_FLOOR_PLAN,
  async (req, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.post(
        APIs.PROPERTIES.CREATE_FLOOR_PLAN,
        req
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteFloorPlan = createAsyncThunk(
  PROPERTIES.DELETE_FLOOR_PLAN,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosPropertyUrl.delete(
        APIs.PROPERTIES.DELETE_FLOOR_PLAN.replace(':uuid', uuid)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
