import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { CUSTOMER } from '../constants/Roles';
import { ROUTENAME } from '../constants/RoutesConstants';
import { getUserRole } from './Permission';

const AuthRoute = ({ children, type }) => {
  const loggedInUserRole = getUserRole();
  const { profileDetails } = useSelector((state) => state.common);

  const permissionRoute = profileDetails?.portals?.[0]?.permissions || {};

  let hasPermission = !profileDetails;

  if (profileDetails) {
    if (Array.isArray(type)) {
      hasPermission = type.some(
        (routeType) => permissionRoute?.[routeType]?.isAccessible
      );
    } else {
      hasPermission = permissionRoute?.[type]?.isAccessible;
    }
  }

  return hasPermission ? (
    children
  ) : (
    <Navigate
      to={
        loggedInUserRole === CUSTOMER
          ? `${ROUTENAME.DASHBOARD}/${ROUTENAME.PROPERTIES}`
          : ROUTENAME.DEFAULT_ROUTE
      }
    />
  );
};

export default AuthRoute;
