import { createSlice } from '@reduxjs/toolkit';

import { WORK_ORDER_TYPE_ROUTINE } from '../../constants/Constants';
import {
  assignUnAssignWOTechnician,
  createDefectQuote,
  createWorkOrder,
  deleteDefectQuotationProduct,
  getAssetInspectionForm,
  getDefectQuotesDetail,
  getWorkOrder,
  getWorkOrderDropdownList,
  getWorkOrderList,
  getWorkOrderQuotation,
  patchDefectQuotationStatus,
  postAddDefectQuoteProduct,
  updateDefectQuotationProduct,
  updateDefectQuotationStatus,
} from './api';

const initialGetData = {
  isLoading: true,
  error: null,
  workOrderList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};
const initialDetails = {
  isLoading: true,
  error: null,
  workOrder: {},
};

const initialDefectQuoteData = {
  isLoading: true,
  error: null,
  defectQuotesList: [],
  page: 1,
  size: 10,
  total: 0,
  totalCount: 0,
};

const initialDefectQuotesDetail = {
  isLoading: null,
  error: null,
  data: [],
};

const initialAsssignTechnician = {
  isLoading: false,
  error: null,
  data: null,
};

const initialAddDefectProductData = {
  isLoading: false,
  error: false,
  data: [],
};

const initialUpdateQuotationItem = {
  isLoading: false,
  error: false,
  data: [],
};

const workOrderSlice = createSlice({
  name: 'workOrder',
  initialState: {
    get: { ...initialGetData },
    details: { ...initialDetails },
    defectQuotes: { ...initialDefectQuoteData },
    defectQuotesDetail: { ...initialDefectQuotesDetail },
    addDefectQuoteProduct: { ...initialAddDefectProductData },
    updateQuotationStatus: { ...initialDefectQuotesDetail, isLoading: false },
    updateQuotationItems: { ...initialUpdateQuotationItem },
    deleteQuotationItems: { ...initialUpdateQuotationItem },
    patchDefectQuoteStatus: { ...initialUpdateQuotationItem },
    createWorkOrder: { ...initialDefectQuotesDetail },
    workOrderDropdownList: { ...initialDefectQuotesDetail },
    assetInspectionForm: { ...initialDefectQuotesDetail },
    asssignTechnician: { ...initialAsssignTechnician },
    createDefectQuote: { ...initialAsssignTechnician },
  },
  reducers: {
    resetPagination(state) {
      state.get = { ...initialGetData };
    },
    resetWorkOrderData(state) {
      state.details = { ...initialDetails };
    },
    resetDefectQuotes(state) {
      state.defectQuotes = { ...initialDefectQuoteData };
    },
    resetDefectQuotesDetail(state) {
      state.defectQuotesDetail = { ...initialDefectQuotesDetail };
    },
    resetAddDefectProductData(state) {
      state.addDefectQuoteProduct = { ...initialAddDefectProductData };
    },
    resetUpdateQuotationItems(state) {
      state.updateQuotationItems = { ...initialUpdateQuotationItem };
    },
    resetdeleteQuotationItems(state) {
      state.deleteQuotationItems = { ...initialUpdateQuotationItem };
    },
    resetPatchDefectQuoteStatus(state) {
      state.patchDefectQuoteStatus = { ...initialUpdateQuotationItem };
    },
    resetUpdateQuotationStatus(state) {
      state.updateQuotationStatus = {
        ...initialDefectQuotesDetail,
        isLoading: false,
      };
    },
    resetCreateWorkOrder(state) {
      state.createWorkOrder = { ...initialDefectQuotesDetail };
    },
    resetWorkOrderDropdownList(state) {
      state.workOrderDropdownList = { ...initialDefectQuotesDetail };
    },
    resetAssetInspectionForm(state) {
      state.assetInspectionForm = { ...initialDefectQuotesDetail };
    },
    resetAssignTechnician(state) {
      state.asssignTechnician = { ...initialAsssignTechnician };
    },
    resetCreateDefectQuote(state) {
      state.createDefectQuote = { ...initialAsssignTechnician };
    },
  },
  extraReducers: (builder) => {
    builder
      // WorkOrder List
      .addCase(getWorkOrderList.pending, (state) => {
        state.get.isLoading = true;
      })
      .addCase(getWorkOrderList.fulfilled, (state, action) => {
        state.get.isLoading = false;

        state.get.workOrderList = action.payload.data;

        state.get.total = action.payload.pagination?.total_items;
        state.get.totalCount = action.payload.pagination?.total_count;
      })
      .addCase(getWorkOrderList.rejected, (state, action) => {
        state.get.isLoading = false;
        state.get.error = action.payload;
      })
      // Get Workorder
      .addCase(getWorkOrder.pending, (state) => {
        state.details.isLoading = true;
      })
      .addCase(getWorkOrder.fulfilled, (state, action) => {
        state.details.isLoading = false;
        state.details.workOrder =
          action.payload?.data?.length > 0 ? action.payload?.data[0] : {};
      })
      .addCase(getWorkOrder.rejected, (state, action) => {
        state.details.isLoading = false;
        state.details.error = action.payload;
      })
      // WorkOrder quotation List
      .addCase(getWorkOrderQuotation.pending, (state) => {
        state.defectQuotes.isLoading = true;
        state.defectQuotes.error = null;
      })
      .addCase(getWorkOrderQuotation.fulfilled, (state, action) => {
        state.defectQuotes.isLoading = false;
        state.defectQuotes.defectQuotesList = action.payload.data[0];

        state.defectQuotes.total = action.payload.pagination?.total_items;
        state.defectQuotes.totalCount = action.payload.pagination?.total_count;
      })
      .addCase(getWorkOrderQuotation.rejected, (state, action) => {
        state.defectQuotes.isLoading = false;
        state.defectQuotes.error = action.payload;
        state.defectQuotes.defectQuotesList = [];
      })
      // WorkOrder quotation Detail
      .addCase(getDefectQuotesDetail.pending, (state) => {
        state.defectQuotesDetail.isLoading = true;
      })
      .addCase(getDefectQuotesDetail.fulfilled, (state, action) => {
        state.defectQuotesDetail.isLoading = false;

        state.defectQuotesDetail.data = action.payload.data[0];
      })
      .addCase(getDefectQuotesDetail.rejected, (state, action) => {
        state.defectQuotesDetail.isLoading = false;
        state.defectQuotesDetail.error = action.payload;
      })
      // Create
      .addCase(postAddDefectQuoteProduct.pending, (state) => {
        state.addDefectQuoteProduct.isLoading = true;
      })
      .addCase(postAddDefectQuoteProduct.fulfilled, (state, action) => {
        state.addDefectQuoteProduct.isLoading = false;
        state.addDefectQuoteProduct.error = false;
        state.addDefectQuoteProduct.data = action.payload.data[0];
      })
      .addCase(postAddDefectQuoteProduct.rejected, (state, action) => {
        state.addDefectQuoteProduct.isLoading = false;
        state.addDefectQuoteProduct.error = action.payload;
      })

      // update quotation status
      .addCase(updateDefectQuotationStatus.pending, (state) => {
        state.updateQuotationStatus.isLoading = true;
      })
      .addCase(updateDefectQuotationStatus.fulfilled, (state, action) => {
        state.updateQuotationStatus.isLoading = false;

        state.updateQuotationStatus.data = action.payload;
      })
      .addCase(updateDefectQuotationStatus.rejected, (state, action) => {
        state.updateQuotationStatus.isLoading = false;
        state.updateQuotationStatus.error = action.payload;
      })
      // update quotation item details
      .addCase(updateDefectQuotationProduct.pending, (state) => {
        state.updateQuotationItems.isLoading = true;
      })
      .addCase(updateDefectQuotationProduct.fulfilled, (state, action) => {
        state.updateQuotationItems.isLoading = false;

        state.updateQuotationItems.data = action.payload;
      })
      .addCase(updateDefectQuotationProduct.rejected, (state, action) => {
        state.updateQuotationItems.isLoading = false;
        state.updateQuotationItems.error = action.payload;
      })

      // delete quotation item details
      .addCase(deleteDefectQuotationProduct.pending, (state) => {
        state.deleteQuotationItems.isLoading = true;
      })
      .addCase(deleteDefectQuotationProduct.fulfilled, (state, action) => {
        state.deleteQuotationItems.isLoading = false;

        state.deleteQuotationItems.data = action.payload;
      })
      .addCase(deleteDefectQuotationProduct.rejected, (state, action) => {
        state.deleteQuotationItems.isLoading = false;
        state.deleteQuotationItems.error = action.payload;
      })

      // patch quotation status
      .addCase(patchDefectQuotationStatus.pending, (state) => {
        state.patchDefectQuoteStatus.isLoading = true;
      })
      .addCase(patchDefectQuotationStatus.fulfilled, (state, action) => {
        state.patchDefectQuoteStatus.isLoading = false;

        state.patchDefectQuoteStatus.data = action.payload;
      })
      .addCase(patchDefectQuotationStatus.rejected, (state, action) => {
        state.patchDefectQuoteStatus.isLoading = false;
        state.patchDefectQuoteStatus.error = action.payload;
      })
      // create work order
      .addCase(createWorkOrder.pending, (state) => {
        state.createWorkOrder.isLoading = true;
      })
      .addCase(createWorkOrder.fulfilled, (state, action) => {
        state.createWorkOrder.isLoading = false;

        state.createWorkOrder.data = action.payload;
      })
      .addCase(createWorkOrder.rejected, (state, action) => {
        state.createWorkOrder.isLoading = false;
        state.createWorkOrder.error = action.payload;
      })
      // WorkOrderDropdown List
      .addCase(getWorkOrderDropdownList.pending, (state) => {
        state.workOrderDropdownList.isLoading = true;
      })
      .addCase(getWorkOrderDropdownList.fulfilled, (state, action) => {
        state.workOrderDropdownList.isLoading = false;

        state.workOrderDropdownList.data = action.payload.data
          ?.filter((row) => row.work_order_type === WORK_ORDER_TYPE_ROUTINE)
          .map((row) => ({
            ...row,
            work_order_dropdown_display_name: `${row.woid}-${row.work_order_name}`,
          }));
      })
      .addCase(getWorkOrderDropdownList.rejected, (state, action) => {
        state.workOrderDropdownList.isLoading = false;
        state.workOrderDropdownList.error = action.payload;
      })
      // Asset Inspection Form
      .addCase(getAssetInspectionForm.pending, (state) => {
        state.assetInspectionForm.isLoading = true;
      })
      .addCase(getAssetInspectionForm.fulfilled, (state, action) => {
        state.assetInspectionForm.isLoading = false;

        state.assetInspectionForm.data = action.payload.data;
      })
      .addCase(getAssetInspectionForm.rejected, (state, action) => {
        state.assetInspectionForm.isLoading = false;
        state.assetInspectionForm.error = action.payload;
      })
      // Assign Unassign Technician
      .addCase(assignUnAssignWOTechnician.pending, (state) => {
        state.asssignTechnician.isLoading = true;
      })
      .addCase(assignUnAssignWOTechnician.fulfilled, (state, action) => {
        state.asssignTechnician.isLoading = false;
        state.asssignTechnician.data = action.payload.data;
      })
      .addCase(assignUnAssignWOTechnician.rejected, (state, action) => {
        state.asssignTechnician.isLoading = false;
        state.asssignTechnician.error = action.payload;
      })
      .addCase(createDefectQuote.pending, (state) => {
        state.createDefectQuote.isLoading = true;
      })
      .addCase(createDefectQuote.fulfilled, (state, action) => {
        state.createDefectQuote.isLoading = false;
        state.createDefectQuote.data = action.payload;
      })
      .addCase(createDefectQuote.rejected, (state, action) => {
        state.createDefectQuote.isLoading = false;
        state.createDefectQuote.error = action.payload;
      });
  },
});

export const {
  resetPagination,
  resetWorkOrderData,
  resetDefectQuotes,
  resetDefectQuotesDetail,
  resetAddDefectProductData,
  resetUpdateQuotationStatus,
  resetUpdateQuotationItems,
  resetPatchDefectQuoteStatus,
  resetdeleteQuotationItems,
  resetCreateWorkOrder,
  resetWorkOrderDropdownList,
  resetAssetInspectionForm,
  resetAssignTechnician,
  resetCreateDefectQuote,
} = workOrderSlice.actions;

export default workOrderSlice.reducer;
