import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Card,
  Divider,
  Grid,
  Link,
  Paper,
  Typography,
} from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart, SpaceBetween } from '../../assets/commonStyled';
import {
  DARK_GREEN,
  DIVIDER_COLOR,
  PRIMARY,
  TEXT_COLOR,
  TURQUOISE,
  WARNING,
} from '../../constants/Colors';
import {
  COMPLIANCE_STATUS,
  CUSTOMER_COMPLIANCE_STATUS,
  IN_PROGRESS_STATUS,
  LABELS,
  NON_COMPLIANCE_STATUS,
} from '../../constants/Constants';
import getCityByStateHook from '../../hooks/getCityByStateHook';
import getStatesHook from '../../hooks/getStatesHook';
// import { getAccountManagerCommonDropdownList } from '../../store/accountManager/api';
import {
  getComplianceStatusDropdownList,
  getCustomerPropertyChartData,
} from '../../store/complianceStatus/api';
import {
  getDefectCount,
  getInvoiceCount,
  getProperty,
  getPropertyList,
  getPropertyListForMap,
  getQuoteCount,
} from '../../store/property/api';
import { formattedDate } from '../../utils';
import CustomBarChart from '../Chart/BarChart/CustomBarChart';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import {
  FilterButton,
  RefreshDashboardButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import GoogleMapWithCluster from '../CommonComponents/MarkerClusterer';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import PropertyDetails from '../Properties/PropertyDetails';
import ChartCard from './ChartCard';

//default filters
const defaultFilters = {
  city: null,
  state: null,
  // accoutnManager: null,
  complianceStatus: null,
  fromDate: null,
  toDate: null,
};

const colors = [PRIMARY, WARNING];

const CustomerPropertyDashboard = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { profileDetails } = useSelector((state) => state.common);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPageData, setPerPageData] = useState(5);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('property_name');

  const [searchText, setSearchText] = useState('');
  const [filters, setFilters] = useState(defaultFilters);

  const cityList = getCityByStateHook(filters.state?.value);
  const stateList = getStatesHook();

  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [propertyListForMap, setPropertyListForMap] = useState([]);
  const [markers, seMarkers] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [refresh, setRefresh] = useState(false);

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);
  const [propertyId, setPropertyId] = useState();
  const [propertyDataloading, setPropertyDataLoading] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [propertyNumId, setPropertyNumId] = useState();

  const [countsData, setCountsData] = useState({
    invoice: [],
    quotes: [],
    defects: [],
  });

  const [chartLoading, setChartLoading] = useState(false);

  const filterHeight = (isFilterOpen) => (isFilterOpen ? 650 : 580);

  // const { accountManagerDropdownLoading, accountManagerDropdownData } =
  //   getDropdownListHook({
  //     reducerName: 'accountManager',
  //     dropdownListName: 'accountManagerDropdownList',
  //     labelName: 'display_name',
  //     valueName: 'id',
  //   });

  const { propertyList } = useSelector((state) => state.property);

  const getMarkers = (data = []) =>
    data
      .filter((property) => property.latitude && property.longitude)
      .map((property) => {
        const color =
          property.property_compliance?.name === 'current'
            ? DARK_GREEN
            : property.property_compliance?.name === IN_PROGRESS_STATUS
              ? TURQUOISE
              : WARNING;

        return {
          id: property.id,
          lat: parseFloat(property.latitude),
          lng: parseFloat(property.longitude),
          tooltipText: property.property_name,
          color: color,
        };
      });

  const [selectedDuration, setSelectedDuration] = useState({
    label: 'Six Months',
    value: 6,
  });

  const dataDropdown = [
    { label: 'Monthly', value: 1 },
    { label: 'Quarterly', value: 3 },
    { label: 'Six Months', value: 6 },
    { label: 'Annually', value: 12 },
  ];
  const handlePropertyDetaiAPICall = (id) => {
    dispatch(getProperty(id))
      .then((response) => {
        setSelectedProperty(response.payload?.data?.[0]); // Set the complete property data
      })
      .catch((error) => {
        console.error('Error fetching property data:', error);
      })
      .finally(() => {
        setPropertyDataLoading(false); // Set loading to false whether success or error occurs
      });
  };

  const StatusBox = ({ color, text }) => (
    <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center' }}>
      <Box
        sx={{
          height: '12px',
          width: '12px',
          background: color,
          borderRadius: '50%',
        }}
      />
      <Typography variant="body1" color={color}>
        {text}
      </Typography>
    </Box>
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'property_name',
        headerName: t('attributes.name'),
        flex: 1.5,
        renderCell: ({ row }) => (
          <Link to="#">
            <Typography
              variant="body1"
              sx={{ cursor: 'pointer' }}
              color={TEXT_COLOR}
              onClick={() => {
                setPropertyDataLoading(true);
                setPropertyNumId(row?.id);
                setPropertyId(row?.uuid);
                handlePropertyDetaiAPICall(row?.uuid);
              }}
            >
              {row?.property_name}
            </Typography>
          </Link>
        ),
      },
      {
        field: 'address',
        headerName: t('attributes.address'),
        flex: 1.5,
        sortable: false,
      },
      {
        field: 'Compliance Status',
        headerName: t('attributes.customer.compliance_status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row?.complience_status ? (
            <StatusBox
              color={row?.complience_status?.color}
              text={row?.complience_status?.text}
            />
          ) : (
            '-'
          ),
      },
      {
        field: 'invoices',
        headerName: t('attributes.customer.invoices'),
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'quotes',
        headerName: t('attributes.customer.quotes'),
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'defects',
        headerName: t('attributes.customer.defects'),
        flex: 0.5,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.customer.created_at'),
        flex: 1,
        valueFormatter: (params) => (params ? formattedDate(params) : ''),
      },
      {
        field: 'actions',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        hideable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setPropertyDataLoading(true);
              setPropertyNumId(row?.id);
              setPropertyId(row?.uuid);
              handlePropertyDetaiAPICall(row?.uuid);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  const getAllProperties = useCallback(() => {
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    setIsDataLoading(true);
    dispatch(
      getPropertyList({
        size: perPageData,
        page: currentPage,
        search: searchText,
        order: order,
        orderBy: orderBy,
        state: filters.state?.value,
        city: filters.city?.value,
        // accountManager: filters?.accoutnManager?.value,
        latest_compliance_status: filters?.complianceStatus?.value,
        list_column_names: visibleFieldsString,
        fromDate: filters?.fromDate,
        toDate: filters?.toDate,
      })
    ).finally(() => {
      setIsDataLoading(false);
    });
  }, [
    dispatch,
    perPageData,
    currentPage,
    order,
    orderBy,
    filters,
    searchText,
    columns,
    columnVisibilityModel,
    refresh,
  ]);

  useEffect(() => {
    seMarkers(getMarkers(propertyListForMap));
  }, [propertyListForMap, refresh]);

  useEffect(() => {
    // dispatch(getAccountManagerCommonDropdownList());
    dispatch(getComplianceStatusDropdownList());
    dispatch(
      getPropertyListForMap({
        size: 100,
        page: currentPage,
      })
    )
      .then((res) => {
        setPropertyListForMap(res.payload.data);
      })
      .finally(() => {});
  }, [dispatch, refresh]);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  const debouncedFetchData = useCallback(debounce(getAllProperties, 500), [
    getAllProperties,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  const rows = propertyList?.data?.data?.map((pl, index) => {
    const { invoice, quotes, defects } = countsData;

    const invoiceCount = invoice
      ?.map((i) => i.property_uuid)
      ?.includes(pl?.uuid)
      ? invoice?.find((i) => i.property_uuid === pl?.uuid)?.count
      : 0;

    const quoteCount = quotes?.map((i) => i.property_uuid)?.includes(pl?.uuid)
      ? quotes?.find((i) => i.property_uuid === pl?.uuid)?.count
      : 0;

    const defectCount = defects?.map((i) => i.property_uuid)?.includes(pl?.uuid)
      ? defects?.find((i) => i.property_uuid === pl?.uuid)?.count
      : 0;

    return {
      id: pl.id,
      uuid: pl?.uuid,
      property_name: pl?.property_name,
      address: pl?.address,
      complience_status: pl?.latest_compliance_status && {
        text:
          pl?.latest_compliance_status === COMPLIANCE_STATUS
            ? LABELS.COMPLIANCE_STATUS
            : LABELS.NON_COMPLIANCE_STATUS,
        color:
          pl?.latest_compliance_status === COMPLIANCE_STATUS
            ? PRIMARY
            : WARNING,
      },
      invoices: invoiceCount,
      quotes: quoteCount,
      defects: defectCount,
      created_at: pl?.created_at,
      actions: <></>,
    };
  });

  useEffect(() => {
    setChartLoading(true);

    dispatch(getCustomerPropertyChartData())
      .then((res) => {
        let statusData = [COMPLIANCE_STATUS, NON_COMPLIANCE_STATUS].map(
          (d) =>
            res?.payload?.data?.find((pd) => pd?.compliance_status === d)
              ?.count || 0
        );

        setChartData(statusData);
      })
      .finally(() => setChartLoading(false));

    dispatch(getInvoiceCount()).then((res) =>
      setCountsData((prev) => ({ ...prev, invoice: res?.payload?.data }))
    );
    dispatch(getQuoteCount()).then((res) =>
      setCountsData((prev) => ({ ...prev, quotes: res?.payload?.data }))
    );
    dispatch(getDefectCount()).then((res) =>
      setCountsData((prev) => ({ ...prev, defects: res?.payload?.data }))
    );
  }, [dispatch, refresh]);

  const handleDefaultStep = () => {
    const currentPath = location.pathname;

    navigate(currentPath, { replace: true });
    setPropertyId('');
    setIsFilterComponentVisible(false);
    resetFilter();
  };

  return propertyId ? (
    <PropertyDetails
      propertyId={propertyId}
      selectedProperty={selectedProperty}
      propertyDataloading={propertyDataloading}
      isEditable={false}
      propertyNumId={propertyNumId}
      handleDefaultStep={handleDefaultStep}
    />
  ) : (
    <Box
      sx={{
        bgcolor: DIVIDER_COLOR,
        height: '100%',
      }}
    >
      <SpaceBetween sx={{ flexWrap: 'wrap', padding: '8px' }}>
        {t('common.welcome')}, {profileDetails?.customer_name || '-'}
        <RefreshDashboardButton onClick={() => setRefresh(!refresh)} />
      </SpaceBetween>

      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Card sx={{ height: '100%' }}>
            <Typography variant="body1" sx={{ padding: '8px' }}>
              All Properties
            </Typography>
            <Divider />
            <Box sx={{ height: '340px', padding: '8px' }}>
              {markers ? (
                <GoogleMapWithCluster groupedMarkers={markers} />
              ) : (
                <CustomCircularLoader />
              )}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <ChartCard
            title="Compliance Summary by Properties"
            isEditableChart={false}
            height="340px"
            isSettingMode={false}
          >
            {chartLoading ? (
              <CustomCircularLoader />
            ) : (
              <CustomBarChart
                colors={colors}
                data={chartData}
                xLabels={[
                  LABELS.COMPLIANCE_STATUS,
                  LABELS.NON_COMPLIANCE_STATUS,
                ]}
                isFullLabel={true}
                isYAxis={false}
              />
            )}
          </ChartCard>
        </Grid>
      </Grid>

      <Paper sx={{ marginTop: '16px' }}>
        <SpaceBetween sx={{ padding: '8px', flexWrap: 'wrap', rowGap: '8px' }}>
          <Typography variant="body1">All Properties Snapshot</Typography>
          <FlexEnd sx={{ flexWrap: 'wrap-reverse', rowGap: '8px' }}>
            <ResetFilterButton
              onClick={resetFilter}
              disabled={
                !(
                  searchText ||
                  filters.state ||
                  filters.city ||
                  filters.fromDate ||
                  filters.toDate ||
                  // filters.accoutnManager ||
                  filters.complianceStatus
                )
              }
            />
            <FilterButton
              onClick={() =>
                setIsFilterComponentVisible(!isFilterComponentVisible)
              }
              isActive={isFilterComponentVisible}
            />
            <CustomSearch
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
            />
          </FlexEnd>
        </SpaceBetween>

        <Divider />
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              borderRadius: '4px',
              margin: '8px',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.customer.compliance_status')}
              options={CUSTOMER_COMPLIANCE_STATUS}
              width="190px"
              value={filters?.complianceStatus}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, complianceStatus: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('common.state')}
              options={stateList}
              width="190px"
              value={filters?.state}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, state: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('common.city')}
              options={cityList}
              width="190px"
              value={filters?.city}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, city: newVal }))
              }
              // disabledDropdown={!filters.state}
            />
            {/* <Autocomplete
              placeholder={t('attributes.account_manager.account_manager')}
              options={accountManagerDropdownData}
              value={filters?.accoutnManager}
              onChange={(e, newVal) =>
                setFilters((prev) => ({ ...prev, accoutnManager: newVal }))
              }
              width="190px"
              isLoadingData={accountManagerDropdownLoading}
            /> */}
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  fromDate: val[0],
                  toDate: val[1],
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({ ...pre, toDate: null, fromDate: null }));
              }}
              fromDate={filters?.fromDate}
              toDate={filters?.toDate}
              placement="topEnd"
            />
          </FlexStart>
        )}
        <CustomGridTable
          columns={columns}
          rows={rows}
          total={propertyList?.data?.total}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          rowsPerPageOptions={[5, 10, 20]}
          perPageData={perPageData}
          setPerPageData={setPerPageData}
          order={order}
          orderBy={orderBy}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          isLoading={isDataLoading}
          noData={<NoRecordFound />}
          checkboxSelection={false}
          columnVisibilityModel={columnVisibilityModel}
          setColumnVisibilityModel={setColumnVisibilityModel}
          filterHeight={filterHeight(isFilterComponentVisible)}
        />
      </Paper>
    </Box>
  );
};

export default CustomerPropertyDashboard;
