import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../../constants/Constants';
import { formatDateAPI } from '../../../utils';
import { BILLING_CONTRACT } from '../../actionPrefix';
import { axiosInstanceBilling } from '../../axios';

// Get Contract List
export const getBillingContractList = createAsyncThunk(
  BILLING_CONTRACT.GET_BILLING_CONTRACT_LIST,
  async (
    {
      page,
      search,
      page_size,
      active,
      order,
      orderBy,
      category,
      customerUUID,
      list_column_names,
      propertyUUID,
      contractStartDate,
      contractValidTill,
      createdAt,
      uuid,
      createdFrom,
      createdTo,
      contractStartDateFrom,
      contractStartDateTo,
      contractValidTillDateFrom,
      contractValidTillDateTo,
      limit,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    if (typeof active === 'boolean') {
      params.append('is_active', active);
    }
    // Add params conditionally
    if (search) params.append('search', search);
    if (page) params.append('page', page);
    if (page_size) params.append('size', page_size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (category) params.append('categories', category);
    if (customerUUID) params.append('customer_uuid', customerUUID);
    if (propertyUUID) params.append('property_uuid', propertyUUID);
    if (contractStartDate)
      params.append('contract_start_date', contractStartDate);
    if (contractValidTill)
      params.append('contract_valid_till', contractValidTill);
    if (createdAt) params.append('created_at', createdAt);
    if (list_column_names)
      params.append('list_column_names', list_column_names);
    if (uuid) params.append('uuid', uuid);
    // Note: For Contract Start and Valid Till date will use the simple contract date without time so no need to convert to UTC and Local.
    if (contractStartDateFrom)
      params.append(
        'contract_start_date__gte',
        moment(contractStartDateFrom).format(DATE_RANGE_FORMAT_API)
      );
    if (contractStartDateTo)
      params.append(
        'contract_start_date__lte',
        moment(contractStartDateTo).endOf('day').format(DATE_RANGE_FORMAT_API)
      );
    if (contractValidTillDateFrom)
      params.append(
        'contract_end_date__gte',
        moment(contractValidTillDateFrom).format(DATE_RANGE_FORMAT_API)
      );
    if (contractValidTillDateTo)
      params.append(
        'contract_end_date__lte',
        moment(contractValidTillDateTo)
          .endOf('day')
          .format(DATE_RANGE_FORMAT_API)
      );
    if (createdFrom)
      params.append(
        'created_at__gte',
        formatDateAPI(moment(createdFrom).format(DATE_RANGE_FORMAT_API))
      );
    if (createdTo)
      params.append(
        'created_at__lte',
        formatDateAPI(
          moment(createdTo).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );

    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.BILLING_CONTRACT.GET_BILLING_CONTRACT_LIST}?${params.toString()}`
      );

      return { ...response.data, active };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Create Contract
export const createBillingContract = createAsyncThunk(
  BILLING_CONTRACT.CREATE_BILLING_CONTRACT_LIST,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.BILLING_CONTRACT.CREATE_BILLING_CONTRACT_LIST,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Get Contract By ID
export const getBillingContractById = createAsyncThunk(
  BILLING_CONTRACT.GET_BILLING_CONTRACT_BY_ID,
  async ({ billing_contract_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.get(
        `${APIs.BILLING_CONTRACT.GET_BILLING_CONTRACT_BY_ID.replace(':billing_contract_uuid', billing_contract_uuid)}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update Contract By ID
export const updateBillingContractById = createAsyncThunk(
  BILLING_CONTRACT.UPDATE_BILLING_CONTRACT_BY_ID,
  async ({ contract_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.put(
        `${APIs.BILLING_CONTRACT.UPDATE_BILLING_CONTRACT_BY_ID.replace(':contract_uuid', contract_uuid)}`,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Update Contract Details By ID
export const updateBillingContractDetails = createAsyncThunk(
  BILLING_CONTRACT.UPDATE_BILLING_CONTRACT_DETAILS,
  async ({ contract_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.put(
        `${APIs.BILLING_CONTRACT.UPDATE_BILLING_CONTRACT_DETAILS.replace(':contract_uuid', contract_uuid)}`,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
