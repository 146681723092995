import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { SUPER_ADMIN } from '../actionPrefix';
import { axiosInstanceBilling } from '../axios';

const {
  ACCOUNT_PARTNER_CONFIGURATION,
  GET_PARTNER_CONFIGURATION,
  VERIFY_PARTNER_CONFIGURATION,
} = SUPER_ADMIN;

export const addAccountPartnerConfiguration = createAsyncThunk(
  ACCOUNT_PARTNER_CONFIGURATION,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.INTEGRATIONS.ACCOUNT_PARTNER_CONFIGURATION,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAccountPartnerConfiguration = createAsyncThunk(
  GET_PARTNER_CONFIGURATION,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.get(
        APIs.INTEGRATIONS.ACCOUNT_PARTNER_CONFIGURATION,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const verifyAccountPartnerConfiguration = createAsyncThunk(
  VERIFY_PARTNER_CONFIGURATION,
  async (data, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceBilling.post(
        APIs.INTEGRATIONS.VERIFY_ACCOUNT_PARTNER_CONFIGURATION,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
