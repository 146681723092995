import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';

import { DarkLogo } from '../../../assets/images';
import { DIVIDER_COLOR } from '../../../constants/Colors';
import {
  ASSETS_STATUS_REPORT_LABEL,
  SCHEDULE_BOARD_MONTH,
} from '../../../constants/Constants';
import { formatDate } from '../../../utils';
import CustomCircularLoader from '../../CommonComponents/CustomLoader';

const InvoiceContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const InvoiceHeader = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  border: `1px solid ${DIVIDER_COLOR}`,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  border: `1px solid ${DIVIDER_COLOR}`,
}));

const AssetServiceReport = ({
  isLoading,
  inspectionFormData,
  property,
  asset,
  work_order_name,
  frequency,
}) => {
  const { t } = useTranslation();

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <InvoiceContainer>
      <Box display="flex" justifyContent="space-between">
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          flexDirection="column"
          gap="8px"
        >
          <InvoiceHeader>
            <Typography minWidth="130px" variant="body1">
              {t('attributes.customer.customer')} {t('attributes.name')}:
            </Typography>
            <Typography variant="body2">{property?.customer || '-'}</Typography>
          </InvoiceHeader>
          <InvoiceHeader>
            <Typography minWidth="130px" variant="body1">
              {t('attributes.property.property')} {t('attributes.name')}:
            </Typography>
            <Typography variant="body2">
              {property?.property_name || '-'}
            </Typography>
          </InvoiceHeader>
          <InvoiceHeader>
            <Typography minWidth="130px" variant="body1">
              {t('attributes.property.property')} {t('attributes.address')}:
            </Typography>
            <Typography variant="body2" maxWidth={200}>
              {property?.zip_code
                ? `${property?.address || ''}, ${property?.city || ''} ${property?.state || ''} ${property?.zip_code || ''}`
                : '-'}
            </Typography>
          </InvoiceHeader>
          <InvoiceHeader>
            <Typography minWidth="130px" variant="body1">
              {t('attributes.work_order.work_order_name')}:
            </Typography>
            <Typography variant="body2">{work_order_name || '-'}</Typography>
          </InvoiceHeader>
          <InvoiceHeader>
            <Typography minWidth="130px" variant="body1">
              {t('attributes.property.frequency')}:
            </Typography>
            <Typography variant="body2">{frequency || '-'}</Typography>
          </InvoiceHeader>
        </Box>
        <Box>
          <img src={DarkLogo} alt="Fire Logo" />
        </Box>
      </Box>
      <Box border={`1px solid ${DIVIDER_COLOR}`} marginTop={'24px'}>
        <Table>
          <TableHead>
            <TableRow>
              <StyledTableHeaderCell>
                {t('attributes.property.asset')}/{t('attributes.location')}
              </StyledTableHeaderCell>
              <StyledTableHeaderCell sx={{ textAlign: 'center' }}>
                {t('attributes.reports.result')}
              </StyledTableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <Typography variant="body1" minWidth="300px">
                  {asset?.asset?.label || '-'}
                </Typography>
                <Typography variant="body2">
                  {asset?.asset?.location || '-'}
                </Typography>
                <Typography variant="body2">
                  {t('attributes.last')} {t('attributes.property.service')}:{' '}
                  {formatDate(asset?.inspected_time, SCHEDULE_BOARD_MONTH)}
                </Typography>
              </StyledTableCell>
              <StyledTableCell sx={{ textAlign: 'center' }}>
                <Typography variant="body2">
                  {ASSETS_STATUS_REPORT_LABEL[asset?.status?.toLowerCase()]}
                </Typography>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
        {inspectionFormData?.map((data) => (
          <Box padding="8px 0px 8px 16px">
            <Typography variant="body1">{data?.title}</Typography>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="body2" maxWidth={'300px'}>
                  {data?.description}
                </Typography>
                <br />
                <Typography variant="body2">{data?.comment}</Typography>
                <br />
                <Typography variant="body2">{data?.result}</Typography>
              </Box>

              <RadioGroup sx={{ display: 'flex', flexDirection: 'row' }}>
                <FormControlLabel
                  value="0"
                  control={
                    <Radio
                      checked={'0' === data?.radio_checked}
                      contentEditable={false}
                    />
                  }
                  label="Pass"
                />
                <FormControlLabel
                  value="1"
                  control={
                    <Radio
                      checked={'1' === data?.radio_checked}
                      contentEditable={false}
                      color="error"
                    />
                  }
                  label="Fail"
                />
                <FormControlLabel
                  value="2"
                  control={
                    <Radio
                      checked={'2' === data?.radio_checked}
                      contentEditable={false}
                      color="default"
                    />
                  }
                  label="No Check"
                  checked={'2' === data?.radio_checked}
                />
              </RadioGroup>
            </Box>
          </Box>
        ))}
      </Box>
    </InvoiceContainer>
  );
};

export default AssetServiceReport;
