import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Box, Menu, MenuItem, Modal, Typography, styled } from '@mui/material';

import { COMPLETED_STATUS, RE_VISIT } from '../../constants/Constants';
import { formatStatus } from '../../utils';
import CustomButton from '../CommonComponents/CustomButton';
import CustomTextField from '../CommonComponents/CustomTextField';
import { ConfirmationModal } from '../CommonComponents/Modal';

const FooterButton = styled(Box)({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: '0 16px 16px',
  columnGap: '16px',
});

const TitleStyled = styled(Box)(({ theme }) => ({
  background: theme.palette.divider,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '16px',
  borderRadius: '4px 4px 0 0',
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 470,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '0 0 4px 4px',
};

const StatusMenu = ({
  anchorEl,
  open,
  onClose,
  onStatusSelected = () => {},
}) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [reasonValue, setReasonValue] = useState('');

  const onSubmit = (val) => {
    setStatus(val);
    if (val === COMPLETED_STATUS) {
      setIsConfirmationModalOpen(true);
    }
    if (val === RE_VISIT) {
      setIsModalOpen(true);
    }
  };

  useEffect(() => {
    if (!isModalOpen) {
      setReasonValue('');
    }
  }, [isModalOpen]);

  return (
    <>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        sx={{ '& .MuiPaper-root': { width: '165px' } }}
      >
        <MenuItem onClick={() => onSubmit(RE_VISIT)}>
          <Typography variant="body1">
            {formatStatus(RE_VISIT, '10px', '10px')}
          </Typography>
        </MenuItem>
        <MenuItem onClick={() => onSubmit(COMPLETED_STATUS)}>
          <Typography variant="body1">
            {formatStatus(COMPLETED_STATUS, '10px', '10px')}
          </Typography>
        </MenuItem>
      </Menu>

      {isConfirmationModalOpen && (
        <ConfirmationModal
          open={isConfirmationModalOpen}
          setOpen={setIsConfirmationModalOpen}
          title={t('common.status.completed')}
          description={t('common.work_order_completion_confirmation')}
          onConfirm={() => {
            onStatusSelected(status);
            onClose();
            setIsConfirmationModalOpen(false);
          }}
          onClose={() => setIsConfirmationModalOpen(false)}
          confirmText={t('common.status.complete')}
        />
      )}
      {isModalOpen && (
        <Modal open={isModalOpen}>
          <Box sx={style}>
            <TitleStyled>
              <Typography variant="body1">
                {t('common.status.revisit')}
              </Typography>
              <CloseIcon
                onClick={() => setIsModalOpen(false)}
                style={{ cursor: 'pointer' }}
              />
            </TitleStyled>
            <Box sx={{ padding: '16px' }}>
              <CustomTextField
                label={t('attributes.reason')}
                fullWidth
                value={reasonValue}
                onChange={(e) => setReasonValue(e?.target?.value)}
                multiline
                rows={3}
                onClear={() => setReasonValue('')}
                isRequired={false}
                sx={{
                  '& .MuiOutlinedInput-input': { padding: 0 },
                }}
              />
            </Box>

            <FooterButton>
              <CustomButton
                text={t('common.cancel')}
                color="inherit"
                onClick={() => setIsModalOpen(false)}
              />
              <CustomButton
                text={t('attributes.save')}
                onClick={() => {
                  onStatusSelected(status, reasonValue);
                  setIsModalOpen(false);
                  onClose();
                }}
              />
            </FooterButton>
          </Box>
        </Modal>
      )}
    </>
  );
};
export default StatusMenu;
