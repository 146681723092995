import React from 'react';

import { Box, Chip, Typography } from '@mui/material';

import { FONT_SIZE } from '../../constants/Constants';

export const ChipStatusLabel = ({ label, color = '', bgColor = '' }) => (
  <Chip
    label={label}
    variant="outlined"
    size="small"
    sx={{
      '&.MuiChip-root': {
        color: color,
        borderColor: color,
        background: bgColor,
      },
    }}
  />
);

const StatusLabel = ({
  label,
  color,
  height = '12px',
  width = '12px',
  fontSize = FONT_SIZE,
}) => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'left',
      alignItems: 'center',
    }}
  >
    <Box sx={{ minWidth: '16px' }}>
      <Box
        sx={{
          bgcolor: color,
          height: height,
          width: width,
          borderRadius: '50%',
        }}
      />
    </Box>
    <Typography
      sx={{
        color: color,
        fontSize: fontSize,
        fontWeight: '600',
        marginLeft: '8px',
      }}
    >
      {label}
    </Typography>
  </Box>
);

export default StatusLabel;
