import React, { useState } from 'react';

import EventNoteIcon from '@mui/icons-material/EventNote';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import SearchIcon from '@mui/icons-material/Search';
import {
  CircularProgress,
  Autocomplete as CustomAutoComplete,
  InputAdornment,
  styled,
} from '@mui/material';

import CustomTextField from './CustomTextField';

const StyledAutocomplete = styled(CustomAutoComplete)(({ theme }) => ({
  width: '100%',
  '& .MuiFormControl-root': {
    background: theme.palette.common.white,
  },
}));

const CustomCircularProgress = styled(CircularProgress)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

const RotatingSearchIcon = styled(SearchIcon)(({ open }) => ({
  transform: open ? 'rotate(180deg)' : 'rotate(0deg)', // Rotate on open
  transition: 'transform 0s', // Smooth transition for icon rotation
  cursor: 'pointer',
}));

export const CalendarIcon = styled(EventNoteIcon)(() => ({
  cursor: 'pointer',
}));

const Autocomplete = ({
  placeholder = 'Select',
  options,
  multiple = false,
  handleMultipleFilter = false,
  width = '100%',
  useSearchIcon = false, // Control whether to show the search icon instead of the default arrow
  value, // Add value prop
  onChange, // Add onChange prop
  disabledDropdown = false,
  isLoadingData = false,
  setBlur = () => {},
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  const safeValue = value || (multiple ? [] : null);

  return (
    <StyledAutocomplete
      disablePortal
      limitTags={handleMultipleFilter ? 1 : undefined}
      autoComplete={false}
      options={options}
      disabled={disabledDropdown}
      multiple={multiple}
      value={safeValue} // Ensure value is passed and controlled
      onChange={onChange} // Ensure onChange is handled
      getOptionLabel={(option) => option?.label}
      isOptionEqualToValue={(option, value) => option.value === value?.value}
      popupIcon={
        isLoadingData ? (
          <CustomCircularProgress size={15} />
        ) : useSearchIcon ? (
          <RotatingSearchIcon open={open} />
        ) : multiple &&
          handleMultipleFilter &&
          safeValue &&
          safeValue.length > 0 ? (
          false
        ) : undefined
      } // Hide the default up/down arrow
      clearIcon={!isLoadingData && <HighlightOffOutlinedIcon />}
      sx={{
        ...(multiple &&
          handleMultipleFilter && {
            '& .MuiAutocomplete-tag': {
              maxHeight: '20px',
              maxWidth: '86px',
            },
            '& .MuiInputBase-root': {
              padding: '2.5px 7px !important',
            },
            height: '40px',
            maxHeight: '40px',
            zIndex: 1200,
          }),
        width: width,
        ...(useSearchIcon && {
          '& .MuiOutlinedInput-root': {
            padding: '2.5px !important',
          },
          '& .MuiInputBase-input': {
            padding: '7.5px 65px 7.5px 12.5px!important',
          },
        }),
      }}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      loading={isLoadingData}
      renderInput={(params) => (
        <CustomTextField
          {...params}
          setBlur={setBlur}
          placeholder={placeholder}
          {...rest}
          onChange={multiple ? () => {} : onChange}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>
                {rest.calendarIcon ? (
                  <InputAdornment position="start">
                    <CalendarIcon />
                  </InputAdornment>
                ) : null}
                {params.InputProps.startAdornment}
              </>
            ),
            endAdornment: params.InputProps.endAdornment,
          }}
        />
      )}
      {...rest}
    />
  );
};

export default Autocomplete;
