import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';
import { PieChart as CustomPieChart } from '@mui/x-charts/PieChart';

import {
  DASHBOARD_CHART_LABEL_TYPE,
  FONT_SIZE,
} from '../../../constants/Constants';

export const NoData = () => (
  <g>
    <text
      x="50%"
      y="50%"
      textAnchor="middle"
      dominantBaseline="middle"
      style={{
        fontSize: '14px',
        fontWeight: 400,
        fill: '#000000',
        zIndex: 99999,
      }}
    >
      No data to display
    </text>
  </g>
);

const PieChart = ({
  data = [],
  isRadius = false,
  isArcLabel = true,
  isLegend = true,
  labelType = DASHBOARD_CHART_LABEL_TYPE.PERCENTAGE,
  onChartClick = () => {},
}) => {
  const navigate = useNavigate();
  // Data Config
  let defaultConfig = {
    data: data,
    type: 'pie',
    cx: 120,
    cy: 150,
    outerRadius: 110,
  };
  // Legend Config
  let defaultLegendConfig = {};

  if (isRadius) {
    defaultConfig = {
      ...defaultConfig,
      innerRadius: 30,
      paddingAngle: 5,
      cornerRadius: 5,
      startAngle: 0,
      endAngle: 360,
    };
  }

  const TOTAL = data.map((item) => item.value).reduce((a, b) => a + b, 0);

  const getArcLabel = (params) => {
    const percent = params.value / TOTAL;

    return labelType === DASHBOARD_CHART_LABEL_TYPE.PERCENTAGE
      ? `${(percent * 100).toFixed(0)}%`
      : params.value;
  };

  if (isArcLabel) {
    defaultConfig = { ...defaultConfig, arcLabel: getArcLabel };
  }

  // Legend

  defaultLegendConfig = {
    position: {
      vertical: 'middle',
      horizontal: 'right',
    },
    labelStyle: {
      fontSize: FONT_SIZE,
      fontWeight: 400,
    },
    itemMarkWidth: 12,
    itemMarkHeight: 12,
    markGap: 5,
    itemGap: 10,
    hidden: true,
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomPieChart
        className={data?.length > 0 ? 'custom-pie-chart' : ''}
        series={[defaultConfig]}
        slotProps={{
          legend: defaultLegendConfig,
        }}
        slots={{
          noDataOverlay: NoData,
        }}
        onItemClick={(e, data) => {
          const redirectTo = onChartClick(data?.dataIndex);

          if (redirectTo) {
            navigate(redirectTo);
          }
        }}
      />
      {isLegend && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
            flex: 1,
            flexWrap: 'wrap',
            justifyContent: 'center',
            height: '100%',
            padding: '16px 0',
          }}
        >
          {data?.map((d, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                columnGap: '10px',
                whiteSpace: 'nowrap',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  height: '12px',
                  width: '12px',
                  backgroundColor: d?.color,
                }}
              />
              <Typography variant="body2">{d?.label}</Typography>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default PieChart;
