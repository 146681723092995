import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';

import {
  GoogleIndoorMap,
  GoogleMap,
  SSOLogo,
  XeroLogo,
} from '../../assets/images';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { GLOBAL_ADMIN, SUPER_ADMIN } from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import Cards from '../CommonComponents/CustomCards';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const Integrations = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cardData = {
    [GLOBAL_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_GOOGLE_INDOOR_MAPS);
        },
        rightIcon: (
          <img
            src={GoogleIndoorMap}
            alt="Google Indoor Maps"
            height="35px"
            width="35px"
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.googleIndoorMaps'),
        description: t('attributes.integrations.googleIndoorMapsDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_SSO);
        },
        rightIcon: (
          <img src={SSOLogo} alt="SSO Logo" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.ssoTitle'),
        description: t('attributes.integrations.ssoDescription'),
      },
      {
        onclick: () => {
          navigate(ROUTENAME.GLOBAL_ADMIN_GOOGLE_MAPS);
        },
        rightIcon: (
          <img src={GoogleMap} alt="Google Maps" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.googleMaps'),
        description: t('attributes.integrations.googleMapsDescription'),
      },
    ],
    [SUPER_ADMIN]: [
      {
        onclick: () => {
          navigate(ROUTENAME.XERO_INTEGRATION);
        },
        rightIcon: (
          <img src={XeroLogo} alt="Xero Logo" height="35px" width="35px" />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.integrations.xero'),
        description: t('attributes.integrations.xeroDecription'),
      },
    ],
  };

  const DataManagementqWrapper = (
    <StyledMainWrapper
      title={t('attributes.integrations.integrations')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards cardData={cardData} role={getUserRole()} />
    </StyledMainWrapper>
  );

  return <>{DataManagementqWrapper}</>;
};

export default Integrations;
