import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import EastOutlinedIcon from '@mui/icons-material/EastOutlined';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';

import {
  DATA_AMANAGEMENT_ICON_COLOR,
  DIVIDER_COLOR,
} from '../../constants/Colors';
import { SUPER_ADMIN } from '../../constants/Roles';
import { ROUTENAME } from '../../constants/RoutesConstants';
import { getUserRole } from '../../hoc/Permission';
import Cards from '../CommonComponents/CustomCards';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';

const Reporting = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const cardData = {
    [SUPER_ADMIN]: [
      {
        onclick: () => navigate(ROUTENAME.ACTIVITY_REPORT),
        rightIcon: (
          <PollOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
              color: DATA_AMANAGEMENT_ICON_COLOR,
            }}
          />
        ),
        leftIcon: (
          <EastOutlinedIcon
            style={{
              height: '24px',
              width: '24px',
            }}
          />
        ),
        title: t('attributes.reports.activity_report'),
        description: t('attributes.reports.activity_report_description'),
      },
    ],
  };

  const ReportingWrapper = (
    <StyledMainWrapper
      title={t('attributes.reports.reporting')}
      style={{ height: '100%', backgroundColor: DIVIDER_COLOR }}
    >
      <Cards cardData={cardData} role={getUserRole()} />
    </StyledMainWrapper>
  );

  return <>{ReportingWrapper}</>;
};

export default Reporting;
