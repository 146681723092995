import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { WORK_ORDER } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

export const getWorkOrderList = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDERS,
  async (
    {
      page,
      size,
      search,
      limit,
      order,
      orderBy,
      customer,
      property,
      workOrderTypes,
      technician,
      status,
      appointmentFromDate,
      appointmentToDate,
      dueFromDate,
      dueToDate,
      list_column_names,
      unassignedWorkOrder,
      propertyZone,
      createdAtFrom,
      createdAtTo,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search_by', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (customer) params.append('customer_uuid', customer);
    if (property) params.append('property_uuid', property);
    if (workOrderTypes) params.append('work_order_type', workOrderTypes);
    if (technician) params.append('technician_uuid', technician);
    if (status && typeof status === 'object') {
      if (status?.filter?.latest_status)
        params.append('latest_status__in', status.filter.latest_status);
      if (status?.filter?.schedule_status)
        params.append('schedule_status__in', status.filter.schedule_status);
    }
    if (appointmentFromDate)
      params.append(
        'scheduled_start_date_time__gte',
        formatDateAPI(moment(appointmentFromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (dueFromDate)
      params.append(
        'due_date__gte',
        formatDateAPI(moment(dueFromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (appointmentToDate)
      params.append(
        'scheduled_end_date_time__lte',
        formatDateAPI(
          moment(appointmentToDate).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );
    if (dueToDate)
      params.append(
        'due_date__lte',
        formatDateAPI(
          moment(dueToDate).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );
    if (unassignedWorkOrder)
      params.append('unassigned_work_order', unassignedWorkOrder);
    if (list_column_names && search)
      params.append('list_column_names', list_column_names);
    if (propertyZone) params.append('property_zone__in', propertyZone);
    if (createdAtFrom)
      params.append(
        'created_at__gte',
        formatDateAPI(moment(createdAtFrom).format(DATE_RANGE_FORMAT_API))
      );
    if (createdAtTo)
      params.append(
        'created_at__lte',
        formatDateAPI(
          moment(createdAtTo).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDERS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWorkOrder = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDER,
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDER.replace('{uuid}', id)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
export const updateWorkOrderStatus = createAsyncThunk(
  WORK_ORDER.UPDATE_WORK_ORDER_STATUS,
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.put(
        `${APIs.WORK_ORDER.GET_WORK_ORDER.replace('{uuid}', id)}`,
        data
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getWorkOrderQuotation = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDER_DEFECT_QUOTES,
  async ({ id, order, orderBy, list_column_names }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDER_QUOTATIONS.replace('{work_order_uuid}', id)}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectsQuotesList = createAsyncThunk(
  WORK_ORDER.GET_DEFECT_LIST,
  async (
    { page, workorder_uuid, limit, order, size, orderBy, list_column_names },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (order) params.append('order', order);
    if (limit) params.append('limit', limit);
    if (orderBy) params.append('order_by', orderBy);

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_DEFECT_LIST.replace(':workorder_uuid', workorder_uuid)}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectQuote = createAsyncThunk(
  WORK_ORDER.GET_DEFECT_QUOTE,
  async ({ work_order_id, defect_id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_DEFECT_QUOTE.replace(':work_order_uuid', work_order_id).replace(':defect_uuid', defect_id)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectQuotesDetail = createAsyncThunk(
  WORK_ORDER.GET_DEFECT_QUOTE,
  async ({ work_order_id, quote_id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_DEFECT_QUOTES_DETAIL.replace(':work_order_uuid', work_order_id).replace(':quotation_uuid', quote_id)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssetsList = createAsyncThunk(
  WORK_ORDER.GET_ASSET_LIST,
  async (
    { page, workorder_uuid, limit, order, orderBy, list_column_names },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (limit) params.append('size', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);

    if (list_column_names)
      params.append('list_column_names', list_column_names);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_ASSET_LIST.replace(':workorder_uuid', workorder_uuid)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getAssetDetails = createAsyncThunk(
  WORK_ORDER.GET_ASSET_DETAILS,
  async ({ work_order_id, asset_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_ASSET_LIST.replace(':workorder_uuid', work_order_id)}?asset_uuid=${asset_uuid}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const postAddDefectQuoteProduct = createAsyncThunk(
  WORK_ORDER.ADD_DEFECT_QUOTE_PRODUCT,
  async ({ work_order_uuid, quotation_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.WORK_ORDER.ADD_DEFECT_QUOTE_PRODUCT.replace(':work_order_uuid', work_order_uuid).replace(':quotation_uuid', quotation_uuid)}`,
        data
      );

      return response.data;
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const updateDefectQuotationStatus = createAsyncThunk(
  WORK_ORDER.UPDATE_DEFECT_QUOTE,
  async ({ work_order_id, quote_id, status }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        `${APIs.WORK_ORDER.GET_DEFECT_QUOTES_DETAIL.replace(':work_order_uuid', work_order_id).replace(':quotation_uuid', quote_id)}`,
        { status: status }
      );

      return { ...response.data };
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const updateDefectQuotationProduct = createAsyncThunk(
  WORK_ORDER.UPDATE_DEFECT_QUOTE_PRODUCT,
  async ({ quotation_item_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        `${APIs.WORK_ORDER.UPDATE_DEFECT_QUOTE_PRODUCT.replace(':quotation_item_uuid', quotation_item_uuid)}`,
        data
      );

      return { ...response.data };
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const deleteDefectQuotationProduct = createAsyncThunk(
  WORK_ORDER.DELETE_DEFECT_QUOTE_PRODUCT,
  async ({ quotation_item_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.delete(
        APIs.WORK_ORDER.DELETE_DEFECT_QUOTE_PRODUCT.replace(
          ':quotation_item_uuid',
          quotation_item_uuid
        )
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const patchDefectQuotationStatus = createAsyncThunk(
  WORK_ORDER.PATCH_DEFECT_QUOTE_STATUS,
  async ({ work_order_uuid, quotation_uuid, data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.patch(
        `${APIs.WORK_ORDER.PATCH_DEFECT_QUOTE_STATUS.replace(':work_order_uuid', work_order_uuid).replace(':quotation_uuid', quotation_uuid)}`,
        data
      );

      return { ...response.data };
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const createWorkOrder = createAsyncThunk(
  WORK_ORDER.CREATE_WORK_ORDER,
  async ({ data }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.WORK_ORDER.CERATE_WORK_ORDER}`,
        { ...data }
      );

      return { ...response.data };
    } catch (error) {
      // Forward the custom error object to the rejected promise
      return rejectWithValue(error);
    }
  }
);

export const getWorkOrderDropdownList = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDERS_DROPDOWN,
  async (
    {
      page,
      size,
      search,
      limit,
      order,
      orderBy,
      customer,
      property,
      workOrderTypes,
      technician,
      latest_status__in,
      appointmentFromDate,
      appointmentToDate,
      dueFromDate,
      dueToDate,
      list_column_names,
      unassignedWorkOrder,
      propertyZone,
    },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (page) params.append('page', page);
    if (search) params.append('search_by', search);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);
    if (order) params.append('order', order);
    if (orderBy) params.append('order_by', orderBy);
    if (customer) params.append('customer_uuid', customer);
    if (property) params.append('property_uuid', property);
    if (workOrderTypes) params.append('work_order_type', workOrderTypes);
    if (technician) params.append('technician_uuid', technician);
    if (latest_status__in)
      params.append('latest_status__in', latest_status__in);
    if (appointmentFromDate)
      params.append(
        'scheduled_start_date_time__gte',
        formatDateAPI(moment(appointmentFromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (dueFromDate)
      params.append(
        'execute_start_date_time__gte',
        formatDateAPI(moment(dueFromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (appointmentToDate)
      params.append(
        'scheduled_end_date_time__lte',
        formatDateAPI(
          moment(appointmentToDate).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );
    if (dueToDate)
      params.append(
        'execute_end_date_time__lte',
        formatDateAPI(
          moment(dueToDate).endOf('day').format(DATE_RANGE_FORMAT_API)
        )
      );
    if (unassignedWorkOrder)
      params.append('unassigned_work_order', unassignedWorkOrder);
    if (list_column_names && search)
      params.append('list_column_names', list_column_names);
    if (propertyZone) params.append('property_zone__in', propertyZone);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDERS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Inspection form
export const getAssetInspectionForm = createAsyncThunk(
  WORK_ORDER.GET_ASSET_INSPECTION_FORM,
  async ({ work_order_id, asset_uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_ASSET_INSPECTION_FORM.replace(':work_order_uuid', work_order_id)}?asset_uuid=${asset_uuid}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getPreChecklist = createAsyncThunk(
  WORK_ORDER.GET_WORK_ORDER_PRECHECKLIST,
  async ({ work_order_id, page, size, limit }, { rejectWithValue }) => {
    const params = new URLSearchParams();

    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (limit) params.append('limit', limit);

    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.WORK_ORDER.GET_WORK_ORDER_PRECHECKLIST.replace('{work_order_uuid}', work_order_id)}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const assignUnAssignWOTechnician = createAsyncThunk(
  WORK_ORDER.ASSIGN_UNASSIGN_TECHNICIAN,
  async ({ work_order_uuid, req }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.put(
        `${APIs.WORK_ORDER.ASSIGN_UNASSIGN_TECHNICIAN.replace(':work_order_uuid', work_order_uuid)}`,
        req
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const createDefectQuote = createAsyncThunk(
  WORK_ORDER.CREATE_DEFECT_QUOTE,
  async ({ uuid }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.WORK_ORDER.CREATE_DEFECT_QUOTE.replace('{uuid}', uuid)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
