import { useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Box, Divider, Typography } from '@mui/material';

import {
  CustomCard,
  FormFieldWrapper,
  FormWrapper,
} from '../../assets/commonStyled';
import {
  FREQUENCY_TYPE_LIST,
  TOLERANCE_TYPE_LIST,
} from '../../constants/Constants';
import { Validation } from '../../constants/FieldValidationMsg';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import CustomTextField from '../CommonComponents/CustomTextField';

const RoutineServiceFrequenciesForm = ({
  editData,
  clearErrors,
  reset,
  control,
  trigger,
  watch,
  setValue,
  setServerErrors,
  isFromCompanyForm = false,
  standardsDropdownData,
  standardsDropdownLoading,
}) => {
  const { t } = useTranslation();
  // TODO: - Move with edit later
  const isLoading = false;
  const clearHandler = (name) => setValue(name, '');
  const frequency = watch('frequency');
  const frequency_type = watch('frequency_type');

  useEffect(() => {
    const code =
      frequency && frequency_type
        ? frequency > 1
          ? `${frequency}${frequency_type?.value}`
          : `${frequency_type?.value}`
        : '';
    setValue('code', code);
  }, [frequency, frequency_type]);

  useEffect(() => {
    if (editData) {
      reset({
        uuid: editData?.uuid,
        name: editData?.name,
        standard: { label: editData?.standard, value: editData?.standard },
        applicability_note: editData?.applicability_note,
        internal_note: editData?.internal_note,
        frequency: editData?.frequency,
        frequency_type: {
          label: FREQUENCY_TYPE_LIST.filter(
            (item) => item.value === editData?.frequency_type
          )?.[0]?.label,
          value: editData?.frequency_type,
        },
        tolerance: editData?.tolerance,
        tolerance_type: {
          label: TOLERANCE_TYPE_LIST.filter(
            (item) => item.value === editData.tolerance_type
          )?.[0]?.label,
          value: editData?.tolerance_type,
        },
      });
    } else {
      reset();
    }
  }, []);

  return isLoading ? (
    <CustomCircularLoader />
  ) : (
    <FormWrapper>
      <CustomCard>
        <FormFieldWrapper>
          <Controller
            name="name"
            control={control}
            rules={{
              required: `${t('attributes.name')} ${Validation.general.required}`,
              maxLength: {
                value: 80,
                message: Validation.general.contactName,
              },
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.name')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('name');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />

          <Controller
            name="code"
            control={control}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <CustomTextField
                label={t('attributes.code')}
                fullWidth
                value={value}
                onChange={(e) => {
                  onChange(e);
                }}
                helperText={error ? error.message : ''}
                error={error}
                disabled
                onClear={() => clearHandler(name)}
              />
            )}
          />

          <Controller
            name={'standard'}
            control={control}
            rules={{
              required: `${t('attributes.routineServiceFrequencies.standard')} ${Validation.general.required}`,
            }}
            render={({
              field: { onChange, value, name },
              fieldState: { error },
            }) => (
              <Autocomplete
                label={t('attributes.routineServiceFrequencies.standard')}
                options={standardsDropdownData}
                isLoadingData={standardsDropdownLoading}
                value={value}
                onChange={(e, newValue) => {
                  onChange(newValue);
                  trigger('standard');
                }}
                helperText={error ? error.message : ''}
                error={error}
                onClear={() => clearHandler(name)}
              />
            )}
          />
          <Controller
            name="applicability_note"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.generalTextArea,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label={t(
                  'attributes.routineServiceFrequencies.applicabilityNote'
                )}
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('applicability_note');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
          <Controller
            name="internal_note"
            control={control}
            rules={{
              maxLength: {
                value: 300,
                message: Validation.general.generalTextArea,
              },
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <CustomTextField
                label={t('attributes.routineServiceFrequencies.internalNote')}
                multiline
                isRequired={false}
                rows={3}
                value={value}
                onChange={(e) => {
                  onChange(e);
                  trigger('internal_note');
                }}
                onClear={() => {
                  onChange('');
                }}
                helperText={error ? error.message : ''}
                error={error}
              />
            )}
          />
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">Frequency</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="frequency"
              control={control}
              rules={{
                required: `${t('attributes.routineServiceFrequencies.frequency')} ${Validation.general.required}`,
                maxLength: {
                  value: 2,
                  message: Validation.routineServiceFrequencies.frequency,
                },
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <CustomTextField
                  fullWidth
                  label={`${t('attributes.routineServiceFrequencies.frequency')}#`}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('frequency');
                  }}
                  onClear={() => {
                    onChange('');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Controller
              name={'frequency_type'}
              control={control}
              rules={{
                required: `${t('attributes.routineServiceFrequencies.frequencyType')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.select')}
                  options={FREQUENCY_TYPE_LIST}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('frequency_type');
                  }}
                  onClear={() => {
                    onChange('');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
      <CustomCard>
        <Box sx={{ padding: '16px' }}>
          <Typography variant="body1">Tolerance</Typography>
        </Box>
        <Divider />
        <FormFieldWrapper>
          <Box sx={{ display: 'flex', columnGap: '8px', width: '100%' }}>
            <Controller
              name="tolerance"
              control={control}
              rules={{
                required: `${t('attributes.routineServiceFrequencies.tolerance')} ${Validation.general.required}`,
                maxLength: {
                  value: 2,
                  message: Validation.routineServiceFrequencies.tolerance,
                },
                validate: (value) => {
                  if (value && !/^\d+$/.test(value)) {
                    return Validation.general.numericValue;
                  }

                  return true;
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <CustomTextField
                  fullWidth
                  label={`${t('attributes.routineServiceFrequencies.tolerance')}#`}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    trigger('tolerance');
                  }}
                  onClear={() => {
                    onChange('');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
            <Controller
              name={'tolerance_type'}
              control={control}
              rules={{
                required: `${t('attributes.routineServiceFrequencies.toleranceType')} ${Validation.general.required}`,
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                  label={t('common.select')}
                  options={TOLERANCE_TYPE_LIST}
                  value={value}
                  onChange={(e, newValue) => {
                    onChange(newValue);
                    trigger('tolerance_type');
                  }}
                  onClear={() => {
                    onChange('');
                  }}
                  helperText={error ? error.message : ''}
                  error={error}
                />
              )}
            />
          </Box>
        </FormFieldWrapper>
      </CustomCard>
    </FormWrapper>
  );
};

export default RoutineServiceFrequenciesForm;
