import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { formatDateAPI } from '../../utils';
import { CUSTOMER } from '../actionPrefix';
import { axiosInstanceTechnician } from '../axios';

const {
  GET_DEFECT_MONTHLY_CHART,
  GET_DEFECT_STATUS_MONTHLY_CHART,
  GET_DEFECT_BY_EQUIPEMENT_CHART,
  GET_DEFECT_BY_PROPERTY_CHART,
  GET_DEFECT_VIEW,
} = CUSTOMER;

export const getMonthlyDefectChartData = createAsyncThunk(
  GET_DEFECT_MONTHLY_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.DEFECT.GET_DEFECT_MONTHLY_CHART}`,
        {
          from_date: formatDateAPI(start_date),
          to_date: formatDateAPI(end_date),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMonthlyDefectStatusChartData = createAsyncThunk(
  GET_DEFECT_STATUS_MONTHLY_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.DEFECT.GET_DEFECT_STATUS_MONTHLY_CHART}`,
        {
          from_date: formatDateAPI(start_date),
          to_date: formatDateAPI(end_date),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectByEquipementType = createAsyncThunk(
  GET_DEFECT_BY_EQUIPEMENT_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.DEFECT.GET_DEFECT_BY_EQUIPEMENT_CHART}`,
        {
          from_date: formatDateAPI(start_date),
          to_date: formatDateAPI(end_date),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDefectByProperty = createAsyncThunk(
  GET_DEFECT_BY_PROPERTY_CHART,
  async ({ start_date, end_date }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.post(
        `${APIs.DEFECT.GET_DEFECT_BY_PROPERTY_CHART}`,
        {
          from_date: formatDateAPI(start_date),
          to_date: formatDateAPI(end_date),
        }
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getSingleDefect = createAsyncThunk(
  GET_DEFECT_VIEW,
  async ({ defect_id }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceTechnician.get(
        `${APIs.DEFECT.GET_DEFECT_VIEW.replace(':uuid', defect_id)}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
