import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Box } from '@mui/material';
import { debounce } from 'lodash';

import { FlexEnd, FlexStart } from '../../assets/commonStyled';
import { DIVIDER_COLOR } from '../../constants/Colors';
import { exportToCSV } from '../../constants/common';
import {
  CUSTOMER_DEFECT_STATUS,
  DEFECT_SEVERITY,
} from '../../constants/Constants';
import {
  COLUMNS_TO_EXPORT,
  COLUMNS_TO_MAP,
  EXPORT_FILE_NAMES,
} from '../../constants/ExportConstant';
import getDropdownListHook from '../../hooks/getDropdownListHook';
import { snackbarToggle } from '../../store/CommonReducer';
import { getPropertyList } from '../../store/property/api';
import {
  getCustomerDefectList,
  getCustomerList,
} from '../../store/users/customer/api';
import { formatStatus, formattedDate } from '../../utils';
import { View } from '../CommonComponents/ActionComponent';
import Autocomplete from '../CommonComponents/AutoComplete';
import CustomButton from '../CommonComponents/CustomButton';
import CustomDateRangePicker from '../CommonComponents/CustomDateRangePicker';
import CustomGridTable from '../CommonComponents/CustomGridTable';
import {
  FilterButton,
  ResetFilterButton,
} from '../CommonComponents/FilterButton';
import NoRecordFound from '../CommonComponents/NoDataPage/NoRecordFound';
import CustomSearch from '../CommonComponents/Search';
import StyledMainWrapper from '../CommonComponents/StyledMainWrapper';
import SwipeableDrawer from '../CommonComponents/SwipeableDrawer';
import ViewDefect from './ViewDefect';

const filterHeight = (isFilterOpen) => (isFilterOpen ? 357 : 301);

//default filters
const defaultFilters = {
  property: null,
  status: null,
  severity: null,
  customer: null,
  dateRange: {
    fromDate: null,
    toDate: null,
  },
};

const DefectList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const queryStatus = searchParams.get('status');

  const [isFilterComponentVisible, setIsFilterComponentVisible] =
    useState(false);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [perPageData, setPerPageData] = useState(10);
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
  const [selectedRows, setSelectedRows] = useState(null);
  const [filters, setFilters] = useState(defaultFilters);
  const [isDataLoading, setIsDataLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');

  const [defects, setDefects] = useState(null);
  const [selectedDefect, setSelectedDefect] = useState(null);

  const [customerOptions, setCustomerOptions] = useState(null);
  const [customerLoading, setCustomerLoading] = useState(false);

  const { propertyDropdownLoading, propertyDropdownData } = getDropdownListHook(
    {
      reducerName: 'property',
      dropdownListName: 'propertyList',
      labelName: 'property_name',
      valueName: 'uuid',
      loading: 'loading',
    }
  );

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'id',
        headerName: t('attributes.defects.defectsId'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => (row.id ? `D-${row.id}` : '-'),
      },
      {
        field: 'title',
        headerName: t('attributes.defects.defectTitle'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'severity',
        headerName: t('attributes.defects.severity'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) =>
          row.severity ? formatStatus(row?.severity) : '-',
      },
      {
        field: 'customer_name',
        headerName: `${t('attributes.customer.customer')}  ${t('attributes.name')}`,
        flex: 1,
        sortable: true,
      },
      {
        field: 'property_name',
        headerName: t('attributes.defects.propertyName'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'asset',
        headerName: `${t('attributes.defects.asset')} ${t('attributes.name')}`,
        flex: 1,
        sortable: false,
      },
      {
        field: 'raised_by',
        headerName: t('attributes.defects.raisedBy'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'description',
        headerName: t('attributes.defects.description'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'created_at',
        headerName: t('attributes.defects.identifiedDate'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => formattedDate(row?.created_at),
      },

      {
        field: 'status',
        headerName: t('attributes.defects.status'),
        flex: 1,
        sortable: false,
        renderCell: ({ row }) => (row?.status ? formatStatus(row.status) : '-'),
      },

      {
        field: 'edit',
        headerName: t('attributes.defects.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <View
            onClick={() => {
              setOpen(true);
              setSelectedDefect(row);
            }}
          />
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = defects?.map((defect, index) => ({
    ...defect,
    id: defect?.id || index + 1,
    severity: defect?.severity,
    property_name: defect?.property?.property_name || '-',
    customer_name: defect?.property?.customer || '-',
    asset: defect?.asset?.label || '-',
    raised_by: defect?.work_order?.technician?.technician_name || '-',
    description: defect?.defect_description,
    title: defect?.defect_title || '-',
    status:
      CUSTOMER_DEFECT_STATUS.find(
        (status) => status.value === defect?.latest_status
      )?.label || '-',
    date: defect?.created_at ? formattedDate(defect?.created_at) : '',
  }));

  const getDefects = useCallback(() => {
    setSelectedRows(null);
    setIsDataLoading(true);
    const visibleFieldsString = columns
      .filter((col) => columnVisibilityModel[col.field] !== false)
      .map((col) => col.field)
      .join(',');

    dispatch(
      getCustomerDefectList({
        perPageData: perPageData,
        page: currentPage,
        order: order,
        orderBy: orderBy,
        property_uuid: filters?.property?.value,
        customer_uuid: [filters?.customer?.value],
        severity: filters?.severity?.value,
        status: filters?.status?.value,
        fromDate: filters?.dateRange?.fromDate,
        toDate: filters?.dateRange?.toDate,
        search: searchText,
        list_column_names: visibleFieldsString,
      })
    )
      .then((res) => {
        setDefects(res?.payload?.data?.[0] || []);
        setCurrentPage(res?.payload?.pagination?.current_page || 1);
        setPerPageData(res?.payload?.pagination?.items_per_page || 10);
        setTotal(res?.payload?.pagination?.total_count || 0);
      })
      .finally(() => setIsDataLoading(false));
  }, [
    perPageData,
    currentPage,
    order,
    orderBy,
    columns,
    columnVisibilityModel,
    filters,
    searchText,
  ]);

  // Debounced function to avoid frequent API calls
  const debouncedFetchData = useCallback(debounce(getDefects, 500), [
    getDefects,
  ]);

  useEffect(() => {
    debouncedFetchData();

    return () => {
      debouncedFetchData.cancel();
    };
  }, [debouncedFetchData]);

  // For property dropdown
  useEffect(() => {
    const req = {
      limit: -1,
      customerUuid: filters?.customer?.value,
    };

    dispatch(getPropertyList(req));
  }, []);

  useEffect(() => {
    setCustomerLoading(true);
    dispatch(getCustomerList({ limit: -1 }))
      .then((res) => {
        const options = res?.payload?.data?.map((c) => ({
          label: c?.customer_name,
          value: c?.uuid,
        }));

        setCustomerOptions(options);
      })
      .finally(() => setCustomerLoading(false));
  }, []);

  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData]);

  const resetFilter = () => {
    setFilters(defaultFilters);
    setSearchText('');
  };

  // Reset pagination on page size, filters, or searchText change
  useEffect(() => {
    setCurrentPage(1);
  }, [perPageData, filters, searchText]);

  useEffect(() => {
    if (queryStatus) {
      setFilters((pre) => ({
        ...pre,
        severity: DEFECT_SEVERITY?.find((s) => s.value === queryStatus),
      }));
      setIsFilterComponentVisible(true);
    }
  }, [queryStatus]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(rows?.filter((row) => newSelection.includes(row.id)));
  };

  const renderedComponent = (
    <>
      <Box sx={{ padding: '8px' }}>
        <FlexEnd>
          <ResetFilterButton
            onClick={resetFilter}
            disabled={
              !(
                searchText ||
                filters?.dateRange?.fromDate ||
                filters?.dateRange?.toDate ||
                filters?.severity ||
                filters?.status ||
                filters?.property ||
                filters?.customer
              )
            }
          />
          <FilterButton
            onClick={() =>
              setIsFilterComponentVisible(!isFilterComponentVisible)
            }
            isActive={isFilterComponentVisible}
          />
          <CustomSearch
            value={searchText}
            onChange={(e) => {
              setSearchText(e.target.value);
            }}
          />
        </FlexEnd>
        {isFilterComponentVisible && (
          <FlexStart
            style={{
              background: DIVIDER_COLOR,
              padding: '8px',
              margin: '8px 0',
            }}
          >
            <Autocomplete
              placeholder={t('attributes.customer.customer')}
              options={customerOptions}
              width="190px"
              value={filters?.customer}
              isLoadingData={customerLoading}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, customer: newVal }))
              }
            />
            <Autocomplete
              placeholder={t('attributes.property.property')}
              options={propertyDropdownData}
              isLoadingData={propertyDropdownLoading}
              width="190px"
              value={filters?.property}
              onChange={(e, newVal) => {
                setFilters((pre) => ({
                  ...pre,
                  property: newVal,
                }));
              }}
            />
            <Autocomplete
              placeholder={t('attributes.defects.severity')}
              options={DEFECT_SEVERITY}
              width="190px"
              value={filters?.severity}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, severity: newVal }))
              }
            />

            <Autocomplete
              placeholder={t('attributes.defects.status')}
              options={CUSTOMER_DEFECT_STATUS}
              width="190px"
              value={filters?.status}
              onChange={(e, newVal) =>
                setFilters((pre) => ({ ...pre, status: newVal }))
              }
            />
            <CustomDateRangePicker
              onOkClick={(val) => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: val[0], toDate: val[1] },
                }));
              }}
              onClear={() => {
                setFilters((pre) => ({
                  ...pre,
                  dateRange: { fromDate: null, toDate: null },
                }));
              }}
              fromDate={filters?.dateRange?.fromDate}
              toDate={filters?.dateRange?.toDate}
            />
          </FlexStart>
        )}
      </Box>
      <CustomGridTable
        columns={columns}
        rows={rows}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        perPageData={perPageData}
        setPerPageData={setPerPageData}
        order={order}
        orderBy={orderBy}
        setOrder={setOrder}
        setOrderBy={setOrderBy}
        columnVisibilityModel={columnVisibilityModel}
        setColumnVisibilityModel={setColumnVisibilityModel}
        onRowSelectionModelChange={handleSelectionChange}
        noData={<NoRecordFound />}
        isLoading={isDataLoading}
        filterHeight={filterHeight(isFilterComponentVisible)}
      />
    </>
  );

  return (
    <>
      <StyledMainWrapper
        title={t('attributes.defects.defects')}
        btn={
          <CustomButton
            text={t('attributes.export')}
            color="secondary"
            sx={{ height: '52%' }}
            startIcon={<OpenInNewIcon />}
            onClick={() => {
              if (!selectedRows || selectedRows?.length === 0) {
                dispatch(
                  snackbarToggle({
                    isOpen: true,
                    isErrorMsg: true,
                    msg: t('message.common.noRecordExportMessage'),
                  })
                );
                setSelectedRows(null);
              } else {
                exportToCSV(
                  selectedRows,
                  COLUMNS_TO_EXPORT.SUPER_ADMIN_DEFECTS,
                  EXPORT_FILE_NAMES.SUPER_ADMIN_DEFECTS,
                  COLUMNS_TO_MAP.DEFECTS
                );
              }
            }}
          />
        }
      >
        <Box sx={{ width: '100%', p: '16px 16px 0 16px' }}>
          {renderedComponent}
        </Box>
      </StyledMainWrapper>
      {open && (
        <SwipeableDrawer
          open={open}
          title={selectedDefect?.id}
          onClose={() => {
            setOpen(false);
          }}
          width={500}
        >
          <ViewDefect defectUUID={selectedDefect?.uuid} />
        </SwipeableDrawer>
      )}
    </>
  );
};

export default DefectList;
