import {
  Box,
  Card,
  CardContent,
  Grid,
  styled,
  Typography,
} from '@mui/material';

const IconBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  fontSize: '11px',
}));

const CustomCardContent = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  flexDirection: 'column',
  gap: '8px',
  paddingBottom: 0,
}));

const Cards = ({ cardData, role }) => (
  <Grid container spacing="25px">
    {cardData[role]?.map((card, index) => (
      <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
        <Card
          sx={{
            height: '120px',
            boxShadow: 0,
            borderRadius: '4px',
            cursor: 'pointer',
          }}
          onClick={card.onclick}
        >
          <CustomCardContent>
            <IconBox>
              {card.rightIcon}
              {card.leftIcon}
            </IconBox>
            <CustomTypography variant="common600">
              {card.title}
            </CustomTypography>
            <CustomTypography variant="common400">
              {card.description}
            </CustomTypography>
          </CustomCardContent>
        </Card>
      </Grid>
    ))}
  </Grid>
);

export default Cards;
