import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Stack, Typography } from '@mui/material';

import { AppleStore, DarkLogo, GoogleStore } from '../assets/images';
import { PRIMARY, SECONDARY } from '../constants/Colors';

export const DeepLinking = () => {
  const { t } = useTranslation();

  const redirectToStore = (navigator) => {
    if (navigator === 'app-store') {
      window.location.href = process.env.REACT_APP_APP_STORE_LINK;
    } else if (navigator === 'play-store') {
      window.location.href = process.env.REACT_APP_PLAY_STORE_LINK;
    }
  };

  const checkAppInstallation = () => {
    const userAgent = navigator.userAgent.toLowerCase();

    if (userAgent.includes('iphone') || userAgent.includes('ipad')) {
      // Try to open the app using deep link
      window.location.href = process.env.REACT_APP_IOS_DEEP_LINKING_URL; // iOS deep link (replace with your app's deep link)
    }

    if (userAgent.includes('android')) {
      // Try to open the app using deep link
      window.location.href = process.env.REACT_APP_ANDROID_DEEP_LINKING_URL; // Android deep link (replace with your app's deep link)
    }
  };

  useEffect(() => {
    checkAppInstallation();
  }, []);

  return (
    <Stack
      sx={{
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
      spacing={2}
    >
      <img src={DarkLogo} alt="" />
      <Typography
        variant="body1"
        sx={{
          textAlign: 'center',
          textTransform: 'uppercase',
          letterSpacing: '2px',
          fontWeight: '800',
          maxWidth: '240px',
        }}
      >
        {t('attributes.deeplink.header')}
      </Typography>
      <Stack
        sx={{
          padding: '16px',
          bgcolor: SECONDARY,
          borderRadius: '6px',
          maxWidth: '342px',
          alignItems: 'center',
        }}
        spacing={2}
      >
        <Typography
          sx={{ color: PRIMARY, textAlign: 'center' }}
          variant="body1"
        >
          {t('attributes.deeplink.title')}
        </Typography>
        <Typography
          sx={{ textAlign: 'center', maxWidth: '290px' }}
          variant="body2"
        >
          {t('attributes.deeplink.description')}
        </Typography>
        <Stack direction="row" spacing={2}>
          <img
            src={GoogleStore}
            style={{ height: '40px', cursor: 'pointer' }}
            onClick={() => redirectToStore('play-store')}
          />
          <img
            src={AppleStore}
            style={{ height: '40px', cursor: 'pointer' }}
            onClick={() => redirectToStore('app-store')}
          />
        </Stack>
      </Stack>
    </Stack>
  );
};
