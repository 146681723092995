import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';

import { APIs } from '../../constants/APIConstants';
import { DATE_RANGE_FORMAT_API } from '../../constants/Constants';
import { formatDateAPI } from '../../utils';
import { DOCUMENTS } from '../actionPrefix';
import { axiosInstanceDocument } from '../axios';

const {
  GET_DOCUMENTS,
  UPLOAD_BULK_DOCUMENT,
  DOWNLOAD_SAMPLE_TEMPLATE,
  GET_DOCUMENT_UPLOAD_STATUS,
  DOWNLOAD_IMPORTED_FILE,
} = DOCUMENTS;

export const getDocumentList = createAsyncThunk(
  GET_DOCUMENTS,
  async (
    { page, search, size, status, order, orderBy, fromDate, toDate },
    { rejectWithValue }
  ) => {
    const params = new URLSearchParams();

    // Add params conditionally
    if (order) params.append('order_direction', order);
    if (page) params.append('page', page);
    if (size) params.append('size', size);
    if (status) params.append('current_status', status);
    if (fromDate)
      params.append(
        'created_at__gte',
        formatDateAPI(moment(fromDate).format(DATE_RANGE_FORMAT_API))
      );
    if (toDate)
      params.append(
        'created_at__lte',
        formatDateAPI(moment(toDate).endOf('day').format(DATE_RANGE_FORMAT_API))
      );
    if (search) params.append('search', search);
    if (orderBy) params.append('order_by', orderBy);

    try {
      const response = await axiosInstanceDocument.get(
        `${APIs.DOCUMENTS.GET_DOCUMENTS}?${params.toString()}`
      );

      return { ...response.data };
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const uploadBulkDocument = createAsyncThunk(
  UPLOAD_BULK_DOCUMENT,
  async ({ file_type, file }, { rejectWithValue }) => {
    let formData = new FormData();

    formData.append('file', file);
    try {
      const response = await axiosInstanceDocument.post(
        `bulk_upload`,
        formData,
        {
          params: {
            file_type: file_type,
          },
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getDocumentUploadStatus = createAsyncThunk(
  GET_DOCUMENT_UPLOAD_STATUS,
  async ({ documentId }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceDocument.get(
        `bulk_upload/${documentId}/status`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadSampleTemplate = createAsyncThunk(
  DOWNLOAD_SAMPLE_TEMPLATE,
  async ({ code }, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceDocument.get(
        APIs.DOCUMENTS.GET_SAMPLE_TEMPLATE.replace(':code', code),
        { responseType: 'blob' }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const downloadImportedFile = createAsyncThunk(
  DOWNLOAD_IMPORTED_FILE,
  async (documentId, { rejectWithValue }) => {
    try {
      const response = await axiosInstanceDocument.get(
        APIs.DOCUMENTS.DOWNLOAD_IMPORTED_FILE.replace(':documentId', documentId)
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
