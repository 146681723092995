import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Box, styled, Typography } from '@mui/material';

import { DarkLogo } from '../../assets/images';
import { formatPriceWithDecimalValue, formattedDate } from '../../utils';
import CustomCircularLoader from '../CommonComponents/CustomLoader';

const InvoiceContainer = styled(Box)(() => ({
  backgroundColor: 'white',
  height: '100%',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
}));

const PreviewDefectQuoteInvoice = ({
  companyData,
  propertyData,
  isPropertyLoading,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { data, isLoading } = useSelector(
    (state) => state.workOrder.defectQuotesDetail
  );

  const { id, quotation_items, quote_date, due_date } = data;

  // Calculate Subtotal, Tax, and Total
  const subTotal = quotation_items?.reduce(
    (sum, item) => sum + item?.sell_price * item?.qty,
    0
  );
  // const tax = subTotal * 0.0625;
  const total = subTotal;

  return isPropertyLoading || isLoading ? (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
      }}
    >
      <CustomCircularLoader />
    </Box>
  ) : (
    <InvoiceContainer>
      {/* Header */}
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Box>
          <Box>
            <Typography variant="body1">{companyData?.company_name}</Typography>
            <Typography variant="body2">
              {companyData?.address}
              <br />
              {`${companyData?.state}, ${companyData?.city}, ${companyData?.zip_code}`}
            </Typography>
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <Typography variant="body1">
              {t('attributes.work_order.bill_to')}
            </Typography>
            <Typography variant="body2">
              {propertyData?.customer} <br />
              {propertyData?.address}
              <br />{' '}
              {`${propertyData?.state}, ${propertyData?.city}, ${propertyData?.zip_code}`}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'end',
            alignContent: 'end',
          }}
        >
          <img src={DarkLogo} alt="Fire Logo" height="40px" width="107px" />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              gap: '20px',
            }}
          >
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body1">
                {t('attributes.work_order.quote')}
              </Typography>
              <Typography variant="body1">
                {t('attributes.work_order.quote_date')}
              </Typography>
              <Typography variant="body1">
                {t('attributes.work_order.quote_due')}
              </Typography>
              <Typography variant="body1">
                {t('attributes.work_order.ref')} #
              </Typography>
            </Box>

            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2">{`Q-${id}`}</Typography>
              <Typography variant="body2">
                {formattedDate(quote_date) ?? '-'}
              </Typography>
              <Typography variant="body2">
                {formattedDate(due_date) ?? '-'}
              </Typography>
              <Typography variant="body2">-</Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Property Table */}
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          <Box sx={{ paddingTop: '40px' }}>
            <Box sx={{ display: 'flex', gap: '4px' }}>
              <Typography variant="body1">
                {' '}
                {t('attributes.property.propertyName')}:
              </Typography>
              <Typography variant="body2">
                {propertyData?.property_name}
              </Typography>
            </Box>
            <Box sx={{ paddingTop: '16px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'left',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.desciption')}
                      </Typography>
                    </th>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'center',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">{t('common.qty')}</Typography>
                    </th>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'right',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.unit_price')}
                      </Typography>
                    </th>
                    <th
                      style={{
                        padding: '8px',
                        border: '1px solid #EBEBEB',
                        textAlign: 'right',
                        backgroundColor: '#ebebeb20',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.amount')}
                      </Typography>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {quotation_items?.map((item, index) => (
                    <tr key={index}>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'left',
                        }}
                      >
                        <Typography variant="body1">
                          {item.product_catalogue?.product_name}
                        </Typography>
                        <Typography variant="body2">
                          {item.asset_details?.label}
                        </Typography>
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'center',
                        }}
                      >
                        {item.qty}
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                        }}
                      >
                        <Typography variant="body2">
                          {item.sell_price?.toFixed(2)}
                        </Typography>
                      </td>
                      <td
                        style={{
                          padding: '8px',
                          border: '1px solid #EBEBEB',
                          textAlign: 'right',
                        }}
                      >
                        <Typography variant="body2">
                          {formatPriceWithDecimalValue(
                            item.qty * item.sell_price
                          )}
                        </Typography>
                      </td>
                    </tr>
                  ))}

                  {/* Subtotal, Tax, Total rows */}
                  {/* <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">Subtotal</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body2">
                      ${subTotal.toFixed(2)}
                    </Typography>
                  </td>
                </tr>
                <tr>
                  <td
                    colSpan={3}
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: 'none',
                    }}
                  >
                    <Typography variant="body1">Tax (6.25%)</Typography>
                  </td>
                  <td
                    style={{
                      padding: '8px',
                      textAlign: 'right',
                      border: '1px solid #EBEBEB',
                    }}
                  >
                    <Typography variant="body2">${tax.toFixed(2)}</Typography>
                  </td>
                </tr> */}
                  <tr>
                    <td
                      colSpan={3}
                      style={{
                        padding: '8px',
                        textAlign: 'right',
                        border: 'none',
                      }}
                    >
                      <Typography variant="body1">
                        {t('common.total')}
                      </Typography>
                    </td>
                    <td
                      style={{
                        padding: '8px',
                        textAlign: 'right',
                        border: '1px solid #EBEBEB',
                      }}
                    >
                      <Typography variant="body1">
                        {total?.toFixed(2)}
                      </Typography>
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Footer */}
      <Box sx={{ padding: '20px 0 10px 0' }}>
        <Typography variant="body1" style={{ textDecoration: 'underline' }}>
          {t('common.termsAndCondition')}
        </Typography>
        <Typography variant="body2">
          {t('common.paymentDecription1')}
        </Typography>
        <Typography variant="body1" style={{ paddingTop: '5px' }}>
          {`${t('common.paymentDecription2')} ${companyData?.company_name}`}
        </Typography>
      </Box>
    </InvoiceContainer>
  );
};

export default PreviewDefectQuoteInvoice;
