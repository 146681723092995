import { uniqBy } from 'lodash';
import moment from 'moment';

import BasicBarChart from './components/Chart/BarChart/CustomBarChart';
import LineChart from './components/Chart/LineChart/LineChart';
import Piechart from './components/Chart/PieChart/PieChart';
// import StackedBarChart from './components/Chart/StackedBarChart/StackedBarChart';
import StatusLabel, {
  ChipStatusLabel,
} from './components/CommonComponents/StatusLabel';
import {
  DARK_SKY,
  LIGHT_SKY,
  LIGHT_WARNING,
  PRIMARY,
  ROW_HIGHLIGHTER_COLOR,
  WARNING,
} from './constants/Colors';
import {
  APPOINTMENT_CONFIRMED,
  APPROVED,
  ASSETS_STATUS_LABEL,
  BAR_CHART,
  CHART_COLOR,
  CHART_CONST,
  CLOSED_STATUS,
  COMPLETED,
  COMPLIANCE_STATUS,
  COUNTRY_COLOR,
  CREATED,
  CRITICAL_STATUS,
  CUSTOMER_COMPLIANCE_STATUS,
  CUSTOMER_DEFECT_QUOTE_LIST,
  CUSTOMER_QUOTATION_STATUS,
  DATE_CONVERT_UTC_FORMAT_API,
  DATE_FORMAT,
  DATE_FORMAT_API,
  DATE_FORMAT_API_WITH_TIME,
  FAIL_STATUS,
  INSPECTION_FORM,
  IN_PROGRESS,
  IN_PROGRESS_STATUS,
  IN_REVIEW,
  LABELS,
  LINE_CHART,
  NON_COMPLIANCE_STATUS,
  NON_CONFORMANCE_STATUS,
  NON_CRITICAL_STATUS,
  NO_CHECK_STATUS,
  OPEN_STATUS,
  PASS_STATUS,
  PENDING,
  PIE_CHART,
  PURCHASE_ORDER_STATUS,
  REJECTED_STATUS,
  RESOLVED,
  RE_VISIT,
  SCHEDULED,
  STATUS_BASED_COLOR,
  TWELEVE_MONTHS,
  redirectURL,
} from './constants/Constants';

export const loggedInUserRole = () => {
  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  return user?.role_name;
};

export const loggedInUserDetail = () => {
  const user_info = localStorage.getItem('user_info');
  const user = user_info ? JSON.parse(user_info) : null;

  return user;
};

export const getRandomColor = () => {
  const color = `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padStart(6, '0')}`;

  return color;
};

/**
 * Format date to be displayed in the UI
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment(date).format(defaultFormat);
};

/**
 * Format date to be sent to API
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDateForAPI = (date, defaultFormat = DATE_FORMAT_API) => {
  if (!date) return null;

  return moment(date).format(defaultFormat); // Convert UTC to local and format it
};

/**
 * Format date to be displayed in the UI
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formattedDate = (date, defaultFormat = DATE_FORMAT) => {
  if (!date) return null;

  return moment.utc(date).local().format(defaultFormat); // Convert UTC to local and format it
};

/**
 * Format date to be sent to API
 * @param {string | Date} receivedDate - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API_WITH_TIME] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const filterDateFormatted = (
  receivedDate,
  defaultFormat = DATE_FORMAT_API_WITH_TIME
) => {
  const date = new Date(receivedDate);
  const newDate = moment(date).utc().format(defaultFormat);

  return newDate;
};

export const datePickerFormat = (receivedDate) =>
  moment.utc(receivedDate).local().toDate();

/**
 * Format date to be sent to API
 * @param {string | Date} date - date in string or Date format
 * @param {string} [defaultFormat=DATE_FORMAT_API_WITH_TIME] - desired date format
 * @returns {string} - formatted date in the desired format
 */
export const formatDateAPI = (
  date,
  defaultFormat = DATE_FORMAT_API_WITH_TIME,
  defaultUTCFormat = DATE_CONVERT_UTC_FORMAT_API
) => {
  if (!date) return null;

  return moment(date, defaultUTCFormat).utc().format(defaultFormat);
};

export const debounce = (func, wait) => {
  let timeout;

  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

export function getUniqueFrequencies(frequencyList) {
  const uniqueFrequencies = {};

  frequencyList.forEach((freq) => {
    uniqueFrequencies[freq.code] = freq;
  });

  return Object.values(uniqueFrequencies);
}

export const clearTextfields = (setFun, name) => setFun(name, '');

export const getWidgetChartData = (chartName, chartType, data) => {
  switch (chartType) {
    case PIE_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        const resultantData = uniqBy(data, (o) => o?.name?.trim().toLowerCase())
          ?.map((d) => ({
            label: capitalizeFirstLetter(d?.display_name),
            value: d?.count,
            color: CHART_COLOR[d?.name?.toLowerCase()],
            id: d?.name?.toLowerCase(),
          }))
          ?.filter((d) => d.value !== 0);

        return {
          data: resultantData,
        };
      }
      if (chartName === CHART_CONST.PROPERTY_COMPLIANCE) {
        const resultantData = uniqBy(data, (o) =>
          o?.compliance_status?.trim().toLowerCase()
        )
          ?.map((d) => ({
            label: CUSTOMER_COMPLIANCE_STATUS?.find((c) =>
              d?.compliance_status == null
                ? c?.value === LABELS.NA
                : c.value === d?.compliance_status
            )?.label,
            value: d?.count,
            color:
              CHART_COLOR[
                d?.compliance_status == null
                  ? 'not_applicable'
                  : d?.compliance_status?.toLowerCase()
              ],
            id:
              d?.compliance_status == null
                ? LABELS.NA
                : d?.compliance_status?.toLowerCase(),
          }))
          ?.filter((d) => d.value !== 0);

        return {
          data: resultantData,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        return {
          data: uniqBy(data, (o) => o?.severity?.trim().toLowerCase())
            ?.map((d) => ({
              label: capitalizeFirstLetterRemoveUnderscore(d?.severity)
                ?.split(' ')
                ?.join('-'),
              value: d?.count,
              color: CHART_COLOR[d?.severity?.toLowerCase()],
              id: d?.severity?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }

      if (chartName === CHART_CONST.QUOTES) {
        return {
          data: uniqBy(data, (o) => o?.status?.trim().toLowerCase())
            ?.map((d) => ({
              label: CUSTOMER_DEFECT_QUOTE_LIST?.find(
                (c) => c.value === d?.status
              )?.label,
              value: d?.count,
              color: CHART_COLOR[d?.status?.toLowerCase()],
              id: d?.status?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }

      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        return {
          data: uniqBy(data, (o) => o?.status?.trim().toLowerCase())
            ?.map((d) => ({
              label: capitalizeFirstLetter(d?.status),
              value: d?.count,
              color: CHART_COLOR[d?.status?.toLowerCase()],
              id: d?.status?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }
      if (chartName === CHART_CONST.INVOICES) {
        const invoiceData = uniqBy(data, (o) =>
          o?.status?.trim().toLowerCase()
        )?.map((d) => ({
          label: capitalizeFirstLetter(d?.status),
          value: d?.count,
          color: CHART_COLOR[d?.status?.toLowerCase()],
          id: d?.status?.toLowerCase(),
        }));

        return {
          data: invoiceData?.filter((v) => v.value !== 0),
        };
      }
      if (
        chartName === CHART_CONST.CUSTOMER_PROPERTIES ||
        chartName === CHART_CONST.FIRE_COMPANY ||
        chartName === CHART_CONST.USERS
      ) {
        return {
          data: data
            ?.map((d, index) => ({
              label: d?.state || '',
              value: d?.count,
              color: COUNTRY_COLOR[index],
              id: d?.state?.toLowerCase(),
            }))
            ?.filter((d) => d.value !== 0),
        };
      }
      break;
    }

    case BAR_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.name]);
          labels?.push(d?.display_name);
          count.push(d?.count);
          ids?.push(d?.name?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }

      if (chartName === CHART_CONST.PROPERTY_COMPLIANCE) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(
            CHART_COLOR[
              d?.compliance_status == null
                ? 'not_applicable'
                : d?.compliance_status?.toLowerCase()
            ]
          );
          labels?.push(
            CUSTOMER_COMPLIANCE_STATUS?.find((c) =>
              d?.compliance_status == null
                ? c?.value === LABELS.NA
                : c.value === d?.compliance_status
            )?.label
          );
          count.push(d?.count);
          ids?.push(
            d?.compliance_status == null
              ? LABELS.NA
              : d?.compliance_status?.toLowerCase()
          );
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.severity?.toLowerCase()]);
          labels?.push(
            capitalizeFirstLetterRemoveUnderscore(d?.severity)
              ?.split(' ')
              ?.join('-')
          );
          count.push(d?.count);
          ids?.push(d?.severity?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      if (chartName === CHART_CONST.QUOTES) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status]);
          labels?.push(
            CUSTOMER_DEFECT_QUOTE_LIST?.find((c) => c.value === d?.status)
              ?.label
          );
          count.push(d?.count);
          ids?.push(d?.status?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }

      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status.toLowerCase()]);
          labels?.push(capitalizeFirstLetter(d?.status));
          count.push(d?.count);
          ids?.push(d?.status?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }

      if (chartName === CHART_CONST.INVOICES) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d) => {
          colors?.push(CHART_COLOR[d?.status.toLowerCase()]);
          labels?.push(capitalizeFirstLetter(d?.status));
          count.push(d?.count);
          ids?.push(d?.status?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      if (
        chartName === CHART_CONST.CUSTOMER_PROPERTIES ||
        chartName === CHART_CONST.FIRE_COMPANY ||
        chartName === CHART_CONST.USERS
      ) {
        let colors = [];
        let labels = [];
        let count = [];
        let ids = [];

        data?.forEach((d, index) => {
          colors?.push(COUNTRY_COLOR[index]);
          labels?.push(d?.state);
          count.push(d?.count);
          ids?.push(d?.state?.toLowerCase());
        });

        return {
          colors: colors,
          data: count,
          labels: labels,
          ids: ids,
        };
      }
      break;
    }

    case LINE_CHART: {
      if (chartName === CHART_CONST.PROPERTIES) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.display_name);
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.display_name,
            color: CHART_COLOR[d?.name],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.DEFECTS) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(capitalizeFirstLetterRemoveUnderscore(d?.severity));
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.severity,
            color: CHART_COLOR[d?.severity?.toLowerCase()],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.QUOTES) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(
            d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' ')
          );
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.status
              ?.split('_')
              ?.map((s) => s.charAt(0).toUpperCase() + s.slice(1))
              .join(' '),
            color: CHART_COLOR[d?.status],
            data: count,
          })),
          labels: count,
        };
      }
      if (chartName === CHART_CONST.PURCHASE_ORDER) {
        let labels = [];
        let count = [];

        data?.forEach((d) => {
          labels?.push(d?.status[0] + d?.status?.substr(1)?.toLowerCase());
          count.push(d?.count);
        });

        return {
          data: data?.map((d) => ({
            label: d?.status[0] + d?.status?.substr(1)?.toLowerCase(),
            color: CHART_COLOR[d?.status.toLowerCase()],
            data: count,
          })),
          labels: count,
        };
      }
      break;
    }

    default:
      break;
  }
};

const chartClickHandler = ({ role, chartName, clickDataStatus }) => {
  const redirectionUrl = redirectURL?.[role]?.[chartName];

  return redirectionUrl ? `${redirectionUrl}?status=${clickDataStatus}` : false;
};

export const displayChart = (item) => {
  const data = item.widget_data[item?.type];

  switch (item?.type) {
    case PIE_CHART:
      return (
        <Piechart
          data={data?.data}
          isRadius={item?.is_radius}
          labelType={item?.label_type}
          onChartClick={(dataIndex) =>
            chartClickHandler({
              role: item?.role_name,
              chartName: item?.chart_name,
              clickDataStatus: data?.data?.[dataIndex]?.id,
            })
          }
        />
      );
    case BAR_CHART:
      return (
        <BasicBarChart
          data={data?.data}
          xLabels={data?.labels}
          colors={data?.colors}
          onChartClick={(dataIndex) =>
            chartClickHandler({
              role: item?.role_name,
              chartName: item?.chart_name,
              clickDataStatus: data?.ids?.[dataIndex],
            })
          }
        />
      );
    case LINE_CHART:
      return <LineChart data={data?.data} xLabels={data?.labels} />;

    // case GAUGE_CHART:
    //   break;
    // case SPARKLINE_CHART:
    //   break;
    // case SCATTER_CHART:
    //   break;
    default:
  }
};

export const isCreatedWithinLastFiveMinutes = (createdAt) => {
  if (!createdAt) return false;
  const nowUTC = moment.utc();
  const createdAtDate = moment.utc(createdAt);

  const diffInMinutes = nowUTC.diff(createdAtDate, 'minutes');

  return diffInMinutes <= 5;
};

export const findLabelByValue = (list, value) => {
  const item = list.find((option) => option.value === value);

  return item ? { label: item.label, value: item.value } : null;
};

export const findLabelByLabel = (list, label) => {
  const item = list.find((option) => option.label === label);

  return item ? { label: item.label, value: item.value } : null;
};

export const addSpaceAndCapitalizeString = (value) =>
  value
    ?.split('-')
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    ?.join(' ');

export const capitalizeFirstLetter = (value) =>
  value
    ?.split('-')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const capitalizeFirstLetterRemoveUnderscore = (value) =>
  value
    ?.split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');

export const formatStatus = (status, height = '12px', width = '12px') => {
  const lowerCaseStatus =
    typeof status === 'string' ? status?.toLowerCase() : status;

  if (status === ASSETS_STATUS_LABEL.fail || lowerCaseStatus === FAIL_STATUS) {
    return <StatusLabel label={ASSETS_STATUS_LABEL.fail} color="#C54036" />;
  }
  if (status === LABELS?.RE_VISIT || lowerCaseStatus === RE_VISIT) {
    return (
      <StatusLabel
        label={LABELS.RE_VISIT}
        color={STATUS_BASED_COLOR[RE_VISIT]}
      />
    );
  }
  if (status === LABELS?.IN_REVIEW || lowerCaseStatus === IN_REVIEW) {
    return (
      <StatusLabel
        label={LABELS.IN_REVIEW}
        color={STATUS_BASED_COLOR[IN_REVIEW]}
      />
    );
  }
  if (status === ASSETS_STATUS_LABEL.pass || lowerCaseStatus === PASS_STATUS) {
    return <StatusLabel label={ASSETS_STATUS_LABEL.pass} color="#95C020" />;
  }
  if (status === APPROVED || lowerCaseStatus === APPROVED) {
    return <StatusLabel label="Approved" color="#95C020" />;
  }
  if (status === REJECTED_STATUS || lowerCaseStatus === REJECTED_STATUS) {
    return <StatusLabel label="Rejected" color="#C54036" />;
  }
  if (
    status === ASSETS_STATUS_LABEL.noCheck ||
    lowerCaseStatus === NO_CHECK_STATUS
  ) {
    return <StatusLabel label={ASSETS_STATUS_LABEL.noCheck} color="#59366B" />;
  }

  if (lowerCaseStatus === true) {
    return <StatusLabel label="Yes" color="#59C3C3" />;
  }
  if (lowerCaseStatus === false) {
    return <StatusLabel label="No" color="#454545" />;
  }

  if (
    lowerCaseStatus === CUSTOMER_QUOTATION_STATUS?.PENDING?.toLocaleLowerCase()
  ) {
    return (
      <StatusLabel label="Pending" color={CHART_COLOR.submit_to_customer} />
    );
  }

  if (lowerCaseStatus === CRITICAL_STATUS) {
    return (
      <StatusLabel
        label="Critical"
        color="#C04B20"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === NON_CONFORMANCE_STATUS) {
    return (
      <StatusLabel
        label="Non-Conformance"
        color="#7A4E8C"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === NON_CRITICAL_STATUS) {
    return (
      <StatusLabel
        label="Non-Critical"
        color="#95C020"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === OPEN_STATUS) {
    return <StatusLabel label="Open" color="#FF0000" />;
  }
  if (lowerCaseStatus === 'close') {
    return <StatusLabel label="Close" color="#95C020" />;
  }
  if (lowerCaseStatus === CLOSED_STATUS) {
    return <StatusLabel label="Closed" color="#95C020" />;
  }
  if (lowerCaseStatus === IN_PROGRESS_STATUS) {
    return <StatusLabel label="In Progress" color="#2196F3" />;
  }

  // Work Order list status start
  if (status === CREATED || status === CREATED.toLowerCase()) {
    return (
      <StatusLabel
        label={CREATED}
        color="#454545"
        height={height}
        width={width}
      />
    );
  }
  if (status === SCHEDULED || status === SCHEDULED.toLowerCase()) {
    return (
      <StatusLabel
        label={SCHEDULED}
        color="#59C3C3"
        height={height}
        width={width}
      />
    );
  }
  if (
    status === APPOINTMENT_CONFIRMED ||
    status === APPOINTMENT_CONFIRMED.toLowerCase()
  ) {
    return (
      <StatusLabel
        label={APPOINTMENT_CONFIRMED}
        color="#2CCDA1"
        height={height}
        width={width}
      />
    );
  }
  if (status === IN_PROGRESS || status === IN_PROGRESS.toLowerCase()) {
    return (
      <StatusLabel
        label={IN_PROGRESS}
        color="#F7A142"
        height={height}
        width={width}
      />
    );
  }
  if (status === COMPLETED || status === COMPLETED.toLowerCase()) {
    return (
      <StatusLabel
        label={COMPLETED}
        color="#95C020"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === RESOLVED) {
    return (
      <StatusLabel
        label="Resolved"
        color={PRIMARY}
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === PENDING) {
    return (
      <StatusLabel
        label="Pending"
        color="#FD7E14"
        height={height}
        width={width}
      />
    );
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DELETED) {
    return <StatusLabel label="Deleted" color="#C54036" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.SUBMITTED) {
    return <StatusLabel label="Submitted" color="#95C020" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.DRAFT) {
    return <StatusLabel label="Draft" color="#999999" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.CREATED) {
    return <StatusLabel label="Created" color="#59C3C3" />;
  }
  if (lowerCaseStatus === PURCHASE_ORDER_STATUS.PAID) {
    return <StatusLabel label="Paid" color="#395B50" />;
  }
  // Work Order list status end
};

export const formatChipStatus = (status) => {
  const lowerCase = status?.toLowerCase();

  if (lowerCase === RESOLVED) {
    return (
      <ChipStatusLabel
        label={LABELS.CLOSED}
        bgColor={ROW_HIGHLIGHTER_COLOR}
        color={PRIMARY}
      />
    );
  }
  if (lowerCase === PENDING || lowerCase === OPEN_STATUS) {
    return (
      <ChipStatusLabel
        label={LABELS.OPEN}
        bgColor={LIGHT_SKY}
        color={DARK_SKY}
      />
    );
  }

  if (lowerCase === COMPLIANCE_STATUS) {
    return (
      <ChipStatusLabel
        label={LABELS.COMPLIANCE_STATUS}
        bgColor={ROW_HIGHLIGHTER_COLOR}
        color={PRIMARY}
      />
    );
  }
  if (lowerCase === NON_COMPLIANCE_STATUS) {
    return (
      <ChipStatusLabel
        label={LABELS.NON_COMPLIANCE_STATUS}
        bgColor={LIGHT_WARNING}
        color={WARNING}
      />
    );
  }
};

export const formatPriceWithDecimalValue = (price) => {
  if (price == null || isNaN(price)) return '';
  if (price == Math.floor(price)) return price;
  const numPrice = parseFloat(price);

  return numPrice.toFixed(2);
};

export const extractInitials = (str) => {
  // Split the string by spaces
  let parts = str?.split(' ');

  if (parts?.length > 0) {
    if (parts.length === 1) {
      return parts[0][0];
    } else {
      return parts[0][0] + (parts.length > 1 ? parts[1][0] : '');
    }
  } else {
    return '-';
  }
};

export const getAmountByQtyAndPrice = (data) =>
  data?.reduce((total, item) => {
    const amount = item.qty * Number(item.price);

    return total + amount;
  }, 0);

export const getXAxisLabel = (totalMonth, isYear = false) => {
  const currentMonth = moment().month();
  const currentYear = moment().year();
  const axisLabel = [...Array(totalMonth)].map((_, index) =>
    currentMonth < index
      ? `${TWELEVE_MONTHS[index]} ${isYear ? currentYear - 1 : ''}`
      : `${TWELEVE_MONTHS[currentMonth - index]} ${isYear ? currentYear : ''}`
  );

  return axisLabel?.reverse();
};

export const getMergedArray = (array1, array2) =>
  array1.map((obj) => {
    const match = array2.find((item) => item.id === obj.id);

    return match ? { ...obj, ...match } : obj; // Override matching objects
  });

export const searchFromArray = (searchVal, array) => {
  if (searchVal) {
    return array?.filter((a) =>
      a?.name?.toLocaleLowerCase()?.includes(searchVal?.toLowerCase())
    );
  }

  return array;
};

export function transformInspectionForm(obj) {
  // Helper function to extract radio and comment values from elements
  const extractElementValues = (elements) => {
    let radioValue = '';
    let resultValue = '';
    let commentValue = '';

    elements?.forEach((element) => {
      if (element?.type === INSPECTION_FORM.RADIO_GROUP) {
        radioValue = element?.value || '';
      }
      if (element?.type === INSPECTION_FORM.COMMENT) {
        if (element?.title === INSPECTION_FORM.RESULT) {
          resultValue = element?.value?.trim() || '';
        } else if (element?.title === INSPECTION_FORM.COMMENT_CAPITAL) {
          commentValue = element?.value?.trim() || '';
        }
      }
    });

    return { radioValue, resultValue, commentValue };
  };

  // Recursive function to process panels and their nested elements
  const processPanel = (panel) => {
    const { radioValue, resultValue, commentValue } = extractElementValues(
      panel?.elements || []
    );

    const parentPanel = {
      title: panel?.title || '',
      description: panel?.description?.trim() || '',
      radio_checked: radioValue,
      result: resultValue,
      comment: commentValue,
    };

    // Process nested panels
    const nestedPanels = (panel.elements || [])
      .filter((el) => el?.type === INSPECTION_FORM.PANEL)
      .flatMap(processPanel);

    return [parentPanel, ...nestedPanels];
  };

  // Main transformation logic
  const processInspectionForm = (data) => {
    const pages = data?.inspection_form_json?.pages || [];
    const result = [];

    pages.forEach((page) => {
      page?.elements?.forEach((element) => {
        if (element.type === 'panel') {
          const processedPanels = processPanel(element);

          result.push(...processedPanels);
        }
      });
    });

    return result.flat();
  };

  try {
    return processInspectionForm(obj);
  } catch (error) {
    return [];
  }
}

export const transformToTitleCase = (title) =>
  title
    ?.split(' ')
    ?.map((val) => val?.charAt(0)?.toUpperCase() + val?.slice(1))
    ?.join(' ');

export const getMomentDateTime = ({
  format = 'YYYY-MM-DD HH:mm:ss',
  months = 6,
  monthYear = 'months',
  startDateKey = 'start_date',
  endDateKey = 'end_date',
  dateType = 'formated',
} = {}) => {
  switch (dateType) {
    case 'formated':
      return {
        [startDateKey]: moment()
          .clone()
          .subtract(months, monthYear)
          .startOf('day')
          .format(format),
        [endDateKey]: moment().clone().endOf('day').format(format),
      };
    case 'toDate':
      return {
        [startDateKey]: moment()
          .clone()
          .subtract(months, monthYear)
          .startOf('day')
          .toDate(),
        [endDateKey]: moment().clone().endOf('day').toDate(),
      };
  }
};
