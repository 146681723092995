import React from 'react';

import { Skeleton } from '@mui/material';

import { DIVIDER_COLOR, PRIMARY, SECONDARY } from '../../constants/Colors';
import CustomButton from './CustomButton';

export const CustomBadgeButton = ({
  text = '',
  variant = 'outlined',
  filterName,
  onClick = () => {},
  endIcon,
  fullWidth = false,
  startIcon,
  isLoading = false,
  ...rest
}) =>
  isLoading ? (
    <Skeleton
      variant="rectangular"
      width="80px"
      height={24}
      sx={{
        borderRadius: '24px',
      }}
    />
  ) : (
    <CustomButton
      text={text}
      variant={variant}
      startIcon={startIcon}
      endIcon={endIcon}
      onClick={onClick}
      sx={{
        padding: '0px 16px',
        color: !filterName ? PRIMARY : 'black',
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        border: `1px solid ${!filterName ? PRIMARY : 'black'}`,
        borderRadius: '24px',
        backgroundColor: filterName ? DIVIDER_COLOR : SECONDARY,
        maxWidth: !filterName || fullWidth ? 'auto' : '100px',
        whiteSpace: 'nowrap', // Prevents text from wrapping
        overflow: 'hidden', // Hides overflow content
        textOverflow: 'ellipsis', // Displays ellipsis for overflowing text
        '&:hover': {
          backgroundColor: filterName ? DIVIDER_COLOR : SECONDARY,
          color: !filterName ? PRIMARY : 'black',
          border: `1px solid ${!filterName ? PRIMARY : 'black'}`,
        },
      }}
      {...rest}
    />
  );
