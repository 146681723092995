import React from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { Box, styled, Typography } from '@mui/material';

import { PRIMARY } from '../../constants/Colors';
import CustomCircularLoader from './CustomLoader';

const CustomCard = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderWidth: '1px',
  borderStyle: 'solid',
  borderColor: '#0000002b',
  borderRadius: '4px',
  padding: '16px',
  height: '72px',
}));

const UploadedDocumentView = ({
  fileName,
  fileSize,
  isUploading,
  onDelete,
  isUploadStatusRequired = true,
  ...rest
}) => (
  <CustomCard {...rest}>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      <UploadFileIcon sx={{ color: PRIMARY, height: '28px', width: '28px' }} />
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2">{fileName}</Typography>
        <Typography variant="body2" sx={{ color: '#0000006e' }}>
          {fileSize && `${fileSize}`}
          {isUploadStatusRequired &&
            (isUploading
              ? ' . Loading'
              : fileSize
                ? ' . Uploaded'
                : 'Uploaded')}
        </Typography>
      </Box>
    </Box>
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
      {onDelete && (
        <DeleteIcon sx={{ color: '#0000008a' }} onClick={onDelete} />
      )}
      {isUploading && (
        <CustomCircularLoader
          sx={{ height: '32px !important', width: '32px !important' }}
        />
      )}
    </Box>
  </CustomCard>
);

export default UploadedDocumentView;
