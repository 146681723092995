import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useOutletContext } from 'react-router-dom';

import { Box } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { uniqueId } from 'lodash';
import moment from 'moment';

import { DIVIDER_COLOR, STATUS_COLORS } from '../../constants/Colors';
import {
  DASHBOARD,
  MONTHS_DROPDOWN,
  OVERDUE,
  PAID,
  PENDING,
  SIX_MONTHS,
} from '../../constants/Constants';
import { ROUTENAME } from '../../constants/RoutesConstants';
import {
  getInvoiceByAmount,
  getInvoiceByAmountChart,
  getInvoiceByStatus,
  getInvoiceByStatusChart,
} from '../../store/invoices/api';
import { formatPriceWithDecimalValue, getXAxisLabel } from '../../utils';
import CustomLineChart from '../Chart/LineChart/CustomLineChart';
import StackedBarChart from '../Chart/StackedBarChart/StackedBarChart';
import CustomCircularLoader from '../CommonComponents/CustomLoader';
import DashboardCard from '../CommonComponents/DashboardCard';
import ChartCard from '../Dashboard/ChartCard';

const defaultRangeData = {
  invoiceStatusRange: MONTHS_DROPDOWN[0],
  invoiceAmountRange: MONTHS_DROPDOWN[0],
};

const InvoiceDashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { dashboardFilter, refresh } = useOutletContext();

  const quoteInvoiceCardData = [
    {
      title: `${t('common.status.pending')} ${t('attributes.invoice.invoice')}`,
      status: PENDING,
      label: t('common.status.pending'),
      click: true,
      value: 0,
      order: 1,
    },
    {
      title: `${t('attributes.invoice.paid')} ${t('attributes.invoice.invoice')}`,
      status: PAID,
      click: true,
      value: 0,
      order: 2,
      label: t('attributes.invoice.paid'),
    },
    {
      title: `${t('common.status.overdue')} ${t('attributes.invoice.invoice')}`,
      status: OVERDUE,
      value: 0,
      click: true,
      order: 5,
      label: t('attributes.invoice.overdue'),
    },
  ];

  const quoteAmountCardData = [
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.due')}`,
      value: 0,
      status: PENDING,
      click: false,
      order: 3,
    },
    {
      title: `${t('attributes.total')} ${t('attributes.amount')} ${t('attributes.invoice.paid')}`,
      value: 0,
      click: false,
      status: PAID,
      order: 4,
    },
  ];

  const [ranges, setRanges] = useState(defaultRangeData);
  const [isLoading, setIsLoading] = useState(false);

  const [chartLoading, setChartLoading] = useState({
    status: false,
    amount: false,
  });

  const [xAxisLabel, setXaxisLabel] = useState({
    status: SIX_MONTHS,
    amount: SIX_MONTHS,
  });

  const [amountData, setAmountData] = useState([]);
  const [statusData, setStatusData] = useState([]);

  const [cardData, setCardData] = useState({
    status: quoteInvoiceCardData,
    amount: quoteAmountCardData,
  });

  const quoteCardData = [...cardData.status, ...cardData.amount].sort(
    (a, b) => a.order - b.order
  );

  const colors = [STATUS_COLORS.PENDING, STATUS_COLORS.PAID];

  const handleCardClick = (status) => {
    if (status !== DASHBOARD) {
      const formattedStatus = status.toLowerCase().replaceAll(' ', '-');

      navigate(
        `/${ROUTENAME.DASHBOARD}/${ROUTENAME.INVOICES}/${formattedStatus}`
      );
    } else {
      navigate(`/${ROUTENAME.DASHBOARD}/${ROUTENAME.INVOICES}`);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const reqData = {
      start_date: dashboardFilter?.invoice_date?.startDate,
      end_date: dashboardFilter?.invoice_date?.endDate,
      propertyUUID: dashboardFilter?.property?.value,
    };

    dispatch(getInvoiceByAmount(reqData)).then((res) => {
      const latestAmountData = quoteAmountCardData?.map((qi) => {
        const matchedData = res?.payload?.data?.find(
          (s) => s.payment_status === qi.status
        );

        return {
          ...qi,
          value: matchedData?.total || 0,
        };
      });

      setCardData((prev) => ({ ...prev, amount: latestAmountData }));
    });

    dispatch(getInvoiceByStatus(reqData)).then((res) => {
      const latestStatusData = quoteInvoiceCardData?.map((qi) => {
        const matchedData = res?.payload?.data?.find(
          (s) => s.invoice_status === qi.status
        );

        return { ...qi, value: matchedData?.total || 0 };
      });

      setCardData((prev) => ({ ...prev, status: latestStatusData }));
      setIsLoading(false);
    });
  }, [dashboardFilter, refresh]);

  // Status Chart
  useEffect(() => {
    setXaxisLabel((pre) => ({
      ...pre,
      status: getXAxisLabel(ranges.invoiceStatusRange?.value),
    }));
    setChartLoading((pre) => ({ ...pre, status: true }));

    dispatch(
      getInvoiceByStatusChart({
        start_date: moment().subtract(
          ranges.invoiceStatusRange?.value,
          'months'
        ),
        end_date: moment(),
      })
    )
      .then((res) => {
        let pendingStatusData = [];
        let paidStatusData = [];

        for (let index = 0; index < ranges.invoiceStatusRange?.value; index++) {
          // Pending Data
          pendingStatusData.push(
            res?.payload?.data?.find(
              (d) =>
                d.month === moment().month() - index + 1 &&
                d?.payment_status.toLowerCase() === PENDING
            )?.total || 0
          );
          // Paid Data
          paidStatusData.push(
            res?.payload?.data?.find(
              (d) =>
                d.month === moment().month() - index + 1 &&
                d?.payment_status.toLowerCase() === PAID
            )?.total || 0
          );
        }

        const finalData = [
          {
            data: pendingStatusData.reverse(),
            label: t('common.status.pending'),
            id: PENDING,
            stack: 'total',
          },
          {
            data: paidStatusData.reverse(),
            label: t('common.status.paid'),
            id: PAID,
            stack: 'total',
          },
        ];

        setStatusData(finalData);
      })
      .finally(() => setChartLoading((pre) => ({ ...pre, status: false })));
  }, [ranges.invoiceStatusRange, refresh]);

  // Invoice Amount Chart
  useEffect(() => {
    setXaxisLabel((pre) => ({
      ...pre,
      amount: getXAxisLabel(ranges.invoiceAmountRange?.value),
    }));
    setChartLoading((pre) => ({ ...pre, amount: true }));
    dispatch(
      getInvoiceByAmountChart({
        start_date: moment().subtract(
          ranges.invoiceAmountRange?.value,
          'months'
        ),
        end_date: moment(),
      })
    )
      .then((res) => {
        let amountData = [];

        for (let index = 0; index < ranges.invoiceAmountRange?.value; index++) {
          amountData.push(
            res?.payload?.data?.find(
              (d) => d.month === moment().month() - index + 1
            )?.total || 0
          );
        }

        setAmountData(amountData?.reverse());
      })
      .finally(() => setChartLoading((pre) => ({ ...pre, amount: false })));
  }, [ranges?.invoiceAmountRange, refresh]);

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR }}>
      <Box sx={{ width: '100%', padding: '16px 0 30px 0px' }}>
        <Grid container spacing={2}>
          {quoteCardData?.map((item) => (
            <Grid xs={12} sm={6} lg={3} key={uniqueId('dashboardCard')}>
              <DashboardCard
                title={item.title}
                value={formatPriceWithDecimalValue(item.value)}
                click={item.click}
                onClick={() => handleCardClick(item.label)}
                isLoading={isLoading}
              />
            </Grid>
          ))}
        </Grid>
      </Box>

      <Box sx={{ display: 'flex', gap: '16px' }}>
        {/* Invoice Status Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={`${t('attributes.invoice.invoice')} ${t('attributes.invoice.byStatus')}`}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.invoiceStatusRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                invoiceStatusRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            {chartLoading?.status ? (
              <CustomCircularLoader />
            ) : (
              <StackedBarChart
                colors={colors}
                data={statusData}
                xLabels={xAxisLabel?.status}
                legendPosition={{ vertical: 'top', horizontal: 'center' }}
                isAmount={true}
              />
            )}
          </ChartCard>
        </Box>

        {/* Invoice Amount Chart */}
        <Box sx={{ flex: 1 }}>
          <ChartCard
            title={`${t('attributes.invoice.invoice')} ${t('attributes.amount')}`}
            options={MONTHS_DROPDOWN}
            selectedType={ranges.invoiceAmountRange}
            isEditableChart={false}
            setSelectedType={(id, val) =>
              setRanges((prev) => ({
                ...prev,
                invoiceAmountRange: val,
              }))
            }
            height="257px"
            isSettingMode={true}
          >
            {chartLoading?.amount ? (
              <CustomCircularLoader />
            ) : (
              <CustomLineChart
                xAxisLabels={xAxisLabel?.amount}
                chartData={amountData}
                height={257}
                isAmount={true}
              />
            )}
          </ChartCard>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceDashboard;
