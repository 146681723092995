import { createAsyncThunk } from '@reduxjs/toolkit';

import { APIs } from '../../constants/APIConstants';
import { ZONES } from '../actionPrefix';
import { axiosPropertyUrl } from '../axios';

// Get Zones List API
export const getZonesDropdownList = createAsyncThunk(
  ZONES.GET_ZONES_DROPDOWN_LIST,
  async (req, { rejectWithValue }) => {
    const request = {
      ...req,
      post_code: 'all',
      unique_zone: true,
      unique_local_government_area: false,
    };

    try {
      const response = await axiosPropertyUrl.post(
        APIs.ZONES.GET_ZONES_DROPDOWN_LIST,
        request
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);
