import { Box } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import {
  GLOBAL_ADMIN,
  OFFICE_MANAGEMENT,
  OFFICE_STAFF,
  SUPER_ADMIN,
} from '../../constants/Roles';
import { getUserRole } from '../../hoc/Permission';
import GlobalAdminDashboard from './GlobalAdminDashboard';
import SuperAdminDashboard from './SuperAdminDashboard';

const Dashboard = () => {
  const userRole = getUserRole();

  return (
    <Box sx={{ bgcolor: DIVIDER_COLOR, height: '100%' }}>
      {userRole === GLOBAL_ADMIN && <GlobalAdminDashboard />}
      {(userRole === SUPER_ADMIN ||
        userRole === OFFICE_STAFF ||
        userRole === OFFICE_MANAGEMENT) && <SuperAdminDashboard />}
    </Box>
  );
};

export default Dashboard;
