import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import { DIVIDER_COLOR } from '../../constants/Colors';
import { View } from './ActionComponent';
import CustomButton from './CustomButton';
import CustomGridTable from './CustomGridTable';
import NoRecordFound from './NoDataPage/NoRecordFound';

const TemplatesTable = ({ onLoadCallback = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(7);
  const [perPageData, setPerPageData] = useState(10);

  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('template_name');

  const columns = useMemo(() => {
    const baseColumns = [
      {
        field: 'template_name',
        headerName: t('attributes.templates.template_name'),
        flex: 1,
        sortable: true,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '8px' }}>
            {row?.template_name}
            {row?.isDefault && (
              <Box
                sx={{
                  background: DIVIDER_COLOR,
                  padding: '4px 8px',
                  borderRadius: '4px',
                }}
              >
                <Typography variant="body1">{t('common.default')}</Typography>
              </Box>
            )}
          </Box>
        ),
      },
      {
        field: 'created_at',
        headerName: t('attributes.technician.created_at'),
        flex: 1,
        sortable: false,
      },
      {
        field: 'edit',
        headerName: t('attributes.actions'),
        flex: 0.5,
        sortable: false,
        renderCell: ({ row }) => (
          <Box sx={{ display: 'flex', columnGap: '8px' }}>
            <View
              onClick={() => {
                navigate(`${row?.uuid}`);
              }}
            />
            {!row?.isDefault && (
              <CustomButton
                variant="outlined"
                sx={{
                  '&.MuiButton-root': {
                    minWidth: '24px',
                    height: '24px',
                    padding: '5px',
                  },
                }}
              >
                <Typography variant="body2">
                  {t('common.set_as_default')}
                </Typography>
              </CustomButton>
            )}
          </Box>
        ),
      },
    ];

    return baseColumns;
  }, []);

  const rows = [
    {
      id: 1,
      template_name: 'Templates 1',
      created_at: '29/08/2024 09:00 AM',
      isDefault: true,
    },
    {
      id: 2,
      template_name: 'Templates 2',
      created_at: '29/08/2024 09:00 AM',
    },
    {
      id: 3,
      template_name: 'Templates 3',
      created_at: '29/08/2024 09:00 AM',
    },
    {
      id: 4,
      template_name: 'Templates 4',
      created_at: '29/08/2024 09:00 AM',
    },
    {
      id: 5,
      template_name: 'Templates 5',
      created_at: '29/08/2024 09:00 AM',
    },
    {
      id: 6,
      template_name: 'Templates 6',
      created_at: '29/08/2024 09:00 AM',
    },
    {
      id: 7,
      template_name: 'Templates 7',
      created_at: '29/08/2024 09:00 AM',
    },
  ];

  useEffect(() => {}, []);

  return (
    <CustomGridTable
      columns={columns}
      rows={rows}
      total={total}
      currentPage={currentPage}
      setCurrentPage={setCurrentPage}
      perPageData={perPageData}
      setPerPageData={setPerPageData}
      order={order}
      orderBy={orderBy}
      setOrder={setOrder}
      setOrderBy={setOrderBy}
      noData={<NoRecordFound />}
      isLoading={isLoading}
      checkboxSelection={false}
    />
  );
};

export default TemplatesTable;
